import styled from "styled-components";
import React from 'react';
import Button from '../../../style-guide/Button/Button';
import davidoff from '../../../../themes/davidoff';

export default styled(Button)`
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0;
  width: 45%;
  
  @media (min-width: 768px) {
    padding: 0 15px;
    width: auto;
  }
  
  ${props => props.pending ? `
    font-size: 0;
    line-height: 44px;
    
    @media (min-width: 768px) {
      font-size: 0;
      line-height: 68px;
      
      &:hover {
        background-color: ${davidoff.colors.brown};
      }
    }
  `: ``}
  
  ${props => props.subscribed ? `
    font-size: 0;
    line-height: 44px;
    
    @media (min-width: 768px) {
      font-size: 0;
      line-height: 68px;
      
      &:hover {
        background-color: ${davidoff.colors.brown};
      }
    }
  ` : ``}
`;
