import getNested from 'get-nested';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import root from 'window-or-global';
// components
import icons from '../../common/icons/icons';
//styled
import ImageCaption from '../../style-guide/Figcaption/Figcaption';
import {MuteBox, VideoWrapper} from './styled/VideoWrapper';
import VideoBlockWrapper from './styled/VideoBlockWrapper';
import { withCookies, Cookies } from "react-cookie";

class FullWidthVideo extends React.Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired
  };

  constructor (props) {
    super(props);

    this.state = {
      videoUrl: props.paragraph.field_video_link.uri,
      videoTracked: false,
      trackedProgress: false,
      muted: true,
      volume: 1
    };

    this.playVideo = this.playVideo.bind(this);
    this.trackProgress = this.trackProgress.bind(this);
    this.toggleMuteVideo = this.toggleMuteVideo.bind(this);
  }

  toggleMuteVideo () {
    const internalPlayer = this.player.getInternalPlayer();
    if(internalPlayer.mute){
      this.state.muted ? internalPlayer.unMute() : internalPlayer.mute();
    }else{
      this.state.muted ? internalPlayer.setVolume(1) : internalPlayer.setVolume(0);
    }
    this.setState({
      muted: !this.state.muted,
    });
  }

  playVideo () {
    if (root.dataLayer && !this.state.videoTracked) {
      root.dataLayer.push({
        event: 'Play video',
        category: 'Video',
        action: 'play',
        label: this.state.videoUrl,
        currentUrl: root.location.href,
      });

      this.setState({
        videoTracked: true,
      });
    }
  };

  trackProgress(progress) {
    if(progress.played >= 0.9 && !this.state.trackedProgress && root.dataLayer) {
      root.dataLayer.push({
        event: 'Video watched',
        category: 'Video',
        action: 'completed',
        label: this.state.videoUrl,
        currentUrl: root.location.href,
      });

      this.setState({
        trackedProgress: true,
      });
    }
  }

  ref = player => {
    this.player = player
  }

  render() {
    const { paragraph, cookies } = this.props;
    const autoPlay = paragraph.field_video_autoplay || false;
    const hideControls = paragraph.field_video_hide_controls || false;

    var host = 'https://www.youtube.com';

    if (cookies.get("DavidoffCookiePolicy") && cookies.get("DavidoffCookiePolicy") !== 'OPTIMAL') {
      var host = 'https://www.youtube-nocookie.com';
    }

    return (
      <VideoBlockWrapper className='video-element'>
        <VideoWrapper>
          <MuteBox onClick={this.toggleMuteVideo}>
            <label>Turn sound {this.state.muted ? `on` : `off`}</label>
            {this.state.muted ? icons.sound_off : icons.sound_on }
          </MuteBox>
          <ReactPlayer
            url={paragraph.field_video_link.uri}
            playing={autoPlay}
            width='100%'
            height='auto'
            muted={this.state.muted}
            volume={this.state.volume}
            onPlay={this.playVideo}
            controls={!hideControls}
            onProgress={this.trackProgress}
            onReady={() => {
              this.player.getInternalPlayer().setLoop(true);
            }}
            onEnded={() => {
              this.player.seekTo(0);
            }}
            ref={this.ref}
            config={
              {
                youtube: {
                  playerVars: {
                    wmode: 'transparent',
                    autoplay: 1,
                    mute: this.state.muted ? 1 : 0,
                    vq: 'hd1440',
                    controls: 0,
                    loop: 1,
                    modestbranding: 1,
                  },
                  embedOptions: {
                    host: host
                  }
                },
                vimeo: {
                  playerOptions: {
                    api: 1,
                    background: 1,
                    loop: 1,
                    autoplay: 1,
                    autopause: 1,
                  },
                },
                file: {
                  attributes: {
                    autoPlay: autoPlay,
                    controls: !hideControls,
                    muted: this.state.muted,
                    preload: 'auto',
                    loop: true,
                    playsInline: true,
                  },
                },
              }
            }
          />

        </VideoWrapper>
        {getNested(() => paragraph.field_video_link.title, false) &&
        <ImageCaption>
          {paragraph.field_video_link.title}
        </ImageCaption>
        }
      </VideoBlockWrapper>
    );
  }
}

FullWidthVideo.propTypes = {
  paragraph: PropTypes.shape({
    field_video_link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withCookies(FullWidthVideo);
