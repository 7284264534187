import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { observer, inject } from 'mobx-react';
import { SiteStore } from '../../../../stores/SiteStore';
import { TranslationStore } from '../../../../stores/TranslationStore';
import { Notification } from '../../Notification/component'

@inject((allStores) => ({
  siteStore: allStores.siteStore,
  translationStore: allStores.translationStore,
}))
@observer
export class LanguageSwitchNotification extends React.Component {
  render() {
    const { settings, siteStore, translationStore } = this.props;

    // Get notification text from the drupal settings
    if (!siteStore.displayNotification) {
      return null;
    }

    const notificationText = getNested(() => settings.general[siteStore.previousLanguage].disclaimer_message, false);

    if (!notificationText) {
      return null;
    }

    return (
      <Notification
        text={notificationText}
        buttonText={translationStore.translate('close', siteStore.previousLanguage)}
        showButton
        buttonAction={() => siteStore.updateDisplayNotification(siteStore.previousLanguage)}
      />
    );
  }
}

LanguageSwitchNotification.propTypes = {
  language: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  siteStore: PropTypes.instanceOf(SiteStore),
  tranlationStore: PropTypes.instanceOf(TranslationStore),
};

export default LanguageSwitchNotification;
