import styled from 'styled-components';

export const SplitContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 20px 6%;

  @media (min-width: 768px) {
    padding: 0;
    margin: 40px auto 80px auto;
    max-width: 1216px;
    flex-direction: row;
    width: calc(100% - 40px);
  }

  .split-content__column {
    flex-basis: calc(48.685%);
  }

  .split-content__column--left {
    margin-bottom: 0px;
  }

  @media (min-width: 768px) {
    .split-content__column--left {
      padding: 0 1.315%;
      margin-bottom: 0px;
    }

    .split-content__column--right {
      padding: 0 1.315%;
    }
  }

  .split-content__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 20px 6% 0;

    @media (min-width: 768px) {
      margin: 20px 0 20px auto;
      padding: 0;
      max-width: 800px;
      width: 82.5%;
      font-size: 24px;
      line-height: 29px;
    }
  }

  .split-content__column--left{
    p {
      margin: 20px 0 20px auto;
    }
  }
  .split-content__column--right{
    .split-content__title {
      margin: 20px 0;
    }

    p {
      margin: 20px 0;
    }
  }

  .split-content__column--left,
  .split-content__column--right {
    p {
      margin-bottom: 0;
      padding: 0 6% 20px;
      width: 82.5%;

      line-height: 26px;
      ${props => props.theme.fonts.parse('sansSerif', 'regular', '16px')}

      @media (min-width: 768px) {
        padding: 0;
        ${props => props.theme.fonts.parse('sansSerif', 'regular', '18px')}
        line-height: 32px;
      }
    }
  }
`;
