import React from 'react';
import styled from 'styled-components';
import MediaImageContainer from '../../../common/MediaImage/index.c';

export default styled(MediaImageContainer)`
  display: block;
  transition: opacity 450ms ease-in-out 300ms;
  
  //.has-video & {
  //  left: 0;
  //  position: absolute;
  //  top: 0;
  //  z-index: 1;
  //}
  
  ${props => props.videoPlaying ? `
    @media (min-width: 1024px) {
      opacity: 0;
    }
  ` : ``}
  
  img {
    display: block;
  }
`;
