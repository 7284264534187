import React from 'react';
import styled from 'styled-components';

export const DisclaimerText = styled.div`
  ${({theme}) => theme.fonts.parse('sansSerif', 'regular', '14px')}
  color: #fff;
  margin: 0;
  
  @media (min-width: 768px) {
    padding-right: 190px;
  }
`;
