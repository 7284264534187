import React from 'react';
import styled from 'styled-components';

export const Value = styled.span`
  border: 2px solid #8c7d70;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  height: 12px;
  line-height: 19px;
  margin: 0 2px;
  text-indent: -999px;
  width: 12px;
  
  ${(props) => props.activated ? `
    background-color: #8c7d70;
  ` : ''}
`;
