import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AgeGateWrapper from '../styled/AgeGateWrapper';
import AgeGateInner from '../styled/AgeGateInner';
import AgeGateForm from '../styled/AgeGateForm';
import IntroText from '../styled/IntroText';
import Button from '../../../style-guide/Button/Button';
import StyledSelect from '../../../style-guide/Select/Select';
import ValidationMessage from '../../../style-guide/ValidationMessage/ValidationMessage';
import { getBackgroundImagesByURL } from '../../../../misc/cloudinaryBackgrounds';

const backgroundSettings = {
  breakpoints: {
    mobile: {
      height: 700,
      width: 375,
    },
    desktop: {
      height: 700,
      width: 1024,
    },
    desktopXl: {
      height: 700,
      width: 1280,
    },
    desktopXxl: {
      height: 700,
      width: 2100,
    }
  },
};

const backgroundImages = getBackgroundImagesByURL({ url: 'https://res.cloudinary.com/zinodavidoff/image/upload/v1507220296/karsten-wurth-inf1783-97608_nsgq4u.jpg' }, backgroundSettings);
const AgeGate = inject('translationStore')(observer(({
   introText,
   selectOptions,
   selectUpdate,
   selectedCountry,
   buttonText,
   validateAge,
   dateFormat,
   submitForm,
   language,
   referenceInput,
   settings,
   translationStore,
   validation,
}) => {
  const validForm = validation.dataValidated && validation.dateValid && validation.countryValid;
  const validAge = validation.dataValidated && validation.dateValid;
  const validCountry = validation.dataValidated && validation.countryValid;

  /* TODO:
  - Check validationMessage after selecting value (validationMessage should disappear)
   */
  //karsten-wurth-inf1783-97608_nsgq4u

  return (
    <AgeGateWrapper backgrounds={backgroundImages}>
      <AgeGateInner>
        <IntroText>{introText}</IntroText>
        <AgeGateForm onSubmit={submitForm} valid={validForm}>
          <ValidationMessage
            show={validation.message}
          >
            {validation.message}
          </ValidationMessage>
          <StyledSelect>
            <Select
              name='country-selector'
              value={selectedCountry}
              placeholder={translationStore.translate('Select country')}
              options={selectOptions}
              searchable={true}
              onChange={selectUpdate}
              valid={validCountry}
            />
          </StyledSelect>
          <input
            ref={input => referenceInput(input)}
            data-valid={validation.dataValidated ? validAge : true}
            type='text'
            name='value'
            placeholder={translationStore.translate('DD - MM - YYYY')}
            onKeyUp={dateFormat}
            onBlur={validateAge}
          />
          <Button type='submit'>{buttonText}</Button>
        </AgeGateForm>
      </AgeGateInner>
    </AgeGateWrapper>
  );
}));

AgeGate.propTypes = {
  introText: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectUpdate: PropTypes.func.isRequired,
  validateAge: PropTypes.func.isRequired,
  dateFormat: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  validation: PropTypes.shape({
    dateValid: PropTypes.bool.isRequired,
    dataValidated: PropTypes.bool.isRequired,
  }).isRequired,
  referenceInput: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default AgeGate;
