import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';
import MenuListItem from './MenuListItem';

export default styled.span`
  display: inline-block;
  height: 40px;
  
  ${MenuListItem}:hover > & {
      @media only screen and (min-width: 1025px) {
          border-bottom: 1px solid ${davidoff.colors.orange};
      }
  }
`;
