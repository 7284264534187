import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';

export default styled.div`
  background: ${davidoff.colors.lime};
  margin: 0 -7%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms, padding 300ms;
  padding: 0 7%;
  
  @media (min-width: 768px) {
    margin: 0 -20px;
    padding: 0 20px;
    ${props => props.openFilter ? `padding-bottom: 25px;` : ``}
  }
  
  @media (min-width: 1024px){
    background: none;
    max-height: none;
    padding-top: 0;
    padding-bottom: 25px;
  }
  
  ${props => props.openFilter ? `
    max-height: 1500px;
    padding-top 15px;
    
    @media (min-width: 768px) {
      padding-top: 20px;
    }
    
    @media (min-width: 1024px) {
      padding-top: 0;
    }
  ` : ``}
`;
