import React from 'react';
import styled from 'styled-components';

export default styled.div`
  backface-visibility: hidden;
  height: 0;
  overflow: hidden;
  position: relative;
  padding-bottom: ${(500/800) * 100}%;
  width: 100%;
`;
