import React from 'react';
import getNested from 'get-nested';
import PropTypes from 'prop-types';
import { dataMapper } from '../../index';
import RelatedStories from '../RelatedStories/RelatedStories';

const RelatedArticlesView = ({page, data, title}) => {

  if (!page.computed.related_stories || page.computed.related_stories.length === 0) {
    return false;
  }

  const relatedArticles = (page.computed.related_stories).map((articleID) => dataMapper(data, articleID));

  return (
    <RelatedStories key='home-page-related-stories' articles={relatedArticles} data={data} title={title} />
  );
};

RelatedArticlesView.propTypes = {
  page: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  title: PropTypes.bool.isRequired,
};
RelatedArticlesView.defaultProps = {
  title: false,
};

export default RelatedArticlesView;
