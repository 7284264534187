import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import Input from '../../../style-guide/InputText/InputText';

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

const subscribed = keyframes`
  0% {
    background-color: #fff;
    border-color: #fff;
  }

  100% {
    background-color: ${davidoff.colors.brown};
    border-color: ${davidoff.colors.brown};
    color: ${davidoff.colors.brown};
  }
`;

const subscribedAnimation = css`${fpsToMs(8)}ms ease forwards ${subscribed}`;

const EmailInput = styled(Input)`
  -webkit-appearance: none;
  outline: none;
  width: 55%;
  
  ${props => props.subscribed ? css`
    animation: ${subscribedAnimation};
    user-select: none;
  ` : ``}
  
  ${props => props.error ? css`
    border-color: red;
    border-right-color: #fff;
    color: red;
  ` : ``}
  
`;

export default EmailInput;
