import React from 'react';
import PropTypes from 'prop-types';
import { calculateImageHeight } from '../../../misc/index';
// Components
import MediaImageContainer from '../../common/MediaImage/index.c'
//styled
import ImageCaption from '../../style-guide/Figcaption/Figcaption';
import ImageContainer from './styled/ImageContainer';
import ImageElement from './styled/ImageElement';

function getImageWidth(maxWidth, suggestedwidth) {
  return maxWidth > suggestedwidth ? suggestedwidth : maxWidth;
}

const Image = ({ paragraph, data }) => {
  const sizes = {
    column_width_image: {
      height: 450,
      width: 800,
      aspect_ratio: '16:9',
    },
    full_width_image: {
      height: 684,
      width: 1216,
      aspect_ratio: '16:9',
    },
    double_image: {
      height: 380,
      width: 592,
      aspect_ratio: '592:380',
    },
  };

  const imageSettings = {
    crop: 'fill',
    placeholder: '',
    gravity: 'auto',
  };

  const Images = paragraph.field_media_double.map(mediaReference => {
    const sources = [
      {
        settings: Object.assign({}, imageSettings, {
          aspect_ratio: sizes[paragraph.field_image_style].aspect_ratio,
          height: calculateImageHeight(sizes[paragraph.field_image_style], getImageWidth(sizes[paragraph.field_image_style].width, 1216)),
        }),
        media: '(min-width: 1024px)',
      },
      {
        settings: Object.assign({}, imageSettings, {
          aspect_ratio: sizes[paragraph.field_image_style].aspect_ratio,
          height: calculateImageHeight(sizes[paragraph.field_image_style], getImageWidth(sizes[paragraph.field_image_style].width, 1023)),
        }),
        media: '(max-width: 1023px)',
      },
      {
        settings: Object.assign({}, imageSettings, {
          aspect_ratio: sizes[paragraph.field_image_style].aspect_ratio,
          height: calculateImageHeight(sizes[paragraph.field_image_style], getImageWidth(sizes[paragraph.field_image_style].width, 375)),
        }),
        media: '(max-width: 767px)',
      },
    ];

    const settings = Object.assign({}, imageSettings, {
      aspect_ratio: sizes[paragraph.field_image_style].aspect_ratio,
      width: sizes[paragraph.field_image_style].width,
    });

    return (
      <ImageElement
        key={`image-${mediaReference.target_uuid}`}
        imageStyle={paragraph.field_image_style}
      >
        <MediaImageContainer
          imageType="ImageParagraph"
          data={data}
          mediaReference={mediaReference}
          settings={settings}
          sources={sources}
        >
          {({ mediaImage }) => mediaImage.field_media_image.title && <ImageCaption imageStyle={paragraph.field_image_style}>{mediaImage.field_media_image.title}</ImageCaption>}
        </MediaImageContainer>
        </ImageElement>
    );

  });

  return (
    <ImageContainer imageStyle={paragraph.field_image_style}>
      {Images}
    </ImageContainer>
  );
};

Image.propTypes = {
  paragraph: PropTypes.shape({
    field_media_double: PropTypes.arrayOf(PropTypes.shape()),
    field_image_style: PropTypes.string.isRequired,
  }).isRequired,
};

export default Image;
