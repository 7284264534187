/*
* This file contains some basic application configuration that is used
* for various lookup tables in the site.
* - webFonts: This contains the fonts used for certain lanugages
* - country: This contains a lookup table for countries and their langcodes
* - languages: This contains a list of languages we have translations for.
* - googleMaps: This contains a list of countries and their google maps locales
* - ageGate: This contains a lookup table for the age gate countries and the age of entry
*/
const cerillicFont = {
  fonts: [
    'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&amp;subset=cyrillic-ext',
    'https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&amp;subset=cyrillic-ext',
  ],
  loaded: false,
};

const config = {
  // Some languages have different characters that need other fonts since the original font doesn't support it.
  webFonts: {
    ru: cerillicFont,
    bg: cerillicFont,
  },
  // Lookup table for countries and their drupal languages.
  //
  countryLanguage: {
    EN: 'en',
    PL: 'pl',
    RU: 'ru',
    RO: 'ro',
    SK: 'sk',
    BG: 'bg',
    CZ: 'cs',
    HU: 'hu',
    TR: 'tr',
  },
  // List of supported languages in the platform
  // Add languages to this list when the site goes live
  languages: [
    'en',
    'pl',
    'ru',
    'sk',
    'ro',
    'bg',
    'cs',
    'hu',
    'tr',
  ],
  // Lookup table for Drupal language codes with the google maps languages codes
  googleMaps: {},
  // Restrictions object that contains the ISO code mapped
  // to the legal drinking age in a country. By default we use 18. We override this default here.
  // KEY: ISO code Value: age in number
  ageGate: {
    AF: 99999,
    AS: 21,
    BD: 99999,
    BI: 16,
    CA: 19,
    CU: 16,
    EG: 21,
    FI: 20,
    GE: 16,
    GU: 21,
    IS: 20,
    ID: 21,
    IR: 99999,
    JP: 20,
    KZ: 21,
    KW: 99999,
    LY: 99999,
    MT: 17,
    FM: 21,
    MP: 21,
    NO: 20,
    OM: 21,
    PK: 99999,
    PW: 21,
    PY: 20,
    QA: 19,
    WS: 21,
    SA: 99999,
    SB: 21,
    SO: 99999,
    KR: 19,
    LK: 21,
    SD: 99999,
    SE: 20,
    TH: 20,
    TO: 21,
    AE: 21,
    US: 21,
    UZ: 20,
    YE: 99999,
  },
};

export const { webFonts } = config;
export const { countryLanguage } = config;
export const { languages } = config;
export const { googleMaps } = config;
export const { ageGate } = config;
export default config;
