import React from 'react';
import styled from 'styled-components';

export default styled.div`
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    
    @media only screen and (min-width: 500px) {
        float: left;
        width: 35%;    
    }
`;