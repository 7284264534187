import React from 'react';
import styled from 'styled-components';

export default styled.div`
  margin: 15px auto 45px;
  text-align: center;

  @media (min-width: 768px) {
    float: right;
    margin: 22px 0 80px;
    width: 176px;
  }
  
  @media (min-width: 1024px) {   
    width: 200px;
  }
`;
