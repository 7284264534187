import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import davidoff from '../../../../themes/davidoff';
import linkStyles from '../../../../shared-styles/link-styles';

export default styled(Link)`
  ${props => linkStyles.link(props)}
  ${props => props.theme.fonts.parse('sansSerif', 'medium', '12px')}
  color: ${davidoff.colors.gray};
  display: inline-block;
  margin: 0 3px;
  text-transform: uppercase;
  transition: color 300ms;
  letter-spacing: 0.04em;
  
  &:after {
    content: none;
  }
  
  @media (min-width: 768px) {
    font-size: 14px;
  }
  
  &:hover {
    @media (min-width: 1024px) {
      color: ${davidoff.colors.orange};
    }
  }
`;
