import React from 'react';
import styled from 'styled-components';

/*
* Width: depending on the image style given the width is either 100% (full width image style) 70% (column width image style) or 50% (double image style)
 */
export default styled.figure`
  display: inline-block;
  width: ${props => props.imageStyle === 'full_width_image' ? '100%' : (props => props.imageStyle == 'column_width_image' ? '100%' : '48.6%')};

  @media (max-width: 767px) {
    margin-bottom: 2em;
    width: 100%;
  }

  &:last-child {
    @media (min-width: 768px) {
      float: ${props => props.imageStyle === 'full_width_image' ? 'none' : (props => props.imageStyle == 'column_width_image' ? 'none' : 'right')}
    }
  }

  img {
    display: block;
    width: 100%;
  }
`;
