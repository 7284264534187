import React from 'react';
import styled from 'styled-components';
import HyphenTitle from '../../../style-guide/HyphenTitle/HyphenTitle';

const Title = HyphenTitle.h2;

export default styled(Title)`
  line-height: inherit !important;
  margin-top: 0 !important;
  margin-bottom: 0;
  padding-bottom: 10px;
  
  @media (max-width: 767px) {
    margin-top: 40px !important;
  }
`;
