import { observable, computed, action } from 'mobx';
import root from 'window-or-global';
import OverviewStore from './OverviewStore';
import viewPort from '../lib/viewport';
import { dataMapper } from '../components/index';

/**
 * ArticleStore:
 * - Contains a list of all available Articles
 * - Contains a list of displayed Articles
 * - Can filter the list by category and update the displayed list
 */

class ArticleStore extends OverviewStore {
  static instance;

  @observable _featuredArticle = null;
  @observable postsPerPage = 9;
  // eslint-disable-next-line class-methods-use-this
  @computed get scrollOffset() {
    if(root.document && viewPort().width > 767) {
      return 170;
    }

    return 180;
  }

  static getInstance() {
    if(!ArticleStore.instance) {
      ArticleStore.instance = new ArticleStore();
    }

    return ArticleStore.instance;
  }

  @computed get featuredArticle() {
    return {
      ...this._featuredArticle,
      mediaImage: this._featuredArticle.field_media,
    };
  }

  @action setFeaturedArticle(article) {
    this._featuredArticle = article;
  }

  /*
   * Set the Items
   */
  @action setItems(items) {
    this._itemList = items;
    this.updateDisplayList();
    this.updatePages();
  }

  /*
   * Get Filter options for the Articles
   */
  @computed get filterOptions() {
    return this.filters.map((filter) => {
      const filterOption = {
        filter_field: filter.expose.identifier,
        operator: filter.operator,
        options: [],
      };

      if(filter.options) {
        filter.options.forEach((option) => {
          const optionObj = dataMapper(this.data, option);
          filterOption.options.push({
            name: optionObj.name,
            key: optionObj.uuid,
          });
        });
      }

      return filterOption;
    });
  }

  @action getFilteredList() {
    let itemsToDisplay = [];

    if(this.selectedFilter === 'all') {
      itemsToDisplay = this.itemList;
    } else {
      // if not all... go and create a list based on the entered filter.
      // select the correct filter object for this filter.
      const filterOptions = this.filterOptions;
      // Select the filter we are using to filter on.
      // Use some to filter the items have the key selected
      const selectedFilter = filterOptions.filter(filter =>
        filter.options.some(option => option.key === this.selectedFilter));

      // Loop over the list items and filter them
      // Use some to filter the items that contain this term. We only have one filter so we use that for now.
      itemsToDisplay = this.itemList.filter(item =>
        item[selectedFilter[0].filter_field].some(term => term.target_uuid === this.selectedFilter));
    }

    return itemsToDisplay;
  }
}

export default ArticleStore;
