import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";
import nprogress from "nprogress";
import queryString from "query-string";
import root from "window-or-global";
import { observer, inject } from "mobx-react";
import { ThemeProvider } from "styled-components";
import { withCookies, Cookies } from "react-cookie";
import getNested from "get-nested";
import { dataMapper } from "../../index";
import { pickTheme } from "../../../themes/pickTheme";
import ScrollMagic from "../../../misc/ScrollMagic/index";
import "../../../shared-styles/global-styles.js";
// Stores
import SiteStore from "../../../stores/SiteStore";
import HeaderStore from "../../../stores/HeaderStore";
import TranslationStore from "../../../stores/TranslationStore";
// Components
import Header from "../../common/Header/Header";
import Navigation from "../../common/Navigation/Navigation";
import Footer from "../../common/Footer/Footer";
import CookieMessage from "../../common/CookieMessage/container/index";
import { TranslationDisclaimer } from "../../common/TranslationDisclaimer/container/TranslationDisclaimer";
import NewsLetter from "../../common/NewsLetter/NewsLetter";
import ProductHighlight from "../../common/ProductHighlight/ProductHighlight";
import AgeGate from "../../common/AgeGate/container/index";
import SocialFeed from "../../common/social/SocialFeed/container/index";
// Styled Components
import SiteWrapper from "./styled/SiteWrapper";
import { BaseLayoutWrapper } from "./styled/BaseLayoutWrapper";
import { MessageWrapper } from "./styled/MessageWrapper";
import PageTransition from "../../common/PageTransition/PageTransition";
import PageContent from "../../common/PageContent/PageContent";
import loadFont from "../../../misc/fontLoader";
import LanguageSwitchNotification from "../../common/LanguageSwitchNotification/container";
import { CloudinaryImage } from "../../common/image/CloudinaryImage/container";
import { CloudinaryProvider } from "../../common/image/provider/CloudinaryProvider";
import GlobalStyles from "../../../shared-styles/global-styles.js";
import WebpageSchema from "../WebpageSchema";
import OrganizationSchema from "../OrganizationSchema";
import MainLocationSchema from "../MainLocationSchema";

// TODO: Remove this temporary import
import HeaderImage from "../../common/ImageHeader/Component";

@inject("translationStore")
@observer
class BaseLayout extends React.Component {
  constructor(props) {
    super(props);

    const { page, data, translationStore, base, translations } = props;
    // The site store
    this.SiteStore = SiteStore.getInstance();
    this.HeaderStore = HeaderStore.getInstance();
    this.SiteStore.updatePath(props.location.pathname);
    this.SiteStore.updateLanguage(page.langcode);
    this.SiteStore.updateSettings(props.settings);
    this.SiteStore.updateCountry(props.country);
    this.SiteStore.updateData(data);

    const queryParams = data.query || queryString.parse(root.location.search);
    this.SiteStore.updateQueryParams(queryParams);

    // Set the translations
    const language = page.langcode;
    translationStore.setTranslation(language, translations);
    translationStore.setLanguage(language);
    translationStore.setBaseUrl(base);

    // Set the social settings.
    this.SiteStore.socialType = "general";
    this.SiteStore.updateRelatedProductsFromSettings(
      props.page.computed.highlighted_products.map((productUuid) =>
        dataMapper(props.data, productUuid)
      ) || [],
      props.data
    );

    this.scrollMagicAdded = false;
    this.controller = false;
    this.halfWay = false;
    this.endPage = false;
    this.notTracked = {
      halfWay: false,
      endPage: false,
    };

    this.addScrollMagic = this.addScrollMagic.bind(this);
    this.setController = this.setController.bind(this);
    this.overRideSocials = this.overRideSocials.bind(this);

    this.overRideSocials(props);
  }

  listenCookieChange(interval = 1000) {
    let lastCookie = document.cookie;
    setInterval(()=> {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        const formattedLastCookie = lastCookie.split('; ').reduce((prev, current) => {
          const [name, ...value] = current.split('=');
          prev[name] = value.join('=');
          return prev;
        }, {});
        const formattedCookie = cookie.split('; ').reduce((prev, current) => {
          const [name, ...value] = current.split('=');
          prev[name] = value.join('=');
          return prev;
        }, {});
        try {
          window.dispatchEvent(new CustomEvent('cookieChange', {detail: {oldValue: formattedLastCookie, newValue: formattedCookie}}))
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
  }

  componentDidMount() {
    this.props.history.listen(() => {
      // Do your stuff here
      this.HeaderStore.reset();
    });

    this.setController();
    this.addScrollMagic();
    this.listenCookieChange();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isLoading && nextProps.isLoading) {
      nprogress.start();
    }

    // Update the settings and language when the component will update
    // Before render so the store is updated before that
    this.SiteStore.updateDisplayNotification(nextProps.page.langcode);
    this.SiteStore.updateLanguage(nextProps.page.langcode);
    this.SiteStore.updatePath(nextProps.location.pathname);
    this.SiteStore.updateSettings(nextProps.settings);
    const queryParams =
      nextProps.data.query || queryString.parse(root.location.search);
    this.SiteStore.updateQueryParams(queryParams);
    this.SiteStore.updateData(nextProps.data);
    this.SiteStore.updateRelatedProductsFromSettings(
      nextProps.page.computed.highlighted_products.map((productUuid) =>
        dataMapper(nextProps.data, productUuid)
      ) || [],
      nextProps.data
    );

    nextProps.translationStore.setLanguage(nextProps.page.langcode);
    loadFont(nextProps.page.langcode);
  }

  componentWillUnmount() {
    this.controller.destroy();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      nprogress.done();
    }

    const body = root.document.body;
    const html = root.document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    this.halfWay.offset(documentHeight * 0.5);
    this.endPage.offset(documentHeight * 0.9);
    this.notTracked = {
      halfWay: false,
      endPage: false,
    };
  }

  setController() {
    if (!this.controller) {
      this.controller = new ScrollMagic.Controller();
    }
  }

  addScrollMagic() {
    while (!this.scrollMagicAdded) {
      // Use ScollMagic to detect a scroll past a certain element.
      const body = root.document.body;
      const html = root.document.documentElement;

      const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      this.halfWay = new ScrollMagic.Scene({
        triggerElement: root.document.body,
        triggerHook: 0.5,
        offset: documentHeight * 0.5,
        reverse: true,
      })
        .on("enter", () => {
          if (root.dataLayer && !this.notTracked.halfWay) {
            root.dataLayer.push({
              event: "scroll-depth-50",
              category: "Scroll Tracking",
              action: 50,
              label: root.location.href,
            });
            this.notTracked.halfWay = true;
          }
        })
        .addTo(this.controller);

      this.endPage = new ScrollMagic.Scene({
        triggerElement: root.document.body,
        triggerHook: 0.9,
        offset: documentHeight * 0.9,
        reverse: true,
      })
        .on("enter", () => {
          if (root.dataLayer && !this.notTracked.endPage) {
            root.dataLayer.push({
              event: "scroll-depth-90",
              category: "Scroll Tracking",
              action: 90,
              label: root.location.href,
            });
            this.notTracked.endPage = true;
          }
        })
        .addTo(this.controller);

      this.scrollMagicAdded = true;
    }
  }

  overRideSocials(props) {
    const { page } = props;
    let data = props.data;

    if (getNested(() => data.data.data, false)) {
      data = data.data;
    }

    // Couple
    let overrideSocials = false;
    if (getNested(() => page.field_category.target_uuid, false)) {
      const category = dataMapper(data, page.field_category.target_uuid);

      overrideSocials = getNested(
        () => category.field_override_socia_data,
        false
      );
    } else if (getNested(() => page.field_product_group.target_uuid, false)) {
      const productGroup = dataMapper(
        data,
        page.field_product_group.target_uuid
      );

      if (
        productGroup &&
        getNested(() => productGroup.field_category.target_uuid, false)
      ) {
        const category = dataMapper(
          data,
          productGroup.field_category.target_uuid
        );
        overrideSocials = getNested(
          () => category.field_override_socia_data,
          false
        );
      }
    }

    if (overrideSocials) {
      this.SiteStore.socialType = "fragrances";
      this.SiteStore.socialAPIKey = getNested(
        () =>
          this.SiteStore.settings.social.fragrances.social_settings
            .social_token,
        ""
      );
    }

    return overrideSocials;
  }

  render() {
    const {
      children,
      data,
      settings,
      page,
      cookies,
      location,
      translationStore,
    } = this.props;
    const lang = page.langcode;
    const showAgeGate = getNested(() => page.field_age_gate, false);
    let Content = children;

    // Check for the language switcher.
    if (
      showAgeGate &&
      cookies.get("DavidoffAgegatePassed") !==
        md5("ZinoDavidoff-age-gate-oke-passed")
    ) {
      Content = <AgeGate settings={settings} lang={lang} cookies={cookies} />;
    }
    let pageHeader;
    if (getNested(() => page.field_header.target_uuid, false)) {
      pageHeader = dataMapper(data, page.field_header.target_uuid);
    }

    return (
      <ThemeProvider theme={pickTheme(translationStore.currentLanguage)}>
        <CloudinaryProvider
          cloudinaryOptions={{
            cloud_name: "zinodavidoff",
            secure: true,
          }}
        >
          <GlobalStyles />
          <BaseLayoutWrapper>
            <SiteWrapper>
              <MainLocationSchema />
              <OrganizationSchema />
              <WebpageSchema baseUrl={this.props.location.pathname} />
              <Header
                data={data}
                location={location.pathname}
                settings={settings}
                lang={lang}
              />
              <Navigation
                data={data}
                location={location.pathname}
                settings={settings}
                lang={lang}
                isLoading={false}
              />
              <PageContent>
                {Content}
                {!page.additional_blocks && location.pathname !== '/cookies' && <SocialFeed page={page} />}
              </PageContent>
            </SiteWrapper>
            {!page.additional_blocks && location.pathname !== '/cookies' && (
              <NewsLetter
                settings={settings}
                lang={lang}
                showTitle={true}
                bottom={true}
              />
            )}
            <Footer settings={settings} lang={lang} />
            <MessageWrapper>
              <LanguageSwitchNotification settings={settings} language={lang} />
            </MessageWrapper>
          </BaseLayoutWrapper>
        </CloudinaryProvider>
      </ThemeProvider>
    );
  }
}

BaseLayout.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.shape().isRequired,
  page: PropTypes.shape({
    langcode: PropTypes.string.isRequired,
    computed: PropTypes.shape({
      related_stories: PropTypes.arrayOf(PropTypes.string).isRequired,
      highlighted_products: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    additional_blocks: PropTypes.bool,
  }).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  data: PropTypes.shape({}).isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

export default withCookies(BaseLayout);
