import React from 'react';
import styled from 'styled-components';
import bgPattern from '../../../../images/bg-pattern-dot.jpg';

export default styled.div`
  align-items: center;
  background: url(${bgPattern}) repeat #555554;
  background-image: url(${(props) => props.backgrounds.mobile});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 20px 0;
  position: relative;
  
  @media (min-width: 768px) {
    background-image: url(${(props) => props.backgrounds.desktop});
    height: calc(100vh - 217px);
    padding: 0;
  }
  
  @media (min-width: 1024px) {
    background-image: url(${(props) => props.backgrounds.desktopXl});
  }
  
  @media (min-width: 1200px) {
    background-image: url(${(props) => props.backgrounds.desktopXxl});
  }
  
  &::after {
    background-color: rgba(0,0,0, 0.25);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
`;
