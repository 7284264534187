import { inject, observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { Link } from 'react-router-dom';
import { dataMapper } from '../../index';
import RatioImage from '../../common/RatioImage/RatioImage';
import CategoryBlock from './styled/CategoryBlock';
import CategoryTitle from './styled/CategoryTitle';
import TranslationStore from '../../../stores/TranslationStore';

// Aspect ratio's
//
const imageRatios = {
  mobile: '570:350',
  desktop: '384:480',
};

const Category = ({ paragraph, data, translationStore }) => {
  const category = getNested(() =>  paragraph.field_category.target_uuid, false);
  if(!category) {
    return null;
  }

  const categoryData = dataMapper(data, category);

  if(!categoryData) {
    return null;
  }

  const title = categoryData.title;
  const settings = {
    width: 384
  };

  return (
    <CategoryBlock>
      <Link to={paragraph.field_category.url}>
        <RatioImage
          data={data}
          imageType="Category"
          mediaReference={paragraph.field_media}
          aspectRatios={imageRatios}
          settings={settings}
        />
        <CategoryTitle>{title}<span>{translationStore.translate('Find out more')}</span></CategoryTitle>
      </Link>
    </CategoryBlock>
  );
};

Category.propTypes = {
  paragraph: PropTypes.shape({
    field_category: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape().isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};
Category.defaultProps = {};

export default inject('translationStore')(observer(Category));
