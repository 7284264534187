import React from 'react';
import PropTypes from 'prop-types';
import { scrollToElement } from '../../../misc/scrollToElement';
import BodyElement from './styled/BodyElement';

class Body extends React.Component {
  static element;
  static propTypes = {
    paragraph: PropTypes.shape({
      field_body: PropTypes.shape({
        processed: PropTypes.string,
        value: PropTypes.string,
      }).isRequired
    }).isRequired
  };

  componentDidMount() {
    if(this.element) {
      const anchors = [...this.element.querySelectorAll('a[href^="#"]')];
      anchors.forEach((anchor) => {
        anchor.addEventListener('click', scrollToElement);
      });
    }
  }

  componentWillUnmount() {
    if(this.element) {
      const anchors = [...this.element.querySelectorAll('a[href^="#"]')];
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', scrollToElement);
      });
    }
  }

  setReference = (element) => {
    this.element = element;
  };

  render() {
    const { paragraph } = this.props;
    return (
      <BodyElement ref={this.setReference} dangerouslySetInnerHTML={{__html: paragraph.field_body.value}}></BodyElement>
    )
  }
}

export default Body;
