import React from 'react';
import styled from 'styled-components';

export const H1 = styled.h1`
  
`;
export const H2 = styled.h2`
    ${props => props.theme.fonts.parse('sansSerif', 'bold', '22px')}
    color: #fd6b0d;
    line-height: 32px;
    margin-bottom: 0.25em;
`;
export const H3 = styled.h3`

`;
export const H4 = styled.h4`

`;

export default H2;
