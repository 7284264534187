import React from 'react';
import styled from 'styled-components';

export default styled.h1`
  ${(props) => props.theme.fonts.parse('serif', 'medium', '35px')}
  color: #fff;
  line-height: 45px;
  margin: 0 auto;
  max-width: 80%;
  position: relative;
  text-align: center;
  z-index: 100;
  
  @media only screen and (min-width: 768px) {
    align-items: center;
    bottom: 0;
    display: flex;
    font-size: 60px;
    left: 0;
    line-height: 70px;
    max-width: 760px;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  @media only screen and (min-width: 900px) {
    width: 760px;
  }
`;
