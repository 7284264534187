import * as React from 'react';
import ReactLazyLoad, { LazyLoadProps } from 'react-lazyload';
import * as root from 'window-or-global';
import Image, { ImageSource } from '../Image/component';

const LazyLoad = ({
  sources,
  src,
  className,
  children,
  lazyLoadProps,
  placeholder,
}) => {
  const sizedPlaceholder = placeholder ? (
    placeholder
  ) : (
    <Image className={className} src={src} alt="" sources={sources} />
  );

  return (
    <ReactLazyLoad
      {...lazyLoadProps}
      height="auto"
      offset={root.innerHeight && root.innerHeight * 0.2}
      once
      resize
      placeholder={sizedPlaceholder}
    >
      {children}
    </ReactLazyLoad>
  );
};

export default LazyLoad;
