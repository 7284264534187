import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import ImageCaption from '../../style-guide/Figcaption/Figcaption';
import Figure from './styled/Figure';
import TextImageContainer from './styled/TextImageContainer';
import ImageElement from './styled/ImageElement';
import TextField from './styled/TextField';
import { calculateImageHeight } from '../../../misc';

const imageSettings = {
  placeholder: '',
  // aspect_ratio: '383:500',
  aspect_ratio: '16:9', // default ratio
  crop: 'fill',
  gravity: 'auto',
};

// Numbers are based on the design
let imageOptions = (settings) => [
    {
      settings: Object.assign({}, settings, {
        height: calculateImageHeight({ height: 9, width: 16 }, 375),
      }),
      media: '(max-width: 767px)',
    },
    {
      settings: Object.assign({}, settings, {
        height: calculateImageHeight({ height: 9, width: 16 }, 1216),
      }),
      media: '(min-width: 1024px)',
    },
    {
      settings: Object.assign({}, settings, {
        height: calculateImageHeight({ height: 9, width: 16 }, 1024),
      }),
      media: '(min-width: 768px)',
    },
  ];

const TextImage = ({ paragraph, data }) => {
  let image = paragraph.field_image;

  if(paragraph.field_image_ratio) {
    imageSettings.aspect_ratio = paragraph.field_image_ratio;
  }

  const imageSources = imageOptions(imageSettings);

  if(Array.isArray(paragraph.field_image)) {
    image = paragraph.field_image[0];
  }

  return (
    <TextImageContainer position={paragraph.field_position}>
      <Figure position={paragraph.field_position}>
        <ImageElement
          imageType="Text image"
          data={data}
          mediaReference={paragraph.field_media}
          settings={Object.assign({}, imageSettings, {
            height: calculateImageHeight({ height: 9, width: 16 }, 1216),
          })}
          sources={imageSources}
        />
        {getNested(() => image.title, false) &&
        <ImageCaption>{image.title}</ImageCaption>
        }
      </Figure>
      <TextField dangerouslySetInnerHTML={{ __html: paragraph.field_body.value }}/>
    </TextImageContainer>
  );
};

TextImage.propTypes = {
  data: PropTypes.shape(),
  paragraph: PropTypes.shape({
    field_body: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    field_media: PropTypes.shape({
      target_id: PropTypes.number,
      target_type: PropTypes.string,
      target_uuid: PropTypes.string,
      url: PropTypes.string,
    }).isRequired,
    field_position: PropTypes.string.isRequired,
    field_image_ratio: PropTypes.string.isRequired
  }).isRequired,
};

export default TextImage;
