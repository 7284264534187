import React from 'react';
import styled from 'styled-components';
import Player from 'react-player';

export default styled(Player)`
  padding-bottom: 56.25%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  
  @media (min-width: 1024px) {
    display: block;
  }
  
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
