import React from 'react';
import styled from 'styled-components';
import MediaImageContainer from '../../MediaImage/index.c';

export const SpecialActivityImage = styled(MediaImageContainer)`
  min-width: 85px;
  width: 85px;
  
  @media (min-width: 768px) {
    min-width: 176px;
    width: 176px;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
`;
