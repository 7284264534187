import React from 'react';
import styled from 'styled-components';
import HyphenTitle from '../../../style-guide/HyphenTitle/HyphenTitle';

const Title = HyphenTitle.h2;

export default styled(Title)`
  line-height: inherit !important;
  margin-top: 0 !important;
  margin-bottom: 60px;
  padding-bottom: 10px;
  width: 100%;
  text-align: left;
`;
