import styled from "styled-components";
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import root from 'window-or-global';
import getNested from 'get-nested';
import { dataMapper } from '../../../index';
// Stores
import SiteStore from '../../../../stores/SiteStore.js';
import StoreLocatorStore from '../../../../stores/StoreLocatorStore';
// View
import StoreLocatorView from '../component/index';

@observer
class StoreLocator extends Component {
  constructor(props) {
    super(props);

    // Setup stores
    this.SiteStore = SiteStore.getInstance();
    this.StoreLocatorStore = StoreLocatorStore.getInstance();

    // Set the default state
    this.state = {
      currentLocation: false,
      locationValue: '',
      fetchingLocation: false,
      locationSearched: false,
      filterOpened: false,
      apiLoaded: false,
      updateToClosestStore: false,
    };

    // Bind event handlers
    this.searchLocation = this.searchLocation.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.APILoaded = this.APILoaded.bind(this);
    this.onMapChange = this.onMapChange.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.fitMap = this.fitMap.bind(this);
    this.setBoundsToCurrentLocation = this.setBoundsToCurrentLocation.bind(this);
    this.setupStoreLocatorData = this.setupStoreLocatorData.bind(this);
    this.openFilter = this.openFilter.bind(this);

    // Set the position of the map to the current position if we can.
    this.setupStoreLocatorData(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setupStoreLocatorData(nextProps);
  }

  setupStoreLocatorData(props) {
    // set the StoreLocator default fields
    const view = dataMapper(props.data, props.paragraph.field_view_reference.target_uuid);
    this.StoreLocatorStore.filters = view.display.filters;
    this.StoreLocatorStore.data = props.data;
    this.StoreLocatorStore.langcode = props.paragraph.langcode;

    // read out the query params to check if the filters are set there.
    if(getNested(() => this.SiteStore.queryParams.selectedFilters, false)) {
      this.StoreLocatorStore.hasQueryParamFilters = true;
      // reset the filters before adding new ones
      this.StoreLocatorStore.resetFilters();
      this.SiteStore.queryParams.selectedFilters.split(',').forEach(key => this.StoreLocatorStore.selectedFilters.push(key));
      // Update the display list so we show the correct data as well.
      this.StoreLocatorStore.updateDisplayList();
    }

    this.StoreLocatorStore.setupFilterOptions();
  }

  getPosition() {
    if(root.navigator) {
      // Check for current location in the state...
      // If its not here we fetch a new one.
      this.setState({
        fetchingLocation: true,
      });

      if(getNested(() => this.state.currentLocation, false)) {
        this.StoreLocatorStore.navigatorLocation = true; // set the use of the geolocator on true.
        this.setState({
          updateToClosestStore: true,
        });
        this.setBoundsToCurrentLocation(true);
        return this.setLocation(this.state.currentLocation);
      }

      // Get the navigator position (asking for permissions etc.
      navigator.geolocation.getCurrentPosition((position) => {
          this.setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          this.StoreLocatorStore.navigatorLocation = true; // set the use of the geolocator on true.
        this.setBoundsToCurrentLocation(true);
          this.setState({
            updateToClosestStore: true,
          });
        },
        error => console.error('An error occurred during geolocation', error)
      );
    }
  }

  setLocation(position) {
    // Geocode the retrieved location so we have a name to fill
    const geoCoder = new google.maps.Geocoder;
    geoCoder.geocode({ 'location': position }, (results, status) => {
      if(status === 'OK') {
        // Lets search for the city in the results
        const result = results.find(item => item.address_components.some(component => component.types[0] === 'locality'));
        const city = result.address_components.find(component => component.types[0] === 'locality').long_name;
        const country = result.address_components.find(component => component.types[0] === 'country').short_name;
        const countryLongName = result.address_components.find(component => component.types[0] === 'country').long_name;

        if(results[0]) {
          this.StoreLocatorStore.setLocation(position, city, country);
          this.StoreLocatorStore.setLocationValue(`${city ? `${city}, ` : ''}${countryLongName}`);

          // Do a lookup of the place and get the name
          this.setState({
            center: position,
            currentLocation: position,
            fetchingLocation: false,
            locationSearched: true,
          });
        }
      } else {
        this.setState({
          center: position,
          currentLocation: position,
          locationSearched: true,
        });
      }
    });

    return position;
  }

  searchLocation(places) {
    if(places.length >= 1) {
      let place = places[0];
      // Always geocode the results to prevent unexpected results.
      // fetch a geocoded place basted on the place ID
      const geoCoder = new google.maps.Geocoder;
      geoCoder.geocode({ 'placeId': place.place_id }, (results, status) => {
        if(status === 'OK' && results[0]) {
          place = results[0];
          // get bounds of the map
          this.fitMap(place.geometry.viewport);
          // const result = places[0].find(item => item.address_components.some(component => component.types[0] === 'locality'));
          const locality = place.address_components.find(component => component.types[0] === 'locality');
          const countryObj = place.address_components.find(component => component.types[0] === 'country');
          const city = getNested(() => locality.long_name, false) ? locality.long_name : false;
          const country = getNested(() => countryObj.short_name, false) ? countryObj.short_name : false;
          const bounds = this.map.getBounds();
          const ne = bounds.getNorthEast().toJSON();
          const sw = bounds.getSouthWest().toJSON();

          this.StoreLocatorStore.bounds = {
            nw: {
              lat: ne.lat,
              lng: sw.lng,
            },
            se: {
              lat: sw.lat,
              lng: ne.lng,
            },
          };
          this.StoreLocatorStore.setLocation({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            city,
            country);

          this.setState({
            locationSearched: true,
          });
          this.openFilter(false); // always close the filter bar
        }
      });
    }
  }

  fitMap(viewport) {
    if(this.map) {
      this.map.fitBounds(viewport);
      // this.map.panToBounds(viewport);
    } else {
      // ping for the map to appear
      setTimeout(this.fitMap(viewport), 500);
    }
  }

  APILoaded(maps) {
    this.map = maps.map;
    this.maps = maps.maps;
    this.setState({
      apiLoaded: true,
    });
  }

  onMapChange(ev) {
    this.setBoundsToCurrentLocation(this.state.updateToClosestStore);

    this.StoreLocatorStore.bounds = ev.bounds;
    this.setState({
      bounds: ev.bounds,
    });
  }

  openFilter(ev) {
    if(typeof ev !== 'boolean') {
      ev.preventDefault();
    }
    // Over ride the status of the filter when we receive a boolean
    let filterStatus = !this.state.filterOpened;

    if(typeof ev === 'boolean') {
      filterStatus = ev;
    }

    this.setState({
      filterOpened: filterStatus,
    });
  }

  setBoundsToCurrentLocation(updatedBounds) {
    let updateBounds = updatedBounds;
    // Add a pinger... we need this to fix the
    if(this.StoreLocatorStore.isLoadingStores) {
      return setTimeout(this.setBoundsToCurrentLocation, 150);
    }

    if(this.StoreLocatorStore.navigatorLocation !== false && updateBounds) {
      const closestStore = this.StoreLocatorStore.closestStore;

      if(closestStore) {
        updateBounds = false;

        // Check if the clostest store is closer then 50km
        if(this.StoreLocatorStore.hasStoresInRange) {
          // Update the bounds so the current location and the nearest store are in view.
          if(google) {
            const newBounds = new google.maps.LatLngBounds();
            // add bounds for the current location and the closest store
            const currentLocation = new google.maps.LatLng(this.StoreLocatorStore.currentLocation);
            const storeLocation = new google.maps.LatLng({
              lat: closestStore.field_coordinates.lat,
              lng: closestStore.field_coordinates.lon,
            });

            styled(newBounds)(currentLocation);
            styled(newBounds)(storeLocation);

            this.map.fitBounds(newBounds);
          }
        }
      }
    }

    this.setState({
      updateToClosestStore: updateBounds,
    });
  }

  render() {
    const { paragraph } = this.props;

    return (
      <StoreLocatorView
        setCurrentLocation={this.getPosition}
        searchLocation={this.searchLocation}
        onAPILoaded={this.APILoaded}
        apiLoaded={this.state.apiLoaded}
        setLocation={this.setLocation}
        map={this.map}
        fitMap={this.fitMap}
        bounds={this.state.bounds}
        stores={this.StoreLocatorStore.displayedItems}
        openFilter={this.openFilter}
        filterOpened={this.state.filterOpened}
        onMapChange={this.onMapChange}
        showIntro={this.StoreLocatorStore.showIntro}
        zoom={getNested(() => this.map.getZoom, false) ? this.map.getZoom() : 15}
        fetchingLocation={this.state.fetchingLocation}
        paragraph={paragraph}
        locationSearched={this.state.locationSearched}
      />
    );
  }
}

export default StoreLocator;
