import styled from 'styled-components';

export const CampaignBanner = styled.div`
  max-width: ${p => p.isSmall ? 'calc(1216px - 10%)' : '1216px'};
  padding: 20px 0;

  @media (min-width: 768px) {
    margin: 60px auto;
    padding: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  .video-element {
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
  }
`;