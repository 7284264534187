import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
// Styled
import OptionItem from './styled/OptionItem';
import OptionLabel from './styled/OptionLabel';
import OptionInput from './styled/OptionInput';

const StoreLocatorFilterOption = inject('siteStore')(observer(({option, handler, selected, heading, filter, siteStore}) => {
  if(getNested(() => filter.item.field_hidden, false)) {
    return false;
  }

  if(filter.item.field_blacklisted_countries) {
    const lowerCaseBlacklist = filter.item.field_blacklisted_countries.map(v => v.toLowerCase());
    if (lowerCaseBlacklist.includes(siteStore.country.toLowerCase())) {
      return false;
    }
  }

  return (<OptionItem>
    <OptionLabel htmlFor={option.key} heading={heading} selected={selected}>
      {option.label}
      <OptionInput
        id={option.key}
        name={option.key}
        onChange={handler}
        checked={selected}
        type='checkbox'
        value={option.key}
      />
    </OptionLabel>
  </OptionItem>);
}));

StoreLocatorFilterOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  handler: PropTypes.func.isRequired,
  heading: PropTypes.bool
};
StoreLocatorFilterOption.defaultProps = {
  heading: false,
};

export default StoreLocatorFilterOption;
