import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import ProductHighlight from '../../common/ProductHighlightItem/ProductHighlightItem';
import { dataMapper } from '../../index';

const Collection = ({ paragraph, data }) => {
  const collection = dataMapper(data, paragraph.field_collection_reference.target_uuid);

  if(!getNested(() => collection.field_collection.target_uuid, false)) {
    return null;
  }

  const collectionTerm = dataMapper(data, collection.field_collection.target_uuid);
  const collectionHeader = dataMapper(data, collectionTerm.field_header.target_uuid);
  const highlightData = {
    url: paragraph.field_collection_reference.url,
    mediaReference: paragraph.field_media || collectionHeader.field_media,
    title: getNested(() => paragraph.field_override_text, collectionTerm.name),
  };

  return (
    <ProductHighlight data={data} product={highlightData} />
  );
};

Collection.propTypes = {
  paragraph: PropTypes.shape({
    field_collection_reference: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};
Collection.defaultProps = {};

export default Collection;
