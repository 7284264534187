import React from 'react';
import styled from 'styled-components';

const animationSpeed = 1000;
const dotSize = 10;

export default styled.div`
  background-color: #fff;
  //background-color: #f7f6f4;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  // Transition the opacity in a certain speed
  // Transition the 
  //transform: translateX(-100%);
  width: 100%;
  z-index: -1; // under everything...
  
  ${(props) => props.enter ? `
    opacity: 1;
    transition: opacity ${props.enterDuration}ms cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 99999; // over everything
  `: ''}
  
  
  ${(props) => props.leave ? `
    opacity: 0;
    transition: opacity ${props.leaveDuration}ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    z-index: 99999;
  `: ''}
  
  // Logo that animates
  .page-transition-inner {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity ${(props) => props.enterDuration / 2}ms cubic-bezier(0.165, 0.84, 0.44, 1) 750ms;
    width: 65%;
    max-width: 62px;
    
    ${(props) => props.enter ? `
      opacity: 1;
      transition: opacity ${props.enterDuration / 2}ms cubic-bezier(0.165, 0.84, 0.44, 1) 750ms;
    `: ''}
    ${(props) => props.leave ? `
      opacity: 0;
      transition: opacity ${props.leaveDuration / 2}ms cubic-bezier(0.165, 0.84, 0.44, 1);
    `: ''}
    
    svg {
      display: block;
      max-width: 100%;
    }
  }
  
  .dot {
    border: ${(dotSize / 5)}px solid #1d1e2a;
    border-radius: 50%;
    height: ${dotSize}px;
    float: left;
    margin: 0 ${(dotSize / 2)}px;
    transform: scale(0);
    width: ${dotSize}px;
    
    ${(props) => props.enter ? `
      ${animation()}
      
      &:nth-child(2) {
        ${animation( animationSpeed * 0.3)};
      }

      &:nth-child(3) {
        ${animation(animationSpeed * 0.6)};
      }
    `: ''}
  }
  
  @keyframes fx {
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;


function animation(delay = 0) {
  return `animation: fx ${animationSpeed}ms ease infinite ${delay}ms;`
}
