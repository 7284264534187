import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { stripProtocol } from '../../../misc/index';
import davidoff from '../../../themes/davidoff';
// Components
import RatioImage from '../../common/RatioImage/RatioImage';
// Styled
import HypenTitle from '../../style-guide/HyphenTitle/HyphenTitle';
import StoryWrapper from './styled/StoryWrapper';
import StoryContainer from './styled/StoryContainer';
import StoryImageWrapper from './styled/StoryImageWrapper';
import StoryBody from './styled/StoryBody';
import StoryInner from './styled/StoryInner';
import StoryLink from './styled/StoryLink';
import StoryAnimationWrapper from './styled/StoryAnimationWrapper';
import StoryOverflowWrapper from './styled/StoryOverflowWrapper';
import ImageLink from './styled/ImageLink';

const Title = HypenTitle.h2;

class Story extends React.Component {
  constructor(props) {
    super(props);

    this.getColor = this.getColor.bind(this);
    this.shouldInverseTextColor = this.shouldInverseTextColor.bind(this);
    this.invertImagePosion = this.invertImagePosion.bind(this);
  }

  invertImagePosion(position) {
    if(position === 'right') {
      return true;
    }
    return false;
  }

  getColor(color) {
    // return correct background colors and inversed colors for drupal options
    if(color === 'navy') {
      return davidoff.colors.darkblue;
    }

    return '#fff';
  }

  shouldInverseTextColor(color) {
    if(color === 'navy') { return true; }
    return false;
  }

  getStoryImage() {
    const { data, paragraph } = this.props

    const imageRatios = {
      mobile: '488:600', 
      desktop: '488:600',
    };

    const settings = {
      width: 490,
      quality: 100,
    };

    return (
      <RatioImage
        data={data}
        imageType="Story"
        mediaReference={paragraph.field_media}
        aspectRatios={imageRatios}
        settings={settings}
      />
    );
  }

  render() {
    const { paragraph, status, } = this.props;

    return (
      <StoryAnimationWrapper
        inverseTextColor={this.shouldInverseTextColor(paragraph.field_background_color)}
        invertImagePosition={this.invertImagePosion(paragraph.field_position)}
        reverse={status === 'exiting'}
      >
        <StoryWrapper
          backgroundColor={this.getColor(paragraph.field_background_color)}
          inverseTextColor={this.shouldInverseTextColor(paragraph.field_background_color)}
          invertImagePosition={this.invertImagePosion(paragraph.field_position)}
        >
          <StoryContainer>
            <StoryInner
              inverseTextColor={this.shouldInverseTextColor(paragraph.field_background_color)}
              invertImagePosition={this.invertImagePosion(paragraph.field_position)}
            >
              <StoryImageWrapper
                invertImagePosition={this.invertImagePosion(paragraph.field_position)}
              >
                <StoryOverflowWrapper>
                  { 
                    getNested(() => paragraph.field_link.uri) 
                      ? ( 
                        <ImageLink to={paragraph.field_link.uri}>
                          {this.getStoryImage()}
                        </ImageLink>
                      )
                      : this.getStoryImage() 
                  }
                </StoryOverflowWrapper>
                <div className='story-animation-overlay'></div>
              </StoryImageWrapper>
            </StoryInner>
            <StoryInner
              inverseTextColor={this.shouldInverseTextColor(paragraph.field_background_color)}
              invertImagePosition={this.invertImagePosion(paragraph.field_position)}
            >
              <Title>{paragraph.field_label}</Title>
              <StoryBody
                invertImagePosition={this.invertImagePosion(paragraph.field_position)}
              >
                <div dangerouslySetInnerHTML={{__html: paragraph.field_body.value}}/>
                {getNested(() => paragraph.field_link.uri, false) &&
                <StoryLink
                  to={stripProtocol(getNested(() => paragraph.field_link.uri, ""))}
                  title={paragraph.field_link.title}
                >
                  {paragraph.field_link.title}
                </StoryLink>
                }
              </StoryBody>
            </StoryInner>
          </StoryContainer>
        </StoryWrapper>
      </StoryAnimationWrapper>
    );
  };
}

Story.propTypes = {
  paragraph: PropTypes.shape({
    field_label: PropTypes.string.isRequired,
    field_media: PropTypes.shape(),
    field_link: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    field_body: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
    field_background_color: PropTypes.string.isRequired,
    field_position: PropTypes.string.isRequired,
  }).isRequired,
};

export default Story;
