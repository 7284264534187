import React from 'react';
import styled from 'styled-components';

export default styled.ul`
  list-style: none;
  margin: 0;
  text-align: center;
  
  @media (min-width: 1024px) {
    margin: 0 0 30px;
  }
  
  li {
    display: inline-block;
    margin: 0 4px;
    
    @media (min-width: 767px) {
      margin: 0 8px;
    }
  }
`;
