import React from 'react';
import Transition from 'react-transition-group/Transition';
import PropTypes from 'prop-types';
import Story from '../Story';

/*
  HELPER TO DEBUG THE TRANSITION GROUPS
// Helpers for now.
const logStatus = (status) => {
  console.log('Animated Story Status: ', status);
};

const enter = () => logStatus('enter');
const entering = () => logStatus('entering');
const entered = () => logStatus('entered');
const exit = () => logStatus('exit');
const exiting = () => logStatus('exitting');
const exited = () => logStatus('exited');

// Props to add to the transition groups.
onEnter={enter}
onEntering={entering}
onEntered={entered}
onExit={exit}
onExited={exited}
onExiting={exiting}
*/

const duration = 3500;

const AnimatedStory = ({ in: inProp, paragraph, data }) => {
  return (
    <Transition
      in={inProp}
      appear={true}
      timeout={{
        enter: duration,
        exit: 0,
      }}
      mountOnEnter={false}
      unmountOnExit={true}
    >
      {(state) => (
        React.createElement(Story, {
          status: state,
          paragraph,
          data,
        })
      )}
    </Transition>
  );
};

AnimatedStory.propTypes = {
  paragraph: PropTypes.shape().isRequired,
};

export default AnimatedStory;
