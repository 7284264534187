import React from 'react';
import { inject, observer } from 'mobx-react';
import getNested from 'get-nested';
import PropTypes from 'prop-types';
import SiteStore from '../../../stores/SiteStore';
import Article from '../../common/Article/Article';
import Columns from '../../style-guide/Structure/Columns';
import HyphenTitle from '../../style-guide/HyphenTitle/HyphenTitle';
import ArticleWrapper from './styled/ArticleWrapper';
import ArticleBgWrapper from './styled/ArticleBgWrapper';
import ArticleContainer from './styled/ArticleContainer';
import TranslationStore from '../../../stores/TranslationStore';

const Title = HyphenTitle.h2;

/* TODO:
- Sticky label (see PSD)
 */
@inject('translationStore')
@observer
class RelatedStories extends React.Component {
  constructor(props) {
    super(props);

    this.SiteStore = SiteStore.getInstance();
  }

  render() {
    const {articles, title, data, translationStore} = this.props;
    const RelatedArticles = articles.map((article, i) => {
      return <Article
        key={`related-article-${getNested(() => article.uuid, article.path)}-${i}`}
        article={article} 
        isfeatured={article.computed.featured_article} 
        data={data}
      />;
    });

    return (
        <ArticleWrapper>
          <ArticleContainer>
            {title &&
            <Title>{translationStore.translate('Related stories')}</Title>
            }
          </ArticleContainer>
          <ArticleBgWrapper>
            <ArticleContainer>
              <Columns multicolMobile={true}>
                {RelatedArticles}
              </Columns>
            </ArticleContainer>
          </ArticleBgWrapper>
        </ArticleWrapper>
    );
  }
}

RelatedStories.propTypes = {
  articles: PropTypes.array.isRequired,
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.bool,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

RelatedStories.defaultProps = {
  title: false,
};

export default RelatedStories;
