import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
// import structureStyles from '../../../../shared-styles/structure-styles.js';

// TODO: when proper font is available, padding-top should be removed and value should be added to line-height

export default styled.button`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '12px')}
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid ${davidoff.colors.gray};
  border-radius: 0;
  color: ${davidoff.colors.brown};
  line-height: 24px;
  margin: 0 6px;
  padding: 4px 0 0;
  transition: background-color 350ms ease;
  width: 30px;
  
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 28px;
    padding-top: 2px;
    width: 32px;
  }

  &:hover {
    background-color: ${davidoff.colors.lime};
  }
  
  &[disabled] {
    background-color: ${davidoff.colors.lime};
  }
  
`;
