import React from 'react';
import styled from 'styled-components';

export default styled.div`
    margin: 60px auto;
    text-align: center;
    width: 94%;
    
    @media only screen and (min-width: 768px) {
        width: 100%;
        max-width: 800px;
    }
`;

