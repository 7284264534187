import React, { Component } from "react";
import PropTypes from "prop-types";
import getNested from "get-nested";
import root from "window-or-global";
import { inject, observer } from "mobx-react";
import mapper, { dataMapper } from "../../index";
import { formatProductName, stripProtocol } from "../../../misc/index";
// Stores
import ProductDetailStore from "../../../stores/ProductDetailStore";
import SiteStore from "../../../stores/SiteStore";
import TranslationStore from "../../../stores/TranslationStore";
// Components
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import SocialSharingComponent from "../../common/SocialSharingComponent/SocialSharingComponent";
import DetailImageCarousel from "../../common/DetailImageCarousel/DetailImageCarousel";
import ProductDetails from "../../common/ProductDetails/ProductDetails";
import ProductDetailFilter from "../../common/ProductDetailFilter/ProductDetailFilter";
import RelatedArticlesView from "../../common/RelatedArticlesView/RelatedArticlesView";
import BrandIntroduction from "../../paragraph-types/BrandIntroduction/BrandIntroduction";
// import BuyNowButton from '../../common/BuyNowButton/BuyNowButton';
import { H2 } from "../../style-guide/Headings";
//Styled components
import ProductElement from "./styled/ProductElement";
import ProductWrapper from "./styled/ProductWrapper";
import LeftColumn from "./styled/LeftColumn";
import RightColumn from "./styled/RightColumn";
import Title from "./styled/Title";
import ProductDescription from "./styled/ProductDescription";
import { ButtonWrapper } from "./styled/ButtonWrapper";
import StoreLocatorButton from "./styled/StoreLocatorButon";
import BuyButton from "./styled/BuyButton";
import Price from "../../common/ProductPrice";
import ProductHighlight from "../../common/ProductHighlight/ProductHighlight";

@inject("translationStore")
@observer
class Product extends Component {
  static abortNoVariants = false;

  constructor(props) {
    super(props);

    this.setupProduct = this.setupProduct.bind(this);
    this.SiteStore = SiteStore.getInstance();
    this.ProductDetailStore = ProductDetailStore.getInstance();
    this.setupProduct(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setupProduct(nextProps);
  }

  setupProduct(props) {
    const { data, page } = props;
    this.ProductDetailStore.currentVariant = page.uuid;
    this.ProductDetailStore.data = data;
    this.productGroup = dataMapper(data, page.field_product_group.target_uuid);
    this.collection = dataMapper(
      data,
      getNested(() => page.computed.collection.target_uuid, false)
    );
    this.productCategory = dataMapper(
      data,
      this.productGroup.field_category.target_uuid
    );

    // Check for a variants view...
    if (getNested(() => this.productGroup.field_product_variants.target_uuid)) {
      this.variantView = dataMapper(
        data,
        this.productGroup.field_product_variants.target_uuid
      );
      this.ProductDetailStore.variants = this.variantView.results.map(
        (variant) => dataMapper(data, variant)
      );

      let productDetailFilters = dataMapper(
        data,
        this.productCategory.field_product_page_filters.target_uuid
      );

      if (
        getNested(
          () => this.productGroup.field_product_page_filters.target_uuid,
          false
        )
      ) {
        productDetailFilters = dataMapper(
          data,
          this.productGroup.field_product_page_filters.target_uuid
        );
      }

      // First filter variables
      const firstFilter = {
        allVariants: productDetailFilters.field_all_variants,
        filterField: getNested(
          () => productDetailFilters.field_first_filter_property.target_uuid,
          false
        ),
        label: getNested(
          () => productDetailFilters.field_first_filter_label,
          false
        ),
        type: getNested(
          () => productDetailFilters.field_first_filter_display,
          "dropdown"
        ),
      };

      // Second filter variables
      const secondFilter = {
        filterField: getNested(
          () => productDetailFilters.field_second_filter_property.target_uuid,
          false
        ),
        label: getNested(
          () => productDetailFilters.field_second_filter_label,
          false
        ),
      };

      // Third filter variables
      const thirdFilter = {
        filterField: getNested(
          () => productDetailFilters.field_third_filter_property.target_uuid,
          false
        ),
        label: getNested(
          () => productDetailFilters.field_third_filter_label,
          false
        ),
      };

      // Set the selected filters to the properties of the current variant
      const firstFilterProperties = this.ProductDetailStore.findProperty(
        page,
        firstFilter.filterField,
        "filter"
      );
      const secondFilterProperty = this.ProductDetailStore.findProperty(
        page,
        secondFilter.filterField
      );
      const thirdFilterProperty = this.ProductDetailStore.findProperty(
        page,
        thirdFilter.filterField
      );

      if (firstFilterProperties.length > 0) {
        this.ProductDetailStore.updateFirstFilter(
          firstFilterProperties
            .map((property) => property.target_uuid)
            .join(",")
        );
      } else if (firstFilter.filterField && firstFilter.allVariants) {
        // All variant filters filter by target uuid since they have no specific property to filter by.
        this.ProductDetailStore.updateFirstFilter(page.uuid);
      }

      if (secondFilterProperty) {
        this.ProductDetailStore.updateSecondFilter(
          secondFilterProperty.target_uuid
        );
      }

      if (thirdFilterProperty) {
        this.ProductDetailStore.updateThirdFilter(
          thirdFilterProperty.target_uuid
        );
      }

      // Build the filters And set current values
      const filters = [];
      this.ProductDetailStore.rawFilters = [];

      if (firstFilter.filterField) {
        const filterObject = Object.assign({}, firstFilter, {
          filterCount: 0,
        });
        this.ProductDetailStore.rawFilters.push(filterObject);

        // Set the filterAllVariants to the value from the json
        this.ProductDetailStore.allVariants = firstFilter.allVariants;
      } else if (firstFilter.allVariants) {
        const filterObject = Object.assign({}, firstFilter, {
          filterCount: 0,
          filterField: "all-variants",
        });
        this.ProductDetailStore.rawFilters.push(filterObject);

        // set the first filter value to the correct field value
        // so the item gets selected in the filter.
        this.ProductDetailStore.allVariants = filterObject.allVariants;
      }

      if (secondFilter.filterField && firstFilter.allVariants === false) {
        const filterObject = Object.assign({}, secondFilter, {
          type: "dropdown",
          filterCount: 1,
          allVariants: false,
        });
        this.ProductDetailStore.rawFilters.push(filterObject);
      }

      if (thirdFilter.filterField && firstFilter.allVariants === false) {
        const filterObject = Object.assign({}, thirdFilter, {
          type: "dropdown",
          filterCount: 2,
          allVariants: false,
        });
        this.ProductDetailStore.rawFilters.push(filterObject);
      }

      this.ProductDetailStore.buildFilters(filters);
    } else {
      this.abortNoVariants = true;
    }
  }

  componentWillUnmount() {
    this.SiteStore.removeRelatedProductsFromProductGroup();
  }

  trackLocatorButton = () => {
    if (root.dataLayer) {
      root.dataLayer.push({
        event: "navigate-to-store-locator",
        category: "Navigate to the Store locator",
        action: "Navigate to the Store locator",
        label: window.location.href,
      });
    }
  };

  trackBuyButton = (link, country) => {
    if (root.dataLayer) {
      root.dataLayer.push({
        event: "Navigate to buy button",
        category: "Navigate to buy button location",
        action: "Navigate to buy button location",
        label: window.location.href,
        country,
        clickedUrl: link,
      });
    }
  };

  render() {
    if (this.abortNoVariants) {
      return (
        <H2>
          There was a misconfiguration in the CMS please fix the 'variants_view'
          for the product group.
        </H2>
      );
    }

    const isCoffee = this.productCategory.revision_id === 26
    const { data, translationStore, page } = this.props;
    const currentVariant = this.ProductDetailStore.variants.find(
      (variant) => variant.uuid === this.ProductDetailStore.currentVariant
    );

    // Update the highlighted products
    if (currentVariant.computed.highlighted_products.length > 0) {
      const relatedProducts = currentVariant.computed.highlighted_products.map(
        (key) => dataMapper(data, key)
      );

      this.SiteStore.updateRelatedProductsFromProductGroup(
        relatedProducts,
        data
      );
    }

    const Accordions = currentVariant.field_accordion.map((accordion, idx) =>
      mapper(data, false, accordion.target_uuid, idx)
    );

    let notes = this.productGroup.field_notes;
    // override the notes if we have them on a variant level
    if (
      getNested(() => currentVariant.field_emotional_description, []).length > 0
    ) {
      notes = currentVariant.field_emotional_description.peek();
    }

    const Filters = this.ProductDetailStore.filters.map((filter) => (
      <ProductDetailFilter
        key={`product-detail-filter-${filter.filterField}`}
        data={data}
        filter={filter}
        filterCount={this.ProductDetailStore.filters.length}
      />
    ));

    const firstAccordion = dataMapper(
      data,
      getNested(() => currentVariant.field_accordion[0].target_uuid, false)
    );
    const productTitle = formatProductName(
      this.productGroup.field_display_text,
      currentVariant.field_display_text
    );
    const storeLocatorBase = getNested(
      () =>
        this.SiteStore.settings.content[this.SiteStore.language]
          .store_locator_url,
      false
    );

    // Buy buttons.
    let buyButton = undefined;
    let buyButtonUrl = undefined;
    let buyButtonOpenNewTab = false;
    if (currentVariant.field_buy_buttons) {
      const buyButtonsParagraph = dataMapper(
        data,
        currentVariant.field_buy_buttons.target_uuid
      );
      const buyButtonReferences = getNested(
        () => buyButtonsParagraph.field_buy_buttons,
        []
      );
      const buyButtons = buyButtonReferences.map((reference) =>
        dataMapper(data, reference.target_uuid)
      );

      // Find if there is defined a buy button for the user country.
      buyButton = buyButtons.find(
        (paragraph) =>
          paragraph.field_country.toLowerCase() ===
          this.SiteStore.country.toLowerCase()
      );

      // If there was no defined Buy button look for the global/general option.
      if (!buyButton && buyButtonsParagraph.field_buy_button_url) {
        // Check if the user country is banned for this product.
        const lowerCaseBlacklist =
          buyButtonsParagraph.field_blacklisted_countries.map((v) =>
            v.toLowerCase()
          );
        const countryIsBanned = lowerCaseBlacklist.includes(
          this.SiteStore.country.toLowerCase()
        );

        if (!countryIsBanned) {
          buyButtonUrl = buyButtonsParagraph.field_buy_button_url;
          buyButtonOpenNewTab = buyButtonsParagraph.field_new_tab;
        }
      } else if (buyButton) {
        buyButtonUrl = buyButton.field_buy_button_url;
        buyButtonOpenNewTab = buyButton.field_new_tab;
      }
    }

    let locatorURL = false;

    if (
      getNested(() => this.productCategory.field_store_terms_relation, false) &&
      storeLocatorBase
    ) {
      const storeTerm = dataMapper(
        data,
        this.productCategory.field_store_terms_relation.target_uuid
      );
      locatorURL = `${storeLocatorBase}?selectedFilters=${storeTerm.uuid}`;

      if (storeTerm.field_blacklisted_countries) {
        const lowerCaseBlacklist = storeTerm.field_blacklisted_countries.map(
          (v) => v.toLowerCase()
        );
        if (lowerCaseBlacklist.includes(this.SiteStore.country.toLowerCase())) {
          locatorURL = false;
        }
      }
    }

    return (
      <ProductElement>
        {getNested(() => currentVariant.product_page_header, false) &&
          mapper(data, false, currentVariant.product_page_header.target_uuid)}
        <ProductWrapper>
          <Breadcrumb breadcrumbs={currentVariant.breadcrumb} />
          <LeftColumn>
            <DetailImageCarousel
              data={data}
              product={currentVariant}
              isCoffee={isCoffee}
            />
          </LeftColumn>
          <RightColumn>
            <Title>
              {`${productTitle.groupName}`} <br />
              <span>{productTitle.variantName}</span>
              {buyButton && buyButton.field_price && (
                <Price>{buyButton.field_price}</Price>
              )}
            </Title>
            {Filters}
            {(locatorURL || buyButtonUrl) && (
              <ButtonWrapper>
                {buyButtonUrl && (
                  <BuyButton
                    href={stripProtocol(buyButtonUrl)}
                    onClick={() =>
                      this.trackBuyButton(buyButtonUrl, this.SiteStore.country)
                    }
                    target={buyButtonOpenNewTab ? "_blank" : "_self"}
                    hasLocatorButton={!!locatorURL}
                  >
                    {translationStore.translate("Buy online")}
                  </BuyButton>
                )}
                {locatorURL && (
                  <StoreLocatorButton
                    to={locatorURL}
                    onClick={this.trackLocatorButton}
                  >
                    {translationStore.translate("Find a store")}
                  </StoreLocatorButton>
                )}
              </ButtonWrapper>
            )}
            {Accordions.length > 1 && Accordions}
            {Accordions.length === 1 && (
              <ProductDescription
                dangerouslySetInnerHTML={{
                  __html: `${firstAccordion.field_body.value}`,
                }}
              />
            )}
            <SocialSharingComponent />
          </RightColumn>
        </ProductWrapper>
        {notes.length > 0 && <ProductDetails details={notes} data={data} />}
        {getNested(
          () => this.productGroup.field_special_activity.target_uuid,
          false
        ) &&
          mapper(
            data,
            false,
            this.productGroup.field_special_activity.target_uuid
          )}
        {getNested(() => this.collection.field_header.target_uuid, false) && (
          <BrandIntroduction
            data={data}
            paragraph={dataMapper(
              data,
              this.collection.field_header.target_uuid
            )}
            clickable
          />
        )}
        <ProductHighlight data={data} isProductDetailPage />
        <RelatedArticlesView page={page} data={data} title />
      </ProductElement>
    );
  }
}

Product.propTypes = {
  page: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    field_display_text: PropTypes.string.isRequired,
    field_overview_image: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    field_detail_image: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        target_uuid: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    field_buy_buttons: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }),
    field_accordion: PropTypes.arrayOf(
      PropTypes.shape({
        target_uuid: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    field_product_group: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }).isRequired,
    breadcrumb: PropTypes.array.isRequired,
    computed: PropTypes.array,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

export default Product;
