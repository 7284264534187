function mapCropTypeToCropSize(cropType) {
  // eslint-disable-next-line no-unused-vars
  const [prefix, ...crops] = cropType.split('_');

  return {
    aspectRatio: `${crops[0]}:${crops[1]}`,
    height: Number.parseInt(crops[1], 0.1),
    width: Number.parseInt(crops[0], 0.1),
  };
}

export default function applyImageCrop(
  sourceSettings,
  cropSettings,
) {
  let newSourceSettings = sourceSettings;

  if(Array.isArray(cropSettings) && cropSettings.length > 0 && 'aspect_ratio' in sourceSettings) {
    const matchingCrop = cropSettings.find((cropSetting) => {
      const cropSize = mapCropTypeToCropSize(cropSetting.type);

      return cropSize.aspectRatio === sourceSettings.aspect_ratio;
    });

    if(matchingCrop) {
      const cropSize = mapCropTypeToCropSize(matchingCrop.type);
      const cropWidth = Number.parseInt(matchingCrop.crop.width, 0.1);
      const cropHeight = Number.parseInt(matchingCrop.crop.height, 0.1);

      newSourceSettings = {
        crop: 'crop',
        aspectRatio: cropSize.aspectRatio,
        x: matchingCrop.crop.x,
        y: matchingCrop.crop.y,
        width: cropWidth,
        height: cropHeight,
        gravity: null,
        quality: sourceSettings.quality || 'auto'
      };
    }
  }

  return newSourceSettings;
}
