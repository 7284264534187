import React from 'react';
import styled from 'styled-components';
import MediaImageContainer from '../../MediaImage/index.c';

export default styled(MediaImageContainer)`
  img {
    max-height: 80vh;
    max-width: 80vw;
  }
`;
