import React from 'react';
import styled from 'styled-components';

export default styled.div`
  height: 20px;
  position: relative;
  width: 20px;
  // Correct the position of the marker
  top: -10px;
  left: -10px;

  svg {
    fill: #fd6b0d;
    height: 20px;
    position: relative;
    transition: fill 300ms;
    width: 20px;
  }
`;
