import styled, { keyframes } from 'styled-components'

const ProductFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ProductBlock = styled('div')`
  flex: 1;
  flex-basis: calc(50% - 8px);
  margin-bottom: 16px;
  margin-right: 16px;
  opacity: 0;
  
  @media (min-width: 1024px) {
    flex-basis: calc(25% - 24px);
    max-width: calc(25% - 24px);
    margin-bottom: 32px;
    margin-right: 32px;
  }
  
  // fix for the columns
  &:nth-child(2n + 2) {
    margin-right: 0;
    
    @media (min-width: 1024px) {
       margin-right: 32px;
    }
    
    @media (min-width: 1417px) {
      margin-right: 32px;
    }
  }
  
  .animate & {
    animation: ${ProductFadeIn} 750ms cubic-bezier(0, 0, 0.32, 0.92) forwards;
    
    &:nth-child(4n + 4) {
      @media (min-width: 1024px) {
         margin-right: 0;
      }
    }
     
    &:nth-child(1) {
      animation-delay: 1350ms;
    }
  
    &:nth-child(2) {
      animation-delay: 1400ms;
    }
    
    &:nth-child(3) {
      animation-delay: 1450ms;
    }
  
    &:nth-child(4) {
      animation-delay: 1500ms;
    }
  }
`;

export default ProductBlock;
