import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import QuoteElement from './styled/QuoteElement';
import Author from './styled/Author';
import Figure from './styled/Figure';

const Quote = ({ paragraph }) => {
  return (
    <Figure>
      <QuoteElement><span>&ldquo;</span>{paragraph.field_quote}&rdquo;</QuoteElement>
      {paragraph.field_author &&
        <Author>- {paragraph.field_author}</Author>
      }
    </Figure>
  );
};

Quote.Proptypes = {
  paragraph: PropTypes.shape({
    field_quote: PropTypes.string.isRequired,
    field_author: PropTypes.string.isRequired
  }).isRequired
};

export default Quote;
