// eslint-disable-next-line
export function getCloudinaryID(
  url,
  cloudName,
  cloudinaryBase = '//res.cloudinary.com',
) {
  // Return a Cloudinary ID
  const cloudinaryBaseURL = `${cloudinaryBase}/${cloudName}/image/upload/`;
  let ID = '';

  if(url && url.indexOf(cloudinaryBaseURL) > -1) {
    // Always use the second part of a splitted url
    const splittedUrl = url.split(cloudinaryBaseURL)[1];
    // Strip the version from the url
    let cloudinaryID = splittedUrl.split('/');

    if(cloudinaryID.length > 2) {
      // Strip the version from the url
      cloudinaryID = cloudinaryID.slice(1);
      // Concatenate the resulting path
      ID = cloudinaryID.join('/');
    } else {
      ID = cloudinaryID[1];
    }
  } else if(url.indexOf('cloudinary://') > -1) {
    ID = url.replace('cloudinary://', '');
  }

  if(ID && ID !== '') {
    const splitOnDot = ID.split('.');
    splitOnDot.pop();

    return splitOnDot.join('.');
  }

  return url;
}
