import * as React from 'react';

const Context = React.createContext({
  cl: null,
  cloudinaryOptions: {
    secure: true,
  },
  cloudName: '',
  baseImageSettings: {
    crop: 'fill',
    placeholder: '',
    fetch_format: 'auto',
    quality: 'auto:eco'
  },
  previewImageSettings: {
    width: 'auto',
    height: 150,
    effect: 'blur:600',
    quality: 70,
  },
  initCloudinary: url => url,
});

export const CloudinaryProvider = Context.Provider;
export const CloudinaryConsumer = Context.Consumer;
