import React from 'react';
import styled from 'styled-components';
import productBG from '../../../../shared-styles/productBG';

const imageSettings = {
  breakpoints: {
    mobile: {
      breakpoint: '',
      width: 165,
      aspect_ratio: '1:1',
    },
    tablet: {
      breakpoint: '768px',
      height: 280,
      width: 384,
    },
  },
};
export default styled.div`
  ${productBG(imageSettings)}
  overflow: hidden;
`;
