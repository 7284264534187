import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';

export default styled.div`
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  
  @media (min-width: 820px) {
    margin: ${structureStyles.desktopValues.spacing} auto;
    padding: 0;
    width: 800px;
  }
`;
