import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.span`
  float: right;
  
  svg {
    fill: ${davidoff.colors.brown}; 
    height: 20px !important;
    width: 20px !important;
  }
  
  @media (min-width: 1024px) {
    display: none;
  }
`;
