import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import icons from '../../../icons/icons';
import StandardMenuItem from '../StandardMenuItem/StandardMenuItem';
import TopBarFlyOut from '../../TopBarFlyOut/TopBarFlyOut';
import FlyOutStore from '../../../../../stores/FlyOutStore';
import SearchForm from '../../../SearchForm/SearchForm';

@observer
class SearchMenuItem extends React.Component {
  @observable FlyOutStore = FlyOutStore.getInstance();

  constructor(props) {
    super(props);

    this.openFlyOut = this.openFlyOut.bind(this);
  }

  openFlyOut(ev) {
    ev.preventDefault();

    this.FlyOutStore.openFlyOut('search');
  }

  render() {
    const { menuItem, currentLanguage, lang, settings } = this.props;
    const Title = <span>{menuItem.title} {icons.search}</span>;

    return (
      <StandardMenuItem menuItem={menuItem} onClick={this.openFlyOut} title={Title} active={this.FlyOutStore.flyOuts.search}>
        <TopBarFlyOut type='search'>
          <SearchForm focus={this.FlyOutStore.flyOuts.search} lang={lang} settings={settings} currentLanguage={currentLanguage} />
        </TopBarFlyOut>
      </StandardMenuItem>
    );
  }
}

SearchMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    uri: PropTypes.string,
    external: PropTypes.bool.isRequired,
  }).isRequired,
  currentLanguage: PropTypes.shape().isRequired,
  lang: PropTypes.string.isRequired,
  settings: PropTypes.shape().isRequired,
};
SearchMenuItem.defaultProps = {};

export default SearchMenuItem;
