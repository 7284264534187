import React from 'react';
import PropTypes from 'prop-types';
import Image from './styled/Image';
import Label from './styled/ThumbLabel';
import Input from './styled/Input';

const SliderThumb = ({ data, mediaReference, slideIdx, handleChange, selected, className }) => {
  const settings = {
    height: 106,
    width: 176,
    crop: 'fill_pad',
    gravity: 'auto',
  };

  const sources = [
    {
      settings,
      media: '(min-width: 0)',
    }
  ];

  return (
    <div className={className}>
      <Label
        htmlFor={`slide-thumb-${slideIdx}`}
        selected={slideIdx === selected}
      >
        <Image
          imageType="SliderThumb"
          data={data}
          mediaReference={mediaReference}
          sources={sources}
          settings={settings}
        />
        <Input
          type='radio'
          id={`slide-thumb-${slideIdx}`}
          name='slide-thumb'
          value={slideIdx}
          onChange={handleChange}
          checked={slideIdx === selected}
        />
      </Label>
    </div>
  );
};

SliderThumb.propTypes = {
  data: PropTypes.shape().isRequired,
  mediaReference: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  slideIdx: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
};

export default SliderThumb;
