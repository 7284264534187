import { inject, observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import davidoff from '../../../themes/davidoff';
// Stores
import SiteStore from '../../../stores/SiteStore';
// Components
import SocialMenu from '../menus/SocialMenu/SocialMenu';
import FooterMenu from '../menus/FooterMenu/FooterMenu';
import TranslationStore from '../../../stores/TranslationStore';

const CopyText = styled.p`
  color: ${davidoff.colors.gray};
  margin-bottom: 0;
`;
const Footer = styled.footer`
  background: ${davidoff.colors.darkblue};
  color: ${davidoff.colors.gray};
  font-size: 14px;
  height: 225px;
  padding: 30px 0 25px;
  text-align: center;
  
  @media only screen and (min-width: 768px) {
    padding: 60px 0 55px;
  }
  
  p {
    color: ${davidoff.colors.gray};
    font-size: 14px;
  }
`;

@inject('translationStore')
@observer
class FooterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.SiteStore = SiteStore.getInstance();
  }
  render() {
    const { settings, lang, translationStore } = this.props;

    return (
      <Footer className='site-footer' id='footer'>
        <SocialMenu settings={settings} type={this.SiteStore.socialType} />
        <FooterMenu settings={settings} language={lang} />
        <CopyText
          dangerouslySetInnerHTML={{
          __html: `&copy; ${new Date().getFullYear()} ${translationStore.translate('Zino Davidoff - All Rights Reserved', lang, settings)}`,
          }
        } />
      </Footer>
    );
  }
}

FooterComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  settings: PropTypes.shape().isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};
FooterComponent.defaultProps = {
  lang: 'en',
};

export default FooterComponent;
