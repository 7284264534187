import * as React from 'react';
import * as uuid from 'uuid';

export const Image = ({
  sources,
  src,
  alt,
  title,
  className,
  imageType
}) => {
  if (!Array.isArray(sources) || sources.length === 0) {
    return null;
  }

  const fallback = <img imagetype={imageType} className={className} src={src} alt={alt} title={title} />;

  return (
    <picture className={className}>
      {sources.map(source => (
        <source key={uuid.v4()} {...source} />
      ))}
      {fallback}
    </picture>
  )
};

export default Image;
