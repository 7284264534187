import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../themes/davidoff';
import structureStyles from '../../../shared-styles/structure-styles.js';

export default styled.figcaption`
  color: ${davidoff.colors.brown};
  font-size: 14px;
  line-height: 24px;
  margin-left: ${structureStyles.mobileValues.titleIndent};
  padding-top: 1em;
  position: relative;
  
  &:before,
  &:after {
    background-color: ${davidoff.colors.orange};
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 8px;
  }
  
  &:after {
    transform: skew(-20deg, 0deg);
    transform-origin: bottom left;
    width: 60px;
  }
    
  @media (min-width: 768px) {
    margin-left: ${props => props.imageStyle === 'full_width_image' ? '104px' : '32px'};
    max-width: 80%;
  }

  @media (min-width: 1024px) {    
    max-width: 280px;
  }
`;
