import React from 'react';
import PropTypes from 'prop-types';
import { Value } from './styled/Value';
import { Label } from './styled/Label';

const Indicator = ({ paragraph }) => {
  const Indication = [];
  const scale = 12;

  for(let i = 1; i <= scale; i++) {
    Indication.push(<Value key={`${paragraph.field_label}-${i}`} activated={i <= paragraph.field_taste_indicator}>{i}</Value>);
  }

  return (
    <li>
      <Label>{paragraph.field_label}</Label>
      {Indication}
    </li>
  );
};

Indicator.propTypes = {
  paragraph: PropTypes.shape({
    field_label: PropTypes.string.isRequired,
    field_taste_indicator: PropTypes.number.isRequired,
  }).isRequired,
};

export { Indicator };
