import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../themes/davidoff';

export default styled.div`
  ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '14px')}
  background: ${davidoff.colors.brown};
  border-radius: 3px;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 8px;
  padding: 10px 10px 8px;
  position: relative;
  
  &::after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 5px solid ${davidoff.colors.brown};
    bottom: 0;
    content: '';
    left: 50%;
    position: absolute;
    transform: translate(-50%, 100%);
  }
  
  ${(props) => props.show ? `
   display: block;
  ` : `
    display: none;
  `}
`;
