import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import getNested from 'get-nested';
import md5 from 'md5';
import { withCookies, Cookies } from 'react-cookie';
// Stores
import SiteStore from '../../../stores/SiteStore';
// Components
import SearchButton from '../../style-guide/Button/SearchButton';
import Input from '../../style-guide/InputText/InputText';
import TranslationStore from '../../../stores/TranslationStore';

const SearchInput = styled(Input)`
  -webkit-appearance: none;
  -moz-appearance: none;

  ${props => props.error ? `
  border-color: red;
  border-right-color: #fff;
  ` : ``}
  
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    font-size: 16px; // prevent iOS zoom
  }
}
`;

const Form = styled.form`
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

@inject('translationStore')
@observer
class SearchForm extends Component {
  constructor(props) {
    super(props);

    this.fields = {};

    this.state = {
      valid: false,
      validated: false,
    };

    this.SiteStore = SiteStore.getInstance();

    this.addValue = this.addValue.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    // Prefill the search field
    this.addValue();
  }

  // Component update to focus on the field if our prop is set to focus
  componentDidUpdate() {
    // focus on first field when
    const { focus } = this.props;

    if(focus) {
      return this.fields['search'].focus();
    }

    return false;
  }

  addValue() {
    if(getNested(() => this.SiteStore.queryParams.search, false)) {
      this.fields['search'].value = this.SiteStore.queryParams.search;
    }
  }
  // Validate the form, no empty fields allowed
  validate(ev) {
    let valid = false;
    let validated = false;

    // Check if the fields are set only have one field here....
    Object.keys(this.fields).map((field) => {
      if(this.fields[field].value === '') {
        valid = false;
        validated = true;

        this.setState({
          valid: false,
          validated: true,
        });
      } else {
        valid = true;
        validated = true;

        this.setState({
          valid: true,
          validated: true,
        });
      }
    });

    // Prevent submission when the field is invalid or not validated
    if(!valid || !validated) {
      ev.preventDefault();
    } else {
      this.onSubmit(ev);
    }
  }

  onSubmit(ev) {
    const { lang, settings, cookies } = this.props;
    ev.preventDefault();

    let agegateParams = '?';
    if(cookies.get('DavidoffAgegatePassed') === md5('ZinoDavidoff-age-gate-oke-passed')) {
      agegateParams = '?agegate=All&agegate_product=All&';
    }

    window.location.href = `${settings.languages[lang].uri}search${agegateParams}search=${this.fields['search'].value}`;
  }

  render() {
    const { lang, settings, className, translationStore } = this.props;
    
    return (
      <Form
        action={`${settings.languages[lang].uri}search`} // The slash is in the json
        onSubmit={this.validate}
        className={className}
      >
        <SearchInput
          type='text'
          placeholder={translationStore.translate('What are you looking for?')}
          name='search'
          error={!this.state.valid && this.state.validated}
          ref={ref => this.fields['search'] = ref}
        />
        <SearchButton>{translationStore.translate('Search')}</SearchButton>
      </Form>
    );
  }
}

SearchForm.propTypes = {
  focus: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies),
  translationStore: PropTypes.instanceOf(TranslationStore),
};
SearchForm.defaultProps = {
  focus: false,
  className: '',
};

export default withCookies(SearchForm);
