import React from 'react';
import styled from 'styled-components';
import ProductHighlightItem from './HighlightItemWrapper';
import davidoff from '../../../../themes/davidoff';
import linkStyles from '../../../../shared-styles/link-styles.js';

export default styled.h5`
  ${props => linkStyles.link(props)}
  
  color: #333;
  font-size: 12px;
  line-height: 1.25em;
  margin: 15px 7.5px 0;
  text-align: left;
  text-decoration: none;
  z-index: 1;
  
  &::after {
    border-color: #333;
    margin-left: 5px;
  }
  
  &::after {
    border-color: #333;
    margin-left: 5px;
  }
  
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 1.714;
    
    ${ProductHighlightItem} a:hover &::after {
      margin-left: 10px;
    }
  }
`;
