import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import SearchResultLinkText from './SearchResultLinkText';
import PaginatorWrapper from '../../Paginator/styled/PaginatorWrapper';

export default styled.article`
  background-color: ${davidoff.colors.beige};
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 800px;
  transition: background-color 0.35s ease-out;
  
  &:last-of-type {
    margin-bottom: 30px;
  }
  
  &:hover {
  background-color: ${davidoff.colors.lightbrown};

    ${SearchResultLinkText}::after {
      margin-left: 15px;
    }
  }
  
  ${PaginatorWrapper} {
    padding-left: 0;
    width: 80%;
    max-width: 800px;
  }
`;
