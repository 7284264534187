import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ShowEnglishLink = styled(Link)`
  margin-left: 10px;
  
  @media (min-width: 768px) {
    &:hover {
      color: #fff;
    }
  }
`;

export default ShowEnglishLink;
