import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';
import MenuListItem from './MenuListItem';

export default styled.span`
  cursor: pointer;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  
  @media only screen and (min-width: 1025px) {
    line-height: inherit;
  }
  
  ${MenuListItem}:hover > div > a > & {
    @media only screen and (min-width: 1025px) {
      border-bottom: 1px solid ${davidoff.colors.orange};
    }
  }
`;
