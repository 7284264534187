import { getBackgroundImagesByURL } from "../misc/cloudinaryBackgrounds";
import React from "react";

const defaultSettings = {
  crop: "fill",
  breakpoints: {
    mobile: {
      breakpoint: "",
      height: 385,
      width: 385,
      aspect_ratio: "1:1",
    },
  },
};

const getProductBackgrounds = (settings = {}, isCoffee = false) => {
  const bgSettings = Object.assign({}, defaultSettings, settings);
  const productBGImage =
    isCoffee
      ? "https://res.cloudinary.com/zinodavidoff/image/upload/v1661777566/background-image/DAVIDOFF_Background_6zu2_01.jpg"
      : "https://res.cloudinary.com/zinodavidoff/image/upload/v1507225943/Textured-wall_cleaned_yy6tbv.jpg";
  const images = getBackgroundImagesByURL(
    {
      url: productBGImage,
    },
    bgSettings
  );

  const styles = Object.keys(bgSettings.breakpoints)
    .map((breakpoint) => {
      const breakpointObj = bgSettings.breakpoints[breakpoint];
      return `${
        breakpointObj.breakpoint !== ""
          ? `@media (min-width: ${breakpointObj.breakpoint}) {`
          : ""
      }
      background-image: url(${images[breakpoint]});
    ${breakpointObj.breakpoint !== "" ? "}" : ""}`;
    })
    .join("");

  return `
  background-position: center;
  background-size: cover;
  ${styles}
  `;
};

export default getProductBackgrounds;
