import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

const CampaignHeader = styled.div`
  max-height: calc(100vh - 50px);
  width: 100%;

  @media (min-width: 1025px) {
    margin: 0 0 160px;
    padding: 0;
  }
`

export const CampaignHeaderColor = styled(CampaignHeader)`
  ${(props) => `background-color: ${props.backgroundColor};`}
`

export const CampaignHeaderContent = styled.div`
  width: 94%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  > div {
    &:nth-child(1){
      padding-bottom: 1.315%;
      flex: 1;
    }

    &:nth-child(2){
      flex: 0 0 40px;
      padding-top: 1.315%;
    }
  }

  @media (min-width: 768px) {
    max-width: 1008px;
    flex-direction: row;
    width: 100%;

    > div {
      flex: 1 !important;

      &:nth-child(1){
        padding-right: 1.315%;
        padding-bottom: 0;
      }

      &:nth-child(2){
        padding-left: 1.315%;
        padding-top: 0;
      }
    }
  }

  h2{ 
    ${(props) => props.theme.fonts.parse('serif', 'medium', '24px')}
    color: #fff;
    margin: 0;
    
    @media (min-width: 768px) {
      ${(props) => props.theme.fonts.parse('serif', 'medium', '70px')}
    }
  }

  p{
    ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '16px')}
    color: #fff;
    margin: 0 0 2em;

    @media (min-width: 768px) {
      ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '22px')}
    }
  }
`;

export const MediaContainer = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: -120px;
    position: relative;
  }

  img{
    width: 100%;
    height: 100%;
    margin: 40px auto 0;
    max-width: 160px;
    object-position: bottom center;
    object-fit: contain;
    
    @media (min-width: 768px) {
      margin: 0;
      max-width: 280px;
      max-height: calc(100% + 120px);
    }

  }

  .video-element {
    max-width: 100%;
    margin: 40px auto 0;
    width:100%;
    
    @media (min-width: 768px) {
      position: absolute;
      bottom: 0px;
      margin: 0 auto;
      left: 50%; 
      transform: translateX(-50%);
    }
  }
`

export const ContentContainer = styled.div`
  padding: 40px 0;  
  
  @media (min-width: 768px) {
    padding: 0;
    margin: 160px 0;  
  }
`

export const CtaButton = styled.a`
  ${props => props.theme.fonts.parse('sansSerif', 'black', '13px')}
  background-color: transarent;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  letter-spacing: 1px;
  outline: none;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 300ms, color 300ms, border-color 300ms;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: inline-block;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    width: auto;
  }
  
  @media only screen and (min-width: 1200px) {
    min-width: 176px;
  }

  &:hover {
    background-color: ${davidoff.colors.orange};
    border-color: ${davidoff.colors.orange};
    color: #fff;
  }

`;
