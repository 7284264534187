import React from 'react';
import styled from 'styled-components';
// import davidoff from '../../../../themes/davidoff';

export default styled.section`
  @media (min-width: 768px) {
    display: ${props => props.videoPlaying ? 'none' : 'block'};
    background-image: url(${props => props.backgroundImages.desktop});
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 105%;
    left: 0;
  }
`;
