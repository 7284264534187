import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';
import davidoff from '../../../../themes/davidoff';

export default styled.div`
  margin: 30px;
 
  @media (min-width: 820px) {
    margin: ${structureStyles.desktopValues.spacing} auto;
    width: 800px;
  }
  
  h1 {
    font-size: 2.5em;
    line-height: 1.2em;
    
    @media (min-width: 768px) {
      font-size: 3em;
    }
  }
  
  h2 {
    ${({theme}) => theme.fonts.parse('sansSerif', 'bold', '22px')}
    color: ${davidoff.colors.orange};
    margin-bottom: 0.25em;
    font-size: 2em;
    line-height: 1.2em; 
    
    @media (min-width: 768px) {
      font-size: 2.5em;
    }
    
    span {
      font-size: inherit !important;
    }
    
    .components > div > & {
      &:first-child {
        margin-top: 1.6875em;
      }
    }
  }
  
  h3 {
    font-size: 1.75em;
    line-height: 1.2em;

    @media (min-width: 768px) {
      font-size: 2em;
    }
  }
  
  h4 {
    font-size: 1.5em;
    line-height: 1.2em;
  }
`;
