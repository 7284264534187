import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';

export default styled.div`
  color: rgb(${props => props.inverseTextColor ? '255, 255, 255' : '0, 0, 0'});
  
  @media (min-width: 768px) {
    width: 50%;
    max-width: 592px;
    ${props => props.invertImagePosition ? 'order: 2' : ''}; 
    
    margin-${props => props.invertImagePosition ? 'left' : 'right'}: ${structureStyles.desktopValues.gridInnerSpacingHalf};
    margin-${props => props.invertImagePosition ? 'right' : 'left'}: auto;
  }
  
  @media (min-width: 1024px) {
    padding-${props => props.invertImagePosition ? 'right' : 'left'}: ${structureStyles.desktopValues.sideSpacing};
  }
    
  & + & {
    @media (min-width: 768px) {
      align-self: center;
      ${props => props.invertImagePosition ? 'order: 1' : ''}; 
      padding-${props => props.invertImagePosition ? 'right' : 'left'}: 0;
      padding-${props => props.invertImagePosition ? 'left' : 'right'}: ${structureStyles.desktopValues.sideSpacing};
      margin-${props => props.invertImagePosition ? 'right' : 'left'}: ${structureStyles.desktopValues.gridInnerSpacingHalf};
      margin-${props => props.invertImagePosition ? 'left' : 'right'}: auto;
    }
  }
  
  &::after {
    content: '';
    display: block;
    clear: both;
  }
`;
