import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';

export default styled.li`
  // css specificity problem
  display: none !important;
  
  @media only screen and (min-width: 1025px) {
    display: block !important;
    max-width: 280px;
  }
  
  h4 {
    line-height: 28px;
  }
  
  img {
    backface-visibility: hidden;
    display: block;
    max-height: 204px;
    transition: transform 0.35s linear;
  }
  
  .image-wrapper {
    overflow: hidden;
    margin-bottom: 14px;
  }
  
  &:hover img {
    @media only screen and (min-width: 1025px) {
      transition: transform 2s linear;
      transform: scale(1.1);
    }
  }
  
  figcaption {
    ${(props) => props.theme.fonts.parse('serif', 'medium', '18px')}
    color: #000;
    line-height: 28px;
    margin-bottom: 0.25em;
    min-height: 56px;
  }
  
  
  span {
    ${(props) => props.theme.fonts.parse('sansSerif', 'black', '14px')}
    border-bottom: none !important;
    color: ${davidoff.colors.orange};
    letter-spacing: 1px;
    text-transform: uppercase;
    
    &::after {
      border-right: 2px solid ${davidoff.colors.orangeLink};
      border-bottom: 2px solid ${davidoff.colors.orangeLink};
      content: '';
      display: inline-block;
      height: 6px;
      margin-left: 10px;
      position: relative;
      transition: all 0.35s linear;
      transform: rotate(-45deg);
      width: 6px;
    }
  }
  
  &:hover span {
    &::after {
      margin-left: 15px;
    }
  }
`;
