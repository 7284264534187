import React from 'react';
import styled from 'styled-components';

export const VideoWrapper = styled.div`
  padding-bottom: 42.55%;
  width: 100%;
  position: relative;
  
  iframe, video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

`;

export const MuteBox = styled.button`
  position: absolute;
  left: 50%;
  top: 0;
  color: #fff;
  z-index: 9;
  padding: 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  width: 100%;
  max-width: 100vw;
  transform: translateX(-50%);

  label{
    opacity: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-family:'Saya Davidoff Bold';
    float: none;
    width: auto;
    margin: 2px 0 0;
  }

  svg {
    fill: #fff;
    margin: 0 0 0 10px;
    max-height: 24px;
    max-width: 24px;
  }

  &:hover{
    label{
      opacity: 1;
    }
  }
`;