import { observable, action } from 'mobx';
import fetch from 'fetch-everywhere';
import getNested from 'get-nested';

export class TranslationStore {
  static instance;

  static getInstance() {
    if(!TranslationStore.instance) {
      TranslationStore.instance = new TranslationStore();
    }

    return TranslationStore.instance;
  }

  @observable translations = {};
  @observable currentLanguage = 'en';
  @observable baseUrl = '';

  /*
   * Translate function that takes a string (english) and returns the translated version.
   */
  translate = (string, lang) => getNested(() => this.translations[lang || this.currentLanguage][string], string);

  fetchNewTranslation = langCode => (
    new Promise((resolve) => {
      if(!this.translations[langCode]) {
        if (typeof window !== "undefined") {
          if (langCode !== 'und') {
            return resolve(fetch(`${window.location.origin}/api/i18n/${langCode}`)
              .then(result => result.json())
              .then(json => this.setTranslation(langCode, json)));
          }
        }
      }

      return resolve();
    })
  );

  @action
  setTranslation = (langCode, translations) => {
    this.translations[langCode] = translations;
  }

  @action
  setLanguage = (langCode) => {
    if(!this.translations[langCode]) {
      this.fetchNewTranslation(langCode);
    }

    this.currentLanguage = langCode;
  }

  @action
  setBaseUrl = (url) => {
    this.baseUrl = url;
  }
}

export default TranslationStore;
