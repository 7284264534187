const Davidoff = {
  breakpoints: {
    mobile: {
      min: 0,
      max: 480,
    },
  },
  colors: {
    lime: '#f7f5f3',
    darkblue: '#1d1e2a',
    gray: '#d9d1ca',
    lightGrey: '#fbfaf9',
    darkGrey: '#1d1d1b',
    brown: '#8c7d70',
    lightbrown: '#ece8e4',
    beige: '#f4f2f0',
    orange: '#fd6b0d',
    orangeLink: '#fc6b0d',
    black: '#000',
    veryLightGrey: '#f9f8f7',
  },
};

export default Davidoff;
