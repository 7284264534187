import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import BIWrapper from './BrandIntroductionWrapper';
import BIVideoWrapper from './BrandIntroductionVideoWrapper';
import BILabel from './BrandIntroductionLabel';
import BIContent from './BrandIntroductionContent';
import BILink from './BrandIntroductionLink';
import BIBgImage from './BrandIntroductionBgImage';

const baseDelay = 850;

const easing = {
  outExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
  inOutExpo: 'cubic-bezier(1, 0, 0, 1)',
};

const startAnimationClass = '.animate';

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

const slideInLeft = keyframes`
  0% {
		opacity: 0;
		transform: translateX(-100%);
	}
	
	100% {
      opacity: 1;
      transform: translateX(0%);
	}
`;

const slideInTop = keyframes`
  0% {
		transform: translateY(-100%);
	}
	
	100% {
      transform: translateY(0%);
	}
`;

const fadeIn = keyframes`
  0% {
		opacity: 0;
	}
	
	100% {
      opacity: 1;
	}
`;

const fadeOut = keyframes`
  0% {
		opacity: 1;
	}
	
	100% {
      opacity: 0;
	}
`;

const fadeInTextBlack= keyframes`
  0% {
		color: rgba(0, 0, 0, 0);
	}
	
	100% {
		color: rgba(0, 0, 0, 1);
	}
`;

const nudgeBottom = keyframes`
  0% {
    transform: translateY(5px);
	}
	
	100% {
    transform: translateY(0);
	}
`;

const nudgeLeft = keyframes`
  0% {
    transform: translateX(-5px);
	}
	
	100% {
    transform: translateX(0);
	}
`;

const growHyphen = keyframes`
  0% {
    width: 0px;
	}
	
	100% {
    width: 60px;
	}
`;

export default styled(ScrollEffect)`
  overflow: hidden;
  position: relative;

  .bi-animation-overlay {
    background-color: rgb(216,210, 203);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    opacity: 0;
  }
  
  &${startAnimationClass} .bi-animation-overlay {
    animation:
      ${css`
        ${fpsToMs(25)}ms ${easing.OutExpo} ${baseDelay}ms ${slideInLeft},
        ${fpsToMs(50)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(25)}ms ${fadeOut}
      `};
    animation-fill-mode: forwards;
  }

  ${BIWrapper} img, 
  ${BIWrapper} picture, 
  ${BIBgImage} {
    opacity: 0;
    transform: translateX(-5px);
  }
  &${startAnimationClass} ${BIWrapper} img,
  &${startAnimationClass} ${BIWrapper} picture,
  &${startAnimationClass} ${BIBgImage} {
    animation: ${css`
      ${fpsToMs(52)}ms ease ${baseDelay + fpsToMs(18)}ms ${fadeIn},
      ${fpsToMs(65)}ms ease-out ${baseDelay + fpsToMs(20)}ms ${nudgeLeft}
    `};

    animation-fill-mode: forwards;
  }

  ${BIVideoWrapper} {
    opacity: 0;
  }
  
  &${startAnimationClass} ${BIVideoWrapper} {
    animation: ${css`${fpsToMs(52)}ms ease ${baseDelay + fpsToMs(18)}ms ${fadeIn}`};
    animation-fill-mode: forwards;
  }

  .bi-animation-bg::before {
    @media (min-width: 768px) {
      transform: translateY(-100%);
    }
  }
  
  &${startAnimationClass} .bi-animation-bg::before {
    animation: ${css`${fpsToMs(25)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(25)}ms ${slideInTop}`};
    animation-fill-mode: forwards;
  }
  
  ${BILabel} {
    color: rgba(0, 0, 0, 0);
      
    ${startAnimationClass} & {
      animation: ${css`${fpsToMs(1)}ms ease ${baseDelay + fpsToMs(44)}ms ${fadeIn}`}
      animation: ${fpsToMs(20)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(41)}ms ${fadeInTextBlack};
      animation-fill-mode: forwards;
    }
    
    &::before {
      opacity: 0;
      
      ${startAnimationClass} & {
        animation: ${css`${fpsToMs(1)}ms ease ${baseDelay + fpsToMs(44)}ms ${fadeIn}`};
        animation-fill-mode: forwards;
      }
    }
      
    &::after {
      opacity: 0;
      width: 0;
      
      ${startAnimationClass} & {
        animation: ${css`
          ${fpsToMs(1)}ms linear ${baseDelay + fpsToMs(44)}ms ${fadeIn}, 
          ${fpsToMs(6)}ms ease-out ${baseDelay + fpsToMs(44)}ms ${growHyphen}
        `};
        animation-fill-mode: forwards;
      }
    }
  }
  
  &${startAnimationClass} ${BILabel} {
    animation: ${css`${fpsToMs(20)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(41)}ms ${fadeInTextBlack}`};
    animation-fill-mode: forwards;
    
    &::before {
      animation: ${css`${fpsToMs(1)}ms ease ${baseDelay + fpsToMs(44)}ms ${fadeIn}`};
      animation-fill-mode: forwards;
    }
      
    &::after {
      animation: ${css`
        ${fpsToMs(1)}ms linear ${baseDelay + fpsToMs(44)}ms ${fadeIn},
        ${fpsToMs(6)}ms ease-out ${baseDelay + fpsToMs(44)}ms ${growHyphen}
      `};
      animation-fill-mode: forwards;
    }
  }
  
  ${BIContent}, ${BILink} {
    opacity: 0;
    transform: translateY(5px);
  }
  
  &${startAnimationClass} ${BIContent}, &${startAnimationClass} ${BILink}{
    animation: ${css`
      ${fpsToMs(43)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(42)}ms ${fadeIn},
      ${fpsToMs(28)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(38)}ms ${nudgeBottom}
    `};
    animation-fill-mode: forwards;
  }
`;
