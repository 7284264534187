import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';

export default styled.div`
  padding: ${structureStyles.mobileValues.spacing} 0;
  text-align: center;
    
  @media (min-width: 1025px) {
    padding: ${structureStyles.desktopValues.spacing} 0;
  }
`;
