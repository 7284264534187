import React from 'react';
import styled from 'styled-components';

export default styled.div`
  padding: 25px 5%;
  width: 100%;
  
  @media (min-width: 1024px) {
    max-width: 32%;
    min-width: 32%;
    padding: 15px;
    width: auto;
  }
  
  @media (min-width: 1250px) {
    padding: 0;
  }
`;
