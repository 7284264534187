import { observable } from 'mobx';

class FlyOutStore {
  static instance;

  constructor() {
    this.resetAllFlyouts = this.resetAllFlyouts.bind(this);
    this.openFlyOut = this.openFlyOut.bind(this);
    this.closeFlyOut = this.closeFlyOut.bind(this);
  }

  static getInstance() {
    if(!FlyOutStore.instance) {
      FlyOutStore.instance = new FlyOutStore();
    }

    return FlyOutStore.instance;
  }

  @observable flyOuts = {
    newsletter: false,
    language_switcher: false,
    search: false,
  };

  resetAllFlyouts() {
    this.flyOuts = {
      newsletter: false,
      language_switcher: false,
      search: false,
    };
  }

  openFlyOut(type) {
    // Loop over flyouts object and change the properties
    // so only the chosen one is opened
    Object.keys(this.flyOuts).forEach((key) => {
      if(key === type) {
        this.flyOuts[key] = true;
      } else {
        this.flyOuts[key] = false;
      }
    });
  }

  closeFlyOut(type) {
    this.flyOuts[type] = false;
  }
}

export default FlyOutStore;
