import React from "react";
import styled from "styled-components";
import davidoff from "../../../../themes/davidoff";
import productBG from "./../../../../shared-styles/productBG";

const imageSettings = {
  breakpoints: {
    mobile: {
      breakpoint: "",
      height: 640,
      width: 375,
    },
    tablet: {
      breakpoint: "768px",
      height: 1024,
      width: 1024,
    },
    desktop: {
      breakpoint: "1024px",
      height: 768,
      width: 1280,
    },
    desktopL: {
      breakpoint: "1280px",
      height: 1080,
      width: 1920,
    },
    desktopXl: {
      breakpoint: "1920px",
      height: 2160,
      width: 4098,
    },
  },
};

export default styled.div`
  background-color: ${davidoff.colors.lightbrown};
  max-height: 90vh;
  max-width: 90vw;
  padding: 25px 0;
  transition: all 0.35s ease;
  text-align: center;
  ${productBG(imageSettings)}

  @media (min-width: 768px) {
    padding: 50px 0;
  }
`;
