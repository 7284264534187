import React from 'react';
import styled from 'styled-components';
import structure from '../../../../shared-styles/structure-styles';
import productBG from '../../../../shared-styles/productBG'
import MediaImageContainer from '../../MediaImage/index.c';

const imageSettings = {
  breakpoints: {
    mobile: {
      breakpoint: '',
      height: 160,
      width: 300,
    },
    tablet: {
      breakpoint: '768px',
      height: 128,
      width: 176,
    }
  }
};

export default styled(MediaImageContainer)`
  ${productBG(imageSettings)}

  height: auto;
  margin-bottom: 10px;
  width: 100%;
  
  @media (min-width: 768px) {
    height: 128px;
    margin-bottom: 0;
    margin-right: ${structure.desktopValues.spacing}; 
    min-width: 176px;
    max-width: 176px;
    width: auto;
  }
`
