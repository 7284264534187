import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import mapper from '../../index';
import RelatedArticlesView from '../../common/RelatedArticlesView/RelatedArticlesView';
import ProductHighlight from '../../common/ProductHighlight/ProductHighlight'
import { CookieBotDeclaration } from "../../common/Cookiebot/Cookiebot";

class Page extends Component {

  constructor(props) {
    super(props);
  }

  getParagraphs() {
    const { page, data } = this.props;
    return page.field_content.map(item => mapper(data, false, item.target_uuid));
  }

  render() {
    const { page, data, location } = this.props;

    return (
      <div>
        {getNested(() => page.field_header, false) &&
          mapper(data, false, page.field_header.target_uuid)
        }
        <div className="components">{ this.getParagraphs() }</div>
        {getNested(() => page.target_uuid , false) &&
          mapper(data, false, page.target_uuid)
        }
        {location && location.pathname === '/cookies' && (
          <>
            <CookieBotDeclaration cbid={'fe7c810d-7fe7-4209-a874-61522b6c45f6'} />
          </>
        )}
        {!page.additional_blocks && location.pathname !== '/cookies' && (<><RelatedArticlesView page={page} data={data} title />
        <ProductHighlight data={data} /></>)}
      </div>
    );
  }
}

Page.propTypes = {
  page: PropTypes.shape({
    field_content: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    field_header: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    })
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  additional_blocks: PropTypes.bool,
};

export default Page;
