import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import {Link} from 'react-router-dom';
//styled
import Breadcrumbs from './styled/Breadcrumbs';
import BreadcrumbElement from './styled/BreadcrumbElement';
import LastBreadcrumb from './styled/LastBreadcrumb';
import BreadcrumbLink from './styled/BreadcrumbLink';

const Breadcrumb = ({breadcrumbs}) => {
  return (
    <Breadcrumbs>
      <ul>
        {getNested(() => breadcrumbs, false) &&
        breadcrumbs.map((breadcrumb, idx) => {
            if (breadcrumb.uri) {
              return <BreadcrumbElement key={idx}>
                <BreadcrumbLink to={breadcrumb.uri}>
                  {breadcrumb.title}
                </BreadcrumbLink>
                >
              </BreadcrumbElement>
            }
            return <LastBreadcrumb key={idx}>
              {breadcrumb.title}
            </LastBreadcrumb>
          }
        )
        }
      </ul>
    </Breadcrumbs>
  )
};

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
    title: PropTypes.string.isRequired,
    uri: PropTypes.string
  }).isRequired)
};

export default Breadcrumb;
