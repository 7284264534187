import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
// Components
import icons from '../../../../icons/icons';
// Styled Components
import ListItem from './styled/VisualListItem';
import Input from './styled/VisualInput';
import Label from './styled/VisualLabel';
import VisualOptionSelected from './styled/VisualOptionSelected';
import VisualImage from './styled/VisualImage';

const VisualFilterItem = ({option, handler, data, selected}) => {
  const mediaReference = getNested(() => option.variant.field_media_multiple[0]);

  if(!mediaReference) {
    return null
  }

  const settings =  {
    crop: 'pad',
    height: 98,
    gravity: 'center',
    width: 71, // original was: h: 72, w: 52s
  };

  const sources = [
    {
      settings,
      media: '(min-width: 0)',
    }
  ];

  return (
    <ListItem>
      <Label
        htmlFor={`visual-option-${option.key}`}
        title={option.propertyName}
        selected={option.id === selected}
      >
        <VisualImage
          imageType="VisualFilterItem"
          data={data}
          mediaReference={mediaReference}
          sources={sources}
          settings={settings}
        />
        <Input
          id={`visual-option-${option.key}`}
          value={option.id}
          onChange={handler}
          checked={option.id === selected}
        />
        <VisualOptionSelected selected={option.id === selected}>{icons.check}</VisualOptionSelected>
      </Label>
    </ListItem>
  );
};

VisualFilterItem.propTypes = {
  option: PropTypes.shape({
    key: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    variant: PropTypes.shape().isRequired,
  }).isRequired,
  handler: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
};

export default VisualFilterItem;
