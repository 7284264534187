import React from 'react';
import styled from 'styled-components';
import VideoWrapper from '../../BrandIntroduction/styled/BrandIntroductionVideoWrapper';

export default styled.div`
  align-items: center;
  background-image: url(${props => props.images.mobile});
  background-size: cover;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  
  @media (min-width: 768px) {
    background-image: url(${props => props.images.desktop});
    height: auto;
    padding-bottom: 42.5%;
  }
  
  @media only screen and (min-width: 1024px) {
    margin-bottom: 50px;
  }
  
  &:after {
    background-color: rgba(0,0,0,0.2);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  
  .video {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }
  
  ${VideoWrapper} {
    padding: 0;
    height: 100%;
    
    .video-wrapper {
      padding-bottom: 56.25%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
`;
