import React from 'react';
import styled from 'styled-components';

export default styled.div`
  border-top: 1px solid #ece8e4;
  display: flex;
  padding: 20px 0;
  
  ul {
    margin-bottom: 0;
  }
`;
