import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import getNested from 'get-nested';
import root from 'window-or-global';
import debounce from 'lodash.debounce';
import Modal from 'react-modal';
import Player from 'react-player';
import { Link } from 'react-router-dom';
import SiteStore from '../../../stores/SiteStore';
import { stripProtocol, setHttpsProtocol, autoPlayVideo, hasProtocol } from '../../../misc/index';
import modalStyles from '../../../shared-styles/modal-styles';
import BIWrapper from './styled/BrandIntroductionWrapper';
import BIAnimationWrapper from './styled/BrandIntroductionAnimationWrapper';
import BIVideoWrapper from './styled/BrandIntroductionVideoWrapper';
import BIInner from './styled/BrandIntroductionInner';
import BILabel from './styled/BrandIntroductionLabel';
import BIContent from './styled/BrandIntroductionContent';
import BILink from './styled/BrandIntroductionLink';
import BIVideoLink from './styled/BrandIntroductionVideoLink';
import { TranslationStore } from '../../../stores/TranslationStore';
import MediaImageContainer from '../../common/MediaImage/index.c';
import { withCookies, Cookies } from "react-cookie";

const sources = [{
  settings: {
    aspect_ratio: '1920:815',
    crop: 'fill',
    gravity: 'auto',
    placeholder: 'blank',
    width: 1920,
  },
  media: '(min-width: 1024px)',
}, {
  settings: {
    aspect_ratio: '1200:510',
    crop: 'fill',
    gravity: 'auto',
    placeholder: 'blank',
    width: 1200,
  },
  media: '(min-width: 768px)',
}, {
  settings: {
    aspect_ratio: '640:272',
    crop: 'fill',
    gravity: 'auto',
    placeholder: 'blank',
    width: 640,
  },
  media: '(max-width: 767px)',
}];

@inject('translationStore')
@observer
class BrandIntroduction extends React.Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      openPopup: false,
      animationDebug: true,
      autoPlayVideo: autoPlayVideo(),
      videoHasPlayed: false,
      videoPlaying: false,
    };
    this._mounted = false;
    this.SiteStore = SiteStore.getInstance();
    this.videoPlayer = false;
    this.element = false;

    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.checkAutoPlay = debounce(this.checkAutoPlay.bind(this), 100);
    this.scrollToNextBlock = this.scrollToNextBlock.bind(this);
    // this.animationDebugToggle = this.animationDebugToggle.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    root.addEventListener('resize', this.checkAutoPlay);
    // trigger a resize
    if(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
      const evt = document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, root, 0);
      root.dispatchEvent(evt);
    } else {
      root.dispatchEvent(new Event('resize'));
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    root.removeEventListener('resize', this.checkAutoPlay);
  }

  checkAutoPlay() {
    if(!this._mounted) {
      return false;
    }

    const autoPlay = autoPlayVideo();

    if(autoPlay !== this.state.autoPlayVideo) {
      this.setState({
        autoPlayVideo: autoPlay,
      });
    }
  }

  openPopup(ev) {
    ev.preventDefault();

    this.setState({
      openPopup: true,
    });
  }

  closePopup() {
    this.setState({
      openPopup: false,
    });
  }

  scrollToNextBlock(ev) {
    ev.preventDefault();

    // Interactions
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const offset = this.element.targetElement.nextSibling.offsetTop - 57;

    if(isIE11) {
      window.scrollTo(0, offset);
    } else {
      TweenLite.to(window, 1, {
        scrollTo: {
          y: offset,
          autoKill: true,
        },
      });
    }
  }

  onPause = () => {
    this.setState({
      videoPlaying: true,
    });


    if(this.videoPlayer) {
      const internalPlayer = this.videoPlayer.player;
      internalPlayer.play();
    }
  };

  onReady = () => {
    this.setState({
      videoPlaying: true,
    });
  };

  getHeaderImage() {
    const { data, paragraph } = this.props;

    const settings = {
      aspect_ratio: '1920:815',
      crop: 'fill',
      gravity: 'auto',
      height: 815,
    };

    return (<MediaImageContainer
      data={data}
      imageType="Brand introduction"
      mediaReference={paragraph.field_media}
      settings={settings}
      sources={sources}
    />);
  }

  render() {
    const { paragraph, translationStore, hasAnchor, cookies } = this.props;
    const linkArgs = {};
    const videoLink = {};

    let BlockLink = false;

    // Build the correct link for this block
    if(getNested(() => paragraph.field_video_link, false)) {
      linkArgs.to = setHttpsProtocol(paragraph.field_video_link.uri);
      linkArgs.target = '_blank';
      linkArgs.title = paragraph.field_video_link.title === '' ? translationStore.translate('Discover more') : paragraph.field_video_link.title;
    } else if(getNested(() => paragraph.field_link.uri, false)) {
      linkArgs.to = stripProtocol(paragraph.field_link.uri);
      linkArgs.title = paragraph.field_link.title === '' ? translationStore.translate('Discover more') : paragraph.field_link.title;

      if(hasProtocol(paragraph.field_link.uri)) {
        linkArgs.target = '_blank';
      }
    } else if(getNested(() => paragraph.field_no_link_anchor, false)) {
      linkArgs.title = paragraph.field_no_link_anchor;
    }

    // Add a default link
    if(getNested(() => linkArgs.to)) {
      BlockLink = (<BILink
        {...linkArgs}
        onClick={getNested(() => paragraph.field_video_link.uri, false) ? this.openPopup : () => true}
      >
        {linkArgs.title}
      </BILink>);
    } else if(getNested(() => linkArgs.title, false)) {
      BlockLink = (<BILink
        to={'/'}
        {...linkArgs}
        onClick={this.scrollToNextBlock}
      >
        {linkArgs.title}
      </BILink>);
    }

    if (hasAnchor) {
      delete linkArgs.to;

      BlockLink = (<BILink
        {...linkArgs}
        to="#"
        onClick={this.scrollToNextBlock}
      >
        {linkArgs.title}
      </BILink>);
    }

    // Build the video Link
    if(getNested(() => paragraph.field_linked_video, false) && getNested(() => paragraph.field_link.uri, false)) {
      videoLink.to = stripProtocol(paragraph.field_link.uri);
      videoLink.title = paragraph.field_link.title;

      if(hasProtocol(paragraph.field_link.uri)) {
        videoLink.target = '_blank';
      }
    }

    var host = 'https://www.youtube.com';

    return (
      <BIAnimationWrapper ref={e => this.element = e}>
        <div className='bi-animation-overlay' />
        <BIWrapper className={getNested(() => paragraph.field_slow_motion_video, false) ? 'has-video' : ''}>
          {linkArgs.to
            ? (
              <Link
                {...linkArgs}
                onClick={getNested(() => paragraph.field_video_link.uri, false) ? this.openPopup : () => true}
              >
                { this.getHeaderImage() }
              </Link>
            )
            : this.getHeaderImage()
          }
          <BIInner>
            <div className='bi-animation-bg' />
            <BILabel>{paragraph.field_label}</BILabel>
            <BIContent dangerouslySetInnerHTML={{ __html: getNested(() => paragraph.field_text.value, '') }} />
            {BlockLink}
          </BIInner>
          {getNested(() => paragraph.field_slow_motion_video, false) &&
          <BIVideoWrapper>
            <Player
              ref={player => this.videoPlayer = player}
              url={setHttpsProtocol(paragraph.field_slow_motion_video.uri)}
              playing={this.state.videoPlaying}
              loop
              width='100%'
              height='100%'
              muted
              onPause={this.onPause}
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                    mute: 1,
                    vq: 'hd1440',
                    controls: 0,
                  },
                  embedOptions: {
                    host: host
                  }
                },
                vimeo: {
                  playerOptions: {
                    api: 1,
                    background: 1,
                    loop: 1,
                    autoplay: 1,
                    autopause: 1,
                  },
                },
              }}
            />
            {getNested(() => paragraph.field_linked_video, false) && getNested(() => videoLink.to, false) &&
              <BIVideoLink
                {...videoLink}
              >
                {videoLink.title}
              </BIVideoLink>
            }
          </BIVideoWrapper>
          }
          {getNested(() => paragraph.field_video_link.uri, false) &&
          <Modal
            isOpen={this.state.openPopup}
            onRequestClose={this.closePopup}
            style={modalStyles}
            contentLabel='Video modal'
          >
            <BIVideoWrapper>
              <Player
                url={setHttpsProtocol(paragraph.field_video_link.uri)}
                playing
                width='100%'
                height='auto'
                controls
              />
            </BIVideoWrapper>
            <button
              onClick={this.closePopup}
              type='button'
              className='close-modal'
              title='Close'
            />
          </Modal>
          }
        </BIWrapper>
      </BIAnimationWrapper>
    );
  }
}

BrandIntroduction.propTypes = {
  paragraph: PropTypes.shape({
    field_label: PropTypes.string.isRequired,
    field_text: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
    field_media: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    field_link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    field_video_link: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    field_slow_motion_video: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
  clickable: PropTypes.bool,
  hasAnchor: PropTypes.bool,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

BrandIntroduction.defaultProps = {
  clickable: false,
  hasAnchor: false,
};

export default withCookies(BrandIntroduction);
