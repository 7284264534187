import React from 'react';
import styled from 'styled-components';

export default styled.label`
  ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '14px')}
  border-top: 1px solid #e8e3de;
  color: #000;
  display: none;
  margin: 15px -30px 0;
  padding: 15px 30px 5px;
  text-transform: uppercase;
  width: calc(100% + 60px);
  
  @media (min-width: 1024px) {
    display: block;
  }
`;

