import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ThemeContext } from 'styled-components';
import icons from '../icons/icons';
// Stores
import StoreLocatorStore from '../../../stores/StoreLocatorStore';
// components
import InfoWindow from '../GoogleMapsInfoWindow/GoogleMapsInfoWindow';
import MarkerItem from './styled/MarkerItem';
import MarkerNumPoints from './styled/MarkerNumPoints';
import { dataMapper } from '../../index'
import getNested from 'get-nested'

@observer
class GoogleMapsMarker extends React.Component {
  constructor(props) {
    super(props);
    this.StoreLocatorStore = StoreLocatorStore.getInstance();

    this.markerClicked = this.markerClicked.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
  }

  markerClicked() {
    const { points, map, lat, lng } = this.props;

    if(points.length === 1) {
      this.StoreLocatorStore.setOpenMarkerValue(this.props.points[0].store.uuid);
    } else {
      const { $geoService } = this.props;

      map.setZoom($geoService.transform_._zoom + 2);
    }
    map.panTo({ lat, lng });
  }

  closeWindow(ev) {
    ev.stopPropagation();
    this.StoreLocatorStore.setOpenMarkerValue(false);
  }

  render() {
    const { numPoints, points } = this.props;
    let Window = false;

    if(numPoints === 1) {
      const categories = points[0].store.field_product_category.map((category) => {
        const categoryData = dataMapper(this.StoreLocatorStore.data, category.target_uuid);
        return getNested(() => categoryData.name, '');
      }).join(', ');

      Window = <InfoWindow
        store={points[0].store}
        open={this.StoreLocatorStore.openedMarker === points[0].store.uuid}
        closeWindow={this.closeWindow}
        categories={categories}
        theme={this.props.theme}
      />
    }

    return (
      <MarkerItem onClick={this.markerClicked}
        open={this.StoreLocatorStore.openedMarker === points[0].store.uuid}
      >
        {this.StoreLocatorStore.openedMarker === points[0].store.uuid ? icons.acitveMarker : icons.marker }
        {numPoints !== 1  ?
          <MarkerNumPoints theme={this.props.theme} largeNumber={numPoints > 99}>{numPoints}</MarkerNumPoints>
          : ''
        }
        {Window}
      </MarkerItem>
    );
  }
}

function MarkerPoints(props) {
  const theme = React.useContext(ThemeContext);
  console.log(theme);
  return (<MarkerNumPoints theme={theme} largeNumber={props.numPoints > 99}>{props.numPoints}</MarkerNumPoints>);
}

GoogleMapsMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  numPoints: PropTypes.number.isRequired,
  points: PropTypes.array.isRequired,
  theme: PropTypes.shape(),
};

export default GoogleMapsMarker;
