import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { inject, observer } from 'mobx-react';
import icons from '../../../icons/icons';
import ProductStore from '../../../../../stores/ProductStore';
import SiteStore from '../../../../../stores/SiteStore';
import FilterGroup from '../ProductFilterGroup/ProductFilterGroup';
import ProductFilterWrapper from './styled/ProductFilterWrapper';
import FilterInnerWrapper from './styled/FilterInnerWrapper';
import FilterReset from './styled/FilterReset';
import FilterHeader from './styled/FilterHeader';
import { dataMapper } from '../../../../index';
import TranslationStore from '../../../../../stores/TranslationStore';

@inject('translationStore')
@observer
class ProductFilter extends Component {
  constructor(props) {
    super(props);

    this.ProductStore = ProductStore.getInstance();
    this.setUpFilters = this.setUpFilters.bind(this);
    this.setUpFilters(props);
    this.SiteStore = SiteStore.getInstance();
    // catch the selected filters from the view and url.
    const selectedFilters = getNested(() => this.SiteStore.queryParams.selectedFilters, false);

    if(selectedFilters) {
      this.ProductStore.selectedFilters.replace(selectedFilters.split(','));
      this.ProductStore.updateDisplayList();
    }

    if(selectedFilters || this.ProductStore.selectedFilters.length > 0) {
      this.ProductStore.level = 1;
    }

    // Bind methods to object.
    this.handleFilter = this.handleFilter.bind(this);
    this.selectFilters = this.selectFilters.bind(this);
    this.goBack = this.goBack.bind(this);
    this.openFilter = this.openFilter.bind(this);

    this.state = {
      filters: this.selectFilters(),
      level: selectedFilters || this.ProductStore.selectedFilters.length > 0 ? 1 : 0,
      filterOpened: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.currentCategory !== this.props.currentCategory) {
      this.setUpFilters(nextProps, true);
      // Set the filterlevel back to 0
      this.setState({
        level: 0,
      });
      this.ProductStore.level = 0;
    }
  }

  setUpFilters(props, updateState = false) {
    const { currentCategory } = props;
    const filterOptions = this.ProductStore.filterOptions;
    // If the current category is in the nested filters... add it to the selected filters
    if(Object.keys(filterOptions.filters).length === 1) {
      // If only one item lives in the first filter directly select that.
      this.ProductStore.selectedFilters.push(Object.values(filterOptions.filters)[0].key);
    }

    if(getNested(() => filterOptions.filters[currentCategory], false) && this.ProductStore.selectedFilters.indexOf(currentCategory) < 0) {
      this.ProductStore.selectedFilters.push(currentCategory);
    }

    if(updateState) {
      this.setState({
        filters: this.selectFilters(),
        filterOpened: false,
      });
    }
  }

  selectFilters() {
    // if the selected filters are empty... (empty array)
    // show the product categories
    const filterOptions = this.ProductStore.filterOptions;
    if(this.ProductStore.selectedFilters.slice().length === 0) {
      // If the current category is in the filters..
      // we should show the filters for that category not the middle layer
      return [filterOptions];
    }

    // Other wise create a list of filters to show in the next round.
    const newFilters = [];

    this.ProductStore.selectedFilters.forEach((key) => {
      if(!getNested(() => filterOptions.filters[key])) {
        return;
      }

      const selectedFilter = filterOptions.filters[key];

      selectedFilter.order.forEach(filterKey => newFilters.push(selectedFilter.filters[filterKey]));
    });

    return newFilters;
  }

  handleFilter(ev) {
    const target = ev.target;
    const value = target.value;

    // Check if there are more sub filters to be shown
    // Add it when it's checked
    if(target.checked) {
      this.ProductStore.selectedFilters.push(value);
    } else {
      // remove it if it's unchecked
      const index = this.ProductStore.selectedFilters.indexOf(value);

      if(index > -1) {
        this.ProductStore.selectedFilters.splice(index, 1);
      }
    }

    const selectedFilters = this.selectFilters();

    // If the selected filters returns false... we just want to filter the content.
    // And don't update the visual filters.
    if(selectedFilters) {
      const { currentCategory } = this.props;
      let newLevel = this.state.level === 0 ? this.state.level + 1 : this.state.level;

      // check for subcat again.
      if(
        Object.keys(this.ProductStore.filterOptions.filters).length > 0 &&
        getNested(() => this.ProductStore.filterOptions.filters[currentCategory], false)
      ) {
        newLevel = 0;
      }

      this.setState({
        filters: selectedFilters,
        level: newLevel,
      });

      this.ProductStore.filterList(newLevel);
    }
  }

  goBack(ev) {
    ev.preventDefault();

    this.ProductStore.selectedFilters = [];

    this.setState({
      level: 0,
      filters: this.selectFilters(),
    });

    // Filter the list again to update the view.
    this.ProductStore.filterList(0);
  }

  openFilter(ev) {
    ev.preventDefault();

    this.setState({
      filterOpened: !this.state.filterOpened,
    });
  }

  render() {
    // @TODO: Check if this too late to avoid this. If previous components
    // are doing useless work then.
    if(this.ProductStore.filters.length === 0) {
      return null;
    }

    const { currentCategory, data, translationStore } = this.props;
    const categoryObj = dataMapper(data, currentCategory);
    const FilterGroups = this.state.filters.map((filter) => {
      if(filter.key === 'collection' && categoryObj.vid.target_id === 'collections') {
        return false;
      }

      return (
        <FilterGroup
          filter={filter}
          handler={this.handleFilter}
          key={`filter-group-${filter.key}`}
          selectedFilters={this.ProductStore.selectedFilters}
        />
      );
    });
    const displayViewAll = this.ProductStore.level > 0 && Object.keys(this.ProductStore.filterOptions.filters).length > 1;

    return (
      <ProductFilterWrapper className={this.props.className}>
        <FilterHeader openFilter={this.state.filterOpened} onClick={this.openFilter}>
          {this.ProductStore.filteredItems.length} {translationStore.translate('results')}{' '}
          <span>
            {translationStore.translate('Filter results')} {icons.drop_down_arrow}
          </span>
        </FilterHeader>
        <FilterInnerWrapper openFilter={this.state.filterOpened}>
          {FilterGroups}
          {displayViewAll && (
            <FilterReset type='button' onClick={this.goBack}>
              {translationStore.translate('View all')}
            </FilterReset>
          )}
        </FilterInnerWrapper>
      </ProductFilterWrapper>
    );
  }
}

ProductFilter.propTypes = {
  currentCategory: PropTypes.string.isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
};

ProductFilter.defaultProps = {
  className: '',
};

export default ProductFilter;
