import React from 'react';
import styled from 'styled-components';

export default styled.div`
  h2 {
    ${props => props.theme.fonts.parse('sansSerif', 'bold', '22px')}
    color: #fd6b0d;
    line-height: 32px;
    margin-bottom: 0.25em;
  }
`;
