import React from 'react';
import styled from 'styled-components';
import Story from '../Story';
import HypenTitle from '../../../style-guide/HyphenTitle/HyphenTitle';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import typographyStyles from '../../../../shared-styles/typography-styles.js';


export default styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 6% ${structureStyles.mobileValues.spacing};
  width: 100%;
  
  @media (min-width: 768px) {
    padding: 0 ${structureStyles.desktopValues.spacing};
  }
  
  @media (min-width: 1250px) {
    padding: 0;
  }
  
  &, p, em, strong {
    color: rgb(${props => props.inverseTextColor ? '255, 255, 255' : '0, 0, 0'});
    font-size: 16px;
    line-height: 26px;
    
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  ${HypenTitle.h2} {
    ${props => typographyStyles.label(props)};
    left: ${structureStyles.mobileValues.titleIndent};
    margin-bottom: 10px;
    
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 30px;

      ${props => props.invertImagePosition ? 'right' : 'left'}: calc(-${structureStyles.desktopValues.gridInnerSpacing} - ${structureStyles.desktopValues.gridInnerSpacing});
      ${props => props.invertImagePosition ? '' : `padding-left: calc(${structureStyles.desktopValues.gridInnerSpacing} + ${structureStyles.desktopValues.gridInnerSpacing})`};
      ${props => props.invertImagePosition ? 'left' : 'right'}: auto;
      ${props => props.invertImagePosition ? 'float: right;' : ''}
      ${props => props.invertImagePosition ? 'text-align: right;' : ''}
      ${props => props.invertImagePosition ?  'width: 150px;' : `width: calc(${structureStyles.desktopValues.gridInnerSpacing} + ${structureStyles.desktopValues.gridInnerSpacing} + 150px);`}

      margin-bottom: 46px;
      
      &:before,
      &:after {
        bottom: -14px;
        ${props => props.invertImagePosition ? 'left: calc(150px - 62px)' : ''}
      }
    }
      
    @media (max-width: 767px) {
      &:before,
      &:after {
        top: -4px;
        bottom: auto;
      }
    }
  }
  
  h3 {
  }
`;
