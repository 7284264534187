import React from 'react';
import styled from 'styled-components';

export default styled.div`
  margin-bottom: 20px;
  width: 100%;
  
  @media (min-width: 1024px) {
    margin: 0;
    padding: 0;
    width: 17%;
  }
`;
