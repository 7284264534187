import React from 'react';
import styled from 'styled-components';

export default styled.div`
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 400ms;
  width: 100%;
    
  @media only screen and (min-width: 1025px) {
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 150;
    
    &::after {
      background-color: rgba(0,0,0,0.5);
      content: '';
      height: 100vh;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 450ms ease 0ms;
      width: 100%;
      z-index: 10;
    }
  }
  
  .flyout-inner-wrap {
    background: #fff;
    max-height: 0;
    position: relative;
    opacity: 0;
    overflow: hidden;
    transition: max-height 400ms, opacity 250ms;
    z-index: 11;
    
    ${props => props.open ? `
      max-height: 500px;
      opacity: 1;
     ` : ``}
  }
  
  .flyout-inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    opacity: 0;
    padding: 45px 5% 40px;
    transition: opacity 0.45s linear 0.55s;
  }
      
  ${props => props.open ? `
   max-height: 500px;
   opacity: 1;
   overflow: visible;
    
    @media only screen and (min-width: 1025px) {
      //height: 180px;
    }
    
    &::after {
      opacity: 1;
    }
    
    .flyout-inner {
      opacity: 1;
    }
  ` : ``}
  
  .container {
    position: relative;
  }
  
`;
