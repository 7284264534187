import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
// import davidoff from '../../../../themes/davidoff';
import typographyStyles from '../../../../shared-styles/typography-styles.js';

export default styled.span`
  ${(props) => typographyStyles.label(props)};

  left: ${structureStyles.mobileValues.titleIndent};
  position: absolute;
  display: block;
  line-height: 32px;
  top: -32px;
  z-index: 1;
  
  @media (min-width: 768px) {
    left: 32px;  
    top: -32px;
  }
  
  &:before,
  &:after {
    background-color: #fc6b0d;
    content: '';
    left: 0;
    height: 8px;
    position: absolute;
    bottom: -4px;
    width: 8px;
  }
  
  &:after {
    transform: skew(-20deg, 0deg);
    transform-origin: bottom left;
    width: 60px;
  }
`;
