import React from 'react';
import styled from 'styled-components';

export default styled.input.attrs(props => ({
  type: 'radio',
  name: 'visual-filter',
}))`
  left: -9999em;
  position: absolute;
  height: 0;
`;
