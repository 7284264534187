import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default styled(Link)`
  display: block;
  margin-bottom: 2em;
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 80px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }
`;
