import React from 'react';
import styled from 'styled-components';

export default styled.span`
  display: inline-block;
  line-height: 2em;
  width: 80%;
  
  svg {
    height: 11px;
    margin-left: 5px;
    transition: transform 300ms;
    width: 11px;
  }
  
  ${props => props.openFilter ? `
    svg {
      transform: rotate(90deg);
    }
  ` : ``}
`;
