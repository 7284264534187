import React from "react";
import PropTypes from "prop-types";
import { PropTypes as MobxTypes } from "mobx-react";
import getNested from "get-nested";
// import { Carousel } from 'react-responsive-carousel';
import Modal from "react-modal";
import modalStyles from "../../../shared-styles/modal-styles";
import icons from "../icons/icons";
// Components
import DetailImageCarouselSlide from "../DetailImageCarouselSlide/DetailImageCarouselSlide";
import Thumbnail from "../SliderThumb/SliderThumb";
//styled
import Carousel from "./styled/Carousel";
import ThumbSlider from "./styled/ThumbSlider";
import DetailImage from "./styled/DetailImage";
import CloseButton from "./styled/CloseButton";
import SliderNavButton from "./styled/SliderNavButton";
import MagnifyingGlass from "./styled/MagnifyingGlass";
import LargeImage from "./styled/LargeImage";
import PopupImage from "./styled/Image";
import Icons from "../../common/icons/icons";
import MediaImageContainer from "../MediaImage/index.c";

modalStyles.content.maxWidth = "none";

class DetailImageCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      imageUrl: "",
      imageAlt: "",
      magnifying: true,
      activeSlide: 0,
      ModalImage: false,
      isCoffee: props?.isCoffee || false,
    };
    this.mainCarousel = false;
    this.thumbCarousel = false;

    // Bind all the functions
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getSlides = this.getSlides.bind(this);
    this.handleMagnifying = this.handleMagnifying.bind(this);
    this.updateMainSlide = this.updateMainSlide.bind(this);
    this.updateSlideThumb = this.updateSlideThumb.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    this.updateSlideThumb(0, 0);
    this.updateMainSlide({
      target: {
        value: 0,
      },
    });
  }

  /*
   * Open the modal
   */
  openModal() {
    const { data, product } = this.props;
    const selectedMediaReference =
      product.field_media_multiple[this.state.activeSlide];

    const settings = {
      width: 1280,
      height: 768,
    };

    const sources = [
      {
        settings,
        media: "(min-width: 0)",
      },
    ];

    const Image = (
      <PopupImage
        imageType="PopupImage"
        data={data}
        mediaReference={selectedMediaReference}
        sources={sources}
        settings={settings}
      />
    );

    this.setState({
      modalOpen: true,
      ModalImage: Image,
    });
  }

  /*
   * Close the modal
   */
  closeModal() {
    this.setState({
      modalOpen: false,
      imageUrl: "",
    });
  }

  /*
   * Get the detail images into elements
   */
  updateSlideThumb(oldIndex, newIndex) {
    this.setState({ activeSlide: newIndex });

    // this.thumbCarousel.slickGoTo(newIndex);
  }

  updateMainSlide(ev) {
    const newIndex = ev.target.value;

    this.setState({ activeSlide: newIndex });
    this.mainCarousel.slickGoTo(newIndex);
  }

  /*
   * Handle the magnifying of the slider images on hover
   */
  handleMagnifying() {
    this.setState({
      magnifying: !this.state.magnifying,
    });
    this.render();
  }

  getSlides(mediaReferences) {
    const { data } = this.props;

    return mediaReferences.map((mediaReference) => {
      const settings = {
        aspect_ratio: "1:1",
        crop: "fill",
        gravity: "auto",
        width: 480,
        height: 480,
      };

      const sources = [
        {
          settings: {
            aspect_ratio: "1:1",
            crop: "fill",
            gravity: "auto",
            placeholder: "",
            height: 480,
          },
          media: "(max-width: 1920px)",
        },
        {
          settings: {
            aspect_ratio: "1:1",
            crop: "fill",
            gravity: "auto",
            placeholder: "",
            height: 1440,
          },
          media: "(min-width: 1921px)",
        },
      ];

      if (this.state.magnifying) {
        return (
          <div>
            <div className="zoomImageWrapper">
              <MediaImageContainer
                imageType="ImageCarousel"
                data={data}
                sources={sources}
                settings={settings}
                mediaReference={mediaReference}
                component={DetailImageCarouselSlide}
                lazyLoad={false}
              />
            </div>
          </div>
        );
      }

      return (
        <div typey="Typey" key={`product-image-${mediaReference.target_uuid}`}>
          <MediaImageContainer
            imageType="ImageCarousel"
            data={data}
            mediaReference={mediaReference}
            settings={settings}
            sources={sources}
          />
        </div>
      );
    });
  }

  getThumbnails(mediaReferences) {
    const { data } = this.props;

    const settings = {};
    const sources = [
      {
        settings,
        media: "(min-width: 0)",
      },
    ];

    return mediaReferences.map((mediaReference, idx) => (
      <Thumbnail
        imageType="ImageCarousel"
        key={`thumnail-${mediaReference.target_uuid}`}
        data={data}
        mediaReference={mediaReference}
        sources={sources}
        settings={settings}
        slideIdx={idx}
        handleChange={this.updateMainSlide}
        selected={this.state.activeSlide}
      />
    ));
  }

  render() {
    const mediaReferences = this.props.product.field_media_multiple;
    const slides = this.getSlides(mediaReferences);
    const thumbs = this.getThumbnails(mediaReferences);

    const baseCarouselSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      accessibility: true,
      nextArrow: (
        <SliderNavButton direction="next">
          {Icons.drop_down_arrow}
        </SliderNavButton>
      ),
      prevArrow: (
        <SliderNavButton direction="prev">
          {Icons.drop_down_arrow}
        </SliderNavButton>
      ),
    };
    const sliderSettings = Object.assign({}, baseCarouselSettings, {
      arrows: false,
    });
    const thumbSettings = Object.assign({}, baseCarouselSettings, {
      slidesToShow: 4,
      variableWidth: true,
      swipeToSlide: thumbs.length > 2,
      swipe: thumbs.length > 2,
    });

    return (
      <div>
        {/* <MagnifyingGlass onClick={this.openModal}>
          {icons.search}
    </MagnifyingGlass> */}
        <Carousel
          isCoffee={this.state.isCoffee}
          ref={(e) => (this.mainCarousel = e)}
          {...sliderSettings}
          beforeChange={this.updateSlideThumb}
        >
          {slides}
        </Carousel>
        {thumbs.length > 1 && (
          <ThumbSlider
            ref={(e) => (this.thumbCarousel = e)}
            {...thumbSettings}
            isCoffee={this.state.isCoffee}
          >
            {thumbs}
          </ThumbSlider>
        )}
        <Modal
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          style={modalStyles}
          contentLabel="Video modal"
        >
          <LargeImage>{this.state.ModalImage}</LargeImage>
          <CloseButton onClick={this.closeModal}>Close</CloseButton>
        </Modal>
      </div>
    );
  }
}

DetailImageCarousel.propTypes = {
  product: PropTypes.shape({
    field_detail_image: MobxTypes.observableArrayOf(
      PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default DetailImageCarousel;
