import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../themes/davidoff';

export default styled.label`
  ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '14px')}
  color: #676767;
  padding-left: 22px;
  position: relative;
  width: 100%;
  
  &:before {
    border: 1px solid ${davidoff.colors.brown};
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 10px;
  }
  
  ${props => props.heading ? `
      ${(props) => props.theme.fonts.parse('sansSerif', 'black')}
      color: ${davidoff.colors.brown};
      margin-top: 1.5em;
      text-transform: uppercase;
  `: ``}
  
  ${props => props.selected ? `
    &:after {
      background-color: ${davidoff.colors.brown};
      content: '';
      height: 6px;
      left: 3px;
      position: absolute;
      top: 4px;
      width: 6px;
    }
    ` : ``}
`;

