import React from 'react';
import styled from 'styled-components';

export default styled.h3`
  ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '14px')}
  color: #000;
  margin-top: 15px;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
  
  @media (min-width: 768px) {
    font-size: 16px;
    text-align: left;
  }
  
  span {
    text-transform: capitalize;
    ${(props) => props.theme.fonts.parse('sansSerif', 'regular')};
    display: block;
    margin: 5px 0;
  }
`;
