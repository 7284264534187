import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../themes/davidoff';

export default styled.div`
   background-color: rgba(0, 0, 0, 0.5);
   left: 0;
   overflow: hidden;
   position: absolute;
   top: 0;
   transition: height 0.55s ease-in, opacity 0.5s ease-out 0.25s;
   width: 100%;
   z-index: 30;

  ${(props) => props.show ? `
    height: 100%;
    opacity: 1;
  ` : `
    height: 0;
    opacity: 0;
  `}
`;
