import React from "react";
import PropTypes from "prop-types";
import ReactImageMagnify from "react-image-magnify";
import { getCloudinaryID } from "../image/helpers/getCloudinaryID";
import { cl } from "../image/CloudinaryImage/container";
import applyImageCrop from "../../../misc/cropping/applyImageCrop";
import { Loader } from "../../style-guide/Loader/Loader";

import ImageContainer from "./styled/ImageContainer";
import getNested from "get-nested";

export const getImagesByURL = (image, settings, sources) => {
  const imageID = getCloudinaryID(image.url, "zinodavidoff");
  let images = [];
  sources.map((source) => {
    const setting = source.settings;
    const crop = applyImageCrop(
      setting,
      getNested(() => image.computed.crops) || {}
    );

    const url = cl.url(imageID, {
      quality: "auto",
      gravity: "auto",
      crop: "fill",
      ...crop,
    });

    images.push({ height: source.settings.height, url: url });
  });

  images.sort(function (a, b) {
    return a.height - b.height;
  });

  return images;
};

const DetailImageCarouselSlide = ({ mediaImage }) => {
  const image = mediaImage.field_media_image;

  const [showLoader, setShowLoader] = React.useState(false);

  const settings = {
    aspect_ratio: "1:1",
    crop: "fill",
    gravity: "auto",
    width: 480,
    height: 480,
  };

  const sources = [
    {
      settings: {
        aspect_ratio: "1:1",
        crop: "fill",
        gravity: "auto",
        placeholder: "",
        height: 480,
      },
      media: "(max-width: 1920px)",
    },
    {
      settings: {
        aspect_ratio: "1:1",
        crop: "fill",
        gravity: "auto",
        placeholder: "",
        height: 1440,
      },
      media: "(min-width: 1921px)",
    },
  ];

  const images = getImagesByURL(image, settings, sources);

  const onMouseEnter = () => {
    setShowLoader(true);
  };

  return (
    <ImageContainer onMouseEnter={onMouseEnter}>
      <Loader loading={showLoader} complete={!showLoader} />

      <ReactImageMagnify
        key={image.uuid}
        {...{
          largeImage: {
            alt: image.alt,
            src: images[1].url,
            width: 1400,
            height: 1400,
            onLoad: () => setShowLoader(false),
          },
          smallImage: {
            alt: image.alt,
            src: images[0].url,
            isFluidWidth: true,
            onLoad: () => setShowLoader(false),
          },
          enlargedImagePosition: "over",
          enlargedImageContainerClassName: "zoomImage",
          isActivatedOnTouch: true,
        }}
      />
    </ImageContainer>
  );
};

DetailImageCarouselSlide.propTypes = {
  image: PropTypes.shape({
    target_uuid: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default DetailImageCarouselSlide;
