import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import mapper from '../../index';
// Styled components
import Columns from '../../style-guide/Structure/Columns';
import CollectionsWrapper from './styled/CollectionsWrapper';
import CollectionsContainer from './styled/CollectionsContainer';
import CollectionsTitle from './styled/CollectionsTitle';
import CollectionsButton from './styled/Button';

const CollectionsBlock = ({ paragraph, data }) => {
  const Collections = paragraph.field_collection_paragraph.map(collection => mapper(data, false, collection.target_uuid));

  return (
    <CollectionsWrapper>
      <CollectionsContainer>
        <CollectionsTitle hyphenPosition="top">{paragraph.field_label}</CollectionsTitle>
        <Columns multicolMobile={true}>
          {Collections}
        </Columns>
        {getNested(() => paragraph.field_link.uri, false) &&
          <CollectionsButton to={paragraph.field_link.uri}>{paragraph.field_link.title}</CollectionsButton>
        }
      </CollectionsContainer>
    </CollectionsWrapper>
  );
};

CollectionsBlock.propTypes = {
  paragraph: PropTypes.shape({

  }).isRequired,
};
CollectionsBlock.defaultProps = {};

export default CollectionsBlock;
