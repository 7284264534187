import React from 'react';
import styled from 'styled-components';
import CookButtonWrapper from './CookButtonWrapper';
import CookieButton from './CookieButton';
import davidoff from "../../../../themes/davidoff";

export default styled.div`
  background-color: ${davidoff.colors.veryLightGrey};
  width: 66vw;
  padding: 1rem;
  align-self: center;
  margin: 0 auto;
  max-width: 1216px;
  height: auto;

  @media (min-width: 768px) {
    max-height: 33vh;

    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    flex-flow: column nowrap;
  }

  &.container-options{
    ${CookButtonWrapper} {
      margin-top: 0;
    }
    ${CookieButton}{
      padding: 0 45px;
    }
  }

  h2{
    color: ${davidoff.colors.black};
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
  }
`;
