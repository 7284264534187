import React from 'react';
import LdJson from 'react-ld-json';

export default function OrganizationSchema() {
  return (
    <LdJson
      data={{
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        '@id': '#main-organization',
        address: { '@id': '#main-location' },
        location: { '@id': '#main-location' },
        image: { '@type': 'ImageObject', url: `/images/logo.jpg` },
        logo: { '@type': 'ImageObject', url: `/images/logo.jpg` },
        name: 'Zino Davidoff',
        legalName: 'Zino Davidoff Group',
        sameAs: [
          'https://www.facebook.com/zinodavidoff/',
          'https://www.youtube.com/user/DavidoffTheOfficial',
          'https://www.instagram.com/zinodavidoff/',
        ],
        telephone: '',
        url: 'https://www.zinodavidoff.com/',
      }}
    />
  );
}
