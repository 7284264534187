import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';
// Components
import InstagramPost from '../../InstagramPost/InstagramPost';
// Styled Components
import SocialFeedWrapper from '../styled/SocialFeedWrapper';

import SiteStore from '../../../../../stores/SiteStore';
import { Cookies, withCookies } from 'react-cookie';

@observer
class SocialFeedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.SiteStore = SiteStore.getInstance();
    this.state = {
      isVisible: false,
    };
  }

  cookieCheck = (e) => {
    const consentCookies = e.detail.newValue.CookieConsent;
    if (consentCookies) {
      if (consentCookies.includes("marketing:true")) {
        this.setState({ isVisible: true });
      }
      else {
        this.setState({ isVisible: false });
      }
    }
    else {
      this.setState({ isVisible: false });
    }
  }

  componentDidMount(){
    this.fetchSocialItems(this.props);
    window.addEventListener('cookieChange', this.cookieCheck);
  }
  componentWillUnmount() {
    window.removeEventListener('cookieChange', this.cookieCheck);
  }

  fetchSocialItems = (props) => {
    this.SiteStore.fetchSocialItems(props.page.nid);
  };

  render() {
    const { isVisible } = this.state;
    if (!isVisible) {
      return null;
    }
    const InstagramPosts = this.SiteStore.socialPosts && this.SiteStore.socialPosts.length > 0 ? this.SiteStore.socialPosts.map((post) => <InstagramPost
        key={post.id}
        post={{
          image: post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url,
          text: post.caption,
          url: post.permalink,
        }}
      />) : '';

    return (
      <SocialFeedWrapper>{InstagramPosts}</SocialFeedWrapper>
    );
  }
}

SocialFeedComponent.propTypes = {
  page: PropTypes.shape().isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default withCookies(SocialFeedComponent);
