import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';
import MenuListItem from './MenuListItem';

export default styled(NavLink)`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '16px')}
  color: #000;
  display: block;
  letter-spacing: 0.5px;
  line-height: 27px;
  padding: 0 20px;
  text-transform: uppercase;
  transition: all 300ms;
  text-align: left;
  
  @media only screen and (min-width: 1025px) {
    ${(props) => props.theme.fonts.parse('sansSerif', 'medium')}
    border-bottom: 1px solid transparent;
    padding: 0 13px;
    position: relative;
        
    &:before {
      background-color: #d9d1ca;
      border-radius: 50%;
      content: '';
      display: block;
      font-size: 25px;
      height: 4px;
      left: -2px;
      line-height: 20px;
      position: absolute;    
      top: 12px;
      transform: translateY(-50%);
      width: 4px;
      
      ${MenuListItem}:first-child &,
      .level-2 &,
      .level-3 & {
        display: none;
      }
    }
    
    ${MenuListItem}:last-child & {
       padding-right: 0;
    }
  }
  
  &:hover {
    color: ${davidoff.colors.orange};
    cursor: pointer;
  }
  
  &.active {
    color: ${davidoff.colors.orange};
  }
  
  .opened & :hover {
    border-bottom: 1px solid ${davidoff.colors.orange};
  }
`;
