import React from 'react';
import PropTypes from 'prop-types';
const Entities = require('html-entities').XmlEntities;
import getNested from 'get-nested';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { stripProtocol } from '../../../../../misc/index';
import davidoff from '../../../../../themes/davidoff';


const entities = new Entities();

const FooterMenuListItem = styled.li`
  display: inline-block;
  margin: 0 5px;
  position: relative;
  
  &::before {
    color: #fff;
    content: '\\00B7';
    display: block;
    font-size: 25px;
    left: -8px;
    line-height: 32px;
    position: absolute;    
    top: 0;
  }
  
  &:first-child::before {
    display: none;
  }
`;

const FooterMenuLink = styled(NavLink)`
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  transition: color 300ms;
  
  @media (min-width: 360px) {
    font-size: 14px;
  }
  
  @media (min-width: 768px) {
    font-size: 16px;
  }
  
  &:hover,
  &.active {
    color: ${davidoff.colors.orange};
  }
`;

const FooterMenuItem = ({ menuItem }) => {
  const external = false;//menuItem.uri.includes('http');
  const props = {};

  if(external) {
    props.target = '_blank';
  }

  return (
    <FooterMenuListItem>
      <FooterMenuLink
        to={stripProtocol(getNested(() => menuItem.uri, '/'))} {...props}>
        {entities.decode(menuItem.title)}
        </FooterMenuLink>
    </FooterMenuListItem>
  );
};

FooterMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FooterMenuItem;
