// Core
import React from "react";

// API-related
import { dataMapper } from "../../index";
import getNested from "get-nested";

// Media-related
import { getBackgroundImagesByMedia } from "../../../misc/cloudinaryBackgrounds";

// Components
import { CampaignHeaderContent } from "../../common/CampaignHeaderContent/Component";

// Styles
import * as styles from "./styled";

const getBackgroundImages = (data, image) => {
  const imageSettings = {
    breakpoints: {
      mobile: {
        width: 640,
        aspect_ratio: "375:650",
      },
      desktop: {
        width: 1600,
        aspect_ratio: "1920:815",
      },
    },
  };

  const media = dataMapper(
    data,
    getNested(() => image.target_uuid)
  );

  return getBackgroundImagesByMedia(media, imageSettings);
};

export function CampaignHeaderImage(props) {
  const { paragraph, data } = props;
  const [isDesktop, setIsDesktop] = React.useState(true);

  React.useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth >= 768) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };

    checkWindowSize();

    window.addEventListener("resize", checkWindowSize);
    return () => window.removeEventListener("resize", checkWindowSize);
  }, [isDesktop]);

  // Background image
  const mediaBackgroundParagraph = dataMapper(
    data,
    getNested(() => paragraph.field_campaign_h_m_background.target_uuid)
  );

  const imageUris = getBackgroundImages(data, mediaBackgroundParagraph.image);

  // Image
  const mediaParagraph = dataMapper(
    data,
    getNested(() => paragraph.field_campaign_h_m_highlight.target_uuid)
  );

  const image = dataMapper(
    data,
    getNested(() => mediaParagraph.image.target_uuid)
  );

  console.log(image);

  const contentData = {
    title: paragraph.field_campaign_h_m_title,
    image: typeof mediaParagraph !== "undefined" &&
      mediaParagraph.type.target_id == "single_image" && {
        alt: image.field_media_image.alt,
        src: image.field_media_image.url,
      },
    videoParagraph: mediaBackgroundParagraph,
    type: mediaBackgroundParagraph.type.target_id,
    button: paragraph.field_campaign_h_m_button !== null && {
      title: paragraph.field_campaign_h_m_button.title,
      uri: paragraph.field_campaign_h_m_button.uri,
    },
  };

  let sizes = {
    tablet: 0,
    desktop: 0,
    desktopxl: 0,
  };

  const ratio = mediaBackgroundParagraph.image_ratio;

  if (ratio == 0 || ratio == null) {
    sizes = {
      tablet: 432,
      desktop: 576,
      desktopl: 720,
      desktopxl: 810,
    };
  } else if (ratio == 1) {
    sizes = {
      tablet: 329,
      desktop: 438,
      desktopl: 548,
      desktopxl: 617,
    };
  }

  return (
    <styles.CampaignHeaderImage
      imageUri={isDesktop ? imageUris.desktop : imageUris.mobile}
      imageRatio={sizes}
    >
      <CampaignHeaderContent {...contentData} />
    </styles.CampaignHeaderImage>
  );
}
