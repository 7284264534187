import React from 'react';
import PropTypes from 'prop-types';
import ProductPrice from './styled/ProductPrice';

export default function Price(props) {
  return (
    <ProductPrice fontWeight={props.fontWeight} alignText={props.alignText}>{props.children} EUR</ProductPrice>
  );
}

Price.defaultProps = {
  alignText: 'left',
  fontWeight: '300'
};

Price.propTypes = {
  children: PropTypes.node.isRequired,
  alignText: PropTypes.string,
  fontWeight: PropTypes.string
};
