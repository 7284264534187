import React from "react";
import PropTypes from "prop-types";
// import { Image, CloudinaryContext } from 'cloudinary-react';
import { cloudinaryConfig, CloudinaryImage } from "react-cloudinary";
import cloudinary from "cloudinary-core";
import { formatElementKey, getCloudinaryID } from "../../../misc/index";

const cloudinarySettings = {
  cloud_name: "zinodavidoff",
  secure: true,
};

cloudinaryConfig(cloudinarySettings);
// Setup a Cloudinary class
const cl = cloudinary.Cloudinary.new(cloudinarySettings);

/*
 * The ResponsiveImage Component returns a Picture element with sources
 * for any given set of responsive objects.
 * By default all images are generated for 1x and 2x allowing for retina on high dpi screens.
 * Example array for sources:
 *  [ {
      settings: {
        aspect_ratio: '640:272',
        crop: 'fill',
        gravity: 'auto',
        width: 'auto',
        placeholder: '',
        height: 160
      },
      media: '(max-width: 767px)',
    }, {
    settings: {
      aspect_ratio: '1920:815',
      crop: 'fill',
      gravity: 'auto',
      width: 'auto',
      placeholder: '',
      height: 815,
    },
    media: '(min-width: 768px)',
  }]
 */
const ResponsiveImage = ({
  src,
  alt,
  responsive,
  options,
  className,
  sources,
  imageType,
}) => {
  if (!src) {
    return null;
  }

  if (src.indexOf("cloudinary") > -1) {
    const cloudinaryID = getCloudinaryID(src, "zinodavidoff");
    // Manage
    const defaultSettings = {
      fetch_format: "auto",
      quality: "auto",
    };
    const imageSettings = Object.assign({}, defaultSettings, options);

    const imageUrl = cl.url(cloudinaryID, imageSettings);

    const Sources = sources.map((image) => {
      const url = cl.url(
        cloudinaryID,
        Object.assign({}, defaultSettings, image.settings)
      );
      const urlRetina = cl.url(
        cloudinaryID,
        Object.assign({ dpr: 2 }, defaultSettings, image.settings)
      );
      // Always create a srcSet with a retina and a non retina for this media point
      return (
        <source
          key={`img-source-${formatElementKey(image.media)}`}
          srcSet={`${urlRetina} 2x, ${url} 1x`}
          media={image.media}
        />
      );
    });

    return (
      <picture className={className}>
        {Sources}
        <img src={imageUrl} alt={alt} imagetype={imageType} />
      </picture>
    );
  }

  return <img src={src} alt={alt} className={className} />;
};

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      settings: PropTypes.shape().isRequired, // the settings of the image for this media query.
      media: PropTypes.string.isRequired, // The media query that should trigger the image
    })
  ),
  options: PropTypes.shape().isRequired,
};

ResponsiveImage.defaultProps = {
  className: "",
  sources: [],
};

export default ResponsiveImage;
