import fetch from 'fetch-everywhere';

// Keep track of all the paths and store those to cache.
const cache = {};
const cacheLifeTime = 3600;

class API {
  static getPage(base, path) {
    return this.get(`${base}/hn?_format=hn&path=${encodeURIComponent(path)}`);
  }

  static getSettings(base) {
    return this.get(`${base}/hn/settings?_format=hn`);
  }

  static getRedirect(path, cacheKey, baseUrl) {
    return this.get(`/redirect?_format=json&path=${path}`, cacheKey, baseUrl);
  }

  static get(query) {
    // cache purge
    if(query.indexOf('purgeCache') > -1) {
      Object.keys(cache).forEach(cacheItem => delete cache[cacheItem]);
    }

    // check if we have a cached version of the query and if it's not older then 1 hour
    const timeBack = Math.round((new Date()).getTime() / 1000) - cacheLifeTime;

    if(cache[query] && cache[query].age > timeBack) {
      return cache[query].data;
    }

    // If there is a cache version for this query... delete it.
    // We want fresh content after the cachelifetime has expired.
    if(cache[query]) {
      delete cache[query];
    }

    // const headers = new Headers({
    //   'Accept': 'application/json',
    // });
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');

    const options = {
      headers: myHeaders,
      mode: 'cors',
    };
    const request = new Request(query);

    return fetch(request, options)
      .then((res) => {
        cache[query] = {
          age: Math.round((new Date()).getTime() / 1000), // Add the current timestamp to the query
          data: res.json(),
        };
        return cache[query].data;
      // eslint-disable-next-line no-console
      }).catch(error => console.log(error));
  }
}

export default API;
