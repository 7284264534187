import styled from "styled-components";
import davidoff from "../../../../themes/davidoff";

export default WebformWrapper;

export const WebformWrapper = styled.div`
  // Landingspage
  #b2b_form_landingspage fieldset {
    padding: 0;
  }

  @media (min-width: 768px) {
    #b2b_form_landingspage > div:nth-child(3),
    #b2b_form_landingspage > div:nth-child(4) {
      width: 45%;
      display: inline-block;
    }
  }

  @media (min-width: 768px) {
    #b2b_form_landingspage .hrw-fieldset > div > div:nth-child(3),
    #b2b_form_landingspage .hrw-fieldset > div > div:nth-child(4) {
      width: 45%;
      display: inline-block;
    }
  }

  //checkboxes in fieldset
  #b2b_form_landingspage .s1pvztas-0 {
    padding-left: 20px;
  }

  #b2b_form_landingspage fieldset > label {
    padding: 20px;
    font-weight: bold;
  }

  #b2b_form_landingspage .edeQNJ {
    width: 82.6%;
  }

  #b2b_form_landingspage pre {
    white-space: pre-wrap;
  }

  // Webform
  p {
    ${({ theme }) => theme.fonts.parse("sansSerif", "medium")}
    color: ${davidoff.colors.darkGrey};

    @media (min-width: 768px) {
      font-size: 22px;
    }
  }

  label,
  legend {
    ${({ theme }) => theme.fonts.parse("sansSerif", "medium", "16px")}
    color: ${davidoff.colors.darkGrey};
    line-height: 22px;
    margin-bottom: 0;
    padding-left: 20px;
    position: relative;
    text-transform: uppercase;
    width: 100%;

    small {
      position: relative;
      top: -3px;
    }
  }

  .hrw-validation-message {
    padding-left: 20px;
    position: relative;
  }

  input {
    ${({ theme }) => theme.fonts.parse("sansSerif", "regular", "16px")}
    border: none;
    border-radius: 4px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 20px;
    padding: 0 20px;
    width: 100%;

    &::placeholder {
      color: ${davidoff.colors.brown};
      font-style: italic;
    }
  }

  textarea {
    ${({ theme }) => theme.fonts.parse("sansSerif", "regular", "16px")}
    border: none;
    border-radius: 4px;
    min-height: 150px;
    padding: 20px;
    width: 100%;
  }

  input,
  textarea {
    &[aria-invalid="true"] {
      background-color: white;
      border: 1px solid red;
      color: red;
    }
  }

  [class*="styles-input-wrap"] {
    width: 100%;
  }

  input[type="checkbox"],
  input[type="radio"] {
    height: auto;
    width: auto;
    position: absolute;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    opacity: 0;
  }

  input[type="checkbox"] ~ .hrw-inner-label,
  input[type="radio"] ~ .hrw-inner-label {
    padding-left: 0;
  }

  input[type="checkbox"] + span,
  input[type="radio"] + span {
    border: 1px solid ${davidoff.colors.brown};
    height: 12px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 12px;
  }

  input[type="radio"]:checked + span:after,
  input[type="checkbox"]:checked + span:after {
    background-color: ${davidoff.colors.brown};
    content: "";
    left: 1px;
    position: absolute;
    height: 8px;
    top: 1px;
    width: 8px;
  }

  input[type="radio"]:checked + span:after {
    border-radius: 50%;
  }

  [class*="styles-radio-label"],
  [class*="styles-checkbox-label"] {
    ${({ theme }) => theme.fonts.parse("sansSerif", "regular")}
    cursor: pointer;
    float: none;
    line-height: 22px;
    margin: 0 15px 15px;
    position: relative;
    text-transform: none;

    input {
      height: auto;
      width: auto;
      position: absolute;
      overflow: hidden;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      opacity: 0;
    }

    [class*="indicator"] {
      border: 1px solid ${davidoff.colors.brown};
      height: 12px;
      left: 0;
      position: absolute;
      top: 3px;
      width: 12px;
    }

    input:checked + [class*="indicator"]:after {
      background-color: ${davidoff.colors.brown};
      border-radius: 50%;
      content: "";
      left: 1px;
      position: absolute;
      height: 8px;
      top: 1px;
      width: 8px;
    }
  }

  [class*="styles-radio-label"] {
    [class*="indicator"] {
      border-radius: 50%;
    }
  }

  [class*="styles-checkbox-label"] {
    input:checked + [class*="indicator"]:after {
      border-radius: 0;
    }

    [class*="indicator"] {
      top: 1px;
    }
  }

  [class*="validation-message-wrapper"] {
    ${({ theme }) => theme.fonts.parse("sansSerif", "regular", "14px")}
    border-radius: 3px;
    color: red;
    list-style: none;
    margin: 0 0 1em;
    padding: 0 5px;
    position: relative;
    top: -1em;
  }

  button {
    ${({ theme }) => theme.fonts.parse("sansSerif", "black", "16px")}
    background: ${davidoff.colors.brown};
    border: 0;
    border-radius: 4px;
    color: #fff;
    height: 50px;
    line-height: 50px;
    min-width: 175px;
    padding: 0 20px;
    text-transform: uppercase;
  }

  .hrw-select-wrapper {
    padding-right: 0 !important;
    // width: 63.5% !important;
  }

  .Select + .hrw-validation-icon {
    display: none;
  }

  .Select {
    margin-bottom: 20px;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    font-size: 16px !important;
    line-height: 50px !important;
    padding: 0 20px !important;
  }

  .Select-control {
    height: 50px !important;
    border: 0 !important;
    border-radius: 4px;
  }

  .Select-multi-value-wrapper .Select-value,
  .Select-multi-value-wrapper .Select-input {
    line-height: 50px !important;
    height: 50px !important;
  }

  .Select-value {
    ${({ theme }) => theme.fonts.parse("sansSerif", "regular", "14px")}
    background-color: #fff !important;
    border-radius: 4px !important;
    border-color: ${davidoff.colors.lightbrown} !important;
    color: ${davidoff.colors.grey};
  }

  .Select-menu-outer {
    border-color: #ece8e4 !important;

    .Select-option {
      color: #8c7d70 !important;
      padding: 0 20px !important;
      font-size: 12pt !important;
      height: 50px !important;
      line-height: 50px !important;

      &:hover,
      &.is-focused {
        background-color: ${davidoff.colors.lightbrown} !important;
      }
    }
  }
  .hrw-validation-icon {
    display: none;
  }
`;
