import React from 'react';
import PropTypes from 'prop-types';
// import AccordionComponent from '../../common/Accordion/Accordion';
//styled
import AccordionComponent from './styled/Accordion';
import Body from './styled/Body';

// 24-10-2017 - removed the first open accordion add by using: isOpen={idx === 0}
const Accordion = ({paragraph}) => <AccordionComponent title={paragraph.field_title} isOpen={paragraph.field_enabled}>
  <Body dangerouslySetInnerHTML={{__html: paragraph.field_body.value}} />
</AccordionComponent>;

Accordion.Proptypes = {
  paragraph: PropTypes.shape({
    field_title: PropTypes.string.isRequired,
    field_body: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Accordion;
