import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled('a')`
  ${props => props.theme.fonts.parse('sansSerif', 'black', '13px')}
  background-color: ${davidoff.colors.orange};
  border: 1px solid ${davidoff.colors.orange};
  border-radius: 4px;
  color: #fff;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  letter-spacing: 1px;
  outline: none;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 300ms, color 300ms, border-color 300ms;
  width: 100%;
   
  ${props => props.hasLocatorButton ? `
    @media (min-width: 350px) {
      min-width: calc(50% - 7px);
    }
  ` : ''}
  
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    width: auto;
  }
  
  @media only screen and (min-width: 1200px) {
    min-width: 176px;
  }

  &:hover {
    background-color: #fff;
    border-color: ${davidoff.colors.brown};
    color: ${davidoff.colors.brown};
  }
  
  &:nth-child(2) {
    margin-left: 15px;
  }
`;
