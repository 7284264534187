import React from 'react';
import PropTypes from 'prop-types';
import Step from './styled/Step.jsx';

const InstructionStep = ({ step }) => {
    return (
        <Step
            background={step.field_background}
            dangerouslySetInnerHTML={{
                __html: step.field_step.processed
            }}
        />
    );
};

InstructionStep.Proptypes = {
    step: PropTypes.shape({
        field_step: PropTypes.shape({
            processed: PropTypes.string.isRequired,
        }).isRequired,
        field_background: PropTypes.bool.isRequired,
    }).isRequired
};

export default InstructionStep;


