import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import PaginatorWrapper from './styled/PaginatorWrapper';
import PaginatorInnerWrapper from './styled/PaginatorInnerWrapper';
import PaginatorButton from './styled/PaginatorButton';
import PaginatorLink from './styled/PaginatorLink';
import PagerTruncater from './styled/PagerTruncater';

const Paginator = ({ prevPage, nextPage, switchPage, maxPages, currentPage, lang, settings, translationStore }) => {

  if(maxPages < 1) {
    return false;
  }

  // maxPages = lastPage but not the total amount.
  const lastPage = maxPages + 1;

  // Always add page 1
  const numbers = [1];
 
  if (currentPage < 3) {
    // First three pages
    // Add all numbers but 1 as long as i < total pages & i < max pages to print
    for (let i = 1; i < 4 && i < lastPage; i += 1) {
      numbers.push(i + 1);
    }
  } else if (currentPage > lastPage - 3) {
    // Last three pages
    // Add all numbers but 1 as long as i > total pages & i < max pages to print
    for (
      let i = lastPage - 2;
      i > lastPage - 5 && i + 1 > 0;
      i -= 1
    ) {
      numbers.splice(1, 0, i + 1);
    }
  } else {
    // All pages in between
    // Add pages for current - 1, current, and current + 1
    numbers.push(
      currentPage,
      currentPage + 1, // Current page
      currentPage + 2,
    );
  }

  // Check if there are more pages than size of pager - 1
  if (maxPages > 4) {
    // If so, add last page as last item
    numbers.push(lastPage);

    // If page 2 isn't on the 1st index, the pager is truncated.
    if (numbers[1] !== 2) {
      numbers.splice(1, 0, '...first');
    }

    // If total - 1 isn't on the second to last index, the pager is truncated.
    if (numbers[numbers.length - 2] !== lastPage - 1) {
      numbers.splice(numbers.length - 1, 0, '...last');
    }
  }

  const PageLinks = numbers.map(number => {

    // If array item is a truncater item, render that item
    if (typeof number === 'string' && number.startsWith('...')) {
      return <PagerTruncater key={number}>...</PagerTruncater>;
    }
    return (
      <PaginatorButton
        key={`paginator-page-${number}`}
        data-page-number={number-1}
        disabled={parseFloat(currentPage + 1) === number}
        type='button'
        onClick={switchPage}
      >
        {number}
      </PaginatorButton>
    );
  });

  return (
    <PaginatorWrapper>
      <PaginatorInnerWrapper>
        <PaginatorLink
          type='button'
          disabled={parseFloat(currentPage) === 0}
          onClick={prevPage}
          invertArrow={true}
        >
          <span>{translationStore.translate('Previous')}</span>
        </PaginatorLink>
        { PageLinks }
        <PaginatorLink
          type='button'
          onClick={nextPage}
          disabled={parseFloat(currentPage) === maxPages}
        >
          <span>{translationStore.translate('Next')}</span>
        </PaginatorLink>
      </PaginatorInnerWrapper>
    </PaginatorWrapper>
  );
};

Paginator.propTypes = {
  prevPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  switchPage: PropTypes.func.isRequired,
  maxPages: PropTypes.number.isRequired,
  currentPage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  lang: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default inject('translationStore')(observer(Paginator));
