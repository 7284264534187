import React from 'react';
import styled from 'styled-components';

export const Label = styled.span`
  font-size: 14px;
  line-height: 19px;
  display: inline-block;
  padding-right: 20px;
  vertical-align: top;
  width: 130px;
`;
