import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../themes/davidoff';

export default styled.div`
  .Select {
    margin-bottom: 20px;

  .Select-control {
      height: 60px;
      border: 1px solid #ece8e4;
    }

  .Select-placeholder,
  .Select-control .Select-value {
    ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '16px')}
      line-height: 60px;
      padding: 0 20px;
    }

  .Select-placeholder,
  .Select-control .Select-value,
  .Select-control .Select-value .Select-value-label,
  &.has-value .Select-control .Select-value .Select-value-label {
      color: ${davidoff.colors.brown};
    }

  .Select-clear-zone { display: none; }

  .Select-arrow-zone {
      padding-right: 20px;

    .Select-arrow {
        border: none;
        border-right: 2px solid ${davidoff.colors.brown};
        border-bottom: 2px solid ${davidoff.colors.brown};
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
      }
    }

  &.is-open > .Select-control .Select-arrow {
      top: auto;
    }

  .Select-menu-outer {
      border-color: #ece8e4;

      .Select-option {
        color: #8c7d70;
        padding: 0 20px !important;
        font-size: 12pt;
        height:50px;
        line-height:50px;
      }
    }

  .Select-option.is-focused {
      background-color: ${davidoff.colors.lightbrown};
    }
  }
`;
