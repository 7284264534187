import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import getNested from 'get-nested';
import SiteStore from '../../../stores/SiteStore';
import SearchStore from '../../../stores/SearchStore';
import { dataMapper } from '../../index';
// Components
import Paginator from '../../common/Paginator/Paginator';
import { H2 } from '../../style-guide/Headings';
// Styled
import NoResults from './styled/Noresults';
import SearchForm from './styled/SearchBlock';
import SearchContainer from './styled/SearchContainer';
import SearchResult from '../../common/SearchResult/SearchResult';
import SearchResultHeading from './styled/SearchResultHeading';
import TranslationStore from '../../../stores/TranslationStore';

@inject('translationStore')
@observer
class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      noResults: false
    };

    const view = dataMapper(props.data, props.paragraph.field_view_reference.target_uuid);
    // TODO: Remove the Results multiplier
    const results = view.results.map((resultID) => {
      return dataMapper(props.data, resultID);
    });

    this.SiteStore = SiteStore.getInstance();
    this.SearchStore = SearchStore.getInstance();
    this.SearchStore.setItems(results);
    this.SearchStore.filters = view.display.filters;
    this.SearchStore.data = props.data;
    this.SearchStore.updateUrl = false;
    
    // catch the paginated view
    const currentPage = getNested(() => this.SiteStore.queryParams.page, getNested(() => queryString(root.location.search).page, false));

    if(currentPage) {
      this.SearchStore.currentPage = parseFloat(currentPage) - 1;
    }
  }

  render() {
    const { data, translationStore } = this.props;
    const Results = this.SearchStore.displayedItems.map((result, idx) =>
      <SearchResult key={`${result.uuid}-${idx}`} item={result} data={data} />);

    return (
      <SearchContainer ref={(wrapper) => this.SearchStore.wrapperElement = wrapper}>
        <SearchForm
          currentLanguage={this.SiteStore.language}
          lang={this.SiteStore.language}
          settings={this.SiteStore.settings}
        />

        {Results.length === 0 &&
          <NoResults>
            <H2>{translationStore.translate('No results')}</H2>
            <p>
              {translationStore.translate('There are no results for this search query.')}
            </p>
          </NoResults>
        }
        {Results.length > 0 &&
          <SearchResultHeading>{this.SearchStore.itemList.length} {translationStore.translate('search results: ')}</SearchResultHeading>
        }
        {Results}
        <Paginator
          nextPage={this.SearchStore.nextPage}
          prevPage={this.SearchStore.prevPage}
          switchPage={this.SearchStore.switchPage}
          maxPages={this.SearchStore.maxPages}
          currentPage={this.SearchStore.currentPage}
          lang={this.SiteStore.language}
          settings={this.SiteStore.settings}
        />
      </SearchContainer>
    );
  }
}

Search.propTypes = {
  translationStore: PropTypes.instanceOf(TranslationStore),
};
Search.defaultProps = {};

export default Search;
