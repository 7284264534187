import React from 'react';
import styled from 'styled-components';

export default styled.div`
  @media (min-width: 768px) {
    float: left;
    margin-bottom: 80px;
    width: 70%;
  }
  
  @media (min-width: 1024px) {
    width: 74%;
  }  
`;
