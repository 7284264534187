import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.nav`
  color: ${davidoff.colors.gray};
  flex: 0 0 100%;
  margin: 15px 0 5px;
  padding: 0 3%;
  text-transform: uppercase;
  
  @media (min-width: 768px) {
    font-size: 14px;
    margin: 20px 0;
  }
  
  @media (min-width: 1024px) {
    padding: 0;
  }
  
  ul { 
    margin: 0; 
    
    li {
      ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '14px')}
      color: ${davidoff.colors.gray};
    }
  }
`
