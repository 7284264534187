import React from 'react';
import styled from 'styled-components';

export default styled.em`
  ${({theme}) => theme.fonts.parse('sansSerif', 'regular', '16px')}
  display: none;
  padding: 15px 0;
  text-align: center;
  
  @media (min-width: 1024px) {
    display: block;
  }
`;
