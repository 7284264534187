import React from 'react';
import styled from 'styled-components';
import MediaImageContainer from '../../../common/MediaImage/index.c';

export default styled(MediaImageContainer)`
  img {
    max-width: 100%;
    
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`
