import React from 'react';
import styled from 'styled-components';
import linkStyles from '../../../../../shared-styles/link-styles.js';

export default styled.a`
  ${linkStyles.close}
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  
  @media only screen and (min-width: 1025px) {
    right: 10px;
    top: 10px;
  }
  
  ${props => props.open ? `
    opacity: 1;
  ` : ``}
  }
`;
