import * as React from 'react';
import { Cloudinary, Configuration, Transformation } from 'cloudinary-core';
import { ProviderState, CloudinaryProvider as ImageProvider } from '../context';

function extractCloudNameFromUrl(url) {
  const splittedByDomain = url.split('https://res.cloudinary.com/')[1];
  return splittedByDomain.split('/')[0];
}

export class CloudinaryProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cl: props.cloudinaryOptions
        ? Cloudinary.new(props.cloudinaryOptions)
        : null,
      cloudName: props.cloudinaryOptions.cloud_name || '',
      cloudinaryOptions: {
        ...props.cloudinaryOptions,
        secure: true,
      },
      baseImageSettings: {
        crop: 'fill',
        placeholder: '',
        fetch_format: 'auto',
        quality: 'auto',
        ...props.baseImageSettings,
      },
      previewImageSettings: {
        width: 'auto',
        height: 150,
        effect: 'blur:600',
        quality: 70,
        ...props.previewImageSettings,
      },
      initCloudinary: this.initCloudinary,
    };
  }

  initCloudinary = url => {
    if (!this.state.cl) {
      const cloudName = extractCloudNameFromUrl(url);

      this.setState({
        cloudName,
        cl: Cloudinary.new({
          cloud_name: cloudName,
          secure: true,
          ...this.props.cloudinaryOptions,
        }),
      });
    }
  };

  render() {
    return (
      <ImageProvider value={this.state}>{this.props.children}</ImageProvider>
    );
  }
}
