import fetch from "fetch-everywhere";

/*
 * The Social API
 *  Fetches the social data from the social channels
 *  that are specified in the drupal settings
 */

class SocialAPI {
  // Get Social Feed.
  static async getSocialFeed(id) {
    let IGstorage = window.localStorage.getItem(`IGdata${id}`);
    IGstorage = IGstorage == null ? null : JSON.parse(IGstorage);

    if (
      IGstorage == null ||
      IGstorage.timestamp + 86400 < Date.now() // Over 24h "cache.
    ) {
      const url = `/instagram_data?id=${id}`;

      const options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      };

      try {
        const CMSdata = await (await fetch(url, options)).json();
        const IGdata = await (
          await fetch(
            `https://graph.instagram.com/me/media?fields=id,caption,permalink,media_url,media_type,thumbnail_url&access_token=${CMSdata.token}`
          )
        ).json();

        if (CMSdata.caption) {
          IGdata.data.forEach((element) => {
            element.caption =
              CMSdata.caption;
          });
        }

        // Store to no spam IG with requests.
        IGstorage = IGdata.data;
        window.localStorage.setItem(
          `IGdata${id}`,
          JSON.stringify({
            timestamp: Date.now(),
            data: IGdata.data,
          })
        );

        return IGstorage;
      } catch (err) {
        console.log(err.message);
      }
    }

    return IGstorage?.data;
  }
}

export default SocialAPI;
