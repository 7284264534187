import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.button`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '14px')}
  background: none;
  border: 0;
  color: #000;
  overflow: hidden;
  position: absolute;
  top: 18px;
  right: 30px;
  text-transform: uppercase;
  transition: color 300ms;

  &:hover {
    @media (min-width: 1024px) {
      color: ${davidoff.colors.orange};
    }
  }
`;
