import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import root from 'window-or-global';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from 'styled-components';
// Components
import PrimaryMenu from '../menus/PrimaryMenu/PrimaryMenu';
import SecondaryMenu from '../menus/SecondaryMenu/SecondaryMenu';
// Stores
import HeaderStore from '../../../stores/HeaderStore';

@observer
class Navigation extends React.Component {
  @observable HeaderStore = HeaderStore.getInstance();

  static propTypes = {
    settings: PropTypes.shape().isRequired,
    lang: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    root.addEventListener('scroll', () => {
      this.HeaderStore.addFixedHeading();
    });
  }

  render() {
    const { settings, lang, location, isLoading, data } = this.props;
    const pageData = data.data[data.paths[location]];
    return (
        <div className={classNames({ 'menu-opened': this.HeaderStore.menuOpen, 'navigation-wrapper': true })}>
          {pageData && !pageData.additional_blocks && <SecondaryMenu
            settings={settings}
            lang={lang}
            open={this.HeaderStore.menuOpen}
          />
          }
          {pageData && !pageData.additional_blocks && <PrimaryMenu
            data={data}
            location={location}
            settings={settings}
            lang={lang}
            open={this.HeaderStore.menuOpen}
            fixed={this.HeaderStore.fixedHeader}
            isLoading={isLoading}
          />
          }
        </div>
    );
  }
}

export default Navigation;
