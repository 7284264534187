// import getNested from 'get-nested';
import { observable } from 'mobx';
import OverviewStore from './OverviewStore';

/**
 * SearchStore:
 * - Contains a list of all available Articles
 * - Contains a list of displayed Articles
 */

class SearchStore extends OverviewStore {
  static instance;

  @observable postsPerPage = 20; // TODO: Add correct number when finished

  static getInstance() {
    if(!SearchStore.instance) {
      SearchStore.instance = new SearchStore();
    }

    return SearchStore.instance;
  }
}

export default SearchStore;
