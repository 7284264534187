import styled from 'styled-components';
import { Link } from 'react-router-dom';
import buttonStyles from '../../../shared-styles/button-styles';
import VideoWrapperComponent from '../../paragraph-types/BrandIntroduction/styled/BrandIntroductionVideoWrapper';
import davidoff from '../../../themes/davidoff';

const Header = styled('header')`
  align-items: flex-end;
  background-image: url(${props => props.images.mobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  display: flex;
  height: 80vh;
  justify-content: center;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
  
  @media (min-width: 768px) {
    background-image: url(${props => props.images.desktop});
    height: calc(100vh - 171px);
  }
  
  @media (min-width: 1024px) {
    margin-bottom: 50px;
  }
`;

const VideoWrapper = styled(VideoWrapperComponent)`
   width: calc(16 / 9 * 100vw);
  
  @media (min-width: 768px) and (max-aspect-ratio: 16/9){
    width: calc(16 / 9 * 100vw);
  }
  
`;
const HeaderContent = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 500px;
  padding-bottom: 20px;
  position: relative;
  text-align: center;
  z-index: 1;
`;

const Button = styled(Link)`
  ${props => buttonStyles(props)}
  background-color: ${davidoff.colors.orange};
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  width: auto;
  
  &:hover {
    color: #ffffff;

    @media (min-width: 768px) {
      background-color: ${davidoff.colors.brown};
    }
  }
`;

const ScrollDownButton = styled('button')`
  ${props => props.theme.fonts.parse('sansSerif', 'black', '14px')}

  background: transparent;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  line-height: 17px;
  margin: 17px 0 0 0;
  outline: none;
  padding: 0 0 25px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: color 400ms ease-out;
   
  svg {
    bottom: 0;
    display: block;
    fill: #ffffff;
    height: 18px;
    left: 50%;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%) rotate(90deg);
    transition: fill 400ms ease-out;
    width: 18px;
  }
  
  // Hover styles
  &:hover {
    color: ${davidoff.colors.orange};
    
    svg {
      fill: ${davidoff.colors.orange};
    }
  }
`;

const styles = {
  Header,
  HeaderContent,
  Button,
  ScrollDownButton,
  VideoWrapper,
};

export default styles;
