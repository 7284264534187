import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../icons/icons';
// Styled components
import PostWrapper from './styled/PostWrapper';
import PostImage from './styled/PostImage';
import PostText from './styled/PostText';
import PostLink from './styled/PostLink';
import PostPlaybtn from "./styled/PostPlaybtn";
import Playbtn from "../../../../svg/instagram-play.svg"

const InstagramPost = ({post}) => {
  let videoIcon = post.isVideo ? <PostPlaybtn src={Playbtn} /> : '';

  return (
    <PostWrapper>
      <PostLink href={post.url} target='_blank'>
        {videoIcon}
        <PostImage
          src={post.image}
          alt={post.text}
        />
        <PostText>{post.text} {icons.external}</PostText>
      </PostLink>
    </PostWrapper>
  );
};

InstagramPost.propTypes = {
  post: PropTypes.shape({
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default InstagramPost;
