import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import typographyStyles from '../../../../shared-styles/typography-styles.js';
// import davidoff from '../../../../themes/davidoff';

export default styled.span`
  ${props => typographyStyles.label(props)};
  left: ${structureStyles.mobileValues.titleIndentFull};
  position: relative;
  display: block;
  margin-bottom: 0 !important;
  
  @media (min-width: 768px) {
    left: auto;
  }
  
  &:before,
  &:after {
    background-color: #fc6b0d;
    content: '';
    left: 0;
    height: 8px;
    position: absolute;
    top: -4px;
    width: 8px;
  }
  
  &:after {
    transform: skew(-20deg, 0deg);
    transform-origin: bottom left;
    width: 60px;
  }
`;
