import React from 'react';
import styled from 'styled-components';

export default styled.img`
  display: block;
  height: auto;
  left: 50%;
  min-height: 101%;
  min-width: 101%;
  max-width: 101%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;
