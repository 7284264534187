import styled from 'styled-components';

export default styled.p`
  display: inherit;
  ${props => props.theme.fonts.parse('sansSerif', props.fontWeight || 'regular', '14px')};
  font-weight: ${props => props.fontWeight || '300'};
  color: #000;
  text-align: ${props => props.alignText || 'center'};
  margin-bottom: 0;
  
  @media (min-width: 768px) {
    ${props => props.theme.fonts.parse('sansSerif', props.fontWeight || 'regular', '16px')};
    text-align: left;
  }
`;
