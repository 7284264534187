import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SpecialActivityContent = styled(Link)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  text-align: left;
  text-decoration: none;
  
`;
