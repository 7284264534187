import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';

export default styled.header`
  ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '14px')}
  background: ${davidoff.colors.lime};
  border-bottom: 1px solid ${davidoff.colors.lightbrown};
  border-top: 1px solid ${davidoff.colors.lightbrown};
  text-transform: uppercase;
  margin: 0 -7%;
  overflow: hidden;
  padding: 15px 7%;
  color: #000;
  
  @media (min-width: 768px) {
    border-top: 0;
    margin: 0 -20px;
    padding: 15px 20px;
  }
  
  @media (min-width: 1024px) {
    display: none;
  }
  
  span {
    float: right;
  }
  
  svg {
    height: 11px;
    margin-left: 5px;
    transition: transform 300ms;
    width: 11px;
  }
  
  &.open svg {
   transform: rotate(90deg);
  }
  
`;
