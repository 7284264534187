import React from "react";

import getNested from "get-nested";
import { dataMapper } from "../../index";
// Components
import HyphenTitle from "../../style-guide/HyphenTitle/HyphenTitle";
import ResponsiveImage from "../../style-guide/Image/ResponsiveImage";
import ParallaxImage from "../../style-guide/Image/ParallaxImage";
import FullWidthVideo from "../../paragraph-types/FullWidthVideo/FullWidthVideo";

// Styles
import * as styles from "./styles";

export function CampaignProductHighlight(props) {
  const { paragraph, data } = props;

  const mediaParagraph = dataMapper(
    data,
    getNested(() => paragraph.field_campaign_f_w_m_media.target_uuid)
  );

  const image = dataMapper(
    data,
    getNested(() => mediaParagraph.image.target_uuid)
  );

  const getRatio = (value) => {
    switch (value) {
      case 0:
        return "crop_16_9";
      case 1:
      default:
        return "crop_1920_815";
    }
  };
  const ratio = getRatio(paragraph.image_ratio);

  const [crop] = image.field_media_image.computed.crops.filter(
    (crop) => crop.type === ratio
  );

  // // const origW = parseInt(crop.crop.width, 10) + parseInt(crop.crop.x, 10) * 2;
  // // const origH = parseInt(crop.crop.height, 10) + parseInt(crop.crop.y, 10) * 2;
  const origAR = parseInt(crop.crop.height, 10) / parseInt(crop.crop.width, 10);

  const multiplier = 1920 / parseInt(crop.crop.width, 10);

  const initialResize = {
    quality: 100,
    width: Math.floor(1920 + crop.crop.x * multiplier),
    height: Math.floor(1920 * origAR + crop.crop.y * multiplier),
    crop: "scale",
  };

  const cropOption = {
    x: Math.floor(crop.crop.x * multiplier),
    y: Math.floor(crop.crop.y * multiplier),
    width: Math.floor(crop.crop.width * multiplier),
    height: Math.floor(crop.crop.height * multiplier),
    crop: "crop",
  };

  const options = paragraph.field_campaign_f_w_m_parallax
    ? {
        crop: "scale",
        width: 1920,
        height: 815,
      }
    : {
        transformation: [initialResize, cropOption],
      };

  // const paddingBottom =
  //   ratio === "21:9" ? `${(9 / 21) * 100}%` : `${(9 / 16) * 100}%`;

  return (
    <styles.ProductHighlightWrapper>
      <styles.ProductHighlightWrapperInner>
        <div className="product__highlight_container">
          <HyphenTitle.h2>
            <span>{paragraph.field_campaign_f_w_m_title}</span>
          </HyphenTitle.h2>
        </div>
      </styles.ProductHighlightWrapperInner>
      <section>
        <styles.ProductHighlightWrapperInner>
          <styles.ProductWrapper
            isParallax={paragraph.field_campaign_f_w_m_parallax}
          >
            {mediaParagraph.type.target_id == "video" && (
              <FullWidthVideo paragraph={mediaParagraph} />
            )}
            {paragraph.field_campaign_f_w_m_parallax &&
              mediaParagraph.type.target_id != "video" && (
                <ParallaxImage
                  src={image.field_media_image.url}
                  alt={image.field_media_image.alt}
                  options={options}
                  parallaxSpeed={
                    paragraph.parallax_speed ? paragraph.parallax_speed : 200
                  }
                />
              )}
            {!paragraph.field_campaign_f_w_m_parallax &&
              mediaParagraph.type.target_id != "video" && (
                <ResponsiveImage
                  src={image.field_media_image.url}
                  alt={image.field_media_image.alt}
                  options={options}
                />
              )}
          </styles.ProductWrapper>
        </styles.ProductHighlightWrapperInner>
      </section>
    </styles.ProductHighlightWrapper>
  );
}
