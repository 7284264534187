import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import mapper from '../../index';
// Components
import RelatedArticlesView from '../../common/RelatedArticlesView/RelatedArticlesView';
// Styled
import Intro from '../../style-guide/Intro/Intro';
import IntroContainer from './styled/IntroContainer';
import ProductHighlight from '../../common/ProductHighlight/ProductHighlight'

class Industry  extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { page, data } = this.props;
    const IndustryBlocks = page.field_industry_page_blocks.map((block) => mapper(data, false, block.target_uuid));
    let OtherLines = false;

    if(getNested(() => page.field_collections.target_uuid, false)) {
      OtherLines = mapper(data, false, page.field_collections.target_uuid);
    }

    return (
      <div>
        {getNested(() => page.field_industry_page_header, false) &&
        mapper(data, false, page.field_industry_page_header.target_uuid)
        }
        <IntroContainer>
          <Intro>{page.field_body_summary}</Intro>
        </IntroContainer>
        <div className="components">
          {IndustryBlocks}
        </div>
        {OtherLines}
        <RelatedArticlesView page={page} data={data} title />
        <ProductHighlight data={data} />
      </div>
    );
  }
}

Industry.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    field_industry_page_header: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }),
    field_industry_page_blocks: PropTypes.arrayOf(
      PropTypes.shape({
        target_uuid: PropTypes.string.isRequired,
      })
    )
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default Industry;
