import React from 'react';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  padding: 20px 0;
  
  @media (min-width: 350px) {
    display: flex;
  }

  @media (min-width: 1024px) {
    display: block;
  }
  
  @media (min-width: 768px) and (max-width: 1023px),
   (min-width: 1200px) {
    display: flex;
  }
  
  ul {
    margin-bottom: 0;
  }
`;
