import styled from 'styled-components';
import { Source } from '../container';

export const ImageWrapper = styled('div')`
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${props => formatPlaceHolderSpace(props.sources)} @media print {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  img,
  picture {
    position: absolute;
    width: 100%;

    &.preview {
      filter: blur(3px);
      transform: scale(1.05);
      width: 100%;
    }
  }
`;

export function formatPlaceHolderSpace(sources) {
  let css = '';
  // Reverse the array since the media query interpetation is reversed in css
  // and not the same on the source element.
  const imageSource = [...sources];
  imageSource.reverse();
  imageSource.forEach(({ media, settings }) => {
    const { aspect_ratio, aspectRatio } = settings;

    if(aspect_ratio || aspectRatio) {
      const ratio =
        (aspect_ratio && aspect_ratio.split(':')) ||
        (aspectRatio &&
          typeof aspectRatio === 'string' &&
          aspectRatio.split(':'));
      const styles = `
      ${ratio ? `padding-bottom: ${(ratio[1] / ratio[0]) * 100}%` : ''};`;
      css += wrapWithMediaQuery(styles, media);
    }
  });

  return css;
}

export function wrapWithMediaQuery(css, media) {
  return media && media !== ''
    ? `
    @media ${media}{
      ${css}
    }
  `
    : css;
}

export default ImageWrapper;
