import React from 'react';
import * as styles from './styled';
import parse from 'html-react-parser';

export function SplitText(props) {
  const { paragraph } = props;

  return (
    <styles.SplitContent>
      <div className="split-content__column split-content__column--left">
        <h3 className="split-content__title">{ paragraph.field_campaign_d_p_subtitle_l }</h3>
        {parse(paragraph.field_campaign_d_p_text_l.value)}
      </div>
      <div className="split-content__column split-content__column--right">
        <h3 className="split-content__title">{ paragraph.field_campaign_d_p_subtitle_r }</h3>
        {parse(paragraph.field_campaign_d_p_text_r.value)}

      </div>
    </styles.SplitContent>
  )
}