// Core
import React from 'react';

// API-related
import getNested from 'get-nested';
import { dataMapper } from "../../index";

// Components
import { CampaignHeaderContent } from '../../common/CampaignHeaderContent/Component'

// Styles
import * as styles from './styled'

export function CampaignHeaderColor(props) {
  const { paragraph, data } = props;
  
  const mediaParagraph = dataMapper(
    data, 
    getNested(() => paragraph.field_campaign_h_c_highlight.target_uuid)
  );

  const image = dataMapper(
    data, 
    getNested(() => mediaParagraph.image.target_uuid)
  );


  const contentData = {
    title: paragraph.field_campaign_h_c_title,
    image: mediaParagraph.type.target_id == "single_image" && {
      alt: image.field_media_image.alt, 
      src: image.field_media_image.url
    },
    videoParagraph: mediaParagraph.type.target_id == "video" && mediaParagraph,
    type: mediaParagraph.type.target_id,
    button: {
      title: paragraph.field_campaign_h_c_button.title,
      uri: paragraph.field_campaign_h_c_button.uri,
    }
  }

  return (
    <styles.CampaignHeaderColor 
      backgroundColor={paragraph.field_campaign_h_c_background}
    >
      <CampaignHeaderContent {...contentData} />
    </styles.CampaignHeaderColor>
  ) 
}
