import styled, { css, keyframes } from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import ResponsiveImage from '../../../style-guide/Image/ResponsiveImage';
import { VideoWrapper } from '../../FullWidthVideo/styled/VideoWrapper';
import VideoBlockWrapper from '../../FullWidthVideo/styled/VideoBlockWrapper';

// Animations/Transitions
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const hyphenBeforeGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 8px;
  }
`;

const hyphenAfterGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 60px;
  }
`;

// Variables
const fadeInSlow = css`${fpsToMs(25)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`;
const fadeInSlowMedium = css`${fpsToMs(26)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`;
const hyphenBeforeGrowAnimation = css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenBeforeGrow}`;
const hyphenAfterGrowAnimation = css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenAfterGrow}`;

// Functions
function fpsToMs(frames) {
    // 25 frames = 1000 ms
    // 1 frame = 40 ms
    return frames * 40;
}

// Components
export const ProductWrapper = styled.div`
    margin: 0 auto;
    padding: 0;
    width: 100%;
    ${
      props => props.paddingBottom ?
        `position: relative;
        padding-bottom: ${props.paddingBottom} !important;`
      : ``
    }

    @media (min-width: 1024px) {
        justify-content: flex-start;
    }

    @media (min-width: 1256px) {
        padding: 0;
    }

    img{
      max-width: ${p => p.isParallax ? `none` : `100%`} !important;
      ${p => p.isParallax ? `` : `width:100%;`}
    }

    section {
      padding: 0 0 ${structureStyles.mobileValues.spacing};
      
      @media (min-width: 768px) {
        padding: 0;
      }
    }

    ${VideoBlockWrapper}{
      &.video-element{
        ${
          props => props.paddingBottom ?
            `position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;`
          : ``
        }
      }
    }

    ${VideoWrapper}{
      ${
        props => props.paddingBottom ?
          `padding-bottom: ${props.paddingBottom} !important;`
        : ``
      }
    }

    ${ResponsiveImage}{
      picture{
        ${
          props => props.paddingBottom ?
            `position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;`
          : ``
        }
      }

      img{
        ${
          props => props.paddingBottom ?
            `width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;`
          : ``
        }
      }
    }
  

`;

export const ProductHighlightWrapper = styled.div`
    width: 100%;  
    padding: 0 6%;
  
    @media (min-width: 768px) {
      padding: 0;
      margin: 0 0 60px;
    }
    
    ${props => props.noMargin ? `
       margin: 0;
    
      @media (min-width: 768px) {
        margin: 0;
      }
    ` : ''}

    div.product__highlight_container{
        ${structureStyles.container}
    
    > div {
      @media (max-width: 767px) {
        justify-content: space-between;
      }
    }
  }
`

export const ProductHighlightWrapperInner = styled(ScrollEffect)`
  h2 {
    margin-left: 6%;
    max-width: 320px;
    padding-top: ${structureStyles.mobileValues.spacing};
    text-transform: uppercase;
    z-index: 1;
    font-size: 13px;
    line-height: 1.5;
    
    @media (min-width: 768px) {
      max-width: 480px;
      font-size: 20px;
      margin-left: 70px;
      padding-top: 60px;
    }
    
    &:before,
    &:after {
      bottom: -8px;
      
      @media (min-width: 768px) {
        width: 0;
      }
    }
    
    span {
      @media (min-width: 768px) {
        opacity: 0;
      }
    }
  }

  @media (min-width: 768px) {
    ${ProductWrapper} {
      opacity: 0;
    }
    
    &.animate {
      ${ProductWrapper} {
        animation: ${fadeInSlow};
      }
      
      h2 {
        &:before {
          animation: ${hyphenBeforeGrowAnimation};
        }

        &:after {
          animation: ${hyphenAfterGrowAnimation};
          animation-fill-mode: forwards;
        }

        span {
          animation: ${fadeInSlowMedium};
        } 
      }
    }
  }
`;
