import React from 'react';
import styled from 'styled-components';

export default styled.nav`
  background-color: #fff;
  order: 3;
  padding: 10px 0;
  position: relative;
  z-index: 100;
  
  @media only screen and (min-width: 1025px) {
    border-bottom: 1px solid #ece8e4;
    padding: 15px 0 0;
  }
  
  ${props => props.fixed ? `
    @media only screen and (min-width: 1025px) {
      position: fixed;
      top: 0;
      width: 100%;
    }
  ` : ``}
  
`;
