// Core
import React from "react";

// API-related
import { dataMapper } from "../../index";
import getNested from "get-nested";

// Components
import ResponsiveImage from "../../style-guide/Image/ResponsiveImage";
import FullWidthVideo from "../FullWidthVideo/FullWidthVideo";

// Styles
import * as styles from "./styled";
import { console } from "window-or-global";

export function CampaignBanner(props) {
  const { paragraph, data } = props;

  const mediaParagraph = dataMapper(
    data,
    getNested(() => paragraph.field_campaign_container_media.target_uuid)
  );
  if (mediaParagraph.type.target_id == "video") {
    return (
      <styles.CampaignBanner isSmall={false}>
        <FullWidthVideo paragraph={mediaParagraph} />
      </styles.CampaignBanner>
    );
  }
  const image = dataMapper(
    data,
    getNested(() => mediaParagraph.image.target_uuid)
  );

  const [crop] = image.field_media_image.computed.crops.filter(
    (crop) => crop.type === "crop_16_9"
  );

  // const origW = parseInt(crop.crop.width, 10) + parseInt(crop.crop.x, 10) * 2;
  // const origH = parseInt(crop.crop.height, 10) + parseInt(crop.crop.y, 10) * 2;
  const origAR = parseInt(crop.crop.height, 10) / parseInt(crop.crop.width, 10);

  const multiplier = 1920 / parseInt(crop.crop.width, 10);

  const initialResize = {
    quality: 100,
    width: Math.floor(1920 + crop.crop.x * multiplier),
    height: Math.floor(1920 * origAR + crop.crop.y * multiplier),
    crop: "scale",
  };

  const cropOption = {
    x: Math.floor(crop.crop.x * multiplier),
    y: Math.floor(crop.crop.y * multiplier),
    width: Math.floor(crop.crop.width * multiplier),
    height: Math.floor(crop.crop.height * multiplier),
    crop: "crop",
  };

  const options = {
    transformation: [initialResize, cropOption],
  };

  // TODO: Implement a way to make it 10% smaller (component was present in front-end, reason unknown to me)
  const isSmall = false;

  return (
    <styles.CampaignBanner isSmall={isSmall}>
      <ResponsiveImage
        alt={image.field_media_image.alt}
        src={image.field_media_image.url}
        options={options}
      />
    </styles.CampaignBanner>
  );
}
