import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
// Components
import Logo from '../Logo/Logo';
// Stores
import HeaderStore from '../../../stores/HeaderStore';
// Components
import MenuTrigger from '../menus/MenuTrigger/MenuTrigger';
import HeaderWrapper from './styled/HeaderWrapper';
import Header from './styled/Header';


@observer
class HeaderComponent extends React.Component {
  static propTypes = {
    settings: PropTypes.shape().isRequired,
    lang: PropTypes.string.isRequired,
  };
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.HeaderStore = HeaderStore.getInstance();
  }

  render() {
    const { settings, lang, data, location} = this.props;
    const pageData = data.data[data.paths[location]];
    return (
      <HeaderWrapper 
        className={classNames({ 
          'menu-opened': this.HeaderStore.menuOpen, 
          'header-wrapper': true,
        })}
      >
      <Header
          id='header'
          fixed={this.HeaderStore.fixedHeader}
        >
      <Logo settings={settings} lang={lang} />
        {pageData && !pageData.additional_blocks && <MenuTrigger
            lang={lang}
            settings={settings}
            className={classNames({'menu-opened': this.HeaderStore.menuOpen})}
            onClick={this.HeaderStore.toggleMenu}
          />
        }
      </Header>
      </HeaderWrapper>
    );
  }
}

export default HeaderComponent;
