import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

export default styled(Select)`
    margin-bottom: 0;
    
    .Select-arrow-zone {
      background: #f7f5f3;
    }
`;
