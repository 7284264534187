import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import styled from 'styled-components';
import FooterMenuItem from '../menuItems/FooterMenuItem/FooterMenuItem';

const MenuList  = styled.ul`
  list-style: none;
  margin: 0 0 10px;
  padding: 0 2%;

  @media (min-width: 768px) {
    margin: 0 0 1em;
    padding: 0;
  }
`;

const FooterMenu = ({ settings, language }) => {
  if(!getNested(() => settings.menu[language], false)) {
    return null;
  }

  const menuItems = settings.menu[language].footer.map(menuItem => <FooterMenuItem key={`footer-menu-item-${menuItem.title}`} menuItem={menuItem} />);

  return (
    <MenuList>
      {menuItems}
    </MenuList>
  );
};

FooterMenu.propTypes = {
  settings: PropTypes.shape({}).isRequired,
  language: PropTypes.string.isRequired,
};

FooterMenu.defaultProps = {};

export default FooterMenu;
