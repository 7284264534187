import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

const Form = styled.form`
  ${props => props.bottom ? `
    background-color: ${davidoff.colors.gray};
    padding-bottom: 20px;
    text-align: center;
    position: relative;
    z-index: 2;
    
    @media (min-width: 768px) {
      padding-bottom: 60px;
    }
  ` : ``}
`;

export default Form;
