import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../themes/davidoff';

export default styled.ul`
  list-style: none;
  margin: 0;
  position: relative;
  top: 1px;
  text-align: center;
  border-bottom: 1px solid ${davidoff.colors.lightbrown};

  @media (min-width: 1025px) {
    .background-item:before {
      background-color: #fff;
      content: '';
      left: 0;
      height: 0;
      padding: 0;
      position: absolute;
      top: 42px;
      transition: padding 150ms ease-in-out;
      transition-delay: 0;
      width: 100vw;
      z-index: -1;
      border-bottom: 1px solid ${davidoff.colors.lightbrown};
    }

    .level-1:not(.no-subnav):hover ~ .background-item {
      ${props =>
        !props.isLoading
          ? `
        &:before {
          transition: padding 450ms ease-in-out;
          transition-delay: 600ms;
          padding-bottom: 457px;
        }`
          : ''}
    }
  }
`;
