import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import Title from '../../../common/ProductHighlightItem/styled/Title.jsx';

export default styled.section`
  background-color: ${davidoff.colors.darkblue};
  padding-bottom: 45px;
  text-align: center;
  
  @media (min-width: 768px) {
    padding-bottom: 60px;
  }
  
  ${Title} {
    color: #fff;
    
    &::after {
      border-color: #fff;
    }
  }
`;
