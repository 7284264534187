import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import ShareIcons from '../../../common/SocialSharingComponent/styled/ShareIcons';
import structureStyles from '../../../../shared-styles/structure-styles';

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1216px;

  @media (min-width: 768px) {
    justify-content: space-between;
    padding-bottom: ${structureStyles.desktopValues.spacing};
  }

  ${ShareIcons} {
    background-color: ${davidoff.colors.veryLightGrey};
    padding: 15px 20px 5px;
    text-align: left;

    a {
      background-color: #aea49b;

      &:hover {
        background-color: ${davidoff.colors.orange};
      }
    }
  }
`;
