import React from 'react';
import PropTypes from 'prop-types';
import Column from './styled/Column';

const ColumnMenuItem = ({ children, className }) => {
  return (
    <Column className={className}><ul>{children}</ul></Column>
  );
};

ColumnMenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

ColumnMenuItem.defaultProps = {
  children: null,
  className: '',
};

export default ColumnMenuItem;
