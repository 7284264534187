import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import davidoff from '../../../../themes/davidoff';

const blockWidth = (384/1216) * 100;

export default styled.div`
  ${structureStyles.flexColumn}
  
  @media (min-width: 768px) {
    max-width: ${blockWidth}%;
    min-width: ${blockWidth}%;
    margin-right: ${(30/1216) * 100}%;
    
    &:nth-child(1n + 4) {
      margin-top: ${structureStyles.desktopValues.spacing};
    }
    
    &:nth-child(2n + 2) {
      margin-right: ${(30/1216) * 100}%;
    }
    
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  
  @media (max-width: 767px) {
    margin-bottom: 20px;
    width: 100%;
  }
  
  img, picture {
    display: block;
    transition: transform 1500ms ease;
    width: 100%;
    
    @media (min-width: 768px) {
      height: auto;
    }
  }
  
  &:hover {
    img, picture {
      transform: scale(1.05);
    }
  }
  
  a {
    color: ${davidoff.colors.orangeLink};
    display: block;
    position: relative;
    height: 100%;
    text-decoration: none;
    
    &:hover {
      color: ${davidoff.colors.orangeLink};
    }
  }
`;
