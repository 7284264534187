import React from 'react';
import PropTypes from 'prop-types';
import mapper, {dataMapper} from "../../index";
import InstructionRow from "./InstructionRow/InstructionRow";
import HyphenTitles from '../../style-guide/HyphenTitle/HyphenTitle';
const HyphenTitle = HyphenTitles.h2;

// Styled components
import InstructionWrapper from './styled/InstructionWrapper';
import InstructionHyphenTitle from './styled/InstructionHyphenTitle';

class InstructionBlock extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){

        const { paragraph, data } = this.props;

        const instructions = paragraph.field_instructions.map(instructionRef => {
            const instruction = dataMapper(data, instructionRef.target_uuid);

            return (
                <InstructionRow key={instruction.uuid} instruction={instruction} data={data} />
            );
        });

        return (
            <InstructionWrapper>
                <InstructionHyphenTitle hyphenPosition='bottom' hđyphenAlign='left'>{paragraph.field_title}</InstructionHyphenTitle>
                {instructions}
            </InstructionWrapper>
        );
    };
}


InstructionBlock.propTypes = {
    paragraph: PropTypes.shape({
        field_title : PropTypes.string.isRequired,
        field_instructions: PropTypes.arrayOf(
            PropTypes.shape({
                target_uuid: PropTypes.string.isRequired,
            }).isRequired,
        )
    }).isRequired,
    data: PropTypes.shape({}).isRequired,
};

export default InstructionBlock;
