import { observable } from 'mobx';
import root from 'window-or-global';

class HeaderStore {
  static instance;

  static getInstance() {
    if(!HeaderStore.instance) {
      HeaderStore.instance = new HeaderStore();
    }

    return HeaderStore.instance;
  }

  @observable fixedHeader = false;
  @observable showHorizontalMenu = true;
  @observable showMenuButton = false;
  @observable menuOpen = false;

  constructor() {
    this.addFixedHeading = this.addFixedHeading.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  addFixedHeading() {
    if(root.pageYOffset > 100 && this.fixedHeader === false) {
      this.fixedHeader = true;
      this.showHorizontalMenu = false;
      this.showMenuButton = true;
      document.body.classList.add('fixed');
    } else if(root.pageYOffset <= 100 && !this.menuOpen) {
      this.fixedHeader = false;
      this.showHorizontalMenu = true;
      this.showMenuButton = false;
      document.body.classList.remove('fixed');
    }

    return false;
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;

    // add remove the unit
    if(!this.menuOpen && root.pageYOffset > 0) {
      // the menu was closed so the fixed header needs to be reinitialized
      setTimeout(() => {
        this.fixedHeader = true;
      }, 1200);
    } else if(!this.menu && root.pageYOffset === 0) {
      this.fixedHeader = false;

      setTimeout(() => {
        this.showHorizontalMenu = true;
        this.showMenuButton = false;
      }, 1200);
    } else {
      // menu now open... remove the fixed header
      this.fixedHeader = false;
    }
  }

  reset() {
    this.fixedHeader = false;
    this.showHorizontalMenu = true;
    this.showMenuButton = false;
    this.menuOpen = false;
  }
}

export default HeaderStore;
