import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.header`
  ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '14px')}
  background: ${davidoff.colors.lime};
  color: ${davidoff.colors.brown};
  text-transform: uppercase;
  
  @media (min-width: 768px) {
    margin: 0 -20px;
    padding: 0 20px;
  }
  
  @media (min-width: 1024px) {
    display: none;
  }
`;
