import React from 'react';
import { Provider } from 'mobx-react';
import root from 'window-or-global';
import { BrowserRouter } from 'react-router-dom';
import DrupalRoute from '../lib/react-drupal';
import { CookiesProvider } from 'react-cookie';
import TranslationStore from '../stores/TranslationStore';
import SiteStore from '../stores/SiteStore';
import mapper from '../components/index';
import ScrollToTop from '../components/common/ScrollToTop/ScrollToTop';
import BaseLayout from '../components/layout/BaseLayout/BaseLayout';

const translationStore = TranslationStore.getInstance();
const siteStore = SiteStore.getInstance();
// We need a Root component for React Hot Loading.
function Root() {
  if(!root.INITIALSTATE) {
    return null;
  }

  return (
    <BrowserRouter>
      <ScrollToTop>
        <CookiesProvider>
          <Provider translationStore={translationStore} siteStore={siteStore}>
            <DrupalRoute
              data={root.INITIALSTATE}
              base=""
              layout={BaseLayout}
              mapper={mapper}
              translations={root.TRANSLATIONS}
              country={root.INITIALSTATE.country}
            />
          </Provider>
        </CookiesProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Root;
