import React from 'react';
import styled from 'styled-components';

export default styled.p`
  margin-bottom: 0.8em;
  
  @media (min-width: 768px) {
    padding-right: 15px;
  }
`;
