import React from 'react';
import styled from 'styled-components';

//margin: ${props => props.imageStyle === 'full_width_image' ? '0 -26%' : (props => props.imageStyle == 'column_width_image' ? '0' : '0 -26%')};
export default styled.div`
    display: inline-block;

    ${props => props.imageStyle == 'column_width_image' ? `
      display: block;
      max-width: 800px;
      margin: 60px auto;
      
      &:first-child{
        margin-top:0;

        @media (min-width: 1025px) {
          margin-top: 30px;
        }
      }
    ` : ''
    }
    

    @media (min-width: 1200px) {
      //max-height: 70vmin;
      overflow: hidden;

      ${props => props.imageStyle !== 'column_width_image' ? `
        margin: 0 -26%;
        width: calc(100% + 26% + 26%);
      ` : ` `
      }
    }
  `;
