import React from "react";
import styled from "styled-components";
import davidoff from "../../../../themes/davidoff";

export default styled.div`
  margin-top: 15px;

  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .toolbar {
    flex-basis: 100%;

    @media (min-width: 1440px) {
      flex-basis: auto;
    }
  }

  ul {
    padding: 0;
    margin: 0 20px 20px 0;

    @media (min-width: 768px) {
      margin: 0 20px 0 0;
    }

    li {
      display: inline-block;
      color: #fff;
      padding-top: 8px;
      position: relative;
      margin-right: 20px;

      label {
        ${(props) => props.theme.fonts.parse("sansSerif", "medium", "14px")}
        color: ${davidoff.colors.black};
        display: inline-block;
        float: none;
        letter-spacing: 0.04em;
        line-height: 24px;
        margin: 0;
        padding-top: 4px;
        padding-left: 25px;
        position: relative;
        transition: color 500ms ease-out;
        width: auto;

        &::before {
          top: 50%;
          transform: translateY(-50%);
          content: "";
          height: 7px;
          width: 7px;
          left: 4px;
          position: absolute;
          transition: none;
          background: transparent;
        }

        &::after {
          top: 50%;
          transform: translateY(-50%);
          content: "";
          height: 13px;
          width: 13px;
          left: 0;
          position: absolute;
          transition: none;
          border: 1px solid ${davidoff.colors.black};
        }

        &:hover {
          color: ${davidoff.colors.orangeLink};
        }

        &.active {
          color: ${davidoff.colors.orangeLink};

          &::after {
            width: 100%;
            transition: width 500ms ease-out;
          }
        }
      }

      input {
        position: absolute;
        overflow: hidden;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        opacity: 0;
        width: 0;

        &:checked {
          + label {
            &::before {
              background: ${davidoff.colors.orange};
            }
          }
        }
      }
    }
  }
`;
