import PropTypes from "prop-types";
import * as React from "react";
import ReactPlayer from "react-player";

// components
import icons from "../icons/icons";
import styles from "./styles";
import { dataMapper } from "../../index";
import getNested from "get-nested";
import { getBackgroundImagesByMedia } from "../../../misc/cloudinaryBackgrounds";
import { hasProtocol, stripProtocol } from "../../../misc";
import { withCookies, Cookies } from "react-cookie";

class ImageHeader extends React.Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();

    this.getBackgroundImages = this.getBackgroundImages.bind(this);
  }

  getBackgroundImages = () => {
    const { data, image } = this.props;

    const imageSettings = {
      breakpoints: {
        mobile: {
          width: 640,
          aspect_ratio: "375:650"
        },
        desktop: {
          width: 1600,
          aspect_ratio: "1920:815"
        }
      }
    };

    const media = dataMapper(
      data,
      getNested(() => image.target_uuid)
    );

    return getBackgroundImagesByMedia(media, imageSettings);
  };

  scrollToNextElement = ev => {
    ev.preventDefault();

    const bottomMargin = window.matchMedia("(min-width: 1024px)").matches
      ? 50
      : 25;
    const headerHeight = window.matchMedia("(min-width: 1024px)").matches
      ? 57
      : 50;
    const offset =
      this.headerRef.current.nextSibling.offsetTop -
      bottomMargin -
      headerHeight; // top offset - header - bottom margin

    window.scrollTo({
      top: offset,
      left: 0,
      behavior: "smooth"
    });
  };

  render() {
    var host = 'https://www.youtube.com';

    return (
      <styles.Header
        images={this.getBackgroundImages()}
        ref={this.headerRef}
        innerRef={ref => {
          this.headerRef = ref;
        }}
      >
        {this.props.videoUrl && (
          <styles.VideoWrapper>
            <div className="video-wrapper">
              <ReactPlayer
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                      mute: 1,
                      vq: "hd1440",
                      controls: 0
                    },
                    embedOptions: {
                      host: host
                    }
                  },
                  vimeo: {
                    playerOptions: {
                      api: 1,
                      background: 1,
                      loop: 1,
                      autoplay: 1,
                      autopause: 1
                    }
                  }
                }}
                className="video"
                url={this.props.videoUrl}
                playing={true}
                volume={0}
                width="100%"
                height="100%"
                loop
                muted
              />
            </div>
          </styles.VideoWrapper>
        )}
        <styles.HeaderContent>
          {this.props.cta && (
            <styles.Button
              to={stripProtocol(this.props.cta.url)}
              target={hasProtocol(this.props.cta.url) ? "_blank" : undefined}
            >
              {this.props.cta.label}
            </styles.Button>
          )}
          {!this.props.cta && (
            <styles.ScrollDownButton
              type="button"
              onClick={this.scrollToNextElement}
            >
              {this.props.scrollDownLabel}
              {icons.drop_down_arrow_filled}
            </styles.ScrollDownButton>
          )}
        </styles.HeaderContent>
      </styles.Header>
    );
  }
}

ImageHeader.propTypes = {
  image: PropTypes.shape({
    target_uuid: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  scrollDownLabel: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),
  videoUrl: PropTypes.string
};

export default withCookies(ImageHeader);
