import React from 'react';
import { observer } from 'mobx-react';
import getNested from 'get-nested';
import PropTypes from 'prop-types';
import SiteStore from '../../../stores/SiteStore';
import Columns from '../../style-guide/Structure/Columns';
import ArticleStore from '../../../stores/ArticleStore';
import Article from '../../common/Article/Article';
import ArticleOverviewWrapper from './styled/ArticleOverviewWrapper';
import Paginator from '../../common/Paginator/Paginator';
import { dataMapper } from '../../index';
import ItemListSchema from '../../layout/ItemListSchema';

@observer
class ArticleOverview extends React.Component {
  constructor(props) {
    super(props);

    // Get all the products from the view
    const view = dataMapper(
      props.data,
      props.paragraph.field_view_reference.target_uuid,
    );
    const articles = view.results.map(articleID => {
      return dataMapper(props.data, articleID);
    });
    // setup
    this.SiteStore = SiteStore.getInstance();
    this.ArticleStore = ArticleStore.getInstance();
    this.ArticleStore.setItems(articles);
    this.ArticleStore.filters = view.display.filters;
    this.ArticleStore.data = props.data;

    // catch the paginated view
    let currentPage = getNested(
      () => props.data.query.page,
      getNested(() => queryString(root.location.search).page, false),
    );

    if (currentPage) {
      this.ArticleStore.currentPage = parseFloat(currentPage) - 1;
    } else {
      this.ArticleStore.currentPage = 0;
    }
  }

  render() {
    const { data } = this.props;
    const Articles = [];

    // Sort the articles so the featured item is placed in front of everything.
    if (this.ArticleStore.currentPage === 0) {
      this.ArticleStore.displayedItems.sort((articleA, articleB) => {
        if (
          articleA.computed.featured_article &&
          !articleB.computed.featured_article
        ) {
          return -1;
        }

        if (
          articleB.computed.featured_article &&
          !articleA.computed.featured_article
        ) {
          return 1;
        }

        if (
          articleA.computed.featured_article ===
          articleB.computed.featured_article
        ) {
          return 0;
        }

        return 0;
      });
    }

    Articles.push(
      this.ArticleStore.displayedItems.map((article, idx) => {
        return (
          <Article
            key={`article-overview-${article.uuid}`}
            in={true}
            article={article}
            data={data}
            isfeatured={article.computed.featured_article}
            style={{ animationDelay: `${800 + idx * 240}ms` }}
          />
        );
      }),
    );

    /*
      For now there is no category over view.
      Find the below items we need for it.
      <CategoryFilter
        store={this.ArticleStore}
        categories={this.ArticleStore.filterOptions}
        lang={this.SiteStore.lang}
        settings={this.SiteStore.settings}
      />
    */

    // console.log(this.ArticleStore.displayedItems);

    return (
      <ArticleOverviewWrapper>
        <ItemListSchema
          items={this.ArticleStore.displayedItems.map(item => ({
            url: item.uri ? item.uri : getNested(() => item && item.__hn ? item.__hn.url : '/', '/'),
          }))}
        />
        <Columns ref={wrapper => (this.ArticleStore.wrapperElement = wrapper)}>
          {Articles}
        </Columns>
        <Paginator
          nextPage={this.ArticleStore.nextPage}
          prevPage={this.ArticleStore.prevPage}
          switchPage={this.ArticleStore.switchPage}
          maxPages={this.ArticleStore.maxPages}
          currentPage={this.ArticleStore.currentPage}
          lang={this.SiteStore.language}
          settings={this.SiteStore.settings}
        />
      </ArticleOverviewWrapper>
    );
  }
}

ArticleOverview.propTypes = {
  paragraph: PropTypes.shape({
    field_view_reference: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ArticleOverview;
