import React from 'react';
import styled from 'styled-components';

export default styled.img`
    z-index: 1;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 33px;
    height: 36px;
    opacity: 0.9;

    @media (min-width: 1024px){
      height: 56px;
      width: 53px;
    }

    @media (min-width: 1280px){
      height: 66px;
      width: 63px;
    }

    @media (min-width: 2560px){
      height: 76px;
      width: 73px;
    }
`;
