import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import ArticleLink from './ArticleLink';

export default styled.article`
  margin-bottom: ${structureStyles.mobileValues.spacing};
  overflow: visible !important;
  padding-bottom: ${structureStyles.mobileValues.spacing};
  position: relative;
  width: 100%;
  
  @media (min-width: 768px) {
    ${structureStyles.flexColumn}
    margin-bottom: 0;
    margin-right: ${(30/1216) * 100}%;
    
    &:nth-child(1n + 4) {
      margin-top: ${structureStyles.desktopValues.spacing};
    }
    
    &:nth-child(2n + 2) {
      margin-right: ${(30/1216) * 100}%;
    }
    
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  
  &:hover {
    @media (min-width: 1025px) {
      ${ArticleLink}::after {
        margin-left: 15px;
      }
    }
  }
`;
