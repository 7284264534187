import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.h1`
  ${(props) => props.theme.fonts.parse('serif', 'medium', '30px')}
  color: ${davidoff.colors.darkgray};
  line-height: 1.333;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
  
  span {
    ${(props) => props.theme.fonts.parse('sansSerif', 'regular')};
    font-weight: 300;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`
