import React from 'react';
import styled from 'styled-components';

// TODO: Should only be visible on mobile

export default styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`
