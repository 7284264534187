import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import davidoff from '../../../../themes/davidoff';

export default styled.div`
  background-color: ${davidoff.colors.lime};
  padding: 0 ${structureStyles.mobileValues.spacing} ${structureStyles.mobileValues.spacing};
  
  @media (min-width: 768px) {
    background-color: transparent;
    max-width: 592px;
    position: absolute;
    top: 50%;
    left: calc(50% - ${structureStyles.desktopValues.gridInnerSpacingHalf});
    transform: translateX(-100%) translateY(-50%);
    width: calc(50% - ${structureStyles.desktopValues.gridInnerSpacingHalf} - ${structureStyles.mobileValues.spacing});
    padding: 0 60px 40px;
    z-index: 1;
  }
    
  @media (min-width: 1025px) {
    padding: 0 ${structureStyles.desktopValues.sideSpacing} 25px;
  }
  
  @media (min-width:1220px) {
    width: 50%;
  }
    
  .bi-animation-bg {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    @media (min-width: 768px) {
      opacity: 1;
    }
        
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;
