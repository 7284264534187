import React from 'react';
import mapper from '../../index';
import getNested from 'get-nested';

function CampaignPage(props) {
  const { page, data } = props;

  const getParagraphs = () => {
    return page.field_components.map(item => mapper(data, false, item.target_uuid));
  }

  return (
    <>
      { getNested(() => page.field_campaign_header, false) &&
        mapper(data, false, page.field_campaign_header.target_uuid)
      }

      <div className="components">{ getParagraphs() }</div>

      { getNested(() => page.type.target_uuid , false) &&
        mapper(data, false, page.type.target_uuid)
      }
    </>
  )
}

export default CampaignPage;
