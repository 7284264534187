import React from 'react';
import styled from 'styled-components';

export default styled.header`
  z-index: 100;
  
  @media only screen and (max-width: 1024px) {
    background-color: #fff;
    border-bottom: 1px solid #ece8e4;
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  @media only screen and (min-width: 1025px) {
    padding-top: 40px;
  }
`;
