import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import typographyStyles from '../../../../shared-styles/typography-styles.js';
import productBG from '../../../../shared-styles/productBG';

export default styled.div`
  float: right;
  width: 135px;
  margin: 0 0 ${structureStyles.mobileValues.gridInnerSpacing} ${structureStyles.mobileValues.gridInnerSpacing};
  padding-top: 10px;
  
  @media (min-width: 768px) {
    width: 280px;
    float: right;
    margin: 0 0 30px 30px;
    padding-top: 30px;
  }
  
  @media (min-width: 1024px) {
    margin-right: -13%;
  }
  
  img {
    ${productBG()}
  }
  
  h2 {
    ${props => typographyStyles.label(props)};
    margin: 0;
    margin-left: ${structureStyles.mobileValues.subtitleIndent};
    
    @media (min-width: 768px) {
      margin-left: ${structureStyles.desktopValues.subtitleIndent};
      margin-bottom: 0;
    }
  }
`
