import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import TweenLite from 'gsap/TweenLite';
import root from 'window-or-global';
// import 'gsap/ScrollToPlugin';

let ping = 0;

function hashLinkScroll() {
  const { hash } = root.location;
  if(hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      // ping 3 times for a item... if we didn't find it then it's not htere...
      if(element) {
        TweenLite.to(
          root,
          1,
          {
            scrollTo: {
              y: hash,
              offsetY: 100,
              autoKill: false,
            },
          },
        );
      }
    }, 250);
  } else {
    root.scrollTo(0, 0);
  }
}

class ScrollToTop extends React.Component {
  static propTypes = {
    location: PropTypes.shape().isRequired,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]).isRequired,
  };

  componentDidMount() {
    hashLinkScroll();
  }

  componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location) {
      hashLinkScroll();
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
