import React from 'react';
import styled from 'styled-components';
import PostWrapper from './PostWrapper';

export default styled.span`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '14px')}
  color: #fff;
  left: 0;
  opacity: 0;
  padding: 0 15px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.35s ease 0s;
  width: 100%;
  z-index: 3;

  ${PostWrapper}:hover & {
    @media (min-width: 1025px) {
      opacity: 1;
      transition-delay: 0.25s;
    }
  }

  svg {
    display: inline-block;
    fill: #fff;
    height: 15px;
    margin: 0 5px;
    position: relative;
    top: 2px;
    width: 15px;
  }
`;
