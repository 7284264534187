import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import TopBar from './styled/TopBar';
import TopBarList from './styled/TopBarList';
import DefaultLink from './styled/DefaultLink';
import SearchMenuItem from '../menuItems/SearchMenuItem/SearchMenuItem';
import LanguageSwitchMenuItem from '../menuItems/LanguageSwitchMenuItem/LanguageSwitchMenuItem';
import NewsLetterMenuItem from '../menuItems/NewsLetterMenuItem/NewsLetterMenuItem';

const SecondaryMenu = ({ settings, lang, open}) => {
  const menuItems = getNested(() => settings.menu[lang].secondary, []).map((item) => {
    switch(item.type) {
      case 'newsletter':
        return <NewsLetterMenuItem key={item.key} menuItem={item} settings={settings} lang={lang} />;
        break;
      case 'language_switch':
        return <LanguageSwitchMenuItem key={item.key} menuItem={item} settings={settings} lang={lang} />;
      case 'search':
        return <SearchMenuItem key={item.key} menuItem={item} currentLanguage={settings.languages[lang]} settings={settings} lang={lang} />;
        break;
      default:
        return <DefaultLink key={item.key} menuItem={item} />;
    }
  });

  return (
    <TopBar open={open}>
      <div className="container">
        <TopBarList>
          {menuItems}
        </TopBarList>
      </div>
    </TopBar>
  );
};

SecondaryMenu.propTypes = {
  settings: PropTypes.shape({
    menu: PropTypes.shape().isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SecondaryMenu;
