import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';

export default styled.section`
  padding: ${structureStyles.mobileValues.spacing} 0;
  
  @media (min-width: 768px) {
    padding: 60px 0;
  }
`;
