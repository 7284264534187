import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import root from 'window-or-global';
import getNested from 'get-nested';
import md5 from 'md5';
import { withCookies, Cookies } from 'react-cookie';
import API from './API';
import MetaData from '../../components/common/MetaData/index';
import { formatMetaData } from '../../misc/index';
import {Redirect} from "react-router";
import { CookieBotHead } from "../../components/common/Cookiebot/Cookiebot";

@inject('translationStore')
@observer
class DrupalPage extends Component {
  constructor(props) {
    super(props);
    if(root.INITIALSTATE) {
      this.state = Object.assign({}, root.INITIALSTATE, {
        isLoading: false,
      });
    } else {
      this.state = {
        settings: props.data.settings,
        data: props.data,
        location: props.location,
        country: props.country,
        isLoading: false,
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Load new things if the path has changes...
    const { location: newLocation } = nextProps;
    const { location: oldLocation } = this.props;

    if(
      newLocation.pathname !== oldLocation.pathname ||
      newLocation.search !== oldLocation.search
    ) {
      this.load(nextProps);
    }
  }

  load({ location }) {
    const { base, cookies, translationStore } = this.props;
    const loadID = Math.random();
    let path = location.pathname;

    this.lastLoad = loadID;
    this.setState({
      isLoading: true,
    });

    if(path.indexOf('?page=') > -1) {
      path = path.split('?page=')[0];
    }

    if(cookies.get('DavidoffAgegatePassed') === md5('ZinoDavidoff-age-gate-oke-passed')) {
      const ageGateURL = 'agegate=All&agegate_product=All';

      if(path.indexOf('?') > -1) {
        path += `&${ageGateURL}`;
      } else {
        path += `?${ageGateURL}`;
      }
    }

    API.getSettings(base).then((settings) => {
      API.getPage(base, path).then((data) => {
        const formattedPath = Object.keys(data.paths).find((dataPath) => dataPath === path);
        const page = data.data[data.paths[formattedPath]];
        // Set translation variables.
        if(getNested(() => page.langcode, false)) {
          translationStore.fetchNewTranslation(page.langcode).then(() => {
            if(this.lastLoad === loadID) {
              this.setState({
                isLoading: false,
                data,
                settings,
                location,
              });
              // New page loaded
              if(root.dataLayer) {
                root.dataLayer.push({'event': 'new_page_loaded'});
              }
            }
          });
        }
      });
    });
  }

  render() {
    const mapper = this.props.mapper;
    const Layout = this.props.layout;

    // Check if the state is legit
    if(typeof this.state.data !== 'object') {
      // console.error('No page.content available.');
      return null;
    } else if(typeof this.state.data.paths !== 'object') {
      // console.error('Content type of this page isn\'t defined.');
      return null;
    } else if(typeof this.props.mapper !== 'object' && typeof this.props.mapper !== 'function') {
      // console.error('Mapper not available.');
      return null;
    } else if(Object.keys(this.state.settings).length === 0) {
      // console.error('Settings not defined.');
      return null;
    }

    const pathData = this.state.data.data[Object.keys(this.state.data.data)[0]];
    if (typeof pathData.type !== 'undefined' && pathData.type === 'redirect') {
      const formattedPathRedirect = pathData.redirect_redirect.uri;
      return <Redirect to={formattedPathRedirect} />;
    }
    const formattedPath = Object.keys(this.state.data.paths).find((dataPath) => dataPath.split('?')[0] === this.state.location.pathname);
    const Components = (typeof mapper === 'object') ? mapper[this.state.data.paths[formattedPath]] : mapper(this.state.data, formattedPath);

    if(!Components){
      console.error('Component not found for', this.state.data.paths[formattedPath], ' on page ', this.state.location.pathname, ' in ', this.state.data);
      return <Redirect to='/404'/>;
    }

    let data = this.state.data;

    if(getNested(() => data.data.data, false)) {
      data = data.data;
    }
    const metadata = formatMetaData(data, this.props.location.pathname, this.props.location.search);

    const layoutProps = Object.assign({}, this.props, {
      settings: this.state.settings,
      page: this.state.data.data[this.state.data.paths[formattedPath]],
      location: this.state.location,
      data,
      isLoading: this.state.isLoading,
      country: this.props.country,
    });

    return (
      <Layout {...layoutProps}>
        <MetaData meta={metadata} />
        <Components
          base={this.props.base}
          settings={this.state.settings}
          data={data}
          page={this.state.data.data[this.state.data.paths[formattedPath]]}
          location={this.state.location}
          country={this.props.country}
        />
      </Layout>
    );
  }
}

DrupalPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  base: PropTypes.string.isRequired,
  location: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  cookies: PropTypes.instanceOf(Cookies),
};

export default withCookies(DrupalPage);
