import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
// import Select from '../../../style-guide/Select/Select';

export default styled.div`
  clear: both;
  padding: 10px 0;
  
  @media (min-width: 768px) {
    padding: 20px 0;
  }
  
  &:nth-child(3) {
    border-top: 0;
  }
  
  ul {
    list-style: none;
    margin: 0;
    overflow: auto;
  }
`;
