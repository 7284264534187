import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.h3`
  ${(props) => props.theme.fonts.parse('serif', 'medium', '17px')}
  color: ${davidoff.colors.black};
  line-height: 1.333;
  margin-bottom: 15px;
  margin-top: 24px;
  min-height: 2.6em;
  text-decoration: none;
  
  @media (min-width: 768px) {
    font-size: 26px;
  }
`;
