import React from 'react';
import styled from 'styled-components';

export default styled.figure`
  margin: 25px 0;
  
  @media only screen and (min-width: 768px) {
    margin: 50px 0;
  }
`;
