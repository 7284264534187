import React from 'react';
import PropTypes from 'prop-types';
// import { PropTypes as MobXPropTypes} from 'mobx-react';
import { observer } from 'mobx-react';
// Stores
import ProductDetailStore from '../../../stores/ProductDetailStore';
// Components
import VisualFilter from './filterTypes/Visual/Visual';
import DropDownFilter from './filterTypes/DropDown/DropDown';
// Styled components
import FilterWrapper from './styled/FilterWrapper';
import FilterLabel from './styled/FilterLabel';
import Select from './styled/StyledSelect';

@observer
class ProductDetailFilter extends React.Component {
  constructor(props) {
    super(props);

    this.ProductDetailStore = ProductDetailStore.getInstance();

    this.onChange = this.onChange.bind(this);
  }

  shouldComponentUpdate() {
    // Always update this component...
    // otherwise the change in image is not there
    return true;
  }

  onChange(ev) {
    const { filter } = this.props;
    const key = ev.value || ev.target.value ;

    // check what filter we are... and update the selected filter based on that
    if(filter.filterCount === 0) {
      this.ProductDetailStore.updateFirstFilter(key);
    } else if(filter.filterCount === 1) {
      this.ProductDetailStore.updateSecondFilter(key);
    } else if (filter.filterCount === 2) {
      this.ProductDetailStore.updateThirdFilter(key);
    }

    this.ProductDetailStore.buildFilters();
    this.ProductDetailStore.updateVariant();
  }

  render() {
    const { filter, filterCount, data } = this.props;

    // Don't show the filter if the options are empty.
    if(filter.options.length === 1 && filterCount === 1) {
      return false;
    }
    // Render a filter based on the type... so either a visual one
    // or a select box.
    let value = this.ProductDetailStore.selectedFirsFilter;

    if(filter.filterCount === 1) {
      value = this.ProductDetailStore.selectedSecondFilter;
    }

    if(filter.filterCount === 2) {
      value = this.ProductDetailStore.selectedThirdFilter;
    }

    return (
      <FilterWrapper>
        {filter.label !== '' && filter.label &&
          <FilterLabel>{filter.label}</FilterLabel>
        }
        {filter.type === 'visual' ? (
          <VisualFilter
            data={data}
            options={filter.options}
            selected={value}
            handler={this.onChange}
          />
        ) : (
          <Select>
            <DropDownFilter
              options={filter.options}
              handler={this.onChange}
              value={value}
            />
          </Select>
        )}
      </FilterWrapper>
    )
  }
}

ProductDetailFilter.propTypes = {
  filter: PropTypes.shape({
      options:  PropTypes.array.isRequired,
      type: PropTypes.string.isRequired,
      filterField: PropTypes.string.isRequired,
      allVariants: PropTypes.bool.isRequired,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ]).isRequired,
    }).isRequired,
  filterCount: PropTypes.number.isRequired,
};

export default ProductDetailFilter;
