import React from 'react';
import styled from 'styled-components';

export default styled.div`
  max-width: ${100 / 4}%;
  min-width: ${100 / 4}%;
  padding-bottom: ${100 / 4}%;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    max-width: ${100 / 6}%;
    min-width: ${100 / 6}%;
    padding-bottom: ${100 / 6}%;
  }

  &:nth-child(5),
  &:nth-child(6) {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &:hover::after {
    opacity: 0.75;
    transition: opacity 0.35s ease 0s;
    z-index: 2;
  }

  &::after {
    background-color: #000;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease 0.25s;
    width: 100%;
    z-index: -1;
  }
`;
