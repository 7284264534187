import React from 'react';
import styled from 'styled-components';
import HyphenTitle from '../../../style-guide/HyphenTitle/HyphenTitle';

export const SpecialActivityTite = styled(HyphenTitle.h2)`
  font-size: 14px;
  margin-bottom: 25px;
  
  &:before,
  &:after {
    @media (min-width: 768px) {
      left: 50%;
      transform: translateX(-30px)
    }
  }
  
  &:after {
    @media (min-width: 768px) {
      transform: skew(-20deg, 0deg) translateX(-50%);
    }
  }
`;
