import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../common/icons/icons';
import InfoWindowWrapper from './styled/InfoWindowWrapper';
import InfoWindowHeader from './styled/InfoWindowHeader';
import InfoWindowList from './styled/InfoWindowList';
import InfoWindowListItem from './styled/InfoWindowListItem';
import InfoWindowIcon from './styled/InfoWindowIcon';
import InfoWindowLink from './styled/InfoWindowLink';
import InfoWindowCloseButton from './styled/InfoWindowCloseButton';

function GoogleMapsInfoWindow(props){
  const { store, open, closeWindow, categories, theme } = props;

  return (
    <InfoWindowWrapper style={{display: open ? 'block' : 'none'}}>
      <InfoWindowHeader theme={theme} dangerouslySetInnerHTML={{ __html: store.field_name }} />
      <InfoWindowList>
        <InfoWindowListItem>
          <InfoWindowIcon />
          { store.field_mall_name }
        </InfoWindowListItem>
        <InfoWindowListItem>
          <InfoWindowIcon>{ icons.infowindow_marker }</InfoWindowIcon>
          { store.field_address }
          { store.field_address_second_line }
          {' '}
          { store.field_city }
        </InfoWindowListItem>
        <InfoWindowListItem>
          <InfoWindowIcon>{ icons.infowindow_categories }</InfoWindowIcon>
          {categories}
        </InfoWindowListItem>
        {store.field_email &&
        <InfoWindowListItem>
          <InfoWindowIcon>{icons.infowindow_email}</InfoWindowIcon>
          <InfoWindowLink href={'mailto:' + store.field_email}>{store.field_email}</InfoWindowLink>
        </InfoWindowListItem>
        }
        {store.field_phone_number &&
        <InfoWindowListItem>
          <InfoWindowIcon>{ icons.infowindow_phone }</InfoWindowIcon>
          { store.field_phone_number }
        </InfoWindowListItem>
        }
      </InfoWindowList>
      <InfoWindowCloseButton theme={theme} type='button' onClick={closeWindow}>x</InfoWindowCloseButton>
    </InfoWindowWrapper>
  );
}

GoogleMapsInfoWindow.propTypes = {
  store: PropTypes.shape({
    field_name: PropTypes.string.isRequired,
    field_address: PropTypes.string,
    field_address_second_line: PropTypes.string,
    field_city: PropTypes.string,
    field_mall_name: PropTypes.string,
    field_phone_number: PropTypes.string,
    field_email: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  closeWindow: PropTypes.func.isRequired,
};

export default GoogleMapsInfoWindow;
