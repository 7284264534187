import React from 'react';
import styled from 'styled-components';

export default styled.div`
  background: rgba(0,0,0,0.65);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
`;
