import React from 'react';
import styled from 'styled-components';

export default styled.span`
  bottom: 0;
  height: 16px;
  left: 0;
  margin: auto;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 300ms;
  top: 0;
  width: 16px;
  z-index: 15;
  
  svg { 
    fill :#fff;
    height: 16px;
    width: 16px; 
  } 
  
  ${props => props.selected ? `
    opacity: 1;
  ` : ``};
`;
