import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';
import Accordion from '../../../../Accordion/Accordion';
import AccordionHeading from '../../../../Accordion/styled/AccordionHeading';
import AccordionItem from '../../../../Accordion/styled/AccordionItem';

export default styled(Accordion)`
  margin-bottom: 15px;
  
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
  
  ${AccordionHeading} {
    ${(props) => props.theme.fonts.parse('sansSerif', 'black', '22px')}
    margin: 0 0 15px;
  }
  
  ${AccordionItem} {
    color: ${davidoff.colors.brown};
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    
    svg {
      border: 1px solid ${davidoff.colors.brown};
      border-radius: 100%;
      fill: ${davidoff.colors.brown};
      height: 13px;
      margin-right: 5px;
      padding: 2px;
      transition: transform 300ms;
      width: 13px; 
      
      @media (min-width: 1024px) {
        padding: 0;
        border: 0;
      }
    }
  }
`;
