import React from 'react';
import styled  from 'styled-components';

export default styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
