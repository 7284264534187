import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';
import { dataMapper } from "../../index";

// Stores
import SiteStore from '../../../stores/SiteStore';

// Components
import HyphenTitle from '../../style-guide/HyphenTitle/HyphenTitle';
import ProductHighlightWrapper from './styled/ProductHighlightWrapper';
import ProductHighlightContainer from './styled/ProductHighlightContainer';
import ProductHighlightBg from './styled/ProductHighlightBg';
import TranslationStore from '../../../stores/TranslationStore';
import ProductLink from '../products/Product/styled/ProductLink';
import ProductImageWrapper from '../products/Product/styled/ProductImageWrapper';
import ProductImage from '../products/Product/styled/ProductImage';
import ProductTitle from '../products/Product/styled/ProductTitle';
import Price from '../ProductPrice';
import ProductBlock from './styled/ProductBlock';
import ProductWrapper from './styled/ProductWrapper';

@inject('translationStore')
@observer
class ProductHighlight extends React.Component {
  constructor(props) {
    super(props);
    this.SiteStore = SiteStore.getInstance();
  }

  navigateHighlight = (product) => {
    if(root.dataLayer) {
      root.dataLayer.push({
        event: 'highlight-click',
        category: 'highlight clicked',
        currentUrl: root.location.href,
        productUrl: product.url,
        productTitle: product.title,
      });
    }
  }

  render() {
    const { translationStore, data } = this.props;
    const ProductHighlights = this.SiteStore.relatedProducts;
    const title = this.props.isProductDetailPage ? translationStore.translate('Related products') : translationStore.translate('Product Highlight');

    if(
      !ProductHighlights ||
      ProductHighlights.length === 0
    ) {
      return false;
    }

    const settings = {
      height: 385,
      width: 385,
      crop: 'fill',
      gravity: 'auto',
    };

    const sources = [
      {
        settings,
        media: '(min-width: 0)',
      }
    ];

    const HighlightedProducts = ProductHighlights.map((highlightedProduct) => {
      if (!highlightedProduct || (highlightedProduct && (!highlightedProduct.mediaReference && !highlightedProduct.mediaImage))) {
        return null;
      }
      const product = dataMapper(data, highlightedProduct.key);
      const isCoffee = product && product.__hn ? product.__hn?.locale_paths?.en?.startsWith("/coffee") : false;

      return (
        <ProductBlock key={`highlight-${highlightedProduct.key}`}>
          <ProductLink to={highlightedProduct.url} onClick={() => this.navigateHighlight(highlightedProduct)}>
            <ProductImageWrapper isCoffee={isCoffee}>
              <ProductImage
                imageType="ProductImage"
                data={data}
                mediaReference={highlightedProduct.mediaReference}
                settings={settings}
                sources={sources}
                lazyLoad={false}
              />
            </ProductImageWrapper>
            <ProductTitle>{highlightedProduct.productName.groupName}<span>{highlightedProduct.productName.variantName}</span></ProductTitle>
            {highlightedProduct.buyButton && highlightedProduct.buyButton.field_price && <Price alignText="center">{highlightedProduct.buyButton.field_price}</Price>}
          </ProductLink>
        </ProductBlock>
      );
    });

    return (
      <ProductHighlightWrapper noMargin={this.props.isProductDetailPage}>

        <ProductHighlightContainer>
          <HyphenTitle.h2><span>{title}</span></HyphenTitle.h2>
        </ProductHighlightContainer>
        <ProductHighlightBg>
          <ProductHighlightContainer>
            <ProductWrapper>
              {HighlightedProducts}
            </ProductWrapper>
          </ProductHighlightContainer>
        </ProductHighlightBg>
      </ProductHighlightWrapper>
    )
  }
}

ProductHighlight.propTypes = {
  translationStore: PropTypes.instanceOf(TranslationStore),
  isProductDetailPage: PropTypes.boolean
};

export default ProductHighlight;
