import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';

export default styled.section`
  padding: 0 0 ${structureStyles.mobileValues.spacing};
  
  @media (min-width: 768px) {
    padding: 0;
  }
`;
