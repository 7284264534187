import React from 'react';
import styled from 'styled-components';

export default styled.div`
  background: #fff;
  max-width: 655px;
  padding: 25px;
  position: relative;
  width: 90%;
  z-index: 10;
  
  @media (min-width: 768px) {
    padding: 60px 55px;
  }
`;
