import React from 'react';
import styled from 'styled-components';

export default styled.div`
  max-width: 800px;
  padding: 0 20px;
  
  @media (min-width: 768px) {
    margin: 60px auto;
    padding: 0;
    width: 80%;
  }
`;
