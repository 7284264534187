import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';

const blockWidth = (384/1216) * 100;

export default styled.div`
  ${structureStyles.flexColumn}
  
  @media (max-width: 767px) {
    margin: 0 0 15px;
  }
  
  @media (min-width: 768px) {
    max-width: ${blockWidth}%;
    min-width: ${blockWidth}%;
    margin-right: ${(30/1216) * 100}%;
    
    &:nth-child(1n + 4) {
      margin-top: ${structureStyles.desktopValues.spacing};
    }
    
    &:nth-child(2n + 2) {
      margin-right: ${(30/1216) * 100}%;
    }
    
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  
  img {
    display: block;
    transition: transform 1500ms ease;
    width: 100%;
    
    @media (min-width: 768px) {
      height: auto;
    }
  }
  
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  
  a {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 80px;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    }
  }
`;
