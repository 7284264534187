import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
// Stores
import StoreLocatorStore from '../../../stores/StoreLocatorStore';
import SiteStore from '../../../stores/SiteStore';
import icons from '../../common/icons/icons';
// styled
import FormWrapper from './styled/FormWrapper';
import SearchInput from './styled/SearchInput';
import SearchButton from './styled/SearchButton';

@inject('translationStore')
@observer
class GoogleMapSearchBox extends Component {
  constructor(props) {
    super(props);
    this.input = false;

    // initialize stores
    this.StoreLocatorStore = StoreLocatorStore.getInstance();
    this.SiteStore = SiteStore.getInstance();

    this.state = {
      allowValueFromProps: true,
      value: this.StoreLocatorStore.locationText,
    };

    this.state = {
      apiLoaded: props.apiLoaded,
    };

    this.onPlacesChanged = this.onPlacesChanged.bind(this);
    this.findPlaces = this.findPlaces.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    if(this.input && typeof google !== 'undefined' && this.state.apiLoaded) {
      this.searchBox = new google.maps.places.SearchBox(this.input, {
        types: ['(regions)'],
      });
      this.searchBox.addListener('places_changed', this.onPlacesChanged);

      this.input.value = this.StoreLocatorStore.locationText;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.apiLoaded && typeof google !== 'undefined' && !this.state.apiLoaded) {
      this.searchBox = new google.maps.places.SearchBox(this.input, {
        types: ['(regions)'],
      });
      this.searchBox.addListener('places_changed', this.onPlacesChanged);

      this.input.value = this.StoreLocatorStore.locationText;
    }

    this.setState({
      apiLoaded: nextProps.apiLoaded,
    });
  }

  componentWillUnmount() {
    //  this.searchBox.removeListener('places_changed', this.onPlacesChanged);
  }

  onPlacesChanged() {
    if(this.props.onPlacesChanged) {
      const places = this.searchBox.getPlaces();

      if(places.length > 0) {
        const place = places[0];
        const address = place.address_components ? place.formatted_address : place.name;
        this.updateValue({
          target: {
            value: address,
          },
        });

        this.StoreLocatorStore.navigatorLocation = false;
        this.StoreLocatorStore.searchType = place.types;
        return this.props.onPlacesChanged(places);
      }
    }
  }

  findPlaces() {
    const service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: this.input.value }, (places) => {

      if(places.length > 0) {
        this.updateValue({
          target: {
            value: places[0].formatted_address,
          },
        });
      }

      this.StoreLocatorStore.navigatorLocation = false;
      return this.props.onPlacesChanged(places);
    });
  }

  updateValue(ev) {
    this.StoreLocatorStore.setLocationValue(ev.target.value);

    this.setState({
      value: ev.target.value,
    });
  }

  render() {
    const { translationStore } = this.props;
    return (
      <FormWrapper>
        <SearchInput
          type='text'
          name='location'
          ref={input => this.input = input}
          placeholder={translationStore.translate('Enter a city')}
          onChange={this.updateValue}
          value={this.StoreLocatorStore.locationText}
        />
        <SearchButton
          type='button'
          onClick={this.findPlaces}
          title={translationStore.translate('Search places')}
        >
          {icons.search}
        </SearchButton>
      </FormWrapper>
    );
  }
}

GoogleMapSearchBox.propTypes = {
  onPlacesChanged: PropTypes.func.isRequired,
  map: PropTypes.shape(),
};

GoogleMapSearchBox.defaultProps = {
  map: {},
};

export default GoogleMapSearchBox;
