import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';
import MenuListItem from './MenuListItem';

export default styled(NavLink)`
  ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '14px')}
  color: ${davidoff.colors.brown};
  display: block;
  height: 39px;
  line-height: 39px;
  letter-spacing: 0.5px;
  padding: 0 18px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 300ms;
  
  &:hover {
    @media only screen and (min-width: 1025px) {
      color: ${davidoff.colors.orange};
      
      svg {
        fill: ${davidoff.colors.orange};
      }
    }
  }
 
  @media only screen and (min-width: 1025px) {
    &:before {
      background-color: #d9d1ca;
      border-radius: 50%;
      content: '';
      display: block;
      font-size: 25px;
      height: 4px;
      left: -2px;
      line-height: 20px;
      position: absolute;
      top: 16px;
      width: 4px;
      
      ${MenuListItem}:first-child & {
        display: none;
      }
    }
    
    ${MenuListItem}:last-child & {
        padding-right: 0;
    }
  }
 
  svg {
    fill: ${davidoff.colors.brown};
    height: 10px;
    margin-bottom: 3px;
    position: relative;
    top: 3px;
    transition: fill 300ms;
    width: 10px;
    
    &:first-child {
        margin-right: 10px;
    }
    
    &:last-child,
    &:only-child {
        margin-left: 10px;
        margin-right: 0; // override first-child with only-child
    }
  }
  
`;
  // ${props => props['data-active'] ? `
  //   color: ${davidoff.colors.orange};
  //
  //   svg {
  //     fill: ${davidoff.colors.orange};
  //   }
  // ` : ``}
