import React from 'react';
import styled from 'styled-components';
export default styled.span`
  ${({theme}) => theme.fonts.parse('sansSerif', 'bold', '12px')}
  height: 10px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 12px;
  width: 15px;

  ${({theme}) => theme.name === 'russia' ? 'line-height: 10px;' : ''}

  ${(props) => props.largeNumber ? `
    font-size: 8px;
    left: 50%;
    line-height: 10px;
    margin: 0;
    transform: translateX(-50%);
    width: 20px;
  ` : ``}
`;
