import React from 'react';
import getNested from 'get-nested';
import { inject, observer } from 'mobx-react';
import root from 'window-or-global';
import { dataMapper } from '../../index';
import translate from '../../../misc/translate';
import viewPort from '../../../lib/viewport/index';
import { formatProductName } from '../../../misc/index';
// Stores
import SiteStore from '../../../stores/SiteStore';
// Styled
import SearchResultWrapper from './styled/SearchResultWrapper';
import SearchResultContent from './styled/SearchResultContent';
import Title from './styled/Title';
import ImageElement from './styled/Image';
import ShortText from './styled/ShortText';
import ItemLink from './styled/SearchResultLinkText';
import Link from './styled/SearchLink';

@inject('translationStore')
@observer
class SearchResult extends React.Component {
  constructor(props) {
    super(props);

    this.SiteStore = SiteStore.getInstance();
  }

  getImageOptions = () => {
    const { item } = this.props;

    if (item.field_header) {
      return {
        crop: 'fill',
        gravity: null,
      };
    } else if (!Array.isArray(item.field_media_multiple) && item.field_media && item.field_media.multiple.length > 0) {
      return {
        crop: 'pad',
        gravity: null,
      };
    } else if (Array.isArray(item.field_media_multiple) && item.field_media_multiple.length > 0) {
      return {
        crop: 'pad',
        gravity: null,
      };
    }

    return {};
  }

  getMedia = () => {
    const { data, item } = this.props;

    if (item.field_header) {
      const header = dataMapper(data, getNested(() => item.field_header.target_uuid));

      if (header.field_media) {

        return header.field_media
      }
    } else if (Array.isArray(item.field_media_multiple) &&  item.field_media_multiple.length > 0) {

      return item.field_media_multiple[0];
    }

    return '';
  }

  renderShortText = () => {
    const { item, data } = this.props;
    const getMetaValue = (name) => item.metatag.find((m) => m.attributes.name === name)?.attributes?.content;

    // format text for products
    if (item.type.target_id === 'product') {
      const productGroup = dataMapper(data, item.field_product_group.target_uuid);
      const productName = formatProductName(productGroup.field_display_text, item.field_display_text);

      return productName.variantName;
    } else if (getNested(() => getMetaValue('description'), false)) {
      let description = getMetaValue('description');

      if(description.length > 160) {
        description = `${description.substring(0, 160)}...`;
      }

      return description;
    }

    return '';
  }

  renderTitle = () => {
    const { item, data } = this.props;

    if(item.type.target_id === 'product') {
      const productGroup = dataMapper(data, item.field_product_group.target_uuid);
      const productName = formatProductName(productGroup.field_display_text, item.field_display_text);

      return productName.groupName;
    }

    return item.title;
  }

  render() {
    const { item, translationStore, data } = this.props;
    const mediaReference = this.getMedia();
    const settings = this.getImageOptions();
    const sources = [
      {
        settings: {
          ...settings,
          height: 160,
          width: 300,
        },
        media: '(max-width: 767px)',
      },
      {
        settings: {
          ...settings,
          height: 128,
          width: 176,
        },
        media: '(min-width: 768px)',
      },
    ];

    return (
      <SearchResultWrapper>
        <Link
          to={item && item.__hn ? item.__hn.url : '/'}
        >
          <ImageElement
            imageType="Search result"
            data={data}
            mediaReference={mediaReference}
            settings={settings}
            sources={sources}
          />
          <SearchResultContent>
            <Title>
              {this.renderTitle()}
            </Title>
            <ShortText>
              {this.renderShortText(item)}
            </ShortText>
            <ItemLink>
              {translationStore.translate('Discover this page')}
            </ItemLink>
          </SearchResultContent>
        </Link>
      </SearchResultWrapper>
    )
  }
}

export default SearchResult;
