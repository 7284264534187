import React from 'react';
import styled from 'styled-components';

export default styled.div`
  @media (max-width: 1023px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
    
    ${props => props.openFilter ? `
      max-height: 100vh;
    ` : ``}
  }
`;
