import React from 'react';
import PropTypes from 'prop-types';
// Styled Components
import PopupWrapper from './styled/PopupWrapper';
import PopupInnner from './styled/PopupInnner';

const GoogleMapsNoResults = ({content}) => {
  return (
    <PopupWrapper>
      <PopupInnner dangerouslySetInnerHTML={{ __html: content }} />
    </PopupWrapper>
  );
};

GoogleMapsNoResults.propTypes = {
  content: PropTypes.string.isRequired,
};

export default GoogleMapsNoResults;
