import React from "react";
import PropTypes from "prop-types";
import getNested from "get-nested";
import TweenLite from "gsap/TweenLite";
import "gsap/ScrollToPlugin";
import ReactPlayer from "react-player";
import { getBackgroundImagesByMedia } from "../../../misc/cloudinaryBackgrounds";
import Icons from "../../common/icons/icons";
import { autoPlayVideo } from "../../../misc/index";
// Styled
import Image from "./styled/Image";
import Title from "./styled/Title";
import IntroText from "./styled/IntroText";
import ScrollDownArrow from "./styled/ScrollDownArrow";
import VideoWrapper from "../BrandIntroduction/styled/BrandIntroductionVideoWrapper";
import { dataMapper } from "../..";
import { withCookies, Cookies } from "react-cookie";

class CenteredHeaderText extends React.Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    this.headerElement = React.createRef();
    this.scrollToNextItem = this.scrollToNextItem.bind(this);
  }

  // Interactions
  scrollToNextItem(ev) {
    ev.preventDefault();
    const bottomMargin = window.matchMedia("(min-width: 1024px)").matches
      ? 50
      : 25;
    const headerHeight = window.matchMedia("(min-width: 1024px)").matches
      ? 57
      : 50;
    const offset =
      this.headerElement.current.nextSibling.offsetTop -
      bottomMargin -
      headerHeight; // top offset - header - bottom margin

    window.scrollTo({
      top: offset,
      left: 0,
      behavior: "smooth"
    });
  }

  getBackgroundImages = () => {
    const { data, paragraph } = this.props;

    const imageSettings = {
      breakpoints: {
        mobile: {
          width: 640,
          aspect_ratio: "375:650"
        },
        desktop: {
          width: 1600,
          aspect_ratio: "1920:815"
        }
      }
    };

    const mediaReference = paragraph.field_media;
    const media = dataMapper(
      data,
      getNested(() => mediaReference.target_uuid)
    );
    const images = getBackgroundImagesByMedia(media, imageSettings);

    return images;
  };

  render() {
    const { paragraph, cookies } = this.props;

    if (!paragraph) {
      return null;
    }

    const video =
      paragraph.field_video_link || paragraph.field_slow_motion_video;

    var host = 'https://www.youtube.com';

    if (cookies.get("DavidoffCookiePolicy") && cookies.get("DavidoffCookiePolicy") !== 'OPTIMAL') {
      var host = 'https://www.youtube-nocookie.com';
    }

    return (
      <Image
        images={this.getBackgroundImages()}
        ref={this.headerElement}
        innerRef={ref => {
          this.headerElement = ref;
        }}
      >
        <Title>{paragraph.field_title}</Title>

        {paragraph.field_header_subtitle && (
          <IntroText>{paragraph.field_header_subtitle}</IntroText>
        )}

        {video && (
          <VideoWrapper>
            <div className="video-wrapper">
              <ReactPlayer
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                      mute: 1,
                      vq: "hd1440",
                      controls: 0
                    },
                    embedOptions: {
                      host: host
                    }
                  },
                  vimeo: {
                    playerOptions: {
                      api: 1,
                      background: 1,
                      loop: 1,
                      autoplay: 1,
                      autopause: 1
                    }
                  }
                }}
                className="video"
                url={video.uri}
                playing={autoPlayVideo()}
                volume={0}
                width="100%"
                height="100%"
                loop
                muted
              />
            </div>
          </VideoWrapper>
        )}
        <ScrollDownArrow onClick={this.scrollToNextItem}>
          {Icons.drop_down_arrow}
        </ScrollDownArrow>
      </Image>
    );
  }
}

CenteredHeaderText.Proptypes = {
  data: PropTypes.shape(),
  paragraph: PropTypes.shape({
    field_image: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    field_title: PropTypes.string.isRequired,
    field_video_link: PropTypes.shape({
      uri: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withCookies(CenteredHeaderText);
