import { observable, action } from 'mobx';
import getNested from 'get-nested';
import root from 'window-or-global';
import { dataMapper } from '../components/index';

class ProductDetailStore {
  static instance;

  constructor() {
    this.findProperty = this.findProperty.bind(this);
    this.sortFilters = this.sortFilters.bind(this);
  }

  @observable data = {};
  @observable currentVariant = '';
  @observable variants = [];
  @observable selectedFirsFilter = '';
  @observable selectedSecondFilter = false;
  @observable selectedThirdFilter = false;
  @observable allVariants = false;
  @observable filters = {};
  @observable rawFilters = [];

  static getInstance() {
    if(!ProductDetailStore.instance) {
      ProductDetailStore.instance = new ProductDetailStore();
    }

    return ProductDetailStore.instance;
  }

  @action
  updateFirstFilter(key) {
    this.selectedFirsFilter = key;
  }

  @action
  updateSecondFilter(key) {
    this.selectedSecondFilter = key;
  }

  @action
  updateThirdFilter(key) {
    this.selectedThirdFilter = key;
  }

  @action
  updateVariant() {
    let variantObject = {};

    if(this.allVariants) {
      variantObject = this.variants.find(variant => variant.uuid === this.selectedFirsFilter);
    } else {
      variantObject = this.variants.find((variant) => {
        const matches = {};

        const allProperties = this.findProperty(variant, this.filters[0].filterField, 'filter')
          .map(prop => dataMapper(this.data, prop.target_uuid));
        const filterOptionId = allProperties.map(property => property.uuid).join(',');
        matches.firstFilter = filterOptionId === this.selectedFirsFilter;

        if(this.selectedSecondFilter && this.rawFilters.length > 1) {
          matches.secondFilter = variant.field_properties.some(property =>
            property.target_uuid === this.selectedSecondFilter);
        }

        if(this.selectedThirdFilter && this.rawFilters.length > 2) {
          matches.thirdFilter = variant.field_properties.some(property =>
            property.target_uuid === this.selectedThirdFilter);
        }

        return !Object.keys(matches).some(match => matches[match] === false);
      });
    }

    // If we didn't find a result...
    // check for a empty variantObject..
    if(typeof variantObject === 'undefined' || Object.keys(variantObject).length === 0) {
      // If so we select the first item from the first filter
      // And set the first filter to that value programmatically
      const filters = this.filters.peek();
      variantObject = filters[0].options[0].variant;
      this.updateFirstFilter(filters[0].options[0].id);
    }

    if (variantObject && variantObject.__hn) {
      // eslint-disable-next-line no-underscore-dangle
      if(getNested(() => variantObject.__hn, false)) {
        // eslint-disable-next-line no-underscore-dangle
        const newUrl = variantObject.__hn.url;
        const getMetaValue = (name) => variantObject.metatag.find((m) => m.attributes.name === name)?.attributes?.content;
        const title = getMetaValue('title') || 'DAVIDOFF';
        this.currentVariant = variantObject.uuid;

        // update the current url.
        window.history.pushState('', title, newUrl);
        // triger the datalayer things
        if(root.dataLayer) {
          root.dataLayer.push({ event: 'new_page_loaded' });
        }
      }
    }
  }

  /*
   * buildFilters
   */
  @action
  buildFilters() {
    const multipleFilters = this.rawFilters.length > 1;

    // Build a array with filters. Based on the raw filters
    const filters = this.rawFilters.map((filter) => {
      let options = this.variants;

      filter.options = [];

      if(!filter.allVariants) {
        let variants = this.variants;
        // filter the variants based on the second filter.
        // If there are multiple filters.
        if(
          multipleFilters &&
          filter.filterCount === 0 &&
          this.selectedSecondFilter
        ) {
          // check if the variant applies to the property that is
          // selected in the second filter.
          variants = this.variants.filter(variant =>
            variant.field_properties.some(property =>
              property.target_uuid === this.selectedSecondFilter));
        }

        options = variants.filter((variant) => {
          if(!getNested(() => variant.field_properties, false)) {
            return false;
          }

          return variant.field_properties.some((property) => {
            const propertyParent = dataMapper(this.data, property.target_uuid);
            const parentProperty = propertyParent.parent.find(parent => parent.target_type === 'taxonomy_term');

            if(!getNested(() => parentProperty.target_uuid, false)) {
              return false;
            }

            return parentProperty.target_uuid === filter.filterField;
          });
        });

        // console.log('The options: ', options);

        options.forEach((option) => {
          const propertyField = this.findProperty(option, filter.filterField);
          const propertyData = dataMapper(this.data, propertyField.target_uuid);
          // Find all the properties of the selected type
          const propertySearchType = filter.filterCount === 2 ? 'find' : 'filter';
          const selectedProperties = this.findProperty(option, filter.filterField, propertySearchType);
          let allProperties;

          if(Array.isArray(selectedProperties)) {
            allProperties = selectedProperties.map(prop => dataMapper(this.data, prop.target_uuid));
          } else {
            allProperties = [dataMapper(this.data, selectedProperties.target_uuid)];
          }

          const filterOptionId = allProperties.map(property => property.uuid).join(',');
          const propertyName = allProperties.map(prop => prop.name).join(' / ');

          if(!filter.options.some(item => item.id === filterOptionId)) {
            filter.options.push({
              key: `${option.uuid}-${propertyData.uuid}`,
              id: filterOptionId,
              propertyName, // Map to the property name
              variant: option,
              property: propertyData,
            });
          }
        });
      } else {
        options.forEach((option) => {
          filter.options.push({
            key: option.uuid,
            id: option.uuid,
            propertyName: option.field_display_text, // Map to the property name
            variant: option,
            property: option,
          });
        });
      }
      return filter;
    });

    this.filters = this.sortFilters(filters);
  }

  findProperty(object, propertyKey, type = 'find') {
    return object.field_properties[type]((property) => {
      const propertyParent = dataMapper(this.data, property.target_uuid);
      if(getNested(() => propertyParent.parent, false)) {
        const parentFound = propertyParent.parent.find(parent =>
          parent.target_type === 'taxonomy_term');

        if(parentFound) {
          return parentFound.target_uuid === propertyKey;
        }
      }

      return false;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sortFilters(filters) {
    // Sort the filters by weight from Drupal
    // To sort the filters we need to get all the keys
    // get all the objects
    // Sort the array of keys based on the weight of their opbject
    // Reassign the filters based on the keys they had.
    // Order the properties based on their weights in drupal


    // do the same thing for sub filters
    filters.forEach((filter) => {
      // Sort options by weight as well
      if(getNested(() => filter.options)) {
        filter.order = filter.options.sort((a, b) => {
          if(typeof a === 'undefined') {
            return 1;
          }

          if(
            getNested(() => a.property.weight) !== false &&
            getNested(() => b.property.weight) !== false
          ) {
            return a.property.weight - b.property.weight;
          }

          return 0;
        });
      }
    });

    return filters;
  }
}

export default ProductDetailStore;
