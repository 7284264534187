import React from 'react';
import PropTypes from 'prop-types';
import BrandIntroduction from '../BrandIntroduction/BrandIntroduction';
import { SpecialActivitySmall } from '../../common/SpecialActivitySmall/SpecialActivitySmall';

const SpecialActivity = ({ data, paragraph }) => {
  if(!paragraph.field_enabled) {
    return false;
  }
  // Make a choice between the Brand Introduction version and the smaller one.

  switch(paragraph.field_special_activity_blocktype) {
    case 'full_width':
      const BIParagraph = {
        field_text: paragraph.field_body,
      };

      return <BrandIntroduction data={data} paragraph={Object.assign({}, paragraph, BIParagraph)} clickable />;
    default:
      return <SpecialActivitySmall
        data={data}
        mediaReference={paragraph.field_media}
        description={paragraph.field_body}
        link={paragraph.field_link}
        title={paragraph.field_label}
      />;
  }
};

SpecialActivity.propTypes = {
  data: PropTypes.shape(),
  paragraph: PropTypes.shape({
    field_body: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
    field_media: PropTypes.shape().isRequired,
    field_label: PropTypes.string.isRequired,
    field_link: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    field_special_activity_blocktype: PropTypes.string.isRequired,
    field_enabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export { SpecialActivity }
