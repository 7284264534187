import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import linkStyles from '../../../../shared-styles/link-styles.js';

export default styled(Link)`
  ${props => linkStyles.link(props)}

  display: block;
  padding: 0 ${structureStyles.mobileValues.spacing};
  cursor: pointer;
  margin-top: 0;
    
  @media (min-width: 768px) {
    padding: 0;
    
    &:hover {
      color: ${davidoff.colors.orange};
    
      &::after {
        margin-left: 15px;
      }
    }
  }
`;
