import React from 'react';
import styled from 'styled-components';
import NoResult from '../../../common/products/ProductView/styled/NoResults';

export default styled(NoResult)`
  bottom: auto;
  height: auto;
  left: auto;
  margin: 60px auto;
  max-width: 800px;
  position: static;
  right: auto;
  top: auto;
  width: 80%;
`;
