import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.div`
  cursor: pointer;
  height: 16px;
  padding: 25px;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  z-index: 20;
  
  svg {
    bottom: 0;
    height: 16px;
    fill: ${davidoff.colors.brown};
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: fill 300ms;
    width: 16px;
  }
  
  &:hover svg {
    @media (min-width: 1024px) {
      fill: ${davidoff.colors.orange};
    }
  }
`
