import React from 'react';
import styled from 'styled-components';

export default styled.div`
  clear: both;
  
  h3 {
    ${({theme}) => theme.name === 'russia' ? 'font-weight: 600;' : '' }
  }
  
  @media (min-width: 1024px) {
    h3 {
      font-size: 22px;
      line-height: 1.3;
      margin-bottom: 1em;
    }
  }
`;
