import React from "react";
import * as styles from "./styled";
import getNested from "get-nested";
import { dataMapper } from "../../index";
import ResponsiveImage from "../../style-guide/Image/ResponsiveImage";
import FullWidthVideo from "../../paragraph-types/FullWidthVideo/FullWidthVideo";
import parse from "html-react-parser";
import { JSON } from "window-or-global";

export function SplitTextMedia(props) {
  const { paragraph, data } = props;

  const mediaParagraph = dataMapper(
    data,
    getNested(() => paragraph.field_campaign_m_t_media.target_uuid)
  );

  const image = dataMapper(
    data,
    getNested(() => mediaParagraph.image.target_uuid)
  );

  const getRatio = (value) => {
    switch (value) {
      case 0:
        return "16:9";
      case 2:
        return "375:650";
      case 3:
        return "592:380";
      case 5:
        return "640:272";
      case 6:
        return "488:600";
      case 7:
        return "1:1";
      case 1:
      case 4:
      default:
        return "1920:815"; //is equal to 21:9
    }
  };

  const ratio = getRatio(paragraph.image_ratio_list);
  // const ratio = "592:380";

  let options = {};
  // if (
  //   typeof image !== "undefined" &&
  //   image.field_media_image.computed.crops.length > 0
  // ) {
  //   const lastCrop = image.field_media_image.computed.crops.length - 1;
  //   options = {
  //     effect: "trim",
  //     crop: "crop",
  //     gravity: null,
  //     aspectRatio: ratio,
  //     ...image.field_media_image.computed.crops[lastCrop].crop,
  //   };
  // } else {
  options = {
    effect: "trim",
    crop: "fill",
    aspectRatio: ratio,
    width: 640,
    quality: 100,
  };
  // }
  let paddingBottom = "";

  if (paragraph.media_and_text_image_ratio == 2) {
    paddingBottom = `${150}%`;
  } else {
    paddingBottom =
      ratio === "21:9" ? `${(9 / 21) * 100}%` : `${(9 / 16) * 100}%`;
  }

  return (
    <styles.SplitTextMedia
      reverseLayout={paragraph.field_campaign_m_t_mirrored}
      paddingBottom={
        mediaParagraph.type.target_id == "video" ? paddingBottom : 0
      }
    >
      <div className="split-text-image__image">
        {mediaParagraph.type.target_id == "video" ? (
          <FullWidthVideo paragraph={mediaParagraph} />
        ) : (
          <ResponsiveImage
            src={image.field_media_image.url}
            alt={image.field_media_image.alt}
            options={options}
          />
        )}
      </div>
      <div className="split-text-image__text-wrapper">
        <h3 className="split-text-image__title">
          {paragraph.field_campaign_m_t_subtitle}
        </h3>
        {parse(paragraph.field_campaign_m_t_text.value)}
      </div>
    </styles.SplitTextMedia>
  );
}
