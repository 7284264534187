import React from 'react';
import LdJson from 'react-ld-json';

export default function MainLocationSchema() {
  return (
    <LdJson
      data={{
        '@context': 'https://schema.org/',
        '@type': 'PostalAddress',
        '@id': '#main-location',
        addressCountry: 'Switzerland',
        addressLocality: 'Basel',
        addressRegion: '',
        availableLanguage: 'EN',
        contactType: 'Headquarters',
        name: 'Headquarters',
        postalCode: '4051',
        streetAddress: 'Sternengasse 18',
        telephone: '+41 61 201 10 80',
        email: 'hello@zinodavidoff.com',
        url: 'https://www.zinodavidoff.com/',
      }}
    />
  );
}
