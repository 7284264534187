import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icons from '../../icons/icons';
import davidoff from '../../../../themes/davidoff';
import { capitalizeFirstLetter } from '../../../../misc/index';

const SocialItem = styled(Link)`
  display: block;
  height: 100%;
  text-decoration: none;
  
  svg {
    bottom: 0;
    fill: ${davidoff.colors.brown};
    left: 0;
    max-height: 16px;
    max-width: 16px;
    margin: auto;
    position: absolute;
    right: 0; 
    top: 0;
    transition: fill 300ms;
  }
  
  &:hover svg {
    @media only screen and (min-width: 1024px) {
      fill: ${davidoff.colors.orange};
    }
  }
`;

const SocialLink = ({ channelName, url}) => {
  return (
      <SocialItem
        to={url}
        title={capitalizeFirstLetter(channelName)}
        target='_blank'
      >
        { Icons[channelName] }
      </SocialItem>
  );
};

SocialLink.propTypes = {
  channelName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SocialLink;
