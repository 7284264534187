import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import Button from '../../../style-guide/Button/Button';

export default styled(Button)`
${(props) => props.theme.fonts.parse('sansSerif', 'black', '13px')}
background-color: #fff;
border: 2px solid ${davidoff.colors.orange};
border-radius: 3px;
color: ${davidoff.colors.orange};
height: 40px; 
line-height: 38px;
letter-spacing: 1px;
padding: 0 15px;
text-align: center;
text-transform: uppercase;
transition: background-color 300ms;
width: 100%;

@media only screen and (min-width: 768px) {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  margin-right: 0;
  min-width: 0;
  width: auto;
}

&:hover {
  background-color: ${davidoff.colors.orange};
  color: #fff;
}
`;
