import getNested from 'get-nested';
import { getCloudinaryID } from '../components/common/image/helpers/getCloudinaryID';
import { cl } from '../components/common/image/CloudinaryImage/container';
import applyImageCrop from './cropping/applyImageCrop';

/*
 * getBackgroundImages:
 * Returns a object with breakpoint names as key and image urls as value
 * It accepts a object that looks like the one below
 * const settings = {
 *   url: 'Original url',
 *   breakpoints: {
 *    mobile: {
 *      height: 210,
 *      width: 210,
 *    },
 *   },
 * };
 */

export const getBackgroundImagesByURL = (image, settings) => {
  const images = {};
  const imageID = getCloudinaryID(image.url, 'zinodavidoff');

  Object.keys(settings.breakpoints).forEach((breakpoint) => {
    const setting = settings.breakpoints[breakpoint];
    const crop = applyImageCrop(setting, getNested(() => image.computed.crops) || {});

    images[breakpoint] = cl.url(imageID, {
      quality: 'auto',
      gravity: 'auto',
      crop: 'fill',
      ...crop,
    });
  });

  return images;
};

export const getBackgroundImagesByMedia = (media, settings) => {
  const images = {};

  if(typeof media === 'undefined') {
    return images;
  }
  if(media.field_media_image) {
    return getBackgroundImagesByURL(media.field_media_image, settings);
  }
  if(media.image) {
    return getBackgroundImagesByURL(media.image, settings);
  }

  return images;
};
