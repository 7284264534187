import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.li`
  display: inline-block;
  
  input {
    position: absolute;
    left: -9999em;
  }
  
  label {
    ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '14px')}
    color: ${davidoff.colors.brown};
    margin-bottom: 5px;
    padding: 0 13px;
    position: relative;
    text-transform: uppercase;
    transition: color 300ms;
    width: auto;
    
    &:before {
      background-color: #d9d1ca;
      border-radius: 50%;
      content: '';
      display: block;
      font-size: 25px;
      height: 4px;
      left: -2px;
      line-height: 20px;
      position: absolute;    
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
    }
    
    &:hover {
      @media (min-width: 1024px) {
        color: ${davidoff.colors.orange};
      }
    }
  }
  
  &:first-child label {
    padding-left: 0;
    
    &:before {
      display: none;
    }
    
    &:after {
      left: 0;
      transform: none;
      width: calc(100% - 13px);
    }
  }
  
  &:last-child label {
    padding-right: 0;
    
    &:after {
      left: auto;
      right: 0;
      transform: none;
      width: calc(100% - 13px);
    }
  }
  
  ${props => props.active ? `
    label {
      color: ${davidoff.colors.orange};
      
      &:after {
        background-color: ${davidoff.colors.orange};
        bottom: -16px;
        content: '';
        display: none;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: calc(100% - 26px);
        
        @media (min-width: 768px) {
          display: block;
        }
      }
    }
  ` : ``}
`;
