import React from 'react';
import PropTypes from 'prop-types';
import icons from '../icons/icons';
// Stores
// components
import MarkerItem from './styled/MarkerItem';

const GoogleMapsMarker = () => (<MarkerItem>
  {icons.current_location}
</MarkerItem>);

GoogleMapsMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default GoogleMapsMarker;
