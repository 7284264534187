import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import linkStyles from '../../../../shared-styles/link-styles.js';

export default styled(Link)`
  ${props => linkStyles.link(props)}

  cursor: pointer;
  display: block;
  margin-top: 20px;

  @media (min-width: 768px) {
    &:hover {
      &::after {
        margin-left: 15px;
      }
    }
  }
`;
