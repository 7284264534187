import styled from 'styled-components';

export const CampaignText = styled.div`
  padding: 20px 6%;

  @media (min-width: 1024px) {
    padding: 40px 0 80px;
  }

  h3 {
    font-weight: bold;
    text-transform: uppercase;
    padding: 20px 6% 0;

    margin-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
    width: 100%;

    @media (min-width: 768px) {
      padding: 0;
      margin: 70px auto 20px auto;
      max-width: 800px;

      font-size: 24px;
      line-height: 29px;
    }
  }

  p {
    padding: 0 6% 20px;
    width: 100%;
    font-weight: 600;
    line-height: 26px;
    ${props => props.theme.fonts.parse('sansSerif', 'regular', '16px')};

    @media (min-width: 768px) {
      margin: 0 auto 120px;
      padding: 0;
      max-width: 800px;
      font-size: 18px;
      ${props => props.theme.fonts.parse('sansSerif', 'regular', '18px')};
      line-height: 32px;
      width: 100%;
    }

    &:last-child{
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
`;
