import styled from "styled-components";
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../style-guide/Button/Button';
import davidoff from '../../../../../../themes/davidoff';

export default styled(Button.withComponent(Link))`
  ${(props) => props.theme.fonts.parse('sansSerif', 'medium')}
  background-color: transparent;
  border: 1px solid ${davidoff.colors.lightbrown};
  color: ${davidoff.colors.brown};
  height: 50px;
  line-height: 50px;
  margin: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: border-color 300ms linear, color 300ms linear, background-color 300ms linear;
  
  &:hover {
    @media only screen and (min-width: 768px) {
      background-color: ${davidoff.colors.lightGrey};
      border-color: ${davidoff.colors.lightbrown};
      color: ${davidoff.colors.brown};
      text-decoration: underline;
    }
  }
  
  ${props => props['data-active'] ? `
   background-color: ${davidoff.colors.lightGrey};
   border-color: ${davidoff.colors.lightbrown};
   color: ${davidoff.colors.brown};
   text-decoration: underline;`: ''}
  
  @media only screen and (min-width: 768px) {
    height: 50px;
    line-height: 50px;
    width: 176px;
  }
`;
