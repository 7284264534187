import React from 'react';
import styled from 'styled-components';

export default styled.section`
  position: relative;
  z-index: 0;
  //overflow: hidden;
  
  @media (max-width: 767px) {
    background-image: none;
  }
  
  //@media (min-width: 768px) {
  //  &.has-video {
  //    min-height: 70vmin;
  //  }
  //}
  
  img,
  picture{
    display: block;
    max-width: 100%;
    width: 100%;
  }
  
  //&.has-video img {
  //  @media (min-width: 768px) {
  //    display: none;
  //  }
  //}
`;
