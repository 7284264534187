import React from 'react';
import styled from 'styled-components';

export default styled.div`
  max-height: 0;
  padding-right: 15px;
  transition: max-height 0.5s linear;
  overflow: hidden;

  // an opened accordion
  ${props => props.open ? `
    max-height: 1000px;
  ` : 
  ``}
`;
