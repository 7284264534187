import React from 'react';
import styled from 'styled-components';
import HyphenTitle from '../../../style-guide/HyphenTitle/HyphenTitle';
import structureStyles from '../../../../shared-styles/structure-styles';

export default  styled(HyphenTitle.h2)`
  color: #fff;
  display: block;
  margin: 0 auto 25px;
  max-width: 800px;
  text-align: left;
  
  @media (max-width: 1023px) {
    padding: 10px ${structureStyles.mobileValues.spacing} 0;
  }
  
  @media (min-width: 768px) {
    font-size: 30px;
    margin-bottom: 45px;
  }
  
  &:before,
  &:after {
    @media (max-width: 1023px) {
      left: ${structureStyles.mobileValues.spacing};
    }
  }
`;
