import React from 'react';
import styled from 'styled-components';
import StandardMenuItem from '../../StandardMenuItem/StandardMenuItem';
import davidoff from '../../../../../../themes/davidoff';

export default styled(StandardMenuItem)`
  svg:only-child {
    top: 6px;
    height: 16px;
    width: 16px;
    margin-left: 0;
  }
  
  .flyout-inner {
    padding: 29px 0 22px;
  }
`;
