import React from 'react';
import styled from 'styled-components';

export default styled.div`
  color: #000;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 15px;
`;
