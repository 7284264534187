import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.label`
  display: block;
  float: none;
  margin: 0;
  width: 100%;
  
  ${(props) => props.selected ? `
    border-bottom: 1px solid ${davidoff.colors.orange};
  ` : ''}
`;
