import React from 'react';
import styled, { css, keyframes } from 'styled-components';

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

const subscribed = keyframes`
  0% {
    width: 100%;
  }

  100% {
    width: 180px;
  }
`;

export default styled.div`
  border-radius: 3px;
  height: 40px;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  white-space: nowrap;
  
  @media (min-width: 768px) {
    height: 60px;
    width: 600px;
  }
  
  ${props => props.bottom ? css`
    width: 94%;
  ` : ``}
  
  ${props => props.subscribed ? css`
    animation: ${subscribed} ${fpsToMs(8)}ms ease ${fpsToMs(17)}ms forwards;
  ` : ``}
`;
