import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';

export default styled.button`
  ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '14px')}
  background: none;
  border: 0; // reset button
  border-bottom: 1px solid transparent;
  line-height: 24px;
  margin: 0 0 20px;
  outline: none;
  position: relative;
  text-decoration: none;
  text-transform: none;
  transition: color 300ms;
  
  @media (min-width: 1024px) {
    margin-left: 16px;
  }

  &:after {
    background-color: transparent;
    bottom: 5px;
    content: '';
    height: 1px;
    left: 0;
    margin: auto;
    right: 0;
    position: absolute;
    right: 0;
    transition: background-color 300ms;
    width: 90%;
  }
  
  &:hover {
    @media (min-width: 1024px) {
      color: ${davidoff.colors.orange};
      
      &:after {
        background-color: ${davidoff.colors.orange};
      }
    }
  }
`;
