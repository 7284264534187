import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../shared-styles/structure-styles.js';

export default styled.div`
  ${structureStyles.containerPadding}
  
  ${props =>
  props.multicolMobile
    ? ''
    : `
    @media (min-width: 768px) {
  `};
  ${structureStyles.container}
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${props =>
  props.multicolMobile
    ? ''
    : `
    }
  `};
`;
