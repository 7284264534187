import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { inject, observer } from 'mobx-react';
import { dataMapper } from '../../index';

//styled
import Wrapper from './styled/CollectionWrapper';
import ImageWrapper from './styled/ImageWrapper';
import Image from './styled/Image';
import Header from './styled/Header';
import TagLine from './styled/TagLine';
import Link from './styled/Link';
import TranslationStore from '../../../stores/TranslationStore';

const ConnectionToCollection = ({paragraph, data, translationStore}) => {
  const collection = dataMapper(data, getNested(() => paragraph.field_collection.target_uuid, false));
  const collectionElement = dataMapper(data, getNested(() => collection.field_collection.target_uuid, false));
  const collectionHeader = dataMapper(data, getNested(() => collectionElement.field_header.target_uuid, false))

  const mediaReference = paragraph.field_media || collectionHeader.field_media;

  if(!collection && ! collectionElement || !mediaReference) {
    return null;
  }

  const settings = {
    crop: 'fill',
    width: Math.round((800 * 1.1)),
    height: Math.round((500 * 1.1)),
    placeholder: '',
  };

  const sources = [
    {
      settings,
      media: '(min-width: 0)',
    }
  ];

  return (
    <Wrapper>
      <Header>{translationStore.translate('The')} {collection.title}</Header>
      <Link to={paragraph.field_collection.url}>
        <ImageWrapper>
          <Image
            imageType="ConnectionToCollection"
            data={data}
            mediaReference={mediaReference}
            settings={settings}
            sources={sources}
          />
        </ImageWrapper>
        <TagLine>{translationStore.translate('Discover the')} <span>{collection.title} </span></TagLine>
      </Link>
    </Wrapper>
  )
};

ConnectionToCollection.Proptypes = {
  paragraph: PropTypes.shape({
    field_media: PropTypes.shape(),
    field_collection: PropTypes.shape({
      url: PropTypes.string.isRequired,
      target_uuid: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

export default inject('translationStore')(observer(ConnectionToCollection));
