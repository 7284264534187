import React from 'react';
import PropTypes from 'prop-types';
import MediaImageContainer from '../../../common/MediaImage/index.c';

// styled components

const InstructionImage = ({ mediaReference, data }) => {
  const settings = {
    width: 280,
    placeholder: '',
  };

  const sources = [
    {
      settings,
      media: '(min-width: 0)',
    }
  ];

  return (
    <MediaImageContainer
      imageType="Instruction image"
      data={data}
      mediaReference={mediaReference}
      sources={sources}
      settings={settings}
    />
  );
};

InstructionImage.Proptypes = {
    mediaReference: PropTypes.shape(),
};

export default InstructionImage;


