import React from 'react';
import styled from 'styled-components';

export default styled.div`
  height: 100vh;
  position: relative;

  @media (min-width: 1024px) {
    height: calc(100vh - 50px);
    min-height: 540px;
    transition: 0.75s ease;
    width: 100%;
    
    ${(props) => props.showSideBar ? `
      transition-delay: 1s;
      width: 75%;
    ` : ``}
  }
  @media (min-width: 1025px) {
    height: calc(100vh - 170px);
  }
`;
