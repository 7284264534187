import React from 'react';
import getNested from 'get-nested';
// Content types
import Page from './content-types/Page/index';
import Article from './content-types/Article/index';
import HomePage from './content-types/HomePage/index';
import CategoryPage from './content-types/CategoryPage/index';
import IndustryPage from './content-types/Industry/index';
import Product from './content-types/Product/Product';
// Paragraphs
import Webform from './paragraph-types/WebForm/WebForm';
import Accordion from './paragraph-types/Accordion/Accordion';
import Image from './paragraph-types/Image/Image';
import TextImage from './paragraph-types/TextImage/TextImage';
import Body from './paragraph-types/Body/Body';
import Quote from './paragraph-types/Quote/Quote';
import ArticleOverview from './paragraph-types/ArticleOverview/ArticleOverview';
import CenteredHeaderText from './paragraph-types/CenteredHeaderText/CenteredHeaderText';
import BrandIntroduction from './paragraph-types/BrandIntroduction/BrandIntroduction';
import DiscoverProducts from './paragraph-types/DiscoverProducts/DiscoverProducts';
import Search from './paragraph-types/Search/Search';
import Story from './paragraph-types/Story/Story';
import Category from './paragraph-types/Category/Category';
import CollectionBlock from './paragraph-types/Collection/Collection';
import Collections from './paragraph-types/CollectionsBlock/CollectionsBlock';
import ConnectionToCollection from './paragraph-types/ConnectionToCollection/ConnectionToCollection';
import RelatedProduct from './paragraph-types/RelatedProduct/RelatedProduct';
import FullWidthVideo from './paragraph-types/FullWidthVideo/FullWidthVideo';
import StoreLocator from './paragraph-types/StoreLocator/container/StoreLocator';
import { TasteIndicator } from './paragraph-types/TasteIndicator/TasteIndicator';
import { Indicator } from './paragraph-types/Indicator/Indicator';
import IndustryBlock from './paragraph-types/IndustryBlock/IndustryBlock';
import { SpecialActivity } from './paragraph-types/SpecialActivity/SpecialActivity';
import InstructionBlock from './paragraph-types/InstructionBlock/InstructionBlock';
import CTAButtonHeader from './paragraph-types/CTAButtonHeader/Component'
import CampaignPage from './content-types/CampaignPage';
import { CampaignHeaderColor } from './paragraph-types/CampaignHeaderColor/Component'
import { CampaignText } from './paragraph-types/CampaignText/Component';
import { CampaignCallToAction } from './paragraph-types/CampaignCallToAction/Component';
import { SplitText } from './paragraph-types/SplitText/Component';
import { SplitTextMedia } from './paragraph-types/SplitTextMedia/Component';
import { CampaignHeaderImage } from './paragraph-types/CampaignHeaderImage/Component';
import { CampaignProductTilesSlider } from './paragraph-types/CampaignProductTilesSlider/Component';
import { CampaignBanner } from './paragraph-types/CampaignBanner/Component';
import { CampaignProductHighlight } from './paragraph-types/CampaignProductHighlight/Component';

const contentTypes = {
  node_type: {
    page: Page,
    article: Article,
    homepage: HomePage,
    collection: CategoryPage,
    // collection: Collection,
    product: Product,
    category: CategoryPage,
    industry_page: IndustryPage,
    campaign_page: CampaignPage,
  },
};

const paragraphTypes = {
  paragraphs_type: {
    accordion: Accordion,
    accordion_taste_indicator: TasteIndicator,
    article_overview_: ArticleOverview,
    body: Body,
    brand_introduction: BrandIntroduction,
    campaign_cta: CampaignCallToAction,
    campaign_double_paragraphs: SplitText,
    campaign_full_width_media: CampaignProductHighlight,
    campaign_container_media: CampaignBanner,
    campaign_full_width_text: CampaignText,
    campaign_header_color: CampaignHeaderColor,
    campaign_header_media: CampaignHeaderImage,
    campaign_media_and_text: SplitTextMedia,
    campaign_product_slider: CampaignProductTilesSlider,
    category: Category,
    centered_text_header: CenteredHeaderText,
    collection: CollectionBlock,
    collections: Collections,
    connection_to_collection: ConnectionToCollection,
    discover_products: DiscoverProducts,
    image: Image,
    industry_block: IndustryBlock,
    product_header: CenteredHeaderText,
    quote: Quote,
    related_product: RelatedProduct,
    search: Search,
    story: Story,
    store_locator: StoreLocator,
    special_activity: SpecialActivity,
    taste_indicator: Indicator,
    text_image: TextImage,
    video: FullWidthVideo,
    webform: Webform,
    instructions: InstructionBlock,
    cta_button_header: CTAButtonHeader,
  },
};

const components = Object.assign({}, contentTypes, paragraphTypes);

function mapper(data, path = false, key = '', idx = 0, extraProps = {}) {
  if(path) {
    const pathObj = getNested(() => data.data[data.paths[path]].type, false);
    if(pathObj){
      return components[pathObj.target_type][pathObj.target_id];
    }

    return false;
  }

  if(getNested(() => components[data.data[key].type.target_type][data.data[key].type.target_id], false)) {
    const Component = components[data.data[key].type.target_type][data.data[key].type.target_id];

    return <Component key={key} paragraph={data.data[key]} data={data} idx={idx} {...extraProps} />;
  }

  return false;
}

export const dataMapper = (data, ID) => {
  if (!data) {
    return null;
  }

  return data.data[ID];
};

export default mapper;
