import React from 'react';
import PropTypes from 'prop-types';
import ScrollMagic from '../../../misc/ScrollMagic/index';

/*
 * ScrollEffectComponent
 *  - Used to wrap blocks that need to be animated when they come in view. (scrolled)
 */
class ScrollEffectComponent extends React.Component {
  static propTypes = {
    animationClass: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.bool,
    ]).isRequired,
  };

  static defaultProps = {
    animationClass: 'animate',
    className: '',
  };

  constructor(props) {
    super(props);
    this.targetElement = false;
    this.scrollMagicAdded = false;
    this.addScrollMagic = this.addScrollMagic.bind(this);
  }

  // Start with the basic React component Methods

  componentDidMount() {
    this.setController();
    this.addScrollMagic();
  }

  componentWillUnmount() {
    this.controller.destroy();
  }

  // Add the custom Methods
  setController() {
    if(!this.controller) {
      this.controller = new ScrollMagic.Controller();
    }
  }

  addScrollMagic() {
    while(!this.scrollMagicAdded) {
      if(this.targetElement) {
        const { animationClass } = this.props;
        // Use ScollMagic to detect a scroll past a certain element.
        new ScrollMagic.Scene({
          triggerElement: this.targetElement,
          triggerHook: 'onEnter',
          reverse: false,
        })
          .setClassToggle(this.targetElement, animationClass)
          .addTo(this.controller);
        this.scrollMagicAdded = true;
      }
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={(ref) => {
          this.targetElement = ref;
        }}
      >
        { this.props.children }
      </div>
    );
  }
}

export default ScrollEffectComponent;
