import React from 'react';
import styled from 'styled-components';
import ProductFilter from '../../products/filter/ProductFilter/ProductFilter';
//import structureStyles from '../../../../shared-styles/structure-styles.js';
import ArticleOverviewWrapper from '../../../paragraph-types/ArticleOverview/styled/ArticleOverviewWrapper';

export default styled.div`
  margin: 0 auto;
  text-align: center;
  
  @media (min-width: 768px) {
    margin: 0 auto 30px;
    width: 100%;
  }

  @media (min-width: 1024px) {
    padding-left: 17%;
  }
  
  ${ArticleOverviewWrapper} & {
    @media (min-width: 768px) {
      margin: 30px auto;
      padding: 0;
    }
  }
`;

// center indicator:
// position: relative;
// &::after {
//   content: '';
//   position: absolute;
//   bottom: -10px;
//   left: 50%;
//   height: 10px;
//   width: 1px;
//   background-color: black;
//   transform: translateX(-100%);
// }
