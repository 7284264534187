import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';

export default styled.li`
  @media only screen and (min-width: 1025px) {
    margin: 0 15px;
    opacity: 0;
    
    // &:first-child { margin-left: 0; }
    // &:last-child { margin-right: 0; }
  }
  
  ul {
    border-top: 0;
    display: block;
    padding: 0 !important;
    position: relative;
    text-align: left;
    top: 0;
    width: auto;
    
    li {
      animation: none !important;
      border: 0;
      display: block;
      line-height: inherit;
      padding: 0;
      width: auto !important;
      
      @media only screen and (min-width: 1025px) {
        margin-bottom: 18px;
      }
      
      li { 
        margin: 0;
      }
      
      .level-4 {
        @media only screen and (min-width: 1025px) {
          margin-bottom: 8px;
        }
      }
    }
    
    a {
      ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '14px')}
      line-height: 24px;
      padding-left: 55px;
      text-decoration: none;
      text-transform: none;
      
      @media only screen and (min-width: 1025px) {
        padding: 0;
        line-height: 18px;
      }
      
      &:hover > span {
        @media only screen and (min-width: 1025px) {
          border-bottom-color: ${davidoff.colors.orange};
        }
      }
    }
    
    span {
      border-bottom: 1px solid transparent;
      cursor: pointer;
      height: 40px;
      transition: border 300ms;
      
      @media only screen and (min-width: 1025px) {
        display: inline;
        height: auto;
        line-height: 18px;
      }
    }
  }
`;
