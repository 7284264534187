import styled from 'styled-components';

export const SplitTextMedia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 6%;
  margin: 0 auto;

  .split-text-image__text-wrapper {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: ${p => p.reverseLayout ? 'row-reverse' : 'row'};
    width: 100%;
    max-width: 1216px;
    margin: 120px auto;
    padding: 0;

    .split-text-image__text-wrapper {
      flex-basis: calc(48.685%);
      padding-left: ${p => p.reverseLayout ? '1.315%' : '0'};
      padding-right: ${p => p.reverseLayout ? '0' : '1.315%'};
    }

    .split-text-image__image {
      flex-basis: calc(51.315%);
    }
  }

  .split-text-image__image {
    width: 100%;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
       padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : `0`} !important;
    }

    .video-element {
      position: absolute;
    }

    picture {
      position: relative;
    }

    picture,
    .video-element{
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }

    .video-element {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .split-text-image__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 20px 6% 0;

    @media (min-width: 768px) {
      margin: 20px auto;
      padding: 0;
      width: 65%;
      font-size: 24px;
      line-height: 29px;
    }
  }

  p {
    margin: 0 auto;
    padding: 0 6% 20px;
    line-height: 26px;
    ${props => props.theme.fonts.parse('sansSerif', 'regular', '16px')}

    @media (min-width: 768px) {
      ${props => props.theme.fonts.parse('sansSerif', 'regular', '18px')}
      padding: 0;
      line-height: 32px;
      width: 65%;
    }

    &:last-child{
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }

`;
