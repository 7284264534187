import React from 'react';
import styled from 'styled-components';

export default styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-content: start;

  @media (min-width: 1024px) {
    
    ${props =>
      props.hasFilters &&
    `
        width: 83%;
        justify-content: left;
    `
    }
    ${props =>
    !props.hasFilters &&
    `
        max-width: 1005px;
        justify-content: center;
    `
    }
  }
  
  ${props =>
    !props.hasFilters &&
    `
    @media (min-width: 1065px) {
      
        & div:nth-child(3n) {
            margin-right: 0;
        }
    }
    `
  }
  
  ${props =>
    props.hasFilters &&
    `
    @media (min-width: 1260px) {
        & div:nth-child(3n) {
            margin-right: 0;
        }
    }
    `
  }
}
    
`;
