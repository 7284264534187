import React from 'react'
import styled from 'styled-components';

export default styled.strong`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '14px')}
  bottom: 15px;
  color: #fff;
  left: 15px;
  position: absolute;
  text-transform: uppercase;
  white-space: nowrap;
  
  span {
    @media (max-width: 767px) {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
   left: 30px;
   bottom: 30px; 
  }
`;
