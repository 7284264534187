import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import CategoryBlock from './CategoryBlock';

export default styled.h3`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '14px')}
  bottom: ${structureStyles.mobileValues.spacing};
  color: #fff;
  left: ${structureStyles.mobileValues.spacing};
  margin: 0;
  position: absolute;
  text-transform: uppercase;
  z-index: 1;
  
  @media (min-width: 768px) {
    bottom: 25px;
    left: 30px;
  }
  
  span {
    ${(props) => props.theme.fonts.parse('sansSerif', 'regular', '14px')}
    display: block;
    font-weight: 300;
    max-height: 0;
    overflow: hidden;
    padding-top: 5px;
    transition: max-height 450ms ease, padding 450ms ease;
    
    ${CategoryBlock}:hover & {
      max-height: 35px;
    }
  }
`;
