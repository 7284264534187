import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
// import davidoff from '../../../../themes/davidoff';

export default styled.div`
  padding: 0 ${structureStyles.mobileValues.spacing};
  
  &, p, em, strong {
    font-size: 16px;
    line-height: 26px;
  }
  
  @media (min-width: 768px) {
    font-size: 18px;
    padding: 0;
    
    &, p, em, strong {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  h1, h2 {
    ${(props) => props.theme.fonts.parse('serif', 'medium', '23px')}
    color: #000;
    line-height: 1.2;
    margin-bottom: 10px;
    
    @media (min-width: 768px) {
      font-size: 30px;
    }
    
    @media (min-width: 1025px) {
      font-size: 40px;
    }
  }
  
  ul {
    list-style-position: inside;
    margin-left: 0;
  }
`;
