import React from 'react'
import styled from 'styled-components';
import CollectionWrapper from './CollectionWrapper';
import MediaImageContainer from '../../../common/MediaImage/index.c';

export default styled(MediaImageContainer)`
  display: block;
  height: auto;
  position: relative;
  transition: transform 0.35s linear;
  transform: scale(1);
  width: 100%;
  
  @media (min-width: 768px) {
    ${CollectionWrapper}:hover & {
       transition: transform 2s linear;
      transform: scale(1.1);
    }
  }
`
