import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import styled from 'styled-components';
import SocialChannel from '../../social/SocialChannel/SocialChannel';

const SocialChannels = styled.ul`
  list-style: none;
  margin: 0 0 30px;
`;

const SocialMenu = ({ settings, type } ) => {
  const socialItems = Object.keys(getNested(() => settings.social[type].social_channels, {})).filter(channel => channel !== 'instagram_token' && channel !== 'instagram_token_last_update').map(channel => <SocialChannel key={`social-channel-${channel}`} url={settings.social[type].social_channels[channel]} channelName={channel} />);
  return (
    <SocialChannels>
      {socialItems}
    </SocialChannels>
  );
};

SocialMenu.propTypes = {
  settings: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
};
SocialMenu.defaultProps = {
  type: 'general',
};

export default SocialMenu;
