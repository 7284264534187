import React from 'react';
import styled from 'styled-components';
import davidoff from "../../../../themes/davidoff";
import structureStyles from "../../../../shared-styles/structure-styles";

export default styled.div`
  display: grid;
  padding: 1rem;
  align-self: center;
  height: auto;
  background: none;
  font-size: 18px;

  @media (min-width: 820px) {
    margin: ${structureStyles.desktopValues.spacing} auto;
    padding: 0;
    width: 800px;
  }

  .CookieDeclarationDialogText, .CookieDeclarationIntro, .CookieDeclarationLastUpdated {
    margin: 0 0 14px 0
  }

  .CookieDeclarationType {
    display: block;
    margin: 12px 0 12px 0;
    padding: 8px 8px 0 8px;
    border: 1px solid ${davidoff.colors.black};
    vertical-align: top;
  }

  .CookieDeclarationTypeHeader {
    font-weight: bold;
  }

  .CookieDeclarationTypeDescription {
    margin: 2px 0 16px 0;
  }

  .CookieDeclarationTable {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 18px 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  .CookieDeclarationTableHeader {
    font-weight: bold;
    border-bottom: 1px solid ${davidoff.colors.black};
    text-align: left;
    padding: 4px;
    overflow: hidden;
    font-size: 18px;
  }

  .CookieDeclarationTableHeader[dir='rtl'] {
    text-align: right;
  }

  .CookieDeclarationTableCell {
    text-overflow: ellipsis;
    word-wrap: break-word;
    border-bottom: 1px solid ${davidoff.colors.brown};
    vertical-align: top;
    padding: 20px 5px;
  }

  #CookieDeclarationUserStatusLabelConsentId {
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  #CookieDeclarationUserStatusLabel dt, #CookieDeclarationUserStatusLabel dd {
    display: inline-block;
  }

  #CookieDeclarationUserStatusLabel dt {
    padding-right: 4px;
    font-weight: 400;
    margin-left: 0;
    margin-bottom: .5rem;
  }

  @media all and (max-width: 600px) {
    .CookieDeclaration table.CookieDeclarationTable tr td:nth-child(n+5), .CookieDeclaration table.CookieDeclarationTable tr th:nth-child(n+5), .CookieDeclaration table.CookieDeclarationTable colgroup col:nth-child(n+5) {
      display: none;
    }
  }
`;
