import React from 'react';
import PropTypes from 'prop-types';
import { dataMapper } from '../../../index';
import InstructionStep from "../InstructionStep/InstructionStep";
import InstructionImage from "../InstructionImage/InstructionImage";

// styled components
import Row from './styled/Row';
import InstructionImagesWrapper from './styled/InstructionImagesWrapper';
import InstructionStepsWrapper from './styled/InstructionStepsWrapper';

const InstructionRow = ({ instruction, data }) => {
    const steps = instruction.field_steps.map(stepRef => {
        const step = dataMapper(data, stepRef.target_uuid);
        return (
            <InstructionStep key={stepRef.target_uuid} step={step} data={data} />
        );
    });

    const images = instruction.field_media_multiple.map(mediaReference => <InstructionImage key={mediaReference.target_uuid} imageType="Instruction image" mediaReference={mediaReference} data={data} />);

    return(
        <Row>
            <InstructionImagesWrapper>
                {images}
            </InstructionImagesWrapper>
            <InstructionStepsWrapper>
                {steps}
            </InstructionStepsWrapper>
        </Row>
    );
};

InstructionRow.Proptypes = {
    instruction: PropTypes.shape({
        field_media_multiple: PropTypes.array.isRequired,
        field_steps: PropTypes.array.isRequired,
    }).isRequired
};

export default InstructionRow;
