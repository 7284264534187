import React from 'react';
import LdJson from 'react-ld-json';

export default function ItemListSchema(props) {
  return (
    <LdJson
      data={{
        '@context': 'https://schema.org/',
        '@type': 'ItemList',
        mainEntityOfPage: {
          '@id': '#main-webpage',
        },
        itemListElement: props.items.map((item, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          url: item.url,
        })),
        numberOfItems: props.items.length,
      }}
    />
  );
}
