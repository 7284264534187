import React from 'react';

export default {
  currentLocation: <svg x="0px" y="0px" viewBox="0 0 29.8 30.1">
    <path d="M19.5,15c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5s2-4.5,4.5-4.5S19.5,12.5,19.5,15z M30,15c0-8.3-6.7-15-15-15S0,6.7,0,15
      s6.7,15,15,15S30,23.3,30,15z M27,15c0,6.6-5.4,12-12,12S3,21.6,3,15S8.4,3,15,3S27,8.4,27,15z"/>
  </svg>,
  facebook: <svg x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500">
    <path
        d="M364.819,271.843l11.405-88.524h-87.662v-56.527c0-25.629,7.139-43.1,43.896-43.1l46.841-0.02V4.489 C371.213,3.41,343.41,1,311.01,1c-67.605,0-113.856,41.265-113.856,117.033v65.286H120.7v88.524h76.453V499h91.409V271.843H364.819z "/>
  </svg>,
  twitter: <svg x="0px" y="0px" viewBox="0 0 612 612">
    <g>
      <path
          d="M612,116.258c-22.525,9.981-46.694,16.75-72.088,19.772c25.929-15.527,45.777-40.155,55.184-69.411 c-24.322,14.379-51.169,24.82-79.775,30.48c-22.907-24.437-55.49-39.658-91.63-39.658c-69.334,0-125.551,56.217-125.551,125.513 c0,9.828,1.109,19.427,3.251,28.606C197.065,206.32,104.556,156.337,42.641,80.386c-10.823,18.51-16.98,40.078-16.98,63.101 c0,43.559,22.181,81.993,55.835,104.479c-20.575-0.688-39.926-6.348-56.867-15.756v1.568c0,60.806,43.291,111.554,100.693,123.104 c-10.517,2.83-21.607,4.398-33.08,4.398c-8.107,0-15.947-0.803-23.634-2.333c15.985,49.907,62.336,86.199,117.253,87.194 c-42.947,33.654-97.099,53.655-155.916,53.655c-10.134,0-20.116-0.612-29.944-1.721c55.567,35.681,121.536,56.485,192.438,56.485 c230.948,0,357.188-191.291,357.188-357.188l-0.421-16.253C573.872,163.526,595.211,141.422,612,116.258z"/>
    </g>
  </svg>,
  youtube: <svg x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500">
    <path
        d="M494,150.421c0,0-4.849-34.317-19.772-49.431c-18.951-19.841-40.168-19.932-49.909-21.103 c-69.697-5.022-174.212-5.022-174.212-5.022h-0.235c0,0-104.53,0-174.205,5.022c-9.742,1.17-30.973,1.262-49.909,21.103 c-14.924,15.114-19.795,49.431-19.795,49.431S1,190.725,1,231.015v37.781c0,40.305,4.962,80.608,4.962,80.608 s4.871,34.317,19.795,49.424c18.936,19.848,43.823,19.21,54.902,21.292C120.5,423.935,250,425.135,250,425.135 s104.621-0.182,174.318-5.213c9.741-1.154,30.958-1.246,49.909-21.094C489.151,383.722,494,349.404,494,349.404s5-40.304,5-80.608 v-37.781C499,190.725,494,150.421,494,150.421z M198.578,314.587l-0.03-139.918l134.568,70.198L198.578,314.587z"/>
  </svg>,
  instagram: <svg x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500">
    <g>
      <path fill="none"
            d="M249.996,166.999c-45.828,0-82.994,37.165-82.994,83.016c0,45.821,37.166,82.994,82.994,82.994 c45.844,0,83.017-37.173,83.017-82.994C333.013,204.164,295.84,166.999,249.996,166.999z"/>
      <path fill="none"
            d="M444.121,103.139c-4.528-11.611-9.924-19.901-18.646-28.617c-8.693-8.716-16.991-14.118-28.617-18.632 c-8.77-3.404-21.976-7.47-46.23-8.571c-26.277-1.201-34.149-1.451-100.631-1.451c-66.467,0-74.354,0.25-100.607,1.451 c-24.278,1.102-37.462,5.167-46.239,8.571c-11.626,4.514-19.917,9.916-28.617,18.632c-8.731,8.716-14.134,17.006-18.647,28.617 c-3.389,8.776-7.454,21.976-8.556,46.254c-1.216,26.253-1.459,34.126-1.459,100.607c0,66.489,0.243,74.361,1.459,100.607 c1.102,24.286,5.167,37.478,8.556,46.246c4.514,11.611,9.916,19.909,18.647,28.633c8.7,8.707,16.991,14.103,28.617,18.616 c8.777,3.42,21.96,7.478,46.239,8.587c26.253,1.186,34.126,1.443,100.607,1.443s74.369-0.258,100.631-1.443 c24.255-1.109,37.461-5.167,46.23-8.587c11.626-4.514,19.924-9.909,28.617-18.616c8.723-8.724,14.118-17.021,18.646-28.633 c3.39-8.769,7.447-21.96,8.557-46.246c1.216-26.246,1.443-34.118,1.443-100.607c0-66.481-0.228-74.354-1.443-100.607 C451.568,125.114,447.511,111.915,444.121,103.139z M249.996,377.872c-70.607,0-127.857-57.265-127.857-127.857 c0-70.63,57.25-127.879,127.857-127.879c70.631,0,127.879,57.249,127.879,127.879C377.875,320.607,320.627,377.872,249.996,377.872 z M382.921,146.961c-16.504,0-29.894-13.366-29.894-29.878c0-16.497,13.39-29.878,29.894-29.878 c16.505,0,29.879,13.381,29.879,29.878C412.8,133.595,399.426,146.961,382.921,146.961z"/>
      <path
          d="M497.495,147.333c-1.216-26.497-5.41-44.597-11.581-60.425c-6.337-16.39-14.877-30.266-28.723-44.103 c-13.814-13.853-27.721-22.363-44.088-28.731c-15.836-6.163-33.936-10.365-60.44-11.573c-26.55-1.201-35.03-1.497-102.667-1.497 c-67.614,0-76.102,0.296-102.659,1.497c-26.505,1.208-44.605,5.41-60.433,11.573c-16.368,6.368-30.266,14.878-44.103,28.731 C28.948,56.642,20.438,70.518,14.093,86.908C7.93,102.736,3.728,120.836,2.512,147.333C1.296,173.898,1,182.379,1,250 c0,67.613,0.296,76.094,1.512,102.659c1.216,26.505,5.418,44.604,11.581,60.44c6.345,16.383,14.855,30.259,28.708,44.104 c13.837,13.845,27.735,22.355,44.103,28.724c15.828,6.154,33.928,10.364,60.433,11.58c26.558,1.2,35.045,1.489,102.659,1.489 c67.637,0,76.117-0.289,102.667-1.489c26.505-1.216,44.604-5.426,60.44-11.58c16.367-6.368,30.273-14.879,44.088-28.724 c13.846-13.845,22.386-27.721,28.723-44.104c6.171-15.836,10.365-33.936,11.581-60.44C498.711,326.094,499,317.613,499,250 C499,182.379,498.711,173.898,497.495,147.333z M452.678,350.607c-1.109,24.286-5.167,37.478-8.557,46.246 c-4.528,11.611-9.924,19.909-18.646,28.633c-8.693,8.707-16.991,14.103-28.617,18.616c-8.77,3.42-21.976,7.478-46.23,8.587 c-26.262,1.186-34.149,1.443-100.631,1.443s-74.354-0.258-100.607-1.443c-24.278-1.109-37.462-5.167-46.239-8.587 c-11.626-4.514-19.917-9.909-28.617-18.616c-8.731-8.724-14.134-17.021-18.647-28.633c-3.389-8.769-7.454-21.96-8.556-46.246 c-1.216-26.246-1.459-34.118-1.459-100.607c0-66.481,0.243-74.354,1.459-100.607c1.102-24.278,5.167-37.478,8.556-46.254 c4.514-11.611,9.916-19.901,18.647-28.617c8.7-8.716,16.991-14.118,28.617-18.632c8.777-3.404,21.96-7.47,46.239-8.571 c26.253-1.201,34.141-1.451,100.607-1.451c66.481,0,74.354,0.25,100.631,1.451c24.255,1.102,37.461,5.167,46.23,8.571 c11.626,4.514,19.924,9.916,28.617,18.632c8.723,8.716,14.118,17.006,18.646,28.617c3.39,8.776,7.447,21.976,8.557,46.254 c1.216,26.253,1.443,34.126,1.443,100.607C454.121,316.489,453.894,324.361,452.678,350.607z"/>
      <path
          d="M249.996,122.136c-70.607,0-127.857,57.249-127.857,127.879c0,70.592,57.25,127.857,127.857,127.857 c70.631,0,127.879-57.265,127.879-127.857C377.875,179.385,320.627,122.136,249.996,122.136z M249.996,333.009 c-45.828,0-82.994-37.173-82.994-82.994c0-45.851,37.166-83.016,82.994-83.016c45.844,0,83.017,37.165,83.017,83.016 C333.013,295.836,295.84,333.009,249.996,333.009z"/>
      <path
          d="M382.921,87.204c-16.504,0-29.894,13.381-29.894,29.878c0,16.512,13.39,29.878,29.894,29.878 c16.505,0,29.879-13.366,29.879-29.878C412.8,100.585,399.426,87.204,382.921,87.204z"/>
    </g>
  </svg>,
  whatsapp: <svg x="0px" y="0px" width="90px" height="90px" viewBox="0 0 90 90">
    <g>
      <path id="WhatsApp"
            d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522 c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982 c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537 c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938 c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537 c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333 c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882 c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977 c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344 c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223 C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"/>
    </g>
  </svg>,
  email: <svg x="0px" y="0px" width="612px" height="612px" viewBox="0 0 612 612">
    <g>
      <path
          d="M306.768,346.814h0.131c4.615,0,9.176-1.339,12.866-3.777l1.001-0.643c0.218-0.142,0.446-0.271,0.675-0.424l11.658-9.645 l278.259-229.624c-0.576-0.795-1.557-1.339-2.602-1.339H3.233c-0.751,0-1.448,0.272-2.003,0.729l291.125,239.954 C296.024,345.083,301.259,346.814,306.768,346.814z M0,133.899v340.37l208.55-168.471L0,133.899z M403.668,306.941L612,474.356 V135.031L403.668,306.941z M337.431,361.585c-8.305,6.814-19.168,10.57-30.576,10.57c-11.451,0-22.304-3.734-30.587-10.516 l-47.765-39.394L0,506.806v0.587c0,1.753,1.502,3.244,3.276,3.244h605.491c1.741,0,3.232-1.491,3.232-3.255v-0.544L383.693,323.4 L337.431,361.585z"/>
    </g>
  </svg>,
  globe: <svg x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500">
    <path
        d="M498.402,233.4C490.235,109.099,390.901,9.731,266.6,1.564V1H250h-16.6v0.564C109.099,9.731,9.731,109.099,1.564,233.4H1 V250v16.6h0.564C9.731,390.901,109.099,490.235,233.4,498.402V499H250h16.6v-0.598c124.302-8.167,223.636-107.501,231.803-231.803 H499V250v-16.6H498.402z M156.874,55.282c-16.036,21.314-29.349,48.04-39.043,78.518H68.097 C89.744,100.036,120.487,72.712,156.874,55.282z M50.733,167h58.366c-4.449,20.982-7.271,43.26-8.167,66.4H34.831 C36.59,210.027,42.102,187.684,50.733,167z M34.831,266.6h66.101c0.896,23.142,3.718,45.418,8.167,66.401H50.733 C42.102,312.316,36.59,289.973,34.831,266.6z M68.097,366.199h49.7c9.695,30.479,23.008,57.204,39.077,78.519 C120.487,427.288,89.744,399.997,68.097,366.199z M233.4,463.675c-34.13-9.13-63.512-45.782-81.34-97.476h81.34V463.675z M233.4,333.001h-90.67c-4.681-20.685-7.636-43.028-8.599-66.401H233.4V333.001z M233.4,233.4h-99.269 c0.963-23.373,3.918-45.716,8.599-66.4h90.67V233.4z M233.4,133.8h-81.34c17.829-51.692,47.21-88.346,81.34-97.442V133.8z M431.903,133.8h-49.668c-9.728-30.478-23.007-57.204-39.109-78.518C379.48,72.712,410.257,100.036,431.903,133.8z M266.6,36.35c34.098,9.097,63.513,45.75,81.34,97.442H266.6V36.358z M266.6,167h90.637c4.715,20.684,7.603,43.027,8.631,66.4H266.6V167z M266.6,266.6h99.268c-0.963,23.373-3.916,45.717-8.631,66.401H266.6V266.6z M266.6,463.675v-97.476h81.34 C330.112,417.893,300.697,454.545,266.6,463.675z M343.126,444.718c16.035-21.348,29.382-48.04,39.109-78.519h49.668 C410.257,399.997,379.48,427.288,343.126,444.718z M449.267,333.001h-58.365c4.448-20.983,7.271-43.26,8.167-66.401h66.067 C463.409,289.973,457.898,312.316,449.267,333.001z M399.068,233.4c-0.896-23.141-3.719-45.418-8.167-66.4h58.365 c8.632,20.684,14.143,43.027,15.902,66.4H399.068z"/>
  </svg>,
  drop_down_arrow: <svg version="1.1" viewBox="0 0 129 129" className="dropdown-arrow">
    <g>
      <path
          d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/>
    </g>
  </svg>,
  drop_down_arrow_filled: <svg x="0px" y="0px" width="451.846px" height="451.847px" viewBox="0 0 451.846 451.847">
    <g>
      <path
          d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744 L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"/>
    </g>
  </svg>,
  search: <svg x="0px" y="0px" width="500px" height="500px" viewBox="0 0 500 500">
    <g>
      <path
          d="M1,448.105L53.142,499l153.838-150.201c29.627,19.247,64.889,30.539,102.852,30.539 C414.299,379.338,499,294.637,499,190.169C499,85.695,414.299,1,309.831,1S120.662,85.695,120.662,190.169 c0,40.168,12.608,77.335,33.968,107.967L1,448.105z M191.601,190.169c0-65.194,53.042-118.23,118.23-118.23 s118.23,53.036,118.23,118.23c0,65.188-53.042,118.23-118.23,118.23S191.601,255.357,191.601,190.169z"/>
    </g>
  </svg>,
  check: <svg viewBox="0 0 26 26">
    <path
        d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"/>
  </svg>,
  marker: <svg x="0px" y="0px" viewBox="0 0 64 84">
    <ellipse id="Shadow" className="st0" style={{opacity: 0.5, enableBackground: 'new'}} cx="32" cy="76" rx="16" ry="8"/><path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#1D1E2A'}} d="M54.6,9.4C42.1-3.1,21.9-3.1,9.4,9.4c-12.5,12.5-12.5,32.8,0,45.3L32,77.3l22.6-22.6 C67.1,42.1,67.1,21.9,54.6,9.4z"/><circle style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF'}} cx="32" cy="32" r="20"/>
  </svg>,
  acitveMarker: <svg x="0px" y="0px" viewBox="0 0 64 84">
    <ellipse id="Shadow" className="st0" style={{opacity: 0.5, enableBackground: 'new'}} cx="32" cy="76" rx="16" ry="8"/><path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#fd6b0d'}} d="M54.6,9.4C42.1-3.1,21.9-3.1,9.4,9.4c-12.5,12.5-12.5,32.8,0,45.3L32,77.3l22.6-22.6 C67.1,42.1,67.1,21.9,54.6,9.4z"/><circle style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF'}} cx="32" cy="32" r="20"/>
  </svg>,
  infowindow_categories: <svg viewBox="0 0 71.08 70">
    <path d="M71.08,70H0L9,16H62.08Zm-64-6H64L57,22H14.08Z" /><path d="M54.54,19h-6V11a5,5,0,0,0-5-5h-16a5,5,0,0,0-5,5v8h-6V11a11,11,0,0,1,11-11h16a11,11,0,0,1,11,11Z" />
  </svg>,
  infowindow_email: <svg viewBox="0 0 70 54">
    <path d="M70,54H0V0H70ZM6,48H64V6H6Z" /><polygon points="35 39.24 0.88 5.12 5.12 0.88 35 30.76 64.88 0.88 69.12 5.12 35 39.24" />
  </svg>,
  infowindow_marker: <svg viewBox="0 0 69.98 84.48">
    <path d="M35,84.48,10.23,59.73a35,35,0,1,1,49.5,0ZM35,6A29,29,0,0,0,14.48,55.49L35,76,55.49,55.49A29,29,0,0,0,35,6Z" /><path d="M35,54A19,19,0,1,1,54,35,19,19,0,0,1,35,54Zm0-32A13,13,0,1,0,48,35,13,13,0,0,0,35,22Z" />
  </svg>,
  infowindow_phone: <svg viewBox="0 0 70 70">
    <path d="M52.2,70a33.45,33.45,0,0,1-4.62-.48,34.16,34.16,0,0,1-7.74-2.36,62.45,62.45,0,0,1-9.94-5.44,73.87,73.87,0,0,1-11.74-9.89A77.4,77.4,0,0,1,10,42.51a66.84,66.84,0,0,1-5.25-8.27,45.6,45.6,0,0,1-3-7A38.12,38.12,0,0,1,.32,21.66a19.39,19.39,0,0,1-.3-4q.06-1,.06-1.23l0-.38A9.62,9.62,0,0,1,1,13.25a8.09,8.09,0,0,1,1.78-2.46l9-9A6,6,0,0,1,16,0a5.55,5.55,0,0,1,3.36,1.11,7.73,7.73,0,0,1,1.88,2l.12.21,7.25,13.75a6.26,6.26,0,0,1,.62,4.39,7.2,7.2,0,0,1-2,3.67l-2.55,2.55a12.54,12.54,0,0,0,.66,1.46,27.09,27.09,0,0,0,2.27,3.59,41.25,41.25,0,0,0,4.48,5,42.52,42.52,0,0,0,5.09,4.56,28.58,28.58,0,0,0,3.56,2.29,12.2,12.2,0,0,0,1.4.63L45.46,42a7.42,7.42,0,0,1,7.31-1.44H53l.77.42L66.8,48.66a6.39,6.39,0,0,1,3.11,4.23l0,.2a6.12,6.12,0,0,1-1.8,5.22l-9,8.9A8.1,8.1,0,0,1,56.72,69a9.6,9.6,0,0,1-2.81.88l-.53,0C53.08,69.94,52.68,70,52.2,70ZM6.07,16.79C6.06,17,6,17.45,6,18a13.72,13.72,0,0,0,.24,2.72,32.24,32.24,0,0,0,1.18,4.63,39.74,39.74,0,0,0,2.65,6.1A61,61,0,0,0,14.85,39a71.62,71.62,0,0,0,7.54,8.58,68.11,68.11,0,0,0,10.78,9.11,56.59,56.59,0,0,0,9,4.92,28.92,28.92,0,0,0,6.35,2A27.75,27.75,0,0,0,52.2,64c.24,0,.43,0,.58,0l.37,0a3.77,3.77,0,0,0,1-.33,2.17,2.17,0,0,0,.67-.46L54.9,63l9-8.94L64,54a1.62,1.62,0,0,0-.27-.2l-13-7.65a1.89,1.89,0,0,0-.38,0,1.14,1.14,0,0,0-.79.28l-3.77,3.84a4,4,0,0,1-1.63,1,4.29,4.29,0,0,1-1.39.25h-.3l-1.14-.23A13,13,0,0,1,38.06,50a33.85,33.85,0,0,1-4.37-2.79A47.88,47.88,0,0,1,27.9,42,46.74,46.74,0,0,1,22.8,36.3,32.39,32.39,0,0,1,20,31.92a14.91,14.91,0,0,1-1.48-4l-.05-.28v-.28a4.57,4.57,0,0,1,.25-1.45,4.06,4.06,0,0,1,1-1.61L23,20.93a1.23,1.23,0,0,0,.34-.66c0-.18,0-.23,0-.28L16.15,6.29A1.79,1.79,0,0,0,16,6.08L6.85,15.19a2.18,2.18,0,0,0-.46.66A3.82,3.82,0,0,0,6.07,16.79Zm44.52,29.3.06,0Z" />
  </svg>,
  current_location: <svg x="0px" y="0px" viewBox="0 0 469.333 469.333">
    <path d="M234.667,149.333c-47.147,0-85.333,38.187-85.333,85.333S187.52,320,234.667,320S320,281.813,320,234.667 S281.813,149.333,234.667,149.333z M425.387,213.333C415.573,124.373,344.96,53.76,256,43.947V0h-42.667v43.947 C124.373,53.76,53.76,124.373,43.947,213.333H0V256h43.947c9.813,88.96,80.427,159.573,169.387,169.387v43.947H256v-43.947 C344.96,415.573,415.573,344.96,425.387,256h43.947v-42.667H425.387L425.387,213.333z M234.667,384 c-82.453,0-149.333-66.88-149.333-149.333s66.88-149.333,149.333-149.333S384,152.213,384,234.667S317.12,384,234.667,384z"/>
  </svg>,
  external: <svg x="0px" y="0px" viewBox="0 0 194.818 194.818">
    <g><path d="M185.818,2.161h-57.04c-4.971,0-9,4.029-9,9s4.029,9,9,9h35.312l-86.3,86.3c-3.515,3.515-3.515,9.213,0,12.728 c1.758,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l86.3-86.3v35.313c0,4.971,4.029,9,9,9s9-4.029,9-9v-57.04 C194.818,6.19,190.789,2.161,185.818,2.161z"/><path d="M149,77.201c-4.971,0-9,4.029-9,9v88.456H18v-122h93.778c4.971,0,9-4.029,9-9s-4.029-9-9-9H9c-4.971,0-9,4.029-9,9v140 c0,4.971,4.029,9,9,9h140c4.971,0,9-4.029,9-9V86.201C158,81.23,153.971,77.201,149,77.201z"/></g>
  </svg>,
  sound_on: <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
    <g><path d="M15.53028,6.837 C15.23718,6.5442 14.76258,6.5442 14.46978,6.837 C14.17668,7.1301 14.17668,7.605 14.46978,7.8978 C15.56538,8.9934 16.16898,10.4502 16.16898,12 C16.16898,13.5495 15.56538,15.0066 14.46978,16.1022 C14.17668,16.395 14.17668,16.8699 14.46978,17.1627 C14.61618,17.3094 14.80818,17.3826 14.99988,17.3826 C15.19188,17.3826 15.38388,17.3094 15.53028,17.163 C16.90968,15.7839 17.66898,13.9503 17.66898,12 C17.66898,10.0494 16.90968,8.2161 15.53028,6.837 L15.53028,6.837 Z M17.71428,4.6533 C17.42148,4.3605 16.94688,4.3605 16.65378,4.6533 C16.36098,4.9461 16.36098,5.421 16.65378,5.7138 C18.33288,7.3932 19.25748,9.6258 19.25748,12 C19.25748,14.3742 18.33288,16.6065 16.65378,18.2862 C16.36098,18.579 16.36098,19.0539 16.65378,19.3467 C16.80018,19.4931 16.99218,19.5663 17.18418,19.5663 C17.37588,19.5663 17.56818,19.4931 17.71428,19.3467 C19.67658,17.3838 20.75748,14.7747 20.75748,12 C20.75748,9.225 19.67658,6.6162 17.71428,4.6533 L17.71428,4.6533 Z M19.89858,2.4696 C19.60608,2.1768 19.13088,2.1768 18.83778,2.4696 C18.54498,2.7624 18.54498,3.2373 18.83778,3.5304 C21.09978,5.793 22.34568,8.8008 22.34568,12 C22.34568,15.1992 21.09978,18.207 18.83778,20.4696 C18.54498,20.7627 18.54498,21.2376 18.83778,21.5304 C18.98448,21.6768 19.17618,21.75 19.36818,21.75 C19.56018,21.75 19.75218,21.6768 19.89858,21.5304 C22.44378,18.9843 23.84568,15.5994 23.84568,12 C23.84568,8.4003 22.44378,5.0157 19.89858,2.4696 L19.89858,2.4696 Z M5.99988,6 L11.99988,0 L11.99988,24 L5.99988,18 L-0.00012,18 L-0.00012,6 L5.99988,6 Z"></path></g>
  </svg>,
  sound_off: <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
    <g><path d="M19.937515,11.6363989 L23.7868357,7.78707814 C24.0710548,7.50315001 24.0710548,7.04234861 23.7868357,6.75842048 C23.5029076,6.47449235 23.042688,6.47449235 22.758469,6.75842048 L18.9091482,10.6080321 L15.0595365,6.75842048 C14.7756084,6.47449235 14.3153888,6.47449235 14.0311698,6.75842048 C13.7472417,7.04234861 13.7472417,7.50315001 14.0311698,7.78707814 L17.8807815,11.6363989 L14.0311698,15.4857197 C13.7472417,15.7696478 13.7472417,16.2304492 14.0311698,16.5143773 C14.1734248,16.6563414 14.3593162,16.7273234 14.5454986,16.7273234 C14.7313901,16.7273234 14.9175725,16.6563414 15.0595365,16.5143773 L18.9091482,12.6647657 L22.758469,16.5143773 C22.9007239,16.6563414 23.0866154,16.7273234 23.2727978,16.7273234 C23.4586893,16.7273234 23.6448717,16.6563414 23.7868357,16.5143773 C24.0710548,16.2304492 24.0710548,15.7696478 23.7868357,15.4857197 L19.937515,11.6363989 Z M5.81819945,5.81819945 L11.6363989,-7.10542736e-15 L11.6363989,23.2727978 L5.81819945,17.4545983 L0,17.4545983 L0,5.81819945 L5.81819945,5.81819945 Z"></path></g>
  </svg>

}


