import React from 'react';
import styled from 'styled-components';

export default styled.div`
     
    margin-bottom: 40px;
    
    &::after {
        content: '';
        display: table;
        clear: both;
    }    
    
    @media only screen and (min-width:500px) {
        margin-bottom: 10px;
    }
`;