import React from 'react';
import PropTypes from 'prop-types';
import icons from '../icons/icons';
// Components
import AccordionWrapper from './styled/AccordionWrapper';
import AccordionHeading from './styled/AccordionHeading';
import AccordionBody from './styled/AccordionBody';
import AccordionItem from './styled/AccordionItem';

class AccordionComponent extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array
    ]).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    isOpen: false,
    className: '',
  };

  constructor(props) {
    super(props);

    // set the initial state
    this.state = {
      open: this.props.isOpen,
    };

    // Bind the event handlers to the class
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion(ev) {
    ev.preventDefault();

    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const { children, title, className } = this.props;

    return (
      <AccordionWrapper className={className}>
        <AccordionHeading>
          <AccordionItem className={this.state.open ? 'open' : ''} onClick={this.toggleAccordion} role='button' href="#open">{ icons.drop_down_arrow_filled } { title }</AccordionItem>
        </AccordionHeading>
        <AccordionBody open={this.state.open}>
          { children }
        </AccordionBody>
      </AccordionWrapper>
    );
  }
}


export default AccordionComponent;
