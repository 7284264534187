import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import ProductHighlightItem from '../../ProductHighlightItem/styled/HighlightItemWrapper';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const hyphenBeforeGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 8px;
  }
`;

const hyphenAfterGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 60px;
  }
`;

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

const fadeInSlow = css`${fpsToMs(25)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`;

const fadeInMedium = css`${fpsToMs(25)}ms ease ${fpsToMs(37)}ms forwards ${fadeIn}`;

const fadeInFast = css`${fpsToMs(25)}ms ease ${fpsToMs(44)}ms forwards ${fadeIn}`;

const fadeInSlowMedium = css`${fpsToMs(26)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`;

const hyphenBeforeGrowAnimation = css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenBeforeGrow}`;

const hyphenAfterGrowAnimation = css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenAfterGrow}`;

export default styled(ScrollEffect)`
  margin: 0 0 40px;
  
  @media (min-width: 768px) {
    margin: 0 0 60px;
  }
  
  ${props => props.noMargin ? `
     margin: 0;
  
    @media (min-width: 768px) {
      margin: 0;
    }
  ` : ''}
  
  h2 {
    margin-left: 20px;
    max-width: 190px;
    padding-top: ${structureStyles.mobileValues.spacing};
    text-transform: uppercase;
    z-index: 1;
    
    @media (min-width: 768px) {
      margin-left: 70px;
      padding-top: 60px;
    }
    
    &:before,
    &:after {
      bottom: -8px;
      
      @media (min-width: 768px) {
        width: 0;
      }
    }
    
    span {
      @media (min-width: 768px) {
        opacity: 0;
      }
    }
  }
  @media (min-width: 768px) {
    ${ProductHighlightItem} {
      opacity: 0;
      transform: translateY(50px);
    }
    
    &.animate {
      ${ProductHighlightItem}:first-child {
        animation: ${fadeInSlow};
      }
      
      ${ProductHighlightItem}:nth-child(2) {
        animation: ${fadeInMedium};
      }
  
      ${ProductHighlightItem}:last-child {
        animation: ${fadeInFast};
      }
      
      h2 {
        &:before {
          animation: ${hyphenBeforeGrowAnimation};
        }

        &:after {
          animation: ${hyphenAfterGrowAnimation};
          animation-fill-mode: forwards;
        }

        span {
          animation: ${fadeInSlowMedium};
        } 
      }
    }
  }
`;
