import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import StandardMenuItem from '../menuItems/StandardPrimaryMenuItem/StandardPrimaryMenuItem';
import ColumnMenuItem from '../menuItems/ColumnMenuItem/ColumnMenuItem';
import HighlightMenuItem from '../menuItems/HighlightMenuItem/HighlightMenuItem';
import NavLogo from './styled/NavLogo';
import MainNav from './styled/MainNav';
import MainNavInner from './styled/MainNavInner';
import MainNavWrapper from './styled/MainNavWrapper';

const getMenuItems = (item, location, data, level = 0, isLoading = false) => {
  // First get all child items
  const nextLevel = ++level;
  const children = getNested(() => item.children, []).map(child => {
    return getMenuItems(child, location, data, nextLevel, isLoading);
  });

  switch (item.type) {
    case 'column':
      return (
        <ColumnMenuItem isLoading={isLoading} key={item.key} menuItem={item}>
          {children}
        </ColumnMenuItem>
      );
    case 'highlight':
      return (
        <HighlightMenuItem
          isLoading={isLoading}
          key={item.key}
          menuItem={item}
          data={data}
        />
      );
    default:
      return (
        <StandardMenuItem
          key={item.key}
          menuItem={item}
          clickable={children.length > 0}
          level={level}
          location={location}
          className={children.length > 0 ? '' : 'no-subnav'}
          isLoading={isLoading}
        >
          {children.length > 0 && (
            <div className="subnav-wrapper">
              <ul>{children}</ul>
            </div>
          )}
        </StandardMenuItem>
      );
  }
};

const PrimaryMenu = ({
  settings,
  lang,
  open,
  fixed,
  location,
  isLoading,
  data,
}) => {
  const menuItems = getNested(() => settings.menu[lang].main, []).map(item =>
    getMenuItems(item, location, data, 0, isLoading)
  );

  return (
    <MainNavWrapper fixed={fixed}>
      <NavLogo settings={settings} lang={lang} fixed={fixed} />
      <MainNav open={open}>
        <MainNavInner>
          {menuItems}
          <li className="background-item"></li>
        </MainNavInner>
      </MainNav>
    </MainNavWrapper>
  );
};

PrimaryMenu.propTypes = {
  settings: PropTypes.shape().isRequired,
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  fixed: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape().isRequired,
};

export default PrimaryMenu;
