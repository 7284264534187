import React from 'react';
import styled from 'styled-components';

export default styled.div`
  p, ul, ol {
    font-size: 14px;
    line-height: 1.357;
  }
  
  ul, ol {
    margin-left: 1em;
  }
  
  p {
    margin-bottom: 1em;
  }
  
  h3 {
    margin-top: 1.5em;
    margin-bottom: 0;
    font-size: 1em;
  }
`;
