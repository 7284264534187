import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import getNested from 'get-nested';
// Stores
import SiteStore from '../../../../stores/SiteStore';
import StoreLocatorStore from '../../../../stores/StoreLocatorStore';
// Components
import FilterGroup from '../StoreLocatorFilterGroup/StoreLocatorFilterGroup';
// Styled Components
import FilterWrapper from './styled/FilterWrapper';
import FilterResultLabel from './styled/FilterResultLabel';

@inject('translationStore')
@observer
class StoreLocatorFilter extends Component {
  constructor(props) {
    super(props);

    this.StoreLocatorStore = StoreLocatorStore.getInstance();
    this.SiteStore = SiteStore.getInstance();
    this.handleFilter = this.handleFilter.bind(this);
  }

  handleFilter(ev) {
    const target = ev.target;
    const value = target.value;
    // Check if there are more sub filters to be shown
    // Add it when it's checked
    if(target.checked) {
      this.StoreLocatorStore.addSelectedFilters(value);
    } else {
      this.StoreLocatorStore.removeSelectedFilters(value);
    }

    this.StoreLocatorStore.selectFilters();
  }

  render() {
    const { translationStore } = this.props;
    if(!getNested(() => this.StoreLocatorStore.displayedFilters, false)) {
      return false;
    }

    const FilterGroups = Object.keys(this.StoreLocatorStore.displayedFilters).map((key) => {
      const filter = this.StoreLocatorStore.displayedFilters[key];

      return <FilterGroup
        key={`filter-group-${key}`}
        filter={filter}
        handler={this.handleFilter}
      />;
    });
    return (
      <FilterWrapper>
        <FilterResultLabel>{this.StoreLocatorStore.displayedItems.length} {translationStore.translate('results')}</FilterResultLabel>
        { FilterGroups }
      </FilterWrapper>
    );
  }
}

export default StoreLocatorFilter;
