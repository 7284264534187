import root from "window-or-global";
import getNested from "get-nested";
import viewport from "../lib/viewport";
import { dataMapper } from "../components";

export function getCloudinaryID(
  url,
  cloudName,
  cloudinaryBase = "//res.cloudinary.com"
) {
  // Return a Cloudinary ID
  const cloudinaryBaseURL = `${cloudinaryBase}/${cloudName}/image/upload/`;
  let cloudinaryID = false;

  if (url.indexOf(cloudinaryBaseURL) > -1) {
    // Always use the second part of a splitted url
    const splittedUrl = url.split(cloudinaryBaseURL)[1];
    // Strip the version from the url
    cloudinaryID = splittedUrl.split("/");

    if (cloudinaryID.length > 2) {
      // Strip the version from the url
      cloudinaryID = cloudinaryID.slice(1);
      // Concatenate the resulting path
      cloudinaryID = cloudinaryID.join("/");
    } else {
      cloudinaryID = cloudinaryID[1];
    }
  } else if (url.indexOf("cloudinary://") > -1) {
    cloudinaryID = url.replace("cloudinary://", "");
  }

  if (cloudinaryID) {
    const splitOnDot = cloudinaryID.split(".");
    splitOnDot.pop();
    return splitOnDot.join(".");
  }

  return url;
}

export function formatElementKey(name) {
  return name.replace(/\s+/g, "-").toLowerCase();
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function autoPlayVideo(autoPlayWidth = 1025) {
  if (root.document && viewport().width <= autoPlayWidth) {
    return false;
  }

  return true;
}

export function formatMetaData(data, location, search) {
  const currentPage = data.data[data.paths[location]];

  const shareImgBaseUrl =
    "https://res.cloudinary.com/zinodavidoff/image/upload/g_face,c_fill,h_630,w_1200/";

  const defaultMeta = {
    title: "DAVIDOFF",
    description:
      "Discover DAVIDOFF Cool Water new campaign. DAVIDOFF : Perfumes, coffee, sunglasses, leather bags and luxury accessories.",
    image:
      "https://res.cloudinary.com/zinodavidoff/image/upload/c_fill,dpr_auto,h_630,w_1200/DAVIDOFF_Eyewear_3",
    robots: "index, follow",
    locale: "en_GB",
    lang: "en",
  };

  if (!getNested(() => currentPage.metatag, false)) {
    return defaultMeta;
  }

  const meta = currentPage.metatag;
  const getMetaValue = (name) => meta.find((m) => m.attributes.name === name)?.attributes?.content;

  const generatedMeta = {
    title: getMetaValue("title") || "DAVIDOFF",
    description:
      getMetaValue("description") ||
      "Discover DAVIDOFF Cool Water new campaign. DAVIDOFF : Perfumes, coffee, sunglasses, leather bags and luxury accessories.",
    image:
      getMetaValue("image_src") ||
      "//res.cloudinary.com/zinodavidoff/image/upload/ar_16:9,c_fill,g_auto,h_450/c_fill,dpr_2.0,f_auto,g_auto,q_auto/v1/2022-04/Zino_Davidoff_Look_13_028",
    robots: getMetaValue("robots") || "index, follow",
    locale: getMetaValue("og_locale") || "en_GB",
    lang: currentPage.langcode,
    canonical_url: `https://www.zinodavidoff.com${location}${search}`,
    url: `https://www.zinodavidoff.com${location}`,
  };

  // The optional share image in the CMS is leading. If it exists, don't generate a new image to overwrite it.
  if (!!getMetaValue("image_src")) {
    return Object.assign({}, meta, defaultMeta, generatedMeta);
  }

  // Build a special image with background for the product pages
  if (
    currentPage.type.target_id === "product" &&
    Array.isArray(currentPage.field_media_multiple) &&
    currentPage.field_media_multiple.length > 0
  ) {
    const productBgUrl =
      "https://res.cloudinary.com/zinodavidoff/image/upload/v1507225943/Textured-wall_cleaned_yy6tbv.jpg";
    const mediaImage = dataMapper(
      data,
      currentPage.field_media_multiple[0].target_uuid
    );
    const productImageID = getCloudinaryID(
      mediaImage.field_media_image.url,
      "zinodavidoff"
    );

    generatedMeta.image = `${shareImgBaseUrl}l_${productImageID},h_630,w_auto,c_pad/${getCloudinaryID(
      productBgUrl,
      "zinodavidoff"
    )}`;
  } else if (
    currentPage.type.target_id === "article" ||
    currentPage.type.target_id === "industry_page" ||
    currentPage.type.target_id === "homepage" ||
    currentPage.type.target_id === "category" ||
    currentPage.type.target_id === "page"
  ) {
    if (currentPage.field_media) {
      const mediaImage = dataMapper(data, currentPage.field_media.target_uuid);
      const imageID = getCloudinaryID(
        mediaImage.field_media_image.url,
        "zinodavidoff"
      );

      generatedMeta.image = `${shareImgBaseUrl}${imageID}`;
    } else if (currentPage.field_header) {
      const header = dataMapper(data, currentPage.field_header.target_uuid);
      const mediaReference = header.field_media;

      if (mediaReference) {
        const mediaImage = dataMapper(data, mediaReference.target_uuid);
        const imageID = getCloudinaryID(
          mediaImage.field_media_image.url,
          "zinodavidoff"
        );

        generatedMeta.image = `${shareImgBaseUrl}${imageID}`;
      }
    } else if (currentPage.field_industry_page_header) {
      const header = dataMapper(
        data,
        currentPage.field_industry_page_header.target_uuid
      );
      const mediaReference = header.field_media;

      if (mediaReference) {
        const mediaImage = dataMapper(data, mediaReference.target_uuid);
        const imageID = getCloudinaryID(
          mediaImage.field_media_image.url,
          "zinodavidoff"
        );

        generatedMeta.image = `${shareImgBaseUrl}${imageID}`;
      }
    }
  } else if (
    currentPage.type.target_id === "collection" &&
    currentPage.field_collection
  ) {
    const collectionTerm = dataMapper(
      data,
      currentPage.field_collection.target_uuid
    );

    if (collectionTerm && collectionTerm.field_header) {
      const header = dataMapper(data, collectionTerm.field_header.target_uuid);

      const mediaReference = header.field_media;

      if (mediaReference) {
        const mediaImage = dataMapper(data, mediaReference.target_uuid);
        const imageID = getCloudinaryID(
          mediaImage.field_media_image.url,
          "zinodavidoff"
        );

        generatedMeta.image = `${shareImgBaseUrl}${imageID}`;
      }
    }
  } else {
    const imageID = getCloudinaryID(generatedMeta.image, "zinodavidoff");
    generatedMeta.image = `${shareImgBaseUrl}${imageID}`;
  }

  return Object.assign({}, meta, defaultMeta, generatedMeta);
}

export function stripProtocol(url) {
  if (url && typeof url === 'string') {
    return url.replace("http:", "").replace("https:", "");
  }

  return false;
}

export function setHttpsProtocol(url) {
  return `https:${stripProtocol(url)}`;
}

export function hasProtocol(uri) {
  return uri.indexOf("http") > -1 || uri.indexOf("https") > -1;
}

export function formatProductName(productGroupName, variantName) {
  const variantSplit = variantName.split("*");

  return {
    groupName: `${productGroupName} ${
      variantSplit.length > 1 ? variantSplit[0] : ""
    }`,
    variantName: variantSplit.length > 1 ? variantSplit[1] : variantSplit[0],
  };
}

export function calculateImageHeight(aspectRatio, width) {
  return Math.round((aspectRatio.height * width) / aspectRatio.width);
}
