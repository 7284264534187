import React from 'react';
import styled from 'styled-components';

export default styled.div`
  @media (min-width: 768px) {
    backface-visibility: hidden;
    overflow: hidden;
    padding-top: ${(280 / 384) * 100}%;
    position: relative;
  }
`;
