import { defaultTheme } from './defaultTheme';
import { cyrillicTheme } from './cyrillicTheme';

/*
 * Pick a theme based on the language of the website.
 */
export function pickTheme(language) {
  switch(language) {
    case 'bg':
    case 'ru':
      return cyrillicTheme;
    default:
      return defaultTheme;
  }
}

export default pickTheme;
