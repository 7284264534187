import React from 'react';
import styled from 'styled-components';

export default styled.div`
  background-color: ${(props) => props.isPrivacyPolicy ? 'rgba(0,0,0,0.15)' : 'rgba(0,0,0,0.75)' };
  height: ${(props) => props.isPrivacyPolicy ? '200px' : '100%' };

  ${(props) => props.isPrivacyPolicy ? 'margin-top: calc(100vh - 200px);' : '' };
  padding: 0;
  width: 100%;
  display: flex;

  pointer-events: all; 
`;
