import React from 'react';
import styled from 'styled-components';

export default styled.figure`
  display: inline-block;
  width: 48%;
  float: ${props => props.position == 'right' ? 'right' : 'left'};
  margin: ${props => props.position == 'right' ? '0 0 1.5em 4%' : '0 4% 1.5em 0'};
  
  @media (min-width: 1024px) {
    margin-${props => props.position == 'right' ? 'right' : 'left'}: -13%;
  }
`;
