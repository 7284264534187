import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../../style-guide/Container/Container'
import { DisclaimerWrapper } from '../styled/DisclaimerWrapper';
import { DisclaimerText } from '../styled/DisclaimerText';
import { DisclaimerContent } from '../styled/DisclaimerContent';
import { ShowEnglishLink } from '../styled/ShowEnglishLink';
import { DontShowAgainButton } from '../styled/DontShowAgainButton';

export const TranslationDisclaimerView = (
  {
    translationText,
    englishLinkText,
    englishLinkUrl,
    doNotShowButtonText,
    hideDisclaimer,
    trackEnglishLink,
  }
  ) => {
  return (
    <DisclaimerWrapper>
      <Container>
        <DisclaimerText>
          <DisclaimerContent dangerouslySetInnerHTML={{ __html: translationText}} />
          <ShowEnglishLink to={englishLinkUrl} onClick={trackEnglishLink}>
            {englishLinkText}
          </ShowEnglishLink>
        </DisclaimerText>
        <DontShowAgainButton onClick={hideDisclaimer}>
          {doNotShowButtonText}
        </DontShowAgainButton>
      </Container>
    </DisclaimerWrapper>
  );
};

TranslationDisclaimerView.propTypes = {
  translationText: PropTypes.string.isRequired,
  englishLinkText: PropTypes.string.isRequired,
  englishLinkUrl: PropTypes.string.isRequired,
  doNotShowButtonText: PropTypes.string.isRequired,
  hideDisclaimer: PropTypes.func.isRequired,
};

export default TranslationDisclaimerView;
