import React from "react";
import getNested from "get-nested";
import { CloudinaryImage } from "../image/CloudinaryImage/container";
import applyImageCrop from "../../../misc/cropping/applyImageCrop";

const MediaImage = (props) => {
  if (!props.mediaImage) {
    return null;
  }
  const { settings, sources, ...passProps } = props;

  const crops =
    getNested(() => props.mediaImage.field_media_image.computed.crops) || {};

  let settingsCrop;
  if (crops.length > 0) {
    settingsCrop = {
      transformation: [
        {
          ...applyImageCrop(props.settings, crops),
        },
        {
          crop: "fill",
          height: props.settings.height ? props.settings.height : "",
          width: props.settings.width ? props.settings.width : "",
          quality: props.settings.quality ? props.settings.quality : null,
        },
      ],
    };
  } else {
    settingsCrop = applyImageCrop(props.settings, crops);
  }

  const appliedSources = props.sources.map((source) => {
    let extraTransform = {};

    if (crops.length > 0) {
      extraTransform = {
        crop: "fill",
        height: source.settings.height ? source.settings.height : "",
        width: source.settings.width ? source.settings.width : "",
        quality: source.settings.quality ? source.settings.quality : null,
      };
    }

    let returnValue = {
      ...source,
      settings: {
        transformation: [
          {
            ...source.settings,
            ...applyImageCrop(source.settings, crops),
          },
          { ...extraTransform },
        ],
      },
    };

    return returnValue;
  });

  const appliedSettings = { ...settings, ...settingsCrop };

  return (
    <CloudinaryImage
      src={props.mediaImage.field_media_image.url}
      alt={props.mediaImage.field_media_image.alt}
      title={props.mediaImage.field_media_image.title}
      settings={appliedSettings}
      sources={appliedSources}
      {...passProps}
    />
  );
};

export default MediaImage;
