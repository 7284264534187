import * as React from 'react';
import { CloudinaryConsumer } from '../context';
import { Cloudinary, Transformation } from 'cloudinary-core';

export function withCloudinary(Component) {
  return function ComponentWithCloudinary(props) {
    const shouldTransform = props.settings.transformation ? props.settings.transformation.length > 0 : false;
    return (
      <CloudinaryConsumer>
        {({
          cl,
          initCloudinary,
          baseImageSettings,
          previewImageSettings,
          cloudName,
        }) => (
          <Component
            {...props}
            baseImageSettings={shouldTransform ? null : baseImageSettings}
            cl={cl}
            cloudName={cloudName}
            initCloudinary={initCloudinary}
            previewImageSettings={previewImageSettings}
          />
        )}
      </CloudinaryConsumer>
    );
  };
}
