import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../Accordion/styled/Accordion';
import { IndicatorList } from './styled/IndicatorList';
import mapper, { dataMapper } from '../../index'

const TasteIndicator = ({ paragraph, data }) => {
  return (
    <Accordion title={paragraph.field_title} isOpen={paragraph.field_enabled}>
      <IndicatorList>
        {paragraph.field_body_taste_indicator.map((indicator) => mapper(data, false, indicator.target_uuid))}
      </IndicatorList>
    </Accordion>
  );
};

TasteIndicator.propTypes = {
  paragraph: PropTypes.shape({
    field_body_taste_indicator: PropTypes.arrayOf(
      PropTypes.shape({
        target_uuid: PropTypes.string.isRequired,
      })
    ).isRequired,
    field_title: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape().isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export { TasteIndicator };
