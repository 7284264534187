import React from 'react';
import styled from 'styled-components';

export default styled.button`
  background: transparent;
  border: none;
  bottom: 10px;
  display: block;
  height: 50px;
  left: 50%;
  outline: none;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 50px;
  z-index: 12;
  
  svg {
    fill: #ffffff;
    height: 18px;
    pointer-events: none;
    transform: rotate(90deg);
    width: 18px;
  }
`;
