import React from 'react';
import styled from 'styled-components';

export default styled.div`
  position: relative;
  overflow: hidden;
  
  @media (min-width: 1024px) {
    //height: 61vh;
  }
  
  img {
    width: 100%;
  }
`;
