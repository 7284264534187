import React from "react";
import PropTypes from "prop-types";
import getNested from "get-nested";
import ProductBlock from "./styled/ProductBlock";
import ProductLink from "./styled/ProductLink";
import ProductImageWrapper from "./styled/ProductImageWrapper";
import ProductImage from "./styled/ProductImage";
import ProductTitle from "./styled/ProductTitle";
import { dataMapper } from "../../../index";
import { formatProductName } from "../../../../misc/index";
import SiteStore from "../../../../stores/SiteStore";
import Price from "../../ProductPrice";

const Product = ({ product, data }) => {
  const media = product.field_media_multiple;

  // if (!Array.isArray(media) || media.length === 0) {
  //   return null;
  // }

  const mediaReference = media[0];
  const productGroup = dataMapper(
    data,
    product.field_product_group.target_uuid
  );
  const productCategory = dataMapper(data, productGroup.field_category?.target_uuid);
  const isCoffee = productCategory?.revision_id === 26
  if (!Array.isArray(media) || media.length === 0) {
    return null;
  }
  const productTilte = formatProductName(
    productGroup.field_display_text,
    product.field_display_text
  );

  let buyButton = undefined;
  if (product.field_buy_buttons) {
    const productBuyButton = dataMapper(
      data,
      product.field_buy_buttons.target_uuid
    );
    const buyButtonReferences = getNested(
      () => productBuyButton.field_buy_buttons,
      []
    );
    const buyButtons = buyButtonReferences.map((reference) =>
      dataMapper(data, reference.target_uuid)
    );
    buyButton = buyButtons.find(
      (paragraph) =>
        paragraph.field_country.toLowerCase() ===
        SiteStore.getInstance().country.toLowerCase()
    );
  }

  const settings = {
    height: 385,
    width: 385,
    crop: "fill_pad",
    gravity: "auto",
  };

  const sources = [
    {
      settings,
      media: "(min-width: 0)",
    },
  ];

  return (
    <ProductBlock>
      <ProductLink to={product && product.__hn ? product.__hn.url : '/'}>
        <ProductImageWrapper isCoffee={isCoffee}>
          <ProductImage
            imageType="ProductImage"
            data={data}
            mediaReference={mediaReference}
            settings={settings}
            sources={sources}
            lazyLoad={false}
          />
        </ProductImageWrapper>
        <ProductTitle>
          {productTilte.groupName}
          <span>{productTilte.variantName}</span>
        </ProductTitle>
        {buyButton && buyButton.field_price && (
          <Price alignText="center">{buyButton.field_price}</Price>
        )}
      </ProductLink>
    </ProductBlock>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    __hn: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    field_media_multiple: PropTypes.array,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default Product;
