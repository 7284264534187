import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaData = ({ meta }) => {
  return (
    <Helmet
      title={meta.title}
      link={[
        {
          rel: 'canonical',
          href: meta.canonical_url,
        },
      ]}
      meta={[
        {
          name: 'robots',
          content: meta.robots,
        },
        {
          property: 'og:locale',
          content: meta.locale,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: meta.title,
        },
        {
          property: 'og:description',
          content: meta.description,
        },
        {
          property: 'og:url',
          content: meta.url,
        },
        {
          property: 'og:site_name',
          content: 'DAVIDOFF',
        },
        {
          property: 'og:image',
          content: meta.image,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: meta.title,
        },
        {
          name: 'twitter:description',
          content: meta.description,
        },
        {
          name: 'twitter:image',
          content: meta.image,
        },
      ]}
    />
  );
};

MetaData.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    canonical_url: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
};

export default MetaData;
