import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import getNested from "get-nested";
import root from "window-or-global";
import { withCookies, Cookies } from "react-cookie";
import TranslationStore from "../../../../stores/TranslationStore";
import CookieMessage from "../component/index";

@inject("translationStore")
@observer
class CookieMessageComponent extends Component {
  static propTypes = {
    lang: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    settings: PropTypes.shape({
      general: PropTypes.shape({}).isRequired,
    }).isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    translationStore: PropTypes.instanceOf(TranslationStore),
  };

  constructor(props) {
    super(props);

    this.state = {
      showCookieMessage: false,
      cookieMessage: "",
      buttonText: "",
    };

    // Bind eventListeners
    this.optIn = this.optIn.bind(this);
    this.checkCookie = this.checkCookie.bind(this);
  }

  componentDidMount() {
    !this.props.isPrivacyPolicy &&
      document.body.classList.add("prohibit-scroll");
    this.checkCookie();

    if (root.dataLayer) {
      root.dataLayer.push({
        event: "cookie-optin",
        "cookie-optin": true,
      });
    }
  }

  optIn(ev, val) {
    !this.props.isPrivacyPolicy &&
      document.body.classList.remove("prohibit-scroll");
    const { cookies } = this.props;

    let cookieLevel = "OPTIMAL";

    if (ev) {
      ev.preventDefault();

      if (val) {
        cookieLevel = val;
      }
    } else {
      cookieLevel = cookies.get("DavidoffCookiePolicy");
    }

    const d = new Date();

    cookies.set("DavidoffCookiePolicy", cookieLevel, {
      expires: new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()),
      path: "/",
    });

    this.setState({
      showCookieMessage: false,
    });

    window.dataLayer.push({
      event:
        cookieLevel === "OPTIMAL"
          ? `cookie_bar_opt_in_optimal`
          : `cookie_bar_opt_in_limited`,
    });
  }

  checkCookie() {
    const { cookies } = this.props;

    if (cookies.get("DavidoffCookiePolicy")) {
      this.optIn();
    }

    return this.setState({
      showCookieMessage: !cookies.get("DavidoffCookiePolicy"),
    });
  }

  render() {
    if (!this.state.showCookieMessage) {
      return null;
    }

    const { settings, lang, translationStore, isPrivacyPolicy } = this.props;
    const cookieButtonText = getNested(
      () => settings.general[lang].cookie_button_text,
      ""
    );
    const cookieText = getNested(() => settings.general[lang].cookie_text, "");
    const manageCookieTitle = getNested(
      () => settings.general[lang].manage_cookie_title,
      ""
    );
    const manageCookieButtonText = getNested(
      () => settings.general[lang].manage_cookie_button_text,
      ""
    );
    const manageCookieOptionsButtonText = getNested(
      () => settings.general[lang].manage_cookie_options_button_text,
      ""
    );
    const manageCookieAcceptAllButtonText = getNested(
      () => settings.general[lang].manage_cookie_accept_all_button_text,
      ""
    );
    const cookieLevelLow = getNested(
      () => settings.general[lang].cookie_level_1_text,
      ""
    );
    const cookieLevelMedium = getNested(
      () => settings.general[lang].cookie_level_2_text,
      ""
    );
    const cookieLevelHigh = getNested(
      () => settings.general[lang].cookie_level_3_text,
      ""
    );

    const closeText = translationStore.translate("close");

    return (
      <CookieMessage
        buttonText={cookieButtonText}
        cookieText={cookieText}
        manageCookieTitle={manageCookieTitle}
        manageCookieButtonText={manageCookieButtonText}
        manageCookieOptionsButtonText={manageCookieOptionsButtonText}
        manageCookieAcceptAllButtonText={manageCookieAcceptAllButtonText}
        cookieLevelTexts={[cookieLevelLow, cookieLevelMedium, cookieLevelHigh]}
        optIn={this.optIn}
        closeBar={this.closeBar}
        closeText={closeText}
        isPrivacyPolicy={isPrivacyPolicy}
      />
    );
  }
}

export default withCookies(CookieMessageComponent);
