import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { stripProtocol } from '../../../../../misc/index';
import TopBarItemText from './styled/TopBarItemText';
import MenuItemLink from './styled/MenuItemLink';
import MenuListItem from './styled/MenuListItem';

class StandardMenuItem extends React.Component {
  render() {
    const { menuItem, children, className, title, onClick, active } = this.props;
    const props = {};

    if(menuItem.external) {
      props.target = '_blank';
    }

    const url =  stripProtocol(getNested(() => menuItem.uri, '/')) || '/';

    return (
      <MenuListItem className={className} extraOffset={getNested(() => menuItem.extra_margin, false)}>
        {!getNested(() => menuItem.parent_style, false) &&
          <MenuItemLink
            to={url}
            onClick={onClick}
            data-active={active}
            activeClassName='active'
            {...props}
          >
            <TopBarItemText>
              {title || menuItem.title}
            </TopBarItemText>
          </MenuItemLink>
        }
        {children}
      </MenuListItem>
    );
  }
}

StandardMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    title:PropTypes.string.isRequired,
    uri: PropTypes.string,
    external: PropTypes.bool.isRequired,
    extra_margin: PropTypes.bool,
    parent_style: PropTypes.bool,
  }).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.bool,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

StandardMenuItem.defaultProps = {
  children: null,
  className: '',
  title: false,
  onClick: () => true,
  active: false,
};

export default StandardMenuItem;
