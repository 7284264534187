import React from 'react';
import PropTypes from 'prop-types';
// Styled components
import IntroWrapper from './styled/IntroWrapper';
import IntroInner from './styled/IntroInner';
import IntroHeading from './styled/IntroHeading';
import IntroChildren from './styled/IntroChildren';

const StoreLocatorIntroduction = ({showIntro, content, children}) => {
  return (
    <IntroWrapper showIntro={showIntro}>
      <IntroInner>
        <IntroHeading dangerouslySetInnerHTML={{ __html: content }} />
        <IntroChildren>
          {children}
        </IntroChildren>
      </IntroInner>
    </IntroWrapper>
  );
};

StoreLocatorIntroduction.propTypes = {
  showIntro: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default StoreLocatorIntroduction;
