import React from 'react';
import PropTypes from 'prop-types';
// Styled
import OptionItem from './styled/OptionItem';
import OptionLabel from './styled/OptionLabel';
import OptionInput from './styled/OptionInput';
import OptionAmount from './styled/OptionAmount';

const ProductFilterOption = ({option, handler, selected}) =>
    <OptionItem>
      <OptionLabel htmlFor={option.key} selected={selected}>
        <OptionInput
          id={option.key}
          name={option.key}
          onChange={handler}
          checked={selected}
          type={option.type}
          value={option.key}
        />
        {`${option.label}`} <OptionAmount>{`(${option.numberOfProducts})`}</OptionAmount>
      </OptionLabel>
    </OptionItem>;

ProductFilterOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    numberOfProducts: PropTypes.number.isRequired,
  }).isRequired,
  handler: PropTypes.func.isRequired,
};
ProductFilterOption.defaultProps = {};

export default ProductFilterOption;
