import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import Button from '../../../style-guide/Button/Button';

export default styled.form`
  max-width: 385px;
  margin: 0 auto;
  
  input {
    ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '16px')}
    -webkit-appearance: none;
    padding: 0 32px;
    height: 60px;
    line-height: 60px;
    color: ${davidoff.colors.brown};
    border: 1px solid #ece8e4;
    border-radius: 3px;
    width: 100%;
    
    @media (min-width: 768px) {
      width: ${207 / 385 * 100}%;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    
    &::placeholder {
      color: ${davidoff.colors.brown};
    }
  }
  
  ${Button} {
    border-radius: 3px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    
    @media (min-width: 768px) {
      padding: 0 20px;
      width: ${177 / 385 * 100}%;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  
`;
