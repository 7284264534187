import React from 'react';
import styled from 'styled-components';

export default styled.div`
  font-size: 14px;
  line-height: 1.556;
  max-width: 600px;
  margin: 0 auto 15px;
  text-align: center;
  width: 100%;
  
  @media (min-width: 768px) {
    font-size: 18px;
  }
  
  ${props => props.bottom ? `
    margin-top: 10px;
    width: 94%;
    
    @media (min-width: 768px) {
      margin-top: 35px;
      margin-bottom: 25px;
      width: auto;
    }
  ` : ``}
`;
