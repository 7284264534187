import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import InfoWindowListItem from './InfoWindowListItem';

export default styled.span`
  position: absolute;
  left: 0;
  top: 0;
  
  svg {
    fill: ${davidoff.colors.brown};
    height: 14px !important;
    width: 14px !important;
    
    ${InfoWindowListItem}:first-child & {
      height: 16px !important;
      width: 16px !important;
    }
  }
`;
