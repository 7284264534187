import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import linkStyles from '../../../../shared-styles/link-styles.js';
import davidoff from '../../../../themes/davidoff';

export default styled(Link)`
  ${props => linkStyles.link(props)}
  
  &:hover {
    color: ${davidoff.colors.orangeLink};
  }
  
  &:hover:after {
    @media (min-width: 1024px) {
      margin-left: 15px;
    }
  }
`;
