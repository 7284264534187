import { Cloudinary } from 'cloudinary-core';
import * as React from 'react';
import { LazyLoadProps } from 'react-lazyload';
import {
  InjectedCloudinaryProps,
  withCloudinary,
} from '../../hoc/withCloudinary';
import Image from '../../Image/component';
import { ImageWrapper as DefaultImageWrapper } from '../styled/ImageWrapper';
import { getCloudinaryID } from '../../helpers/getCloudinaryID';
import LazyLoad from '../../LazyLoad';

const fallbackCl = Cloudinary.new({
  secure: true,
  cloud_name: 'zinodavidoff',
});

export const cl = fallbackCl;

function resolveRetinaCropDimensions(settings = {}, dpr = 1) {
  let retinaCropSettings = {};

  if(settings.crop === 'crop' && settings.height && settings.width) {
    const retinaHeight = Math.round(settings.height / dpr);
    const retinaWidth = Math.round(settings.width / dpr);

    retinaCropSettings = {
      height: retinaHeight,
      width: retinaWidth,
    };
  }

  return retinaCropSettings;
}

class CloudinaryImageComponent extends React.Component {
  constructor(props) {
    super(props);

    props.initCloudinary(props.src);
  }

  componentDidCatch(error, errorInfo) {
    console.log('error happend in the cloudinary image: ', error);
    console.log('this info was provided: ', errorInfo);
  }

  formatSources = () => {
    const { sources = [], cl: clProps, baseImageSettings, cloudName, src } = this.props;

    let cl = clProps || fallbackCl;

    if (cl) {
      return sources.map(
        (source) => {
          const cloudinaryID = getCloudinaryID(src, cloudName);
          // Manage
          const transformationOptions = {
            ...baseImageSettings,
            gravity:
              source.settings && source.settings.x ? 'xy_center' : 'auto',
            ...source.settings,
          };

          const url = cl.url(cloudinaryID, transformationOptions);
          
          /**
           * This is a Davidoff specific edit to the cloudinary image component.
           * When cropping with a dedicated crop, dimensions have to change accordingly.
           **/

          const dpr = 2;
          const retinaCropDimensions = resolveRetinaCropDimensions(transformationOptions, dpr);

          const urlRetina = cl.url(cloudinaryID, {
            ...transformationOptions,
            ...retinaCropDimensions,
            dpr,
          });

          return {
            srcSet: `${urlRetina} 2x, ${url} 1x`,
            media: source.media,
          };
        },
      );
    }
    console.warn(
      'Cloudinary was nog initialized yet. the sources could not be formatted.',
    );
    return [];
  };

  formatPreviewSources = () => {
    const { sources = [], cl: clProps, previewImageSettings, cloudName, src } = this.props;

    let cl = clProps || fallbackCl;

    if (cl) {
      return sources.map(
        (source) => {
          const cloudinaryID = getCloudinaryID(src, cloudName);
          // Manage
          const transformationOptions = {
            ...source.settings,
            ...previewImageSettings,
            gravity:
              source.settings && source.settings.x ? 'xy_center' : 'auto',
          };

          const url = cl.url(cloudinaryID, transformationOptions);

          return {
            srcSet: `${url} 1x`,
            media: source.media,
          };
        },
      );
    }
    console.warn(
      'Cloudinary was nog initialized yet. the sources could not be formatted.',
    );
    return [];
  };

  formatImageSrc = () => {
    const { cl: clProps, cloudName, imageType, src, baseImageSettings, settings } = this.props;
    let cl = clProps || fallbackCl

    if (cl) {
      return cl.url(getCloudinaryID(src, cloudName), {
        ...baseImageSettings,
        ...settings,
      });
    }

    console.warn(
      'Cloudinary was nog initialized yet. the url could not be created',
    );
    return '';
  };

  formatPreviewImageSrc = () => {
    const { cl: clProps, cloudName, src, previewImageSettings, settings } = this.props;

    let cl = clProps || fallbackCl

    if (cl) {
      return cl.url(getCloudinaryID(src, cloudName), {
        ...previewImageSettings,
        ...settings,
      });
    }

    console.warn(
      'Cloudinary was nog initialized yet. the url could not be created',
    );
    return '';
  };

  render() {
    const {
      src,
      alt,
      lazyLoad = true,
      lazyLoadProps,
      title,
      Placeholder,
      imageType,
      className,
    } = this.props;

    if (src.includes('cloudinary')) {
      const imageSources = this.formatSources();
      const PictureWrapper = lazyLoad ? LazyLoad : React.Fragment;
      const image = (
        <Image
          className={className}
          imageType={imageType}
          src={this.formatImageSrc()}
          sources={imageSources}
          alt={alt}
          title={title}
        />
      );

      return (
        <React.Fragment>
          <PictureWrapper
            {...(lazyLoad
              ? {
                  className,
                  lazyLoadProps: {
                    ...lazyLoadProps,
                  },
                  sources: this.formatPreviewSources(),
                  src: this.formatPreviewImageSrc(),
                  placeHolder: Placeholder,
                }
              : {})}
          >
            {image}
          </PictureWrapper>
          <noscript>{image}</noscript>
        </React.Fragment>

      );
    }

    return <Image src={src} alt={alt} className={className} />;
  }
}

export const CloudinaryImage = withCloudinary(CloudinaryImageComponent);

export default CloudinaryImage;
