import { observable, action, computed } from 'mobx';
import getNested from 'get-nested';
import queryString from 'query-string';
import geodist from 'geodist';
import API from '../lib/react-drupal/API';
import { dataMapper } from '../components/index';

const storeLocatorAPIBase = '/store-locator-view';

const storeRange = 50;

class StoreLocatorStore {
  static instance;

  constructor() {
    this.getFilteredList = this.getFilteredList.bind(this);
    this.inFilter = this.inFilter.bind(this);
    this.fetchStores = this.fetchStores.bind(this);
    this.setClosestStore = this.setClosestStore.bind(this);
    this.selectFilters = this.selectFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.matchItem = this.matchItem.bind(this);
    this.formatFilter = this.formatFilter.bind(this);
    this.hasSearchType = this.hasSearchType.bind(this);
  }

  static getInstance() {
    if(!StoreLocatorStore.instance) {
      StoreLocatorStore.instance = new StoreLocatorStore();
    }

    return StoreLocatorStore.instance;
  }

  // Setup observables
  @observable navigatorLocation = false;
  @observable itemList = [];
  @observable displayedItems = [];
  @observable data = {};
  @observable filters = []; // filters of the page
  @observable displayedFilters = {};
  @observable closestStore = false;
  @observable langcode = '';
  @observable hasQueryParamFilters = false;
  @observable isLoadingStores = false;
  @observable selectedFilters = [];
  @observable currentLocation = {
    lat: 47.551785,
    lng: 7.592743,
  }; // Lat long of the current location
  @observable bounds = {
    nw: {
      lat: 0,
      lng: 0,
    },
    se: {
      lat: 0,
      lng: 0,
    },
  }; // Map bounds (the north, south, west and east coordinate of the visible map
  @observable locationText = '';
  @observable city = '';
  @observable country = '';
  @observable openedMarker = '';
  @observable showIntro = true;
  @observable searchType = [];

  @action
  setOpenMarkerValue(value) {
    this.openedMarker = value;
  }

  @action
  setLocationValue(value) {
    this.locationText = value;
  }

  @action
  setupFilterOptions() {
    // get Options from the product category field
    this.displayedFilters = {};
    const categoryOptions = this.filters.find(filter => filter.id === 'field_product_category_target_id');

    if(!getNested(() => categoryOptions.options, false)) {
      return false;
    }

    categoryOptions.options.forEach((option) => {
      // make all filters selected by default.
      if(
        !this.hasQueryParamFilters &&
        this.selectedFilters.indexOf(option) === -1
      ) {
        this.selectedFilters.push(option);
      }

      if(!this.inFilter(option)) {
        const term = dataMapper(this.data, option);
        const filter = this.formatFilter(option, term.name, {}, term);

        // Check if there is a parent Term
        if(getNested(() => term.parent[0].target_uuid, false)) {
          // get the parent and fill that box
          const parentID = term.parent[0].target_uuid;
          const parentTerm = dataMapper(this.data, parentID);
          const parentFilters = {};

          parentFilters[option] = filter;

          if(!getNested(() => this.displayedFilters[parentID], false)) {
            if(
              !this.hasQueryParamFilters &&
              this.selectedFilters.indexOf(parentID) === -1
            ) {
              this.selectedFilters.push(parentID);
            }
            this.displayedFilters[parentID] = this.formatFilter(parentID, parentTerm.name, parentFilters, parentTerm);
          } else {
            this.displayedFilters[parentID].filters[option] = filter;
          }
        } else {
          this.displayedFilters[option] = filter;
        }
      }
    });

    return true;
  }

  formatFilter(key, name, filters = {}, term = {}) {
    let selected = true;

    if(this.selectedFilters.length > 0) {
      selected = this.selectedFilters.some(filter => key === filter);
    }

    return {
      key,
      name,
      filters,
      selected, // filters are on by default
      item: term,
    };
  }

  selectFilters() {
    // Add filter to the selected filters
    const updatedFilters = this.displayedFilters;
    // update the filter list based on the newly selected filters
    // find the filter in the displayedFilters
    // Loop over all filters and check if they are in the
    // array.
    Object.keys(updatedFilters).forEach((filterKey) => {
      updatedFilters[filterKey].selected = this.selectedFilters.some(key => key === filterKey);

      // Check deep filters.
      Object.keys(updatedFilters[filterKey].filters).forEach((deepFilterKey) => {
        updatedFilters[filterKey].filters[deepFilterKey].selected = this.selectedFilters.some(key => key === deepFilterKey);
      });
    });

    this.updateFilters(updatedFilters);

    return updatedFilters;
  }

  /*
   * Adds a selected filter
   */
  @action
  addSelectedFilters(key) {
    this.selectedFilters.push(key);
    // Check if this filter is the top level
    // If the item is toplevel we should select all it's children as well
    if(getNested(() => this.displayedFilters[key], false)) {
      Object.keys(this.displayedFilters[key].filters).forEach((filter) => {
        if(this.selectedFilters.indexOf(filter) === -1) {
          this.selectedFilters.push(filter);
        }
      });
    }
  }

  /*
   * Removes the selected filters and triggers a selected check on a deep level (nested filters)
   */
  @action
  removeSelectedFilters(key) {
    // remove it if it's unchecked
    this.removeItemFromFilter(key);
    // Check if it's a top level item
    // If it's top level also deselect all the child categories
    if(getNested(() => this.displayedFilters[key], false)) {
      Object.keys(this.displayedFilters[key].filters).forEach((filter) => {
        this.removeItemFromFilter(filter);
      });
    }

    // If it's a deep level also remove the toplevel since that's not selected anymore.
    if(!getNested(() => this.displayedFilters[key])) {
      const mainItem = Object.keys(this.displayedFilters)
        .find(mainKey => Object.keys(this.displayedFilters[mainKey].filters)
          .some(subKey => subKey === key));

      this.removeItemFromFilter(mainItem);
    }
  }

  @action
  removeItemFromFilter(key) {
    const index = this.selectedFilters.indexOf(key);

    if(index > -1) {
      this.selectedFilters.splice(index, 1);
    }
  }

  /*
   * Updates the filters and the URL and refreshes the list of displayed items afterwards.
   */
  @action
  updateFilters(newFilters) {
    this.displayedFilters = newFilters;
    // Update the item list based on the newly selected filters
    window.history.pushState('', '', `?${queryString.stringify({ selectedFilters: this.selectedFilters })}`);
    this.updateDisplayList();

    return newFilters;
  }

  @action
  resetFilters() {
    this.selectedFilters.clear();
  }

  // Checks if an item is already in the filters or not.
  // Returns true when a filters is already in the displayed filters
  inFilter(key) {
    // first check if the filter is on the first line of filters
    if(getNested(() => this.displayedFilters[key], false)) {
      return true;
    }

    // Loop over all keys and check if our key is in the filters of the children
    return Object.keys(this.displayedFilters).some(filterID =>
      getNested(() => this.displayedFilters[filterID].filters[key], false));
  }

  /*
   * Filters the list of fetches items from the server
   */
  @computed
  get filteredItems() {
    return this.getFilteredList();
  }

  /*
   * Finds the closest store to the current location.
   */
  @action
  setClosestStore() {
    const stores = this.displayedItems.slice();
    stores.sort((a, b) => {
      const distanceA = geodist({ lat: this.currentLocation.lat, lon: this.currentLocation.lng }, {
        lat: a.field_coordinates.lat,
        lon: a.field_coordinates.lon,
      }, { exact: true, unit: 'km' });
      const distanceB = geodist({ lat: this.currentLocation.lat, lon: this.currentLocation.lng }, {
        lat: b.field_coordinates.lat,
        lon: b.field_coordinates.lon,
      }, { exact: true, unit: 'km' });

      return distanceA - distanceB;
    });

    this.closestStore = stores[0];
  }

  // Get the stores.
  // If the bounds are incorrect return false
  fetchStores(country) {
    if(!country || country === '') {
      return false;
    }
    // Do a api call to the correct url
    const countryName = country || '';
    this.isLoadingStores = true;
    const urlLanguage = this.langcode === 'en' ? '' : `/${this.langcode}`;

    // CMSa
    return API.getPage('', `${urlLanguage}${storeLocatorAPIBase}?country=${countryName}`)
      .then((stores) => {
        this.setStores(stores);
        this.isLoadingStores = false; // done loading... we are ready to do all the ohter amizing things that where waiting now.
      });
  }

  @computed
  get closestStoreDistance() {
    if(!this.closestStore) {
      return false;
    }

    return geodist({
      lat: this.currentLocation.lat,
      lon: this.currentLocation.lng,
    }, {
      lat: this.closestStore.field_coordinates.lat,
      lon: this.closestStore.field_coordinates.lon,
    }, { exact: true, unit: 'km' });
  }

  @computed
  get hasStoresInRange() {
    const closestStoreDistance = this.closestStoreDistance;

    if(closestStoreDistance === false) {
      return false;
    }

    return this.closestStoreDistance < storeRange;
  }

  @action
  setStores(storeData) {
    this.data = storeData;
    // Map the stores from the newly fetched view to the displaylist
    const urlLanguage = this.langcode === 'en' ? '' : `/${this.langcode}`;
    const storeView = dataMapper(storeData, storeData.paths[`${urlLanguage}${storeLocatorAPIBase}?country=${this.country ? this.country : ''}`]);
    this.itemList = getNested(() => storeView.results, []).map(storeID => dataMapper(storeData, storeID));

    // After setting them trigger the update view to apply the filtering
    this.updateDisplayList();
  }

  @action
  setLocation(location, city = false, country = false) {
    this.showIntro = false;
    this.currentLocation = location;
    // New location... new stores...
    this.country = country;
    this.city = city;
    this.fetchStores(country);
  }

  /*
   * Filter the list of items based on the selected filters
   */
  getFilteredList() {
    // No filters selected? return everything
    // This feels counterintuitive.. I comment it out for now...
    // Let's see if we get comments on this.
    // if(this.selectedFilters.slice().length === 0) {
    //   // this.displayedItems = this.itemList;
    //   return this.itemList;
    // }
    return this.itemList.filter(item => this.matchItem(item));
  }

  // Check if a item matches the selected filters or not.
  matchItem(item) {
    // check if the product category is in the
    return item.field_product_category.some(category => this.selectedFilters.indexOf(category.target_uuid) > -1);
  }

  /*
   * updateDisplayList:
   * - sets the state of the displayed items to a section of the filtered items,
   * based on the pagination state
   */
  @action
  updateDisplayList() {
    this.displayedItems = this.filteredItems;
    // set the closest store
    this.setClosestStore();
  }

  /*
   * Checks if the searchtypes contain a certain key...
   * used to determine what kind of search somebody did... e.g. country, continent, city
   */
  hasSearchType(name) {
    return this.searchType.some(type => type === name);
  }
}

export default StoreLocatorStore;
