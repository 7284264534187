import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import root from 'window-or-global';
import dayjs from 'dayjs';
import getNested from 'get-nested';
import md5 from 'md5';
import { withCookies, Cookies } from 'react-cookie';
import AgeGate from '../component/index';

@inject('translationStore')
@observer
  class AgeGateContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: {},
      currentValue: '',
      fields: ['country', 'date'],
      validation: {
        message: '',
        dateValid: false,
        countryValid: false,
        dataValidated: false,
      },
    };

    // Ref of the form input
    this.input = false;

    // Bind all function to the component
    this.validateAge = this.validateAge.bind(this);
    this.dateFormat = this.dateFormat.bind(this);
    this.setValidationState = this.setValidationState.bind(this);
    this.updateSelect = this.updateSelect.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.referenceInput = this.referenceInput.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  componentDidMount() {
    // fetch the countries and the restrictions from the server
    if (root.document) {
      // Set correct headers
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      // Set correct options
      const options = {
        headers: headers,
        method: 'POST',
        content_type: 'application/json',
        body: JSON.stringify({
          language: this.props.language,
        }),
      };

      // Fetch the data
      return fetch('/AgeCountries', options)
        .then(response => response.json())
        .then(countries => {
          this.setState({
            countries,
          });
          return true;
        });
    }
  }

  setValidationState(
    field,
    valid,
    message = 'You must be of legal age of drinking to view this content. Please enter your country and date of birth',
  ) {
    const validation = {
      dataValidated: true,
      message: this.props.translationStore.translate(message),
    };

    this.state.fields.forEach(fieldName => {
      validation[`${fieldName}Valid`] =
        field === fieldName
          ? valid
          : this.state.validation[`${fieldName}Valid`];
    });

    this.setState({
      validation,
    });

    return valid;
  }

  dateFormat() {
    const value = this.input.value;
    value.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');

    this.setState({
      value,
    });
  }

  validateAge() {
    // First check if we choose a Country.
    const country = getNested(
      () => this.state.countries[this.state.currentValue],
      false,
    );
    if (!country) {
      this.setValidationState(
        'country',
        false,
        'You must be of legal age of drinking to view this content. Please enter your country and date of birth',
      );

      // this.setValidationState('country', false, 'Please select a country from the list.');
      return false;
    } else {
      this.setValidationState('country', true, 'valid country');
    }

    if (country.age === 99999) {
      this.setValidationState(
        'date',
        false,
        'You must be of legal age of drinking to view this content. Please enter your country and date of birth',
      );

      // this.setValidationState('date', false, 'Sorry, we are not allowed to display this content in your country.');

      return false;
    }

    // Check if value matches the pattern DD-MM-YYYY
    const value = this.input.value;
    const splittedValue = value.split('-');
    // Check if the correct pattern is met.
    if (
      splittedValue.length !== 3 ||
      splittedValue[0].length !== 2 ||
      splittedValue[1].length !== 2 ||
      splittedValue[2].length !== 4
    ) {
      this.setValidationState(
        'date',
        false,
        'You must be of legal age of drinking to view this content. Please enter your country and date of birth',
      );
      // this.setValidationState('date', false, 'Please write your date of birth as: DD-MM-YYYY');

      return false;
    }

    // Check if the value is a valid date
    // const dateOfBirth = moment(new Date(splittedValue[2], splittedValue[1], splittedValue[0]));
    const today = dayjs(new Date());
    const dateOfBirth = dayjs(splittedValue.reverse().join('-'));

    // Check if the date of birth is a valid date
    if (!dateOfBirth.isValid()) {
      this.setValidationState(
        'date',
        false,
        'You must be of legal age of drinking to view this content. Please enter your country and date of birth',
      );
      // this.setValidationState('date', false, 'Please enter a valid date in the date field');

      return false;
    }

    // Check if this person has the required age.
    const age = today.diff(dateOfBirth, 'years', true);

    // Is age within the correct age range...
    if (age >= 130) {
      this.setValidationState(
        'date',
        false,
        `You must be of legal age of drinking to view this content. Please enter your country and date of birth`,
      );
      // this.setValidationState('date', false, `Humans don't get older than 130 years.`);

      return false;
    }

    // Check if some one has the required age.
    if (age < country.age && country.age !== 99999) {
      this.setValidationState(
        'date',
        false,
        `You must be of legal age of drinking to view this content. Please enter your country and date of birth`,
      );
      // this.setValidationState('date', false, `You should be at least ${country.age} years old to view this content`);

      return false;
    }

    this.setValidationState('date', true, '');

    return true;
  }

  submitForm(e) {
    const { cookies } = this.props;
    e.preventDefault();

    // Check if the form is valid again
    // If valid date and country
    if (this.validateAge()) {
      // Set cookies etc.
      cookies.set(
        'DavidoffAgegatePassed',
        md5('ZinoDavidoff-age-gate-oke-passed'),
        {
          maxAge: 60 * 60 * 24 * 1,
          path: '/',
        },
      );

      // make sure to rerender the component above
      window.location.reload();

      return true;
    }

    // Return false when we have a error.
    // The validation method should show the correct message
    // Return false.
    return false;
  }

  updateSelect(data) {
    this.setValidationState('country', true, '');
    this.setState({
      currentValue: data.value,
    });
  }

  referenceInput(input) {
    return (this.input = input);
  }

  formatDate(ev) {
    let newValue = ev.target.value;
    const key = ev.which || ev.keyCode;

    if (key !== 8) {
      const parts = newValue.split('-');

      parts[0] = parts[0].slice(0, 2);
      if (parts[0].length === 2) {
        parts[0] += '-';
      }

      if (parts[1]) {
        parts[1] = parts[1].slice(0, 2);

        if (parts[1].length === 2) {
          parts[1] += '-';
        }
      }
      if (parts[2]) {
        parts[2] = parts[2].slice(0, 4);
      }

      this.input.value = parts.join('');
    }
  }

  render() {
    const { lang, settings } = this.props;
    const selectOptions = Object.keys(this.state.countries).map(country => {
      return {
        value: country,
        label: this.state.countries[country].name,
      };
    });

    return (
      <AgeGate
        introText={this.props.translationStore.translate(
          'We need to make sure you are of legal age to enjoy this content responsibly. ' +
            'Please enter your date of birth and country',
          lang,
          settings,
        )}
        selectedCountry={this.state.currentValue}
        selectOptions={selectOptions}
        selectUpdate={this.updateSelect}
        validateAge={this.validateAge}
        dateFormat={this.formatDate}
        buttonText={this.props.translationStore.translate(
          'Check my age',
          lang,
          settings,
        )}
        submitForm={this.submitForm}
        validation={this.state.validation}
        referenceInput={this.referenceInput}
        language={lang}
        settings={settings}
      />
    );
  }
}

AgeGateContainer.PropTypes = {
  lang: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  rerender: PropTypes.func.isRequired,
};

export default withCookies(AgeGateContainer);
