import React from 'react';
import styled from 'styled-components';

export default styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  
  @media (min-width: 1024px) {
    max-width: 65%;
    min-width: 65%;
  }
`;
