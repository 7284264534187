import getNested from 'get-nested';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import root from 'window-or-global';
import { withCookies, Cookies } from 'react-cookie';
import SiteStore from '../../../../stores/SiteStore';
import TranslationStore from '../../../../stores/TranslationStore';
import TranslationDisclaimerView from '../component/TranslationDisclaimerView';

@inject('translationStore')
@observer
@withCookies
export class TranslationDisclaimer extends React.Component {
  static propTypes = {
    translationStore: PropTypes.instanceOf(TranslationStore),
    lang: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    settings: PropTypes.shape({
      general: PropTypes.shape({}).isRequired,
    }).isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    showMessage: PropTypes.bool.isRequired,
    englishUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showMessage: false,
    };

    // Set the stores
    this.siteStore = SiteStore.getInstance();

    this.checkCookie = this.checkCookie.bind(this);
    this.hideDisclaimer = this.hideDisclaimer.bind(this);
    this.trackEnglishLink = this.trackEnglishLink.bind(this);
  }

  componentDidMount() {
    this.checkCookie();
  }

  checkCookie() {
    const { cookies, showMessage } = this.props;

    return this.setState({
      showMessage: !cookies.get('DavidoffHideTranslations') && showMessage,
    });
  }

  hideDisclaimer(ev) {
    ev.preventDefault();
    const { cookies } = this.props;
    const d = new Date();

    if(root.dataLayer) {
      root.dataLayer.push({
        'event': 'hide-disclaimer',
        'hide-disclaimer': true,
        'url': root.location.href,
      });
    }

    cookies.set('DavidoffHideTranslations', true, {
      expires: new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()),
      path: '/',
    });

    this.setState({
      showMessage: false,
    })
  }

  trackEnglishLink() {
    if(root.dataLayer) {
      root.dataLayer.push({
        'event': 'switch-to-english',
        'switched-to-english': true,
        'url': root.location.href,
      });
    }
  }

  render() {
    if(!this.state.showMessage || this.props.showMessage === false) {
      return null;
    }
    const { translationStore } = this.props;
    const disclaimerSettings = getNested(() => this.siteStore.settings.general[this.siteStore.language], {});
    const translationText = disclaimerSettings.disclaimer_message;
    const englishLinkText = translationStore.translate('Show this page in English');
    const englishLinkUrl = this.props.englishUrl;
    const doNotShowButtonText = translationStore.translate('Don\'t show this again');

    return (
      <TranslationDisclaimerView
        translationText={translationText}
        englishLinkText={englishLinkText}
        englishLinkUrl={englishLinkUrl}
        doNotShowButtonText={doNotShowButtonText}
        hideDisclaimer={this.hideDisclaimer}
        trackEnglishLink={this.trackEnglishLink}
      />
    );
  }
}

export default TranslationDisclaimer;
