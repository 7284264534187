import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import buttonStyles from '../../../../shared-styles/button-styles';
import davidoff from '../../../../themes/davidoff';

export default styled(Link)`
  ${props => buttonStyles(props)}
  background-color: #fff;
  border: 1px solid ${davidoff.colors.brown};
  border-radius: 4px;
  color: ${davidoff.colors.brown};
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
  width: 100%;
  
  @media only screen and (min-width: 768px) {
    width: 100%;
  }
  
  &:hover {
    background-color: ${davidoff.colors.orange};
    border-color: ${davidoff.colors.orange};
    color: #fff;
  }
  
  &:nth-child(2) {
    margin-top: 15px;
    
    @media (min-width: 350px) {
      margin-top: 0;
      margin-left: 15px;
      min-width: calc(50% - 7px);
    }
    
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 0; 
      margin-left: 15px;
    }
    
    @media only screen and (min-width: 1024px) and (max-width: 1199px) {
      margin-left: 0;
      margin-top: 15px;
    }

    @media only screen and (min-width: 1200px) {
      margin-left: 15px;
      min-width: 176px;
    }
  }
`;
