import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.button`
  background: #fff;
  border: 0;
  border-radius: 4px;
  height: 44px;
  outline: none;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 44px;
  
  svg {
    bottom: 0;
    fill: ${davidoff.colors.brown};
    height: 16px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: fill 300ms;
    width: 16px;
  }
  
  &:hover svg {
    fill: ${davidoff.colors.orange};
  }
`;
