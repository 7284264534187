import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export const CtaButton = styled.a`
    ${props => props.theme.fonts.parse('sansSerif', 'black', '13px')}
    background-color: transarent;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    display: block;
    flex-basis: 0;
    flex-grow: 0;
    height: 40px;
    line-height: 40px;
    letter-spacing: 1px;
    outline: none;
    padding: 0 15px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 300ms, color 300ms, border-color 300ms;
    width: auto;

    @media only screen and (min-width: 768px) {
      display: inline-block;
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      padding: 0 30px;
      width: auto;
    }

    @media only screen and (min-width: 1200px) {
        min-width: 176px;
    }

    &:hover {
        background-color: ${davidoff.colors.orange};
        border-color: ${davidoff.colors.orange};
        color: #fff;
    }
`;

export const ContentContainer = styled.div`
    padding: 20px 6%;
    z-index: 9;
    position: relative;

  @media (min-width: 1024px) {
    padding: 0;
    margin: 0 auto;
    max-width: 1008px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const CampaignHeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 6%;

  display: flex;
  flex-direction: row;

  @media (max-width: 1023px){
  flex-direction: column;
    > div {
    &:nth-child(2){
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      ${(props) => props.children.length > 0 && props.children[0] === false ? (`
        justify-content: center;
        align-content: center;
        display: flex;
        flex-flow: wrap;
        top: 30%;
        `) : null}

       h2 {
          flex-basis: 100%;
        }

       a {
        flex-basis: 100%;
       }
    }
  }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0;
    height: 100%;

    > div {
      &:nth-child(1){
        padding-right: 1.315%;
        padding-bottom: 0;
        flex: 5 !important;
        position: relative;
        height: 100%;
      }

      &:nth-child(2){
        flex: 7 !important;
        padding-left: 1.315%;
        padding-top: 0;

        h2 {
          flex-basis: 100%;
        }

        @media (min-width: 1024px){
          ${(props) => props.children.length > 0 && props.children[0] === false ? (`
            left: 50%;
            justify-content: center;
            align-content: center;
            display: flex;
            flex-flow: wrap;
            transform: translate(-50%, -50%);
            width: 50%;
            top: 50%;
            text-align: center;
          `) : (`
            left: 70%;
            transform: translate(-50%, -50%);
            width: 50%;
            top: 50%;
          `)}
        }

        @media (min-width: 1200px){
          ${(props) => props.children.length > 0 && props.children[0] === false ? (`
            left: 50%;
            justify-content: center;
            align-content: center;
            display: flex;
            flex-flow: wrap;
          `) : ('left: 70%;')}
        }

      }
    }
  }

  h2{
    ${(props) => props.theme.fonts.parse('serif', 'medium', '30px')}
    color: #fff;
    margin: auto auto 20px;

    @media (min-width: 1024px) {
      ${(props) => props.theme.fonts.parse('serif', 'medium', '70px')}
    }
  }

  p{
    ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '16px')}
    color: #fff;
    margin: 0 0 2em;

    @media (min-width: 1024px) {
      ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '22px')}
    }
  }
`;

export const MediaContainer = styled.div`
  text-align: center;

  @media (min-width: 1024px) {
   margin-bottom: -120px;
   text-align: center;
   position: relative;
   padding: 120px 0 0;

    picture {
      left: 0;
      position: absolute;
      margin-top: -120px;
      width: 100%;
      height: 100%;
    }
  }

  img{
    width: 100%;
    position: absolute;
    max-width: 180px;
    object-fit: contain;
    object-position: bottom center;
    height: 100%;
    left: 10%;
    
    @media (max-width: 1023px){
      left: 50%;
      top: 75%;
      transform: translate(-50%, -50%);
      height: 100%;
      object-position: bottom center;
      margin: 0 auto;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: auto;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    @media (max-width: 767px) {
      max-height: 250px;
      transform: translate(-50%, -75%);
      top: 100%;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      margin: 0;
      left: 50%;
      transform: translate(-50%, -75%);
      top: 100%;
      max-height: 300px;
      max-width: 200px;
      object-fit: contain;
    }

    @media (min-width: 1024px) {
      margin: 0;
      max-width: 280px;
      max-height: 400px;
      top: 25%;
    }

    @media (min-width: 1279px) {
      max-height: 500px;
      max-width: 330px;
    }

    @media (min-width: 1440px) {
      max-width: 400px;
      max-height: 550px;
    }
  }

  .video-element {
    max-width: 360px;
    margin: 0 auto 20px;

    width: 100%;
    padding-top: 100%;
    position: relative;

    @media (min-width: 768px) {
      padding-top: 360px;
      margin: 0;
    }

    @media (min-width: 1025px) {
      position: absolute;
      bottom: 0px;
      margin: 0 auto;
      left: 50%;/
      transform: translateX(-50%);
      padding-top: 360px;
      width: 100%;
    }

    > div{
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%,-50%);
     width: 100%;
     height: 100%;
    }
  }
`
