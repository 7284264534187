import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SiteStore from '../../../stores/SiteStore';
// Components
import icons from '../../common/icons/icons';
import root from 'window-or-global';
//styled
import ShareIconsWrapper from './styled/ShareIconsWrapper';
import ShareIcons from './styled/ShareIcons';
import Title from './styled/Title';
import Whatsapp from './styled/Whatsapp';
import MailLink from './styled/MailLink';
import Link from './styled/Link';
import TranslationStore from '../../../stores/TranslationStore';

@inject('translationStore')
@observer
class SocialSharingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.SiteStore = SiteStore.getInstance();
  }

  render() {
    const { shareText, translationStore } = this.props;
    const currentUrl = root.Window ? root.document.URL : null;

    return (
      <ShareIconsWrapper>
        <ShareIcons>
          <li>
            <Title>{translationStore.translate('Share:')}</Title>
          </li>
          <li>
            <Link to={`//www.facebook.com/sharer.php?u=${currentUrl}`} target='_blank'>
              {icons.facebook}
            </Link>
          </li>
          <li>
            <Link to={`//twitter.com/intent/tweet?url=${currentUrl}&text=${shareText}`} target='_blank'>
              {icons.twitter}
            </Link>
          </li>
          <li>
            <MailLink href={`mailto:?subject=${encodeURI('I found this on zinodavidoff.com')}&body=${encodeURI(`Check out what I found on Zinodavidoff.com at ${currentUrl}`)}`}>
              {icons.email}
            </MailLink>
          </li>
          <li>
            <Whatsapp>
              <Link to={`whatsapp://send?text=${currentUrl}`} target='_blank'>
                {icons.whatsapp}
              </Link>
            </Whatsapp>
          </li>
        </ShareIcons>
      </ShareIconsWrapper>
    );
  }
}

SocialSharingComponent.propTypes = {
  shareText: PropTypes.string,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

SocialSharingComponent.defaultProps = {
  shareText: '',
};

export default SocialSharingComponent;
