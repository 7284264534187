import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SpecialActivityTite } from './styled/Title';
import { SpecialActivityWrapper } from './styled/Wrapper';
import { SpecialActivityContent } from './styled/SpecialActivityContent';
import { SpecialActivityImage } from './styled/SpecialActivityImage';
import { SpecialActivityText } from './styled/SpecialActivityText';
import { SpecialActivityLink } from './styled/SpecialActivityLink';
import { Container } from '../../style-guide/Container/Container';

const sources = [
  {
    settings: {
      aspect_ratio: '85:52',
      crop: 'fill',
      gravity: 'auto',
      placeholder: '',
      height: 52,
    },
    media: '(max-width: 767px)',
  },
  {
    settings: {
      aspect_ratio: '172:128',
      crop: 'fill',
      gravity: 'auto',
      width: 'auto',
      placeholder: '',
      height: 128,
    },
    media: '(min-width: 768px)',
  }
];

const settings = {
  aspect_ratio: '172:128',
  crop: 'fill',
  gravity: 'auto',
  placeholder: '',
  height: 128,
};

const SpecialActivitySmall = ({ data, mediaReference, title, link, description }) => {
  return (
    <SpecialActivityWrapper>
      <Container small>
        <SpecialActivityTite hyphenPosition='top'>{title}</SpecialActivityTite>
        <SpecialActivityContent
          to={link.uri}
          title={link.title}
        >
          <SpecialActivityImage
            imageType="Special activity image"
            data={data}
            mediaReference={mediaReference}
            settings={settings}
            sources={sources}
          />
          <SpecialActivityText>
            <div dangerouslySetInnerHTML={{__html: description.value}} />
            <SpecialActivityLink>
              {link.title}
            </SpecialActivityLink>
          </SpecialActivityText>
        </SpecialActivityContent>
      </Container>
    </SpecialActivityWrapper>
  );
};

SpecialActivitySmall.propTypes = {
  mediaReference: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export { SpecialActivitySmall }
