// Core
import React from 'react';

// API-related
import { dataMapper } from "../../index";
import getNested from "get-nested";

// Media-related
import { getBackgroundImagesByMedia } from "../../../misc/cloudinaryBackgrounds";

// Styles
import * as styles from './styled'

const getBackgroundImages = (data, image) => {
  const imageSettings = {
    breakpoints: {
      mobile: {
        width: 640,
        aspect_ratio: "375:650"
      },
      desktop: {
        width: 1600,
        aspect_ratio: "1920:815"
      }
    }
  };

  const media = dataMapper(
    data,
    getNested(() => image.target_uuid)
  );

  return getBackgroundImagesByMedia(media, imageSettings);
};

export function CampaignCallToAction(props) {
  const { paragraph, data } = props;
  const [isDesktop, setIsDesktop] = React.useState(true);

  React.useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth >= 768) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };

    checkWindowSize();

    window.addEventListener('resize', checkWindowSize);
    return () => window.removeEventListener('resize', checkWindowSize);
  }, [isDesktop]);

  const imageUris = getBackgroundImages(
    data, 
    paragraph.field_campaign_cta_image
  );

  return (
    <styles.CampaignCallToAction 
      imageUri={isDesktop ? imageUris.desktop : imageUris.mobile}
    >
      <div>
        <h2>{paragraph.field_campaign_cta_title}</h2>
        <p>{paragraph.field_campaign_cta_text}</p>

        <styles.CtaButton 
          href={paragraph.field_campaign_cta_button.uri}
        >
          {paragraph.field_campaign_cta_button.title}
        </styles.CtaButton>
      </div>
    </styles.CampaignCallToAction>
  )
} 
