import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../../shared-styles/structure-styles.js';

export default styled.div`
  ${structureStyles.flexColumn}
  
  @media (max-width: 1023px) {
    ${structureStyles.flexItem.halfMobile};
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    max-width: 315px;
    margin: 0 30px 60px 0;
  }
`;
