// Core
import React from 'react';

// Components
import ResponsiveImage from '../../style-guide/Image/ResponsiveImage';
import FullWidthVideo from '../../paragraph-types/FullWidthVideo/FullWidthVideo';

// Styles
import * as styles from './styled'


export function CampaignHeaderContent(props) {
    const options = {
      effect: "trim",
      width: 240,
    }

    const highlightImg = typeof props.image === "object";

    return (
      <styles.CampaignHeaderContent>
        {highlightImg}
        <styles.MediaContainer>
          {
            props.type == "video" ?
              (
                <FullWidthVideo paragraph={props.videoParagraph} />
              ) :
              (
                <ResponsiveImage
                    {...props.image}
                    options={options}
                />
              )
          }
        </styles.MediaContainer>
        <styles.ContentContainer>
          {
            props.title !== null ?
              (
                <h2>
                  {props.title}
                </h2>
              ) : null
          }
          {
            typeof props.button.uri !== 'undefined' && typeof props.button.title !== 'undefined' ?
              (
                <styles.CtaButton
                  href={props.button.uri}
                >
                  {props.button.title}
                </styles.CtaButton>
              ) : null
          }
        </styles.ContentContainer>
      </styles.CampaignHeaderContent>
    )
  }
