import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.div`
  ${({theme}) => theme.fonts.parse('sansSerif', 'regular', '13px')}
  color: #000;
  margin: 0;
  
  @media (min-width: 768px) {
    min-width: 64%;
    max-width: 64%;
  }
  
  @media only screen and (min-width: 768px) {
    font-size: 14px;
    min-width: 0;
    max-width: none;
  }
  
  p {
  ${({theme}) => theme.fonts.parse('sansSerif', 'regular', '14px')}
    color: #000;
    line-height: 1.5em;
    margin: 0;
    text-align: center;
    
    @media only screen and (min-width: 768px) {
      text-align: left;
    }
  }
  
  a {
    color: ${davidoff.colors.orange};
    transition: color 300ms;
    
    &:hover {
      color: #000;
    }
  }
`;
