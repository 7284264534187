import davidoff from '../themes/davidoff';

const buttonStyles = props => (`
  ${props.theme.fonts.parse('sansSerif', 'black', '13px')}
  background-color: ${davidoff.colors.brown};
  border: 0;
  color: #fff;
  height: 40px;
  line-height: 40px;
  letter-spacing: 1px;
  outline: none;
  padding: 0 15px;
  text-transform: uppercase;
  transition: background-color 300ms;
  width: 30%;
  
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    min-width: 176px;
    padding: 0 30px;
    width: auto;
  }
  
  &:hover {
    @media only screen and (min-width: 768px) {
      background-color: ${davidoff.colors.orange};
    }
  }
`);

export default buttonStyles;
