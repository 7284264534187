import { observable, action, computed, toJS } from 'mobx';
import getNested from 'get-nested';
import { formatProductName } from '../misc/index';
import { dataMapper } from '../components/index';
// API
import SocialAPI from '../api/SocialAPI';

export class SiteStore {
  static instance;

  static getInstance() {
    if(!SiteStore.instance) {
      SiteStore.instance = new SiteStore();
    }

    return SiteStore.instance;
  }

  static createProductHighlightFromSettings(settingsProduct) {
    return {
      key: settingsProduct.key,
      title: settingsProduct.title,
      url: settingsProduct.uri,
      // Field media is not a reference here.
      mediaImage: settingsProduct.field_media,
    };
  }

  @observable settings = {};
  @observable data = {};
  @observable previousLanguage = 'en';
  @observable language = 'en';
  @observable country = '';
  @observable socialType = '';
  @observable _relatedProductsFromSettings = [];
  @observable _relatedProductsFromProductGroup = [];
  @observable displayNotification = false;
  @observable queryParams = {};
  @observable socialPosts = [];

  @computed get relatedProducts() {
    return toJS(this._relatedProductsFromProductGroup.length ? this._relatedProductsFromProductGroup : this._relatedProductsFromSettings);
  }

  createProductHighlightFromProductGroup(product, pageData = this.data) {
    let data = pageData;

    if(getNested(() => data.data.data)) {
      data = data.data;
    }
    if (product) {
      if(getNested(() => product.field_product_group.target_uuid)) {
        const productGroup = dataMapper(data, product.field_product_group.target_uuid);
        const productName = formatProductName(productGroup.field_display_text, product.field_display_text);

        let buyButton;

        if(product.field_buy_buttons) {
          const productBuyButton = dataMapper(data, product.field_buy_buttons.target_uuid);
          const buyButtonReferences = getNested(() => productBuyButton.field_buy_buttons, []);
          const buyButtons = buyButtonReferences.map(reference => dataMapper(data, reference.target_uuid));

          buyButton = buyButtons.find(paragraph => paragraph.field_country.toLowerCase() === this.country.toLowerCase());
        }

        return {
          key: product.uuid,
          title: productName.groupName,
          productName,
          mediaReference: product.field_media || getNested(() => product.field_media_multiple[0]),
          url: product.__hn.url,
          buyButton,
        };
      }
    }

    return null;
  }

  @action fetchSocialItems(entityID) {
    SocialAPI.getSocialFeed(entityID).then((data) => {
      this.socialPosts = data;
    });
  }

  @action
  updateLanguage(language) {
    if(this.language !== language) {
      this.previousLanguage = this.language;
    }

    this.language = language;
  }

  @action
  updateDisplayNotification(newLanguage) {
    if(this.previousLanguage !== newLanguage) {
      this.displayNotification = true;
    } else {
      this.displayNotification = false;
    }
  }

  @action
  updateSettings(settings) {
    this.settings = settings;
  }

  @action
  updatePath(path) {
    this.path = path;
  }

  @action
  updateCountry(country) {
    this.country = country;
  }

  @action
  updateQueryParams(queryParams) {
    this.queryParams = queryParams;
  }

  @action
  updateRelatedProductsFromProductGroup(relatedProducts, data = null) {
    this._relatedProductsFromProductGroup = relatedProducts.map(item => this.createProductHighlightFromProductGroup(item, data));
  }

  @action
  removeRelatedProductsFromProductGroup() {
    this._relatedProductsFromProductGroup = [];
  }

  @action
  updateRelatedProductsFromSettings(relatedProducts) {
    this._relatedProductsFromSettings = relatedProducts.map(item => this.createProductHighlightFromProductGroup(item));
  }

  @action
  updateData(data) {
    this.data = data;
  }
}

export default SiteStore;
