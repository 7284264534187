import React from 'react';
import styled from 'styled-components';

export default styled.div`
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 760px;
  position: relative;
  text-align: center;
  z-index: 10;
  width: 80%;
`;
