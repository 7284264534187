import React from 'react';
import styled, { keyframes, css } from 'styled-components';

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

const slideIn = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 24px;
  }
`;

const slideInAnimation = css`${fpsToMs(8)}ms ease ${fpsToMs(27)}ms forwards ${slideIn}`;

export default styled.span`
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  width: 0;
  
  svg {
    fill: #fff;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }
  
  ${props => props.subscribed ? css`
    animation: ${slideInAnimation};
    bottom: 0;
    height: 24px;
    left: 82px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 0;
  ` : css``}
`;
