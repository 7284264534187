import React from "react";
import { PropTypes as MobxProps, inject, observer } from "mobx-react";
import PropTypes from "prop-types";
// Stores
import SiteStore from "../../../../stores/SiteStore";
// Components
import Product from "../Product/Product";
import NoResults from "./styled/NoResults";
import { H2 } from "../../../style-guide/Headings";

@inject("translationStore")
@observer
class ProductView extends React.Component {
  constructor(props) {
    super(props);

    this.SiteStore = SiteStore.getInstance();
  }
  render() {
    const { products, data, translationStore } = this.props;

    if (products.length === 0) {
      return (
        <NoResults>
          <H2>{translationStore.translate("No results")}</H2>
          <p>
            {translationStore.translate(
              "This product category with the current filters does not have any products."
            )}
          </p>
        </NoResults>
      );
    }

    return products.map((product) => (
      <Product
        key={`product-overview-${product.uuid}`}
        product={product}
        data={data}
      />
    ));
  }
}

ProductView.propTypes = {
  products: PropTypes.oneOfType([MobxProps.observableArray, PropTypes.array])
    .isRequired,
};

export default ProductView;
