import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../../themes/davidoff';

export default styled.li.attrs(props => ({
  className: `level-${props.level}`,
}))`
  border-bottom: 1px solid #f7f6f4;
  line-height: 40px;
  position: relative;

  &:last-child {
    border: 0;
  }

  @media only screen and (min-width: 1025px) {
    border-bottom: 0;
    display: inline-block;
    line-height: 18px;
    padding: 0;
    position: static;
  }

  ${props => (props.extraOffset ? `margin-top: 14px !important;` : '')}

  // &::before {
  //   color: gray;
  //   content: '\\00B7';
  //   display: block;
  //   font-size: 25px;
  //   left: -3px;
  //   line-height: 20px;
  //   position: absolute;
  //   top: 3px;
  // }

  &:first-child::before {
    display: none;
  }

  ul {
    // background-color: #fff;
    list-style: none;
    margin: 0;
    overflow: hidden;
    transition: max-height 300ms linear;
    width: 100%;

    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: center;
      left: 0;
      max-height: 0;
      overflow: visible;
      padding-top: 0;
      position: absolute;
      top: 43px;
      transform: translateX(-999em);
      transition: transform 300ms ease, max-height 300ms ease-in-out,
        padding 300ms ease-in-out, opacity 300ms;
      transition-delay: 600ms
        ${props => (props.isLoading ? `display: none` : '')};
    }
  }

  @media (max-width: 1024px) {
    &.level-1 > .subnav-wrapper > ul {
      ${props =>
        props.subOpen && props.level === 1
          ? `
        max-height: 800px;
        `
          : `
        max-height: 0;
      `}
    }

    &.level-3 > .subnav-wrapper > ul {
      ${props =>
        props.subOpen && props.level === 3
          ? `
        max-height: 800px;
        `
          : `
        max-height: 0;
        `}
    }
  }

  @media (min-width: 1025px) {
    &.dummy:before {
      background-color: #fff;
      content: '';
      left: 0;
      height: 0;
      padding: 0;
      position: absolute;
      top: 40px;
      transition: padding 150ms ease-in-out;
      transition-delay: 0;
      width: 100vw;
      z-index: -1;
      border-bottom: 1px solid ${davidoff.colors.lightbrown};
    }

    &.level-1:not(.no-subnav):hover ~ &.dummy {
      ${props =>
        !props.isLoading
          ? `
        &:before {
          transition: padding 450ms ease-in-out;
          transition-delay: 600ms;
          padding-bottom: 457px;
        }`
          : ''}
    }
  }

  ${props =>
    !props.isLoading
      ? `
    &:hover ul {
      @media only screen and (min-width: 1025px) {
        background-color: #fff;
        transform: translateX(0);
        max-height: 800px;
        opacity: 1;
        padding-bottom: 6px;
        padding-top: 30px;
      }

      @media (min-width: 1025px) {
        > li:first-child {
          animation-delay: 950ms;
          animation-duration: 750ms;
          animation-fill-mode: forwards;
          animation-name: fadein;
          animation-timing-function: ease-out;
          width: 176px;
        }
        > li:nth-child(2) {
          animation-delay: 1150ms;
          animation-duration: 750ms;
          animation-fill-mode: forwards;
          animation-name: fadein;
          animation-timing-function: ease-out;
          width: 176px;
        }
        > li:nth-child(3) {
          animation-delay: 1350ms;
          animation-duration: 1500ms;
          animation-fill-mode: forwards;
          animation-name: fadein;
          animation-timing-function: ease-out;
          width: 280px;
        }
        > li:nth-child(4) {
          animation-delay: 1650ms;
          animation-duration: 1500ms;
          animation-fill-mode: forwards;
          animation-name: fadein;
          animation-timing-function: ease-out;
          width: 280px;
        }
      }
    }
  `
      : ''}

  h4 {
    ${props => props.theme.fonts.parse('sansSerif', 'black', '16px')}
    border-top: 1px solid #f7f6f4;
    color: #000;
    cursor: default;
    margin-bottom: 0;
    padding-left: 55px;
    position: relative;
    text-transform: uppercase;

    @media only screen and (min-width: 1025px) {
      border: 0;
      font-size: 14px;
      margin-bottom: 9px;
      padding: 0;
    }

    span {
      cursor: default !important;
    }

    @media only screen and (max-width: 1024px) {
      &:before {
        border: 2px solid #938579;
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        height: 20px;
        left: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }

      &:after {
        border-bottom: 2px solid #938579;
        border-left: 2px solid #938579;
        box-sizing: border-box;
        content: '';
        height: 6px;
        left: 27px;
        position: absolute;
        top: 17px;
        transform: rotate(-135deg);
        transform-origin: center center;
        transition: all 0.3s linear;
        width: 6px;

        ${props =>
          props.subOpen && props.level === 3
            ? `
          transform: rotate(-45deg);
        `
            : `
        `}
      }

      &.open::after {
        left: 28px;
        top: 15px;
        transform: rotate(-45deg);
      }
    }
  }
`;
