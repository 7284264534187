import React from 'react';
import PropTypes from 'prop-types';
// components
import FilterOption from '../StoreLocatorFilterOption/StoreLocatorFilterOption';
// Styled Components
import FilterWrapper from './styled/FilterGroupWrapper';

const StoreLocatorFilterGroup = ({ filter, handler }) => {
  let subFilters = false;
  if(Object.keys(filter.filters).length > 0) {
    subFilters = Object.keys(filter.filters).map((option) => {
      const subFilter = filter.filters[option];
      return <FilterOption
        key={`store-locator-option-${subFilter.key}`}
        option={{key: subFilter.key, label: subFilter.name}}
        filter={subFilter}
        heading={false}
        selected={subFilter.selected}
        handler={handler}
      />;
    });
  }
  return (
    <FilterWrapper>
      <FilterOption
        option={{key: filter.key, label: filter.name}}
        filter={filter}
        heading={true}
        selected={filter.selected}
        handler={handler}
      />
      {subFilters}
    </FilterWrapper>
  );
};

StoreLocatorFilterGroup.propTypes = {
  filter: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    filters: PropTypes.shape().isRequired,
  }),
};
StoreLocatorFilterGroup.defaultProps = {};

export default StoreLocatorFilterGroup;
