import React from 'react'
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.h2`
  color: ${davidoff.colors.darkGrey};
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 4%;
  position: relative;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 22px;
    margin-left: 72px;
    //text-transform: none;
  }

  &:before,
  &:after {
    background-color: ${davidoff.colors.orange};
    bottom: -9px;
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    width: 8px;
    z-index: 1;
  }

  &:after {
    transform: skew(-20deg, 0deg);
    transform-origin: bottom left;
    width: 60px;
  }
`
