import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';

export default styled.div`
  ${structureStyles.container}
  padding: ${structureStyles.mobileValues.spacing} 6%;
  width: 100%;
  
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    padding: 80px 0;
  }
`;
