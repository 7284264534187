import React from 'react';
import styled from 'styled-components';

export default styled.span`
  svg:first-child {
    height: 16px;
    top: 5px;
    width: 16px;
  }
  
  svg:last-child {
    transform: rotate(90deg);
  }
`;