import React from 'react';
import styled from 'styled-components';
import SearchInput from '../../../GoogleMapSearchBox/styled/SearchInput';
import LocatorButton from '../../../../paragraph-types/StoreLocator/styled/LocatorButton';

export default styled.div`
  background-color: #fff;
  left: 50%;
  max-width: 400px;
  padding: 30px;  
  position: absolute;
  //text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  
  ${SearchInput} {
    border: 1px solid #e8e3de;
    margin-bottom: 0;
  }
  
  ${LocatorButton} {
    display: block;
  }
`;
