import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BrandIntroductionVideoLink = styled(Link)`
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    text-indent: -10000px;
    top: 0;
    width: 100%;
    z-index: 2;
`;

export default BrandIntroductionVideoLink;