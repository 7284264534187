import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import structure from '../../../../shared-styles/structure-styles';

export default styled(Link)`
  color: #000;
  display: block;
  text-decoration: none;
  padding: ${structure.mobileValues.spacing};
  
  @media (min-width: 768px) {
    display: flex;
    padding: ${structure.desktopValues.spacing};
  }
  
  &:hover {
    color: #000;
    text-decoration: none;
  }
`;
