import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../style-guide/Loader/Loader.jsx';

export default styled.div`
  background-color: #fff;
  border-radius: 4px;
  height: 60px;
  left: 50%;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  
  ${Loader} {
    color: #000;
    transform: translateZ(0) scale(1);
  }
`;
