import styled, { css, keyframes } from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import MediaImageContainer from '../../../common/MediaImage/index.c';
import productBG from '../../../../shared-styles/productBG';
import davidoff from '../../../../themes/davidoff';
import Slider from 'react-slick';


import { Link } from 'react-router-dom';
import linkStyles from '../../../../shared-styles/link-styles';


// Animations/Transitions
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const hyphenBeforeGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 8px;
  }
`;

const hyphenAfterGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 60px;
  }
`;

const ProductFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Variables
const fadeInSlow = css`${fpsToMs(25)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`;
const fadeInSlowMedium = css`${fpsToMs(26)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`;
const hyphenBeforeGrowAnimation = css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenBeforeGrow}`;
const hyphenAfterGrowAnimation = css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenAfterGrow}`;

// Functions
function fpsToMs(frames) {
    // 25 frames = 1000 ms
    // 1 frame = 40 ms
    return frames * 40;
}

// Components
export const ProductWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1216px;
    padding: 0 20px;
    width: 100%;

    @media (min-width: 1024px) {
      justify-content: flex-start;
    }

    @media (min-width: 1256px) {
      padding: 0;
    }

    img{
        max-width: 100%;
    }

    section {
        padding: 0 0 ${structureStyles.mobileValues.spacing};
        
        @media (min-width: 768px) {
            padding: 0;
        }
    }
`;

export const ProductTilesWrapper = styled.div`
  width: 100%;  
  margin: 0;
  position: relative;

  @media (min-width: 768px) {
    margin: 0 0 60px;
  }
  
  ${props => props.noMargin ? `
      margin: 0;
  
    @media (min-width: 768px) {
      margin: 0;
    }
  ` : ''}

  div.product__tiles_container{
    ${structureStyles.container}
  
    > div {
      @media (max-width: 767px) {
        justify-content: space-between;
      }
    }
  }
`

export const ProductTilesWrapperInner = styled(ScrollEffect)`
  h2 {
    margin-left: 40px;
    max-width: 190px;
    padding-top: ${structureStyles.mobileValues.spacing};
    text-transform: uppercase;
    z-index: 1;

    font-size: 13px;
    line-height: 1.5;
    
    @media (min-width: 768px) {
      font-size: 20px;
      margin-left: 70px;
      padding-top: 60px;
    }
    
    &:before,
    &:after {
      bottom: -8px;
      
      @media (min-width: 768px) {
        width: 0;
      }
    }
    
    span {
      @media (min-width: 768px) {
        opacity: 0;
      }
    }
  }

  @media (min-width: 768px) {
    ${ProductWrapper} {
      opacity: 0;
    }
    
    &.animate {
      ${ProductWrapper} {
        animation: ${fadeInSlow};
      }
      
      h2 {
        &:before {
          animation: ${hyphenBeforeGrowAnimation};
        }

        &:after {
          animation: ${hyphenAfterGrowAnimation};
          animation-fill-mode: forwards;
        }

        span {
          animation: ${fadeInSlowMedium};
        } 
      }
    }
  }
`;

export const ProductBlock = styled('div')`
  margin-bottom: 16px;
  opacity: 0;
  
  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }
  
  .animate & {
    animation: ${ProductFadeIn} 750ms cubic-bezier(0, 0, 0.32, 0.92) forwards;
     
    &:nth-child(1) {
      animation-delay: 1350ms;
    }
  
    &:nth-child(2) {
      animation-delay: 1400ms;
    }
    
    &:nth-child(3) {
      animation-delay: 1450ms;
    }
  
    &:nth-child(4) {
      animation-delay: 1500ms;
    }
  }
`;

export const ProductImage = styled(MediaImageContainer)`
  img, & {
    display: block;
    transition: transform 0.35s linear;
    transform: scale(1);
    transform-origin: center center;
    width: 100%;
    
    ${ProductBlock}:hover & {
      transition: transform 2s linear;
      transform: scale(1.1);
    }
  }
`;

export const ProductImageWrapper = styled.div`
  ${productBG()}
  overflow: hidden;
`;

export const ProductTitle = styled.h3`
  ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '14px')}
  color: #000;
  margin-top: 15px;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
  
  @media (min-width: 768px) {
    font-size: 16px;
    text-align: left;
  }
  
  span {
    text-transform: capitalize;
    ${(props) => props.theme.fonts.parse('sansSerif', 'regular')};
    display: block;
    margin: 5px 0;
  }
`;

export const CtaButton = styled.a`
  ${props => props.theme.fonts.parse('sansSerif', 'black', '13px')}
  background-color: ${davidoff.colors.orange};
  border: 1px solid ${davidoff.colors.orange};
  border-radius: 4px;
  color: #fff;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  letter-spacing: 1px;
  margin: 20px 0 0;
  outline: none;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 300ms, color 300ms, border-color 300ms;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: inline-block;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
  }
  
  @media only screen and (min-width: 1200px) {
    min-width: 176px;
  }

  &:hover {
    background-color: transparent;
    border-color: ${davidoff.colors.brown};
    color: ${davidoff.colors.brown};
  }
`;

export const ProductTilesSlider = styled(Slider)`
  max-width:100%;
  position: unset !important;

  .slick-slide {
    width: 100%;
    
    @media (min-width: 768px) {
      padding: 0 16px;
      min-height: 480px;
    }
  }
`;

export const NavButton = styled.button`
  &.slick-arrow{
    border: 0;
    background: #1D1E2A;
    height: 70px;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    
    top: calc( ( (100vw - 40px ) / 2) + 44px);

    @media (min-width: 768px) {
      top: calc( ( (100vw - (32px * ${p=>p.amount})) / ${p=>p.amount*2}) + 95px);
    }    

    @media (min-width: 1216px) {
      top: calc( ( (1176px - (32px *  ${p=>p.amount})) / ${p=>p.amount*2}) + 95px);
    }

    @media (min-width: 1256px) {
      top 231px;
    }

    transform: translateY(-50%);
    z-index: 88;
    width: 40px;

    &:before {
      display: none;
    }

  &:hover{
    background: #000;
  }
  
  svg {
    display: block;
    fill: #fff;
    height: 18px;
    width: 18px;
    margin: auto;
  }

  &.slick-next {
    right: 0px;
    left: auto;
  }
  
  &.slick-prev {
    left: 0px;
    transform: translateY(-50%) rotate(-180deg);
  }
  
  &.slick-disabled {
    display: none !important;
  }
}
`;

export const ctaLink =  styled(Link)`
  ${props => linkStyles.link(props)}

  display: block;
  padding: 0 ${structureStyles.mobileValues.spacing};
  cursor: pointer;
  margin-top: 15px;
    
  @media (min-width: 768px) {
    padding: 0;
    
    &:hover {
      color: ${davidoff.colors.orange};
    
      &::after {
        margin-left: 15px;
      }
    }
  }
`;
