import { observable, action } from 'mobx';
import queryString from 'query-string';
import getNested from 'get-nested';
import OverviewStore from './OverviewStore';
import { dataMapper } from '../components/index';
import { TranslationStore } from './TranslationStore';

class ProductStore extends OverviewStore {
  static instance;

  constructor(products) {
    super(products);

    this.getNumberOfProducts = this.getNumberOfProducts.bind(this);
    this.matchItem = this.matchItem.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.findFilerBlock = this.findFilerBlock.bind(this);
    this.translationStore = TranslationStore.getInstance();
  }

  @observable postsPerPage = 12;
  @observable language = 'en';
  @observable settings = {};
  @observable selectedFilters = [];
  @observable filterBlocks = {};
  @observable level = 0;
  @observable filterOptions = {};
  @observable viewType = '';

  static getInstance() {
    if(!ProductStore.instance) {
      ProductStore.instance = new ProductStore();
    }

    return ProductStore.instance;
  }

  @action filterList(filterLevel = 0) {
    // After we selected the latest display items.. apply the search term to it
    // Reset the current page
    this.currentPage = 0;
    this.level = filterLevel;
    // Update the url
    window.history.pushState('', '', `?${queryString.stringify({ selectedFilters: this.selectedFilters })}`);
    // Update the display list
    this.updateDisplayList(true);
    this.updatePages();
  }

  /*
   * Set the getFiltered list to return everything by default
   */
  getFilteredList() {
    // Filter the product list to only keep what we need
    if(this.selectedFilters.slice().length === 0) {
      return this.itemList;
    }

    // Loop over the selected filters (these are taxonomy terms)
    // These taxonomy terms belong to filters in the view
    // These filters in the view have the identifier we need to select by
    return this.itemList.filter(item => this.matchItem(item));
  }

  /*
   * Match items matches items from a list to a specific key.
   */
  matchItem(item) {
    const match = {};
    const selectedFilters = this.selectedFilters.peek();

    selectedFilters.forEach((filterKey) => {
      // Determine relations based on the filter that is selected.
      const currentFilter = this.findFilter(filterKey);

      // @TODO: Find why selectedFilters is filled when entering the view
      // for the first time.
      if(currentFilter) {
        if(!getNested(() => match[currentFilter.operator], false)) {
          match[currentFilter.operator] = {};
        }

        if(currentFilter.operator === 'or') {
          // Find the filter block it belongs to.
          // We need to find the block where the filter belongs to
          // then we need to add the block to the match object
          // And after that we need to find the match and add the result to the filter object.
          const filterBlock = this.findFilerBlock(filterKey);
          if(!getNested(() => match[currentFilter.operator][filterBlock.key])) {
            match[currentFilter.operator][filterBlock.key] = {};
          }

          match[currentFilter.operator][filterBlock.key][filterKey] = this.findMatch(item, filterKey);
        } else {
          match[currentFilter.operator][filterKey] = this.findMatch(item, filterKey);
        }
      }
    });

    const orMatches = {};
    // For the OR Filters use this.
    // If anything is False... this returns true...
    // and not all products apply to the filters
    // Uncomment next line to enable or filters
    if(match.or) {
      Object.keys(match.or).forEach((key) => {
        // Check all the filter blocks for results.
        // If there is a truthy value in each of the ilter blocks
        // We have a match.
        orMatches[key] = Object.keys(match.or[key]).some(newKey => match.or[key][newKey] === true);
      });
    }

    const andMatches = {};
    // For AND filters.
    // A product has to apply to one of e the selected items.
    // So if there is one value true this product should be selected
    // We have a layered filter witch makes it more dificult.
    if(match.and) {
      Object.keys(match.and).forEach((key) => {
        andMatches[key] = !Object.keys(match.and).some(newKey => match.and[newKey] === false);
      });
    }

    // Check if the andmatches are good
    const isAndMatch = !Object.keys(andMatches).some(key => andMatches[key] === false);
    // If there is no and match
    if(!isAndMatch) {
      return false;
    }

    let hasOrMatch = false;
    // check if this has some or matches
    if(Object.keys(orMatches).length > 0) {
      hasOrMatch = !Object.keys(orMatches).some(key => orMatches[key] === false);

      return hasOrMatch;
    }

    return isAndMatch;
  }

  findMatch(item, key) {
    // First find the filter we need to use
    const viewFilter = this.findFilter(key);
    // Check if we can find the identifier on the item
    // Mostly the "Properties" are variant based.
    if(getNested(() => item[viewFilter.expose.identifier], false)) {
      // Loop over all properties and check if they match
      // Then return the result of the check if this item matches
      return item[viewFilter.expose.identifier].some(property => property.target_uuid === key);
    }
    // Otherwise check it with the product group.
    // Mostly the "category" and "collection" fields wil match here.
    const productGroup = dataMapper(this.data, item.field_product_group.target_uuid);

    return getNested(() => productGroup[viewFilter.expose.identifier].target_uuid, false) === key;
  }

  findFilerBlock(key) {
    let filterBlock = false;
    // Loop over all filterobjects in here.
    // Find the parent that has the filterObject in it's filters
    const filterBlocks = this.filterBlocks;
    // check if its the top level item
    const topLevel = Object.keys(filterBlocks.filters).some(filterKey => key === filterKey);

    if(topLevel) {
      return filterBlocks;
    }
    // Deep check for filters.
    // Since we have just two levels of fiters there is no need for recursion.
    // just check the next layer.
    Object.keys(filterBlocks.filters).forEach((filterKey) => {
      Object.keys(filterBlocks.filters[filterKey].filters).forEach((subFilterKey) => {
        const optionInFilter = Object.keys(filterBlocks.filters[filterKey].filters[subFilterKey].options).some(option => option === key);

        // The option is in this filter.
        // We should return this filterblock.
        if(optionInFilter) {
          filterBlock = filterBlocks.filters[filterKey].filters[subFilterKey];
        }
      });
    });

    return filterBlock;
  }

  findFilter(key) {
    return this.filters.find((filter) => {
      let match = false;

      filter.options.forEach((term) => {
        if(term === key) {
          match = true;
        }
      });

      return match;
    });
  }

  getCategories() {
    // Get categories from the view
    const categoryFilters = this.filters.find(filter => filter.id === 'field_category_target_id');
    const terms = getNested(() => categoryFilters.options, false);

    if(!terms) {
      return false;
    }

    // find all the categories in the view.
    // Build a list of categories with their children
    const categories = {};

    terms.forEach((term) => {
      const termData = dataMapper(this.data, term);
      // Check if this term has a parent
      const parent = getNested(() => termData.parent[0].target_uuid, false);

      if(parent) {
        // Check if parent is in the categories already
        if(!getNested(() => categories[parent])) {
          // If not.. add it
          categories[parent] = {
            data: dataMapper(this.data, parent),
            children: [],
          };
        }
        // add our child to the parent. if it doesn't exist already
        categories[parent].children.push({
          data: termData,
          children: [],
        });
      } else if(!getNested(categories[term], false)) {
        // If no parents then it's a toplevel item.... add it to the rest
        categories[term] = {
          data: termData,
          children: [],
        };
      }
    });

    return categories;
  }

  /*
   * Get Filter options for the Products
   */
  @action setFilterOptions() {
    // Filter options is a object with filters
    // First level always shows the categories (take accesories) Top level category page
    // Second level shows sub-category specific filters like properties and collections
    const filterOptions = {
      label: this.translationStore.translate('Our products'),
      key: 'our-products',
      filters: {},
    };
    const useCategoryProperty = this.viewType !== 'collection_products';

    // fetch all properties from products
    this.itemList.forEach((item) => {
      // get properties for this product
      const properties = item.field_properties;
      const productGroup = dataMapper(this.data, item.field_product_group.target_uuid);
      const category = dataMapper(this.data, productGroup.field_category.target_uuid);
      const collection = getNested(() => productGroup.field_collection.target_uuid, false) ?
        dataMapper(this.data, productGroup.field_collection.target_uuid) :
        false;

      // If we are a Top level category
      // if(getNested(() => categories[category.uuid], false)) {
      // Add the children to the filters.
      // If we are a child category remove the category filters. hey are not needed.

      // Build the filter object for this specific product
      // First check if the category for this product is in the filters, not? add it.
      if(!getNested(() => filterOptions.filters[category.uuid], false)) {
        filterOptions.filters[category.uuid] = {
          key: category.uuid,
          label: category.name,
          type: 'radio',
          numberOfProducts: this.getNumberOfProducts(category.uuid),
          data: category,
          filters: {},
        };
      }
      // }
      // Check if this product group belongs to a collection
      // Add the collection to hte category filters if it does.
      if(collection && !getNested(() => filterOptions.filters[category.uuid].filters.collection, false)) {
        // Add the collection filter to the category block and add this to the options
        filterOptions.filters[category.uuid].filters.collection = {
          label: this.translationStore.translate('Collections', this.language, this.settings),
          key: 'collection',
          numberOfProducts: 0,
          options: {},
        };
        filterOptions.filters[category.uuid].filters.collection.options[collection.uuid] = {
          data: collection,
          key: collection.uuid,
          label: collection.field_display_text || collection.name,
          numberOfProducts: this.getNumberOfProducts(collection.uuid, category.uuid || false),
          type: 'checkbox',
        };
      } else if(collection) {
        filterOptions.filters[category.uuid].filters.collection.options[collection.uuid] = {
          data: collection,
          key: collection.uuid,
          label: collection.field_display_text || collection.name,
          numberOfProducts: this.getNumberOfProducts(collection.uuid, category.uuid || false),
          type: 'checkbox',
        };
      }

      properties.forEach((property) => {
        const propData = dataMapper(this.data, property.target_uuid);
        const parent = getNested(() => propData.parent[0].target_uuid, false) ?
          dataMapper(this.data, propData.parent[0].target_uuid) :
          false;
        const option = {
          key: propData.uuid,
          label: propData.name,
          data: propData,
          numberOfProducts: this.getNumberOfProducts(propData.uuid, useCategoryProperty ? category.uuid || false : false),
          type: 'checkbox',
        };
        // check if this has a parent
        if(parent) {
          // use the parent as a title for the options
          // use the child as filter options.
          if(!getNested(() => filterOptions.filters[category.uuid].filters[parent.uuid], false)) {
            filterOptions.filters[category.uuid].filters[parent.uuid] = {
              key: parent.uuid,
              label: parent.name,
              data: parent,
              numberOfProducts: this.getNumberOfProducts(parent.uuid, useCategoryProperty ? category.uuid || false : false),
              options: {},
            };
          }

          filterOptions.filters[category.uuid].filters[parent.uuid].options[option.key] = option;
        }
      });
    });

    const sortedOptions = this.sortFilters(filterOptions);
    this.filterOptions = sortedOptions;
    return filterOptions;
  }

  sortFilters(filterOptions) {
    // Sort the filters by weight from Drupal
    // To sort the filters we need to get all the keys
    // get all the objects
    // Sort the array of keys based on the weight of their opbject
    // Reassign the filters based on the keys they had.
    // Order the properties based on their weights in drupal
    const sortedKeys = Object.keys(filterOptions.filters).sort((a, b) => {
      if(typeof a === 'undefined') {
        return 1;
      }

      if(
        getNested(() => filterOptions.filters[a].data.weight) !== false &&
        getNested(() => filterOptions.filters[b].data.weight) !== false
      ) {
        return filterOptions.filters[a].data.weight - filterOptions.filters[b].data.weight;
      }

      return 0;
    });
    filterOptions.order = sortedKeys;

    // do the same thing for sub filters
    Object.keys(filterOptions.filters).forEach((key) => {
      if(getNested(() => filterOptions.filters[key].filters, false)) {
        filterOptions.filters[key] = this.sortFilters(filterOptions.filters[key]);
      }

      // Sort options by weight as well
      if(getNested(() => filterOptions.filters[key].options)) {
        const sortedOptionKeys = Object.keys(filterOptions.filters[key].options).sort((a, b) => {
          if(typeof a === 'undefined') {
            return 1;
          }

          if(
            getNested(() => filterOptions.filters[key].options[a].data.weight) !== false &&
            getNested(() => filterOptions.filters[key].options[b].data.weight) !== false
          ) {
            return filterOptions.filters[key].options[a].data.weight - filterOptions.filters[key].options[b].data.weight;
          }

          return 0;
        });

        filterOptions.filters[key].order = sortedOptionKeys;
      }
    });

    return filterOptions;
  }

  /*
   * Get the number of products based on the key of a type
   *  returns a number
   */
  getNumberOfProducts(key1, key2) {
    // Loop over the filtered items list
    // and filter the list based on the matches we find.
    // Filter returns a array so we need to take the length of that.
    let products = this.itemList;

    if(key2) {
      products = products.filter(item => this.findMatch(item, key2));
    }

    return products.filter(item => this.findMatch(item, key1)).length;
  }

  /*
   * Set the Items
   */
  @action setItems(items) {
    this._itemList = items;
    // clear filters when we receive new items
    this.selectedFilters = [];
    this.updateDisplayList();
  }
}

export default ProductStore;
