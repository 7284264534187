import React from 'react';
import styled from 'styled-components';

export default styled.div`
  position: relative;
  
  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;
