import React from 'react';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { observer } from 'mobx-react';
import icons from '../../../icons/icons';
import TopBarFlyOut from '../../TopBarFlyOut/TopBarFlyOut';
import FlyOutStore from '../../../../../stores/FlyOutStore';
import SiteStore from '../../../../../stores/SiteStore';
import { stripProtocol } from '../../../../../misc/index';
import { countryLanguage, languages } from '../../../../../config';
import LanguageButton from './styled/LanguageButton';
import MenuItem from './styled/MenuItem';
import LangText from './styled/LangText';

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

@observer
@withCookies
class LanguageSwitchMenuItem extends React.Component {

  constructor(props) {
    super(props);
    this.siteStore = SiteStore.getInstance();
    this.FlyOutStore = FlyOutStore.getInstance();

    this.openFlyOut = this.openFlyOut.bind(this);
    this.closeFlyOut = this.closeFlyOut.bind(this);
    this.shouldShowLanguageSwitcher = this.shouldShowLanguageSwitcher.bind(this);
    this.checkLanguageSwitcherCookie = this.checkLanguageSwitcherCookie.bind(this);
    this.setLanguageSwitcherCookie = this.setLanguageSwitcherCookie.bind(this);
    this.getCookieLanguages = this.getCookieLanguages.bind(this);
    this.getLanguageButtons = this.getLanguageButtons.bind(this);
  }

  componentDidMount() {
    if(this.shouldShowLanguageSwitcher()) {
      this.setLanguageSwitcherCookie();
    }
  }

  openFlyOut(ev) {
    ev.preventDefault();

    this.FlyOutStore.openFlyOut('language_switcher');
  }

  closeFlyOut() {
    this.FlyOutStore.closeFlyOut('language_switcher');
  }

  shouldShowLanguageSwitcher() {
    const { lang } = this.props;
    // show language switcher when:
    // - the current language is not english
    // - a user comes from one of the countries we have translations for
    // - the user has a language switcher cookie, because he visited a page with language switcher today
    if(lang !== 'en') {
      return true;
    }
    else if(languages.indexOf(this.siteStore.country) > -1) {
      return true;
    }
    else if(this.checkLanguageSwitcherCookie()) {
      return true;
    }

    return false;
  }

  checkLanguageSwitcherCookie() {
    const { cookies } = this.props;

    return cookies.get('DavidoffShowLanguageSwitcher') || false;
  }

  setLanguageSwitcherCookie() {
    const { cookies } = this.props;

    cookies.set('DavidoffShowLanguageSwitcher', true, {
      expires: new Date().addHours(4),
      path: '/',
    });

    cookies.set('DavidoffLanguageSwitcherLanguages', this.getCookieLanguages(), {
      expires: new Date().addHours(4),
      path: '/',
    });
  }

  getCookieLanguages() {
    const { cookies, lang } = this.props;
    const cookieLanguages = cookies.get('DavidoffLanguageSwitcherLanguages');

    if(cookieLanguages && cookieLanguages.indexOf(lang) > -1) {
      return cookieLanguages;
    }
    else if(cookieLanguages && cookieLanguages.indexOf(lang) === -1) {
      return `${cookieLanguages},${lang}`;
    }

    return lang;
  }

  getLanguageButtons() {
    // Always send English and the rest of the languages that somebody is allowed to see.
    const { settings, lang } = this.props;
    const cookieLanguages = this.getCookieLanguages().split(',');
    cookieLanguages.push('en'); // Always add English...

    return Object.keys(settings.languages).map((language) => {
      if(
        cookieLanguages.indexOf(language) > -1 ||
        language === this.siteStore.country
      ) {
        return (
          <LanguageButton
            key={`language-${language}`}
            to={stripProtocol(settings.languages[language].uri)}
            data-active={lang === language}
            onClick={this.closeFlyOut}
          >
          {settings.languages[language].title}
          </LanguageButton>);
      }

      return null;
    });
  }

  render() {
    if(!this.shouldShowLanguageSwitcher()) {
      return null;
    }

    const { menuItem, settings, lang } = this.props;
    const Title = <LangText>{ icons.globe } {settings.languages[lang].title}</LangText>;
    const LanguageButtons = this.getLanguageButtons();

    return (
      <MenuItem menuItem={menuItem} title={Title} onClick={this.openFlyOut} active={this.FlyOutStore.flyOuts.language_switcher}>
        <TopBarFlyOut type='language_switcher'>
          {LanguageButtons}
        </TopBarFlyOut>
      </MenuItem>
    );
  }
}

LanguageSwitchMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    title:PropTypes.string.isRequired,
    uri: PropTypes.string,
    external: PropTypes.bool.isRequired,
  }).isRequired,
  settings: PropTypes.shape().isRequired,
  lang: PropTypes.string.isRequired,
  cookies: PropTypes.instanceOf(Cookies),
};

LanguageSwitchMenuItem.defaultProps = {};

export default LanguageSwitchMenuItem;
