import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import FlyOutStore from '../../../../stores/FlyOutStore';
import FlyOutBox from './styled/FlyOutBox';
import Close from './styled/Close';


@observer
class TopBarFlyOut extends React.Component {
  @observable FlyOutStore = FlyOutStore.getInstance();

  constructor(props) {
    super(props);

    this.closeFlyout = this.closeFlyout.bind(this);
  }

  closeFlyout(ev) {
    ev.preventDefault();

    this.FlyOutStore.closeFlyOut(this.props.type);
  }

  render() {
    const { children, type } = this.props;

    return (
      <FlyOutBox open={this.FlyOutStore.flyOuts[type]} onClick={this.closeFlyout}>
        <div className='flyout-inner-wrap'>
          <div className="container">
            <div className="flyout-inner" onClick={(ev) => ev.stopPropagation()}>
              {children}
            </div>
            <Close href="#" onClick={this.closeFlyout} open={this.FlyOutStore.flyOuts[type]} />
          </div>
        </div>
      </FlyOutBox>
    );
  }
}

TopBarFlyOut.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  type: PropTypes.string.isRequired,
};

export default TopBarFlyOut;
