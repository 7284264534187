import React from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import getNested from 'get-nested';
import { dataMapper } from '../../index';
import { formatProductName } from '../../../misc/index';
import { Link } from 'react-router-dom';
//styled
import RelatedProductContainer from './styled/RelatedProductContainer';
import Image from './styled/Image';
import Title from './styled/Title';

class RelatedProduct extends React.Component {
  constructor(props) {
    super(props);

    this.trackProductClick = this.trackProductClick.bind(this);
  }

  trackProductClick() {
    if(root.dataLayer) {
      const { paragraph, data } = this.props;
      const product = dataMapper(data, paragraph.field_product.target_uuid);

      if (product && product.__hn) {
        root.dataLayer.push({
          event: 'related-product-click',
          category: 'Related product clicked',
          currentUrl: root.location.href,
          productUrl: product.__hn.url,
          productTitle: product.title,
        });
      }
    }
  }

  render() {
    const { paragraph, data } = this.props;
    const product = dataMapper(data, paragraph.field_product.target_uuid);
    if(!product) {
      return null;
    }
    const mediaReferences = product.field_media_multiple;

    const productGroup = dataMapper(data, product.field_product_group.target_uuid);
    const productName = formatProductName(productGroup.field_display_text, product.field_display_text).groupName;

    if(!Array.isArray(mediaReferences) || mediaReferences.length === 0) {
      return null;
    }

    const settings = {
      aspect_ratio: '1:1',
      crop: 'fill',
      height: 280,
      placeholder: '',
    };

    const sources = [
      {
        settings,
        media: '(min-width: 0)',
      }
    ];

    return (
      <RelatedProductContainer>
        <Link to={product.__hn ? product.__hn.url : '/'} onClick={this.trackProductClick}>
          <Title>{productName}</Title>
          <Image
            imageType="RelatedProduct"
            data={data}
            mediaReference={mediaReferences[0]}
            settings={settings}
            sources={sources}
          />
        </Link>
      </RelatedProductContainer>
    );
  }
}

RelatedProduct.Proptypes = {
  paragraph: PropTypes.shape({
    field_label: PropTypes.string.isRequired,
    field_product: PropTypes.shape({
      field_overview_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
      }).isRequired,
    }).isRequired
  }).isRequired
}

export default RelatedProduct;
