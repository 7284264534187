import React from 'react';
import styled from 'styled-components';

export const SpecialActivityWrapper = styled.section`
  background-color: #fff;
  padding-bottom: 32px;
  
  @media (min-width: 768px) {
    background-color: #f7f5f3;
    text-align: center;
  }
`;
