import React from 'react';
import PropTypes from 'prop-types';
import ReactTransitionGroup from 'react-transition-group/TransitionGroup';
import getNested from 'get-nested';
import { dataMapper } from '../../index';
// Components
import TabNav from '../TabNav/TabNav';
import Story from '../../paragraph-types/Story/animated/AnimatedStory';
// Styled
import DetailsWrapper from './styled/DetailsWrapper';
import ActiveTabContent from './styled/ActiveTabContent';

// Need to add the slider and the tabs
class ProductDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      tabs: props.details.map(detail => ({
        key: detail.target_uuid,
        data: dataMapper(props.data, detail.target_uuid),
      })),
    };

    this.goToTab = this.goToTab.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      activeTab: 0,
      tabs: nextProps.details.map(detail => ({
        key: detail.target_uuid,
        data: dataMapper(nextProps.data, detail.target_uuid),
      })),
    });
  }

  goToTab(ev) {
    this.setState({
      activeTab: parseFloat(ev.target.value),
    });
  }

  render() {
    // Returning just one note untill client agrees to have more
    const tabNavItems = this.state.tabs.map((tab) => {
      return {
        title: tab.data.field_tab_name || tab.data.field_label,
        key: tab.key,
      }
    });

    return (
      <DetailsWrapper noPadding={tabNavItems.length > 1}>
        {tabNavItems.length > 1 &&
          <TabNav
            navItems={tabNavItems}
            activeTab={this.state.activeTab}
            changeHandler={this.goToTab}
          />
        }
        <ActiveTabContent>
          <ReactTransitionGroup>
            <Story
              data={this.props.data}
              key={this.state.tabs[this.state.activeTab].key}
              paragraph={this.state.tabs[this.state.activeTab].data}
            />
          </ReactTransitionGroup>
        </ActiveTabContent>
        </DetailsWrapper>
    );
  }
}

ProductDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    })
  ),
  data: PropTypes.shape().isRequired,
};

export default ProductDetails;
