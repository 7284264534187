import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.section`
  background-color: ${davidoff.colors.lime};
  padding: 45px 0 10px;
  
  @media (min-width: 768px) {
    padding: 60px 0;
  }
`;
