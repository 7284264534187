import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import getNested from 'get-nested';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { stripProtocol } from '../../../misc/index';
import SiteStore from '../../../stores/SiteStore';
import ArticleBlock from './styled/ArticleBlock';
import ArticleImageWrapper from './styled/ArticleImageWrapper';
import ArticleImage, { ArticleImageWithoutReference } from './styled/ArticleImage';
import ArticleTitle from './styled/ArticleTitle';
import ArticleLabel from './styled/ArticleLabel';
import ArticleLink from './styled/ArticleLink';
import { dataMapper } from "../../index";

const ArticleA = styled(Link)`
  display: block;
  text-decoration: none;
`;

@inject('translationStore')
@observer
class Article extends React.Component {
  constructor(props) {
    super(props);

    this.SiteStore = SiteStore.getInstance();
  }

  getArticleImage() {
    const { article, data } = this.props;
    const imageWidth = Math.round(384 * 1.1);
    const imageHeight = Math.round(280 * 1.1);

    const settings = {
      height: imageHeight,
      width: imageWidth,
      crop: 'fill',
      gravity: 'auto',
    };

    const sources = [
      {
        settings,
        media: '(min-width: 0)',
      }
    ];

    if (article.mediaImage) {
      return (
        <ArticleImageWithoutReference
          imageType="ArticleImage"
          sources={sources}
          mediaImage={article.mediaImage}
          settings={settings}
        />
      );
    } else if (article.field_media) {
      const mediaReference = article.field_media

      return (
        <ArticleImage
          imageType="ArticleImage"
          data={data}
          sources={sources}
          mediaReference={mediaReference}
          settings={settings}
        />
      );
    } else if (article.field_header) {
      const header = dataMapper(data, getNested(() => article.field_header.target_uuid));
      const mediaReference = header.field_media;

      return  (
        <ArticleImage
          imageType="ArticleImage"
          data={data}
          sources={sources}
          mediaReference={mediaReference}
          settings={settings}
        />
      );
    }

    return null;
  }

  render() {
    const { article, isfeatured, translationStore } = this.props;
    const url = article.uri ? article.uri : getNested(() => article && article.__hn ? article.__hn.url : '/', '/');

    return (
      <ArticleBlock>
        {isfeatured &&
          <ArticleLabel>{translationStore.translate('Featured')}</ArticleLabel>
        }
        <ArticleA to={stripProtocol(url)}>
          <ArticleImageWrapper>
            {this.getArticleImage()}
          </ArticleImageWrapper>
          <ArticleTitle>
            {article.title}
          </ArticleTitle>
          <ArticleLink>{translationStore.translate('Read the full story')}</ArticleLink>
        </ArticleA>
      </ArticleBlock>
    );
  }
}

Article.propTypes = {
  article: PropTypes.shape({
    __hn: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    path: PropTypes.shape({
      alias: PropTypes.string.isRequired,
    }),
    uri: PropTypes.string,
    field_media: PropTypes.shape(),
    field_header: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Article;
