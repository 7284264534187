import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export const DontShowAgainButton = styled.button`
  ${({theme}) => theme.fonts.parse('sansSerif', 'regular', '14px')}
  background: transparent;
  border: 0;
  color: #fff;
  margin: 25px 0 0;
  outline: none;
  padding: 0;
  text-decoration: underline;
  transition: color 0.35s ease-out;
  transform: translateY(-50%);
  
  @media (min-width: 768px) {
    margin: 0;
    position: absolute;
    right: 20px;
    top: 50%;
  
    &:hover {
      text-decoration: underline;
      color: ${davidoff.colors.orangeLink}
    }
  }
`;

export default DontShowAgainButton;
