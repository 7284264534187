// import davidoff from '../themes/davidoff';

const mobileSpacingValues = {
  spacing: '20px',
  titleIndent: '20px',
  titleIndentFull: '40px',
  gridInnerSpacing: '16px',
  gridInnerSpacingHalf: '8px',
  subtitleIndent: '35px',
};

const desktopSpacingValues = {
  spacing: '30px',
  sideSpacing: '105px',
  subtitleIndent: '30px',
  gridInnerSpacing: '32px',
  gridInnerSpacingHalf: '22px',
};

const containerStyles = `
  margin: 0 auto;
  max-width: 1216px;
  width: 100%;
`;

const containerPaddingStyles = `
  padding: 0 20px;
  
  @media (min-width:1220px) {
    padding: 0;
  }
`;

const flexColumnStyles = `
  overflow: hidden;
  position: relative;
`;
  // @media (min-width: 768px) {
  //   margin: 0 15px;
  //   flex: 1;
  //
  //   &:first-child {
  //     margin-left: 0;
  //   }
  //
  //   &:last-child {
  //     margin-right: 0;
  //   }
  // }

const flexItemStyles = {
  halfMobile: `
    width: calc(50% - ${mobileSpacingValues.gridInnerSpacingHalf});
  `,
  halfDesktop: `
    width: calc(50% - ${desktopSpacingValues.gridInnerSpacingHalf});
  `,
  third: `
    width: calc(33.33% - ${desktopSpacingValues.gridInnerSpacingHalf});
  `,
};

export default {
  container: containerStyles,
  containerPadding: containerPaddingStyles,
  flexColumn: flexColumnStyles,
  flexItem: flexItemStyles,
  mobileValues: mobileSpacingValues,
  desktopValues: desktopSpacingValues,
};
