import React from 'react';
import styled from 'styled-components';
import ArticleBlock from './ArticleBlock';
import MediaImageContainer from '../../MediaImage/index.c';
import MediaImage from '../../MediaImage/index.p';

export const ArticleImageWithoutReference = styled(MediaImage)`
  display: block;
  text-decoration: none;
  width: 100%;
  
  @media (min-width: 768px) {
    left: 50%;
    position: absolute;
    top: 50%;
    transition: transform 0.35s linear;
    transform: translate(-50%, -50%);
    transform-origin: center center;
  
    ${ArticleBlock}:hover & {
      transition: transform 2s linear;
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`

export default styled(MediaImageContainer)`
  display: block;
  text-decoration: none;
  width: 100%;
  
  @media (min-width: 768px) {
    left: 50%;
    position: absolute;
    top: 50%;
    transition: transform 0.35s linear;
    transform: translate(-50%, -50%);
    transform-origin: center center;
  
    ${ArticleBlock}:hover & {
      transition: transform 2s linear;
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;