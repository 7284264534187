import React from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import { Link } from 'react-router-dom';
//styled
import Title from './styled/Title';
import ProductHighlightItemWrapper from './styled/HighlightItemWrapper';
import ImageWrapper from './styled/ImageWrapper';
import MediaImageContainer from '../MediaImage/index.c';
import MediaImage from '../MediaImage/index.p';

class ProductHighlightItem extends React.Component {
  constructor(props) {
    super(props);

    this.navigateHiglight = this.navigateHiglight.bind(this);
  }

  navigateHiglight() {
    const { product } = this.props;

    if(root.dataLayer) {
      root.dataLayer.push({
        event: 'highlight-click',
        category: 'highlight clicked',
        currentUrl: root.location.href,
        productUrl: product.url,
        productTitle: product.title,
      });
    }
  }

  getMedia = () => {
    const { data, product } = this.props;

    const settings = {
      crop: 'fill',
      width: 384,
      height: 280,
      placeholder: '',
      gravity: null,
    };

    const sources = [
      {
        settings,
        media: '(min-width: 0)',
      }
    ];

    if (product.mediaReference) {
      return (
        <MediaImageContainer
          imageType="ProductHighlightItem"
          data={data}
          mediaReference={product.mediaReference}
          settings={settings}
          sources={sources}
        >
          {props => {
            const width = props.mediaImage.field_media_image.width;
            const height = props.mediaImage.field_media_image.height;

            if (width === height) {
              settings.crop = 'pad';
            }

            return (
              <MediaImage
                imageType="ProductHighlightItem"
                mediaImage={product.mediaImage}
                settings={settings}
                sources={sources}
                lazyLoad={false}
              />
            );
          }}
        </MediaImageContainer>
      );
    } else if (product.mediaImage) {
      const width = product.mediaImage.field_media_image.width;
      const height = product.mediaImage.field_media_image.height;

      if (width === height) {
        settings.crop = 'pad';
      }

      return (
        <MediaImage
          imageType="ProductHighlightItem"
          mediaImage={product.mediaImage}
          settings={settings}
          sources={sources}
        />
      );
    }

    return null;
  }

  render() {
    const { product } = this.props;

    return (
      <ProductHighlightItemWrapper>
        <Link to={product.url} onClick={this.navigateHiglight}>
          <ImageWrapper>
            {this.getMedia()}
          </ImageWrapper>
          <Title>{product.title}</Title>
        </Link>
      </ProductHighlightItemWrapper>
    );
  }
}


ProductHighlightItem.propTypes = {
  data: PropTypes.shape(),
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    mediaReference: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }),
    mediaImage: PropTypes.shape({
      field_media_image: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductHighlightItem;
