import styled from "styled-components";
import React from 'react';
import StyledSelect from '../../../style-guide/Select/Select';

const Select = styled(StyledSelect)`
  .Select {
    margin-bottom: 0;
  }
  
  .Select-control {
    height: 50px !important;
  }
  
  .Select-multi-value-wrapper .Select-value,
  .Select-multi-value-wrapper .Select-input{
    line-height: 50px !important;
    height: 50px !important;
  }
  
  .Select-value {
    background-color: #f7f5f3;
    border-radius: 4px;
  }
  //
  //.Select-arrow-zone {
  //  background-color: #f7f5f3;
  //}
`;

export default Select;
