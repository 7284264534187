// import getNested from 'get-nested';
import { observable, computed, action, toJS } from 'mobx';
import queryString from 'query-string';
import TweenLite from 'gsap/TweenLite';
import root from 'window-or-global';
import 'gsap/ScrollToPlugin';
import viewPort from '../lib/viewport';

/**
 * Overview Store:
 * - Base class for the overviews
 * - Contains a paginator
 * - Contains a setup for filters
 */

class OverviewStore {
  constructor() {
    this.filterList = this.filterList.bind(this);
    this.setItems = this.setItems.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.switchPage = this.switchPage.bind(this);
    this.updateDisplayList = this.updateDisplayList.bind(this);
  }

  // Define the observable properties
  @observable _itemList = [];
  @observable selectedFilter = 'all';
  @observable filters = [];
  @observable data = {};
  @observable updateUrl = true;

  // element to scroll to after a filter happend
  @observable wrapperElement = false;
  // eslint-disable-next-line class-methods-use-this
  @computed get scrollOffset() {
    if(root.document && viewPort().width > 767) {
      return 55;
    }

    return -1;
  }



  // Pagination fields
  @observable currentPage = typeof location !== 'undefined' && location.search ? this.getPageNumberFromUrl() : 0;
  @observable postsPerPage = 4;
  @observable maxPages = 0;

  // Compute the displayed Items based on the pagination paramaters
  @observable _displayedItems = [];

  @computed get itemList() {
    return toJS(this._itemList);
  }

  @computed get displayedItems() {
    return toJS(this._displayedItems);
  }

  @computed get filteredItems() {
    return this.getFilteredList();
  }

  /*
  * Get param from url and replace any character thats not an integer with ''
  * Turn that part of the url string into an integer
  * Return that integer - 1 (0 based index)
  */
  getPageNumberFromUrl() {
    // const intFromString = location.search.replace( /[^\d.]/g, '' );
    const url = new URL(location.href);
    const pageInt = url.searchParams.get("page");
    const intForPagination = parseInt(pageInt, 10);

    return intForPagination ? intForPagination - 1 : 0;
  }

  /*
   * Set the getFiltered list to return everything by default
   */
  getFilteredList() {
    return this.itemList;
  }

  /*
   * Set the Items
   */
  @action setItems(items) {

    this._itemList = items;
    
    this.updateDisplayList();
    this.updatePages();
  }

  /*
   * Filter by Term
   */
  @action filterList(ev) {
    this.selectedFilter = ev.target.value;

    // After we selected the latest display items.. apply the search term to it

    // Reset the current page
    this.currentPage = 0;
    // Update the display list
    this.updateDisplayList(true);
    this.updatePages();
  }

  /*
   * Pagination: nextPage
   * - Updates the current page to go to the next page
   */
  nextPage() {
    if(this.currentPage + 1 <= this.maxPages) {
      this.goToPage(this.currentPage + 1);
    }
  }

  /*
   * Pagination: prevPage
   * - Updates the current page to go to the previous page
   */
  prevPage() {
    if(this.currentPage - 1 >= 0 && this.currentPage - 1 <= this.maxPages) {
      this.goToPage(this.currentPage - 1);
    }
  }

  /*
   * Pagination: switchPage
   * - clickHandler that accepts a data attribute that tells what page it is.
   * - Navigates to the set page
   */
  switchPage(ev) {
    ev.preventDefault();
    const pageNum = ev.target.getAttribute('data-page-number');

    this.goToPage(pageNum);
  }

  /*
   * Pagination: goToPage
   * - Sets the state of current page to the number n
   * - Updates the displayList after
   */
  @action goToPage(n) {
    this.currentPage = parseFloat(n);

    // update the current url.
    if(this.updateUrl) {
      window.history.pushState('', '', `?${queryString.stringify({ page: this.currentPage + 1 })}`);
    }
    // Update the Display list.
    this.updateDisplayList(true);
  }

  /*
   * updateDisplayList:
   * - sets the state of the displayed items to a section of the filtered items,
   * based on the pagination state
   */
  @action updateDisplayList(withScroll = false) {
    const begin = this.currentPage * this.postsPerPage;
    const end = (this.currentPage * this.postsPerPage) + this.postsPerPage;
    this._displayedItems = this.filteredItems.slice(begin, end);

    this.updatePages();

    // After everything is updated. Scroll to the top of the block
    // root.scrollBy(wrapperElement);
    if(this.wrapperElement && withScroll) {
      TweenLite.to(
        root,
        1,
        {
          scrollTo: {
            y: this.wrapperElement,
            offsetY: this.scrollOffset,
            autoKill: false,
          },
        },
      );
    }
  }

  /*
   * updatePages:
   * - Updates the max number of pages based on the length of the filteredItems
   * - Always round up the number.
   */
  @action updatePages() {
    this.maxPages = Math.ceil(this.filteredItems.length / this.postsPerPage) - 1; // -1 because 0 based indexes...
  }
}

export default OverviewStore;
