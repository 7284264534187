import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import Button from '../../../style-guide/Button/Button';

export default styled(Button)`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '13px')}
  background-color: ${davidoff.colors.orange};
  border: 2px solid transparent;
  border-radius: 3px;
  color: #fff;
  height: 40px; 
  line-height: 38px;
  letter-spacing: 1px;
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 300ms;
  width: 100%;
  
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    width: auto;
  }
  
  &:hover {
    background-color: ${davidoff.colors.orange};
  }

  &.accept-all {
    margin-top: 16px;
    margin-left: 16px;

    @media only screen and (min-width: 1024px) {
      margin-left: 16px;
    }
  }
`;
