import styled from 'styled-components';

const ProductWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1216px;
  padding: 0 20px;
  width: 100%;
  
  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
  
  @media (min-width: 1256px) {
    padding: 0;
  }
`;

export default ProductWrapper;
