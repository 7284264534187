const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .85)',
    zIndex: 199,
  },
  content: {
    background: 'transparent',
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: 'auto',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    border: '0',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    padding: 0,
    outline: 'none',
    overflow: 'visible',
    width: '90%',
    maxWidth: '960px',
  },
};

export default modalStyles;
