// Core
import React from "react";
import root, { console, JSON } from "window-or-global";
import SiteStore from "../../../stores/SiteStore";

// API-related
import getNested from "get-nested";
import { dataMapper } from "../../index";

// Components
import HyphenTitle from "../../style-guide/HyphenTitle/HyphenTitle";
import Price from "../../common/ProductPrice";
import Icons from "../../common/icons/icons";
import ProductLink from "../../common/products/Product/styled/ProductLink";

// Styles
import * as styles from "./styles";
import { formatProductName, stripProtocol } from '../../../misc';

// TODO: Buybuttons functionality is not yet implemented here

// type Dimensions = "mobile" | "tablet" | "desktop";
export function CampaignProductTilesSlider(props) {
  const { paragraph, data } = props;
  const [hasDimension, setHasDimension] = React.useState("desktop");

  const instance = SiteStore.getInstance();
  const defaultButtonLabel = getNested(
    () => instance.settings.content[instance.language].product_button_text,
    false
  );

  React.useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth >= 1024) {
        setHasDimension("desktop");
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setHasDimension("tablet");
      } else {
        setHasDimension("mobile");
      }
    };

    checkWindowSize();

    window.addEventListener("resize", checkWindowSize);
    return () => window.removeEventListener("resize", checkWindowSize);
  }, [hasDimension]);

  const navigateHighlight = (product) => {
    if (root.dataLayer) {
      root.dataLayer.push({
        event: "highlight-click",
        category: "highlight clicked",
        currentUrl: root.location.href,
        productUrl: product.url,
        productTitle: product.title,
      });
    }
  };

  const HighlightedProducts =
    paragraph.field_campaign_products &&
    paragraph.field_campaign_products.map((highlightedProduct) => {
      const itemParagraph = dataMapper(
        data,
        getNested(() => highlightedProduct.target_uuid)
      );
      const groupParagraph = dataMapper(
         data,
         getNested(() => itemParagraph.field_product_group.target_uuid)
      );

      if (!itemParagraph) {
        return null;
      }
      if (!itemParagraph.field_media_multiple > 0) {
        return null;
      }

      const settings = {
        height: 385,
        width: 385,
        crop: "fill",
      };

      const sources = [
        {
          settings,
          media: "(min-width: 0)",
        },
      ];
      const productTitle = formatProductName(
        groupParagraph.field_display_text,
        itemParagraph.field_display_text
      );

      return (
        <styles.ProductBlock key={`highlight-${itemParagraph.nid}`}>
          <ProductLink
            to={itemParagraph.path.alias}
            onClick={() => navigateHighlight(itemParagraph)}
          >
            <styles.ProductImageWrapper>
              <styles.ProductImage
                imageType="ProductImage"
                data={data}
                mediaReference={itemParagraph.field_media_multiple[0]}
                settings={settings}
                sources={sources}
                lazyLoad={false}
              />
            </styles.ProductImageWrapper>
            <styles.ProductTitle>
              {productTitle.groupName ? productTitle.groupName : itemParagraph.title}
              <span>
                {productTitle.variantName
                  ? productTitle.variantName
                  : itemParagraph.field_display_text}
              </span>
            </styles.ProductTitle>
            {highlightedProduct.buy_button_url && highlightedProduct.price && (
              <React.Fragment>
                <Price fontWeight="bold">{highlightedProduct.price}</Price>
              </React.Fragment>
            )}
          </ProductLink>
          <styles.ctaLink
            to={stripProtocol(highlightedProduct.buy_button_url)}
            target='_blank'
          >
            {highlightedProduct.buy_button_label
              ? highlightedProduct.buy_button_label
              : defaultButtonLabel}
          </styles.ctaLink>
        </styles.ProductBlock>
      );
    });

  const getSlidesAmount = () => {
    switch (hasDimension) {
      case "desktop":
        return 4;
      case "tablet":
        return 2;
      case "mobile":
        return 1;
    }
  };

  const baseCarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: getSlidesAmount(),
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    accessibility: true,
    nextArrow: (
      <styles.NavButton direction="next" amount={getSlidesAmount()}>
        {Icons.drop_down_arrow}
      </styles.NavButton>
    ),
    prevArrow: (
      <styles.NavButton direction="prev" amount={getSlidesAmount()}>
        {Icons.drop_down_arrow}
      </styles.NavButton>
    ),
  };
  return (
    <styles.ProductTilesWrapper>
      <styles.ProductTilesWrapperInner>
        <div className="product__tiles_container">
          <HyphenTitle.h2>
            <span>{paragraph.field_campaign_p_s_title}</span>
          </HyphenTitle.h2>
        </div>
      </styles.ProductTilesWrapperInner>
      <section>
        <styles.ProductTilesWrapperInner>
          <styles.ProductWrapper>
            <styles.ProductTilesSlider {...baseCarouselSettings}>
              {HighlightedProducts}
            </styles.ProductTilesSlider>
          </styles.ProductWrapper>
        </styles.ProductTilesWrapperInner>
      </section>
    </styles.ProductTilesWrapper>
  );
}
