import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';

export default styled.div`
  ${structureStyles.container}
  
  > div {
    @media (max-width: 767px) {
      justify-content: space-between;
    }
  }
`;
