import React from 'react';
import styled from 'styled-components';
import Columns from '../../../style-guide/Structure/Columns';

export default styled.div`
  display: flex;
  padding: 0 0 20px;
  
  @media (min-width: 768px) {
    padding-bottom: 30px;
  }
  @media (min-width: 1024px) {
    padding: 60px 0 30px;
  }
`;
