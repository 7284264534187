import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import getNested from "get-nested";
import root from "window-or-global";
import queryString from "query-string";
import mapper, { dataMapper } from "../../index";
// Stores
import SiteStore from "../../../stores/SiteStore";
import ProductStore from "../../../stores/ProductStore";
// Components
import Columns from "../../style-guide/Structure/Columns";
import Paginator from "../../common/Paginator/Paginator";
import ProductView from "../../common/products/ProductView/ProductView";
import ProductFilter from "../../common/products/filter/ProductFilter/ProductFilter";
// Styled components
import ProductsWrapper from "./styled/ProductsWrapper";
import ProductItemsWrapper from "./styled/ProductItemsWrapper";
import RelatedArticlesView from "../../common/RelatedArticlesView/RelatedArticlesView";
import ProductHighlight from "../../common/ProductHighlight/ProductHighlight";
import ItemListSchema from "../../layout/ItemListSchema";

@observer
class Category extends React.Component {
  constructor(props) {
    super(props);
    this.setupPage = this.setupPage.bind(this);
    // setup stores for this page
    this.SiteStore = SiteStore.getInstance();
    this.ProductStore = ProductStore.getInstance();

    // Setup the page
    this.setupPage(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.setupPage(nextProps);
    }
  }

  setupPage(props) {
    // Get all the products from the view
    const view = dataMapper(
      props.data,
      props.page.field_product_overview.target_uuid
    );
    const products = view.results.map((productID) =>
      dataMapper(props.data, productID)
    );
    this.ProductStore.selectedFilters.clear();
    this.ProductStore.setItems(products);
    this.ProductStore.viewType = props.page.field_product_overview.target_id;
    this.ProductStore.filters = view.display.filters;
    this.ProductStore.data = props.data;
    this.ProductStore.settings = props.settings;
    this.ProductStore.language = props.lang;
    this.ProductStore.setFilterOptions();
    this.ProductStore.filterBlocks = this.ProductStore.filterOptions;
    // catch the paginated view
    const currentPage = getNested(
      () => this.SiteStore.queryParams.page,
      getNested(() => queryString(root.location.search).page, false)
    );

    if (currentPage) {
      this.ProductStore.currentPage = parseFloat(currentPage) - 1;
    } else {
      this.ProductStore.currentPage = 0;
    }

    this.ProductStore.updateDisplayList();
  }

  render() {
    const { page, data } = this.props;
    const currentCategory = getNested(
      () => page.field_category.target_uuid,
      getNested(() => page.field_collection.target_uuid, "")
    );
    let headerData = getNested(() => page.field_header.target_uuid, false);

    if (!headerData) {
      const collection = getNested(
        () => page.field_collection.target_uuid,
        false
      );
      const collectionData = dataMapper(data, collection);

      if (collectionData) {
        headerData = getNested(
          () => collectionData.field_header.target_uuid,
          false
        );
      }
    }
    // Layout:
    // => BrandIntroduction block
    // => Two column layout with
    // => Filters
    // => Products + Paginator
    // Related stories

    const hasFilters = this.ProductStore.filters.length > 0;

    return (
      <div key={getNested(() => this.SiteStore.queryParams.selectedFilters)}>
        <ItemListSchema
          items={this.ProductStore.displayedItems.map((item) => ({
            url: item.uri ? item.uri : getNested(() => item && item.__hn ? item.__hn.url : '/', "/"),
          }))}
        />
        {headerData && mapper(data, false, headerData, 0, { hasAnchor: true })}
        {getNested(() => page.field_special_activity.target_uuid, false) &&
          mapper(data, false, page.field_special_activity.target_uuid)}
        <ProductsWrapper
          ref={(wrapper) => (this.ProductStore.wrapperElement = wrapper)}
        >
          <Columns>
            {hasFilters && (
              <ProductFilter data={data} currentCategory={currentCategory} />
            )}
            <ProductItemsWrapper
              hasFilters={hasFilters}
              className={`amount-${this.ProductStore.displayedItems.length}`}
            >
              <ProductView
                products={this.ProductStore.displayedItems}
                data={data}
              />
            </ProductItemsWrapper>
            <Paginator
              nextPage={this.ProductStore.nextPage}
              prevPage={this.ProductStore.prevPage}
              switchPage={this.ProductStore.switchPage}
              maxPages={this.ProductStore.maxPages}
              currentPage={this.ProductStore.currentPage}
              lang={this.SiteStore.language}
              settings={this.SiteStore.settings}
            />
          </Columns>
        </ProductsWrapper>
        <RelatedArticlesView page={page} data={data} title />
        <ProductHighlight data={data} />
      </div>
    );
  }
}

Category.propTypes = {
  page: PropTypes.shape({}).isRequired,
};

export default Category;
