import React from 'react';
import styled from 'styled-components';
import HyphenTitle from '../../../style-guide/HyphenTitle/HyphenTitle';

const Title = HyphenTitle.h2;

export default styled(Title)`
  margin-bottom: 5px;

  @media (min-width: 768px) {
    margin-bottom: 15px;
    max-width: 384px;
  }
`;
