import getNested from 'get-nested';

export function translate(string, lang, settings) {
  // get the settings
  // Check in the translations if the translation for that language is available
  // if not log a message and
  const translations = getNested(() => settings.translations[lang], false);

  if(translations) {
    return getNested(translations.filter(obj => obj.original === string)[0].translation, string);
  }

  return string;
}

export default translate;
