import React from "react";
import styled from "styled-components";
import productBG from "../../../../shared-styles/productBG";
const imageSettings = {
  breakpoints: {
    mobile: {
      breakpoint: "",
      height: 170,
      width: 335,
    },
    tablet: {
      breakpoint: "768px",
      height: 170,
      width: 176,
    },
  },
};

export default styled.div`
  margin-bottom: 15px;
  padding: 10px;
  text-align: center;
  ${(props) => productBG(imageSettings, props.isCoffee)}
`;
