import React from 'react';
import styled from 'styled-components';

export default styled.div`
  background: #fff;
  left: 50%;
  padding: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
`;
