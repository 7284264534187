import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withCookies, Cookies } from 'react-cookie';
import md5 from 'md5';
import mapper from '../../index';
import { dataMapper } from '../../index';
// Components
import BrandIntroduction from '../../paragraph-types/BrandIntroduction/BrandIntroduction';
import RelatedArticlesView from '../../common/RelatedArticlesView/RelatedArticlesView';
import TranslationStore from '../../../stores/TranslationStore';
import ProductHighlight from '../../common/ProductHighlight/ProductHighlight'

@inject('translationStore')
@observer
class HomePage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { cookies } = this.props;

    cookies.set('DavidoffReturningVisitor', md5('Davidoff-Returning-Visitor'), {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    });
  }

  render() {
    const { page, data, translationStore } = this.props;

    const article = dataMapper(data, page.field_article_reference.target_uuid);
    const articleHeader = dataMapper(data, article.field_header.target_uuid);
    const articleTitle = page.field_override_title || articleHeader.field_title || '';
    const articleImage = page.field_media || articleHeader.field_media;
    const pageHeader = dataMapper(data, page.field_header.target_uuid);

    const zestForLife = {
      field_label: translationStore.translate('Zest for life'),
      field_text: {
        value: `<h2>${articleTitle}</h2>`,
      },
      field_media: articleImage,
      field_link: {
        title: translationStore.translate('Read the full story'),
        uri: page.field_article_reference.url,
      },
    };

    return (
      <div>
        {
          [
            <BrandIntroduction key={`home-page-BI-${page.field_header.target_uuid}`} data={data} paragraph={pageHeader} clickable />, // Brand introduction
            mapper(data, false, page.field_story.target_uuid), // Brand story
            mapper(data, false, page.field_discover_products.target_uuid), // Products
            <BrandIntroduction key='home-page-zest-for-life' data={data} paragraph={zestForLife} clickable />, // Zest for life
            <RelatedArticlesView key={'home-page-related-stories'} data={data} page={page} data={data} />, // Related stories
            <ProductHighlight data={data} />
          ]
        }
        </div>
    );
  }
}

HomePage.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    field_header: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

export default withCookies(HomePage);
