import React from 'react';
import styled from 'styled-components';

export default styled.ul`
  list-style: none;
  margin: 0;
  
  @media only screen and (min-width: 1025px) {
    text-align: right;
  }
  
  .dropdown-arrow {
    transform: rotate(90deg);
    width: 10px;
  }
`;
