import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import getNested from 'get-nested';
import supercluster from 'points-cluster';
import { ThemeContext } from 'styled-components';
// TODO: Dynamically load
import GoogleMap from 'google-map-react';
// helpers
import { mapsLocaleLookup } from '../../../../misc/mapsLocaleLookup';
// Stores
import SiteStore from '../../../../stores/SiteStore';
import StoreLocatorStore from '../../../../stores/StoreLocatorStore';
// Components
import icons from '../../../common/icons/icons';
import Marker from '../../../common/GoogleMapsMarker/GoogleMapsMarker';
import CurrentLocation from '../../../common/GoogleMapsCurrentLocation/GoogleMapsCurrentLocation';
import GoogleMapsSearchBox from '../../../common/GoogleMapSearchBox/GoogleMapSearchBox';
import StoreLocatorFilter from '../../../common/storeLocator/StoreLocatorFilter/StoreLocatorFilter';
import NoResultsPopup from '../../../common/GoogleMapsNoResults/GoogleMapsNoResults';
import StoreLocatorIntroduction from '../../../common/storeLocator/StoreLocatorIntroduction/StoreLocatorIntroduction';
import { StoreLocatorLoader } from '../../../common/storeLocator/StoreLocatorLoader/StoreLocatorLoader'
// Styled components
import LocatorWrapper from '../styled/LocatorWrapper';
import LocatorLeft from '../styled/LocatorLeft';
import LocatorRight from '../styled/LocatorRight';
import LocatorButton from '../styled/LocatorButton';
import LocatorMobileIcon from '../styled/LocatorMobileIcon';
import SearchWrapper from '../styled/SearchWrapper';
import SearchHint from '../styled/SearchHint';
import GoogleMapWrapper from '../styled/GoogleMap';
import FilterHeader from '../styled/FilterHeader';
import FilterHeaderText from '../styled/FilterHeaderText';
import StoreFilterInner from '../styled/StoreFilterInner';
import { Loader } from '../../../style-guide/Loader/Loader';
import TranslationStore from '../../../../stores/TranslationStore';

@inject('translationStore')
@observer
class StoreLocatorView extends React.Component {
  constructor(props) {
    super(props);

    this.SiteStore = SiteStore.getInstance();
    this.StoreLocatorStore = StoreLocatorStore.getInstance();
    this.updateMarkers = this.updateMarkers.bind(this);
    this.showNoResults = this.showNoResults.bind(this);

    this.state = {
      clusters: [],
      passedIntro: false,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidMount() {
    this.updateMarkers(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateMarkers(nextProps);
  }

  updateMarkers(props) {
    const { zoom, stores } = props;
    const Markers = stores.map((store) => {
      const coordinates = getNested(() => store.field_coordinates, false);

      if(!coordinates) {
        return false;
      }

      return {
        lat: coordinates.lat,
        lng: coordinates.lon,
        store: store,
      };
    });

    const clusters = supercluster(Markers, {
      minZoom: 1,
      maxZoom: 25,
      radius: 130,
    });
    const cluster = clusters({
      bounds: {
        nw: {
          lat: this.StoreLocatorStore.bounds.nw.lat,
          lng: this.StoreLocatorStore.bounds.nw.lng,
        },
        se: {
          lat: this.StoreLocatorStore.bounds.se.lat,
          lng: this.StoreLocatorStore.bounds.se.lng,
        }
      },
      zoom: zoom,
    });

    this.setState({
      clusters: cluster,
      Markers,
    });
  }

  showNoResults (stores, showIntro) {
    const { isLoadingStores, hasStoresInRange } = this.StoreLocatorStore;

    if (
      stores.length === 0 &&
      !showIntro &&
      !isLoadingStores
    ) {
      return true;
    } else if (
      // Check if we have stores in range + we shouldn't show a intro + we are not loading + the search type was not country
      hasStoresInRange === false &&
      !showIntro &&
      !isLoadingStores &&
      !this.StoreLocatorStore.hasSearchType('country')
    ) {
      return true;
    } else if(
      !showIntro &&
      !isLoadingStores &&
      stores.length > 0 &&
      this.StoreLocatorStore.hasSearchType('country')
    ) {
      return false;
    }

    return false;
  }

  render() {
    const {
      apiLoaded,
      fitMap,
      fetchingLocation,
      filterOpened,
      locationSearched,
      map,
      onAPILoaded,
      onMapChange,
      openFilter,
      paragraph,
      searchLocation,
      setCurrentLocation,
      setLocation,
      showIntro,
      stores,
      translationStore,
    } = this.props;
    const { language } = this.SiteStore;

    // Show a Intro screen first.
    return (
      <LocatorWrapper>
        <LocatorLeft showSideBar={locationSearched}>
          <FilterHeader>
            <FilterHeaderText
              openFilter={filterOpened}
              onClick={openFilter}
            >
              <span
                dangerouslySetInnerHTML={{ __html: translationStore.translate('Search &amp; filter') }}
              />
              {icons.drop_down_arrow}
            </FilterHeaderText>
            <LocatorMobileIcon onClick={setCurrentLocation}>
              {icons.current_location}
            </LocatorMobileIcon>
          </FilterHeader>
          <StoreFilterInner openFilter={filterOpened}>
            <LocatorButton
              fetchingLocation={fetchingLocation}
              onClick={setCurrentLocation}
            >
              {translationStore.translate('Use Current Location')}
              <Loader loading={fetchingLocation} complete={!fetchingLocation} />
            </LocatorButton>
            <SearchWrapper onSubmit={searchLocation}>
              <SearchHint
                dangerouslySetInnerHTML={{ __html: translationStore.translate('or search custom location') }}/>
              <GoogleMapsSearchBox
                map={map}
                onPlacesChanged={searchLocation}
                setLocation={setLocation}
                fitMap={fitMap}
                apiLoaded={apiLoaded}
              />
            </SearchWrapper>
            <StoreLocatorFilter />
          </StoreFilterInner>
        </LocatorLeft>
        <LocatorRight showSideBar={locationSearched}>
          {this.StoreLocatorStore.isLoadingStores !== false &&
          <StoreLocatorLoader show={this.StoreLocatorStore.isLoadingStores} />
          }
          {this.showNoResults(stores, showIntro)  &&
          <NoResultsPopup
            content={getNested(() => paragraph.field_no_results_text.value, '')}
          />
          }
          <StoreLocatorIntroduction
            showIntro={showIntro}
            content={getNested(() => paragraph.field_text_introduction.value, '')}
          >
            <LocatorButton
              fetchingLocation={fetchingLocation}
              onClick={setCurrentLocation}
            >
              {translationStore.translate('Use Current Location')}
              <Loader loading={fetchingLocation} complete={!fetchingLocation}/>
            </LocatorButton>
            <SearchWrapper onSubmit={searchLocation}>
              <SearchHint>{translationStore.translate('or search custom location')}</SearchHint>
              <GoogleMapsSearchBox
                map={map}
                onPlacesChanged={searchLocation}
                setLocation={setLocation}
                fitMap={fitMap}
                apiLoaded={apiLoaded}
              />
            </SearchWrapper>
          </StoreLocatorIntroduction>
          <GoogleMapWrapper>
            <Map
              map={map}
              clusters={this.state.clusters}
              currentLocation={this.StoreLocatorStore.currentLocation}
              navigatorLocation={this.StoreLocatorStore.navigatorLocation}
              mapsLocaleLookup={mapsLocaleLookup}
              onAPILoaded={onAPILoaded}
              onMapChange={onMapChange}
              language={language}
            />
          </GoogleMapWrapper>
        </LocatorRight>
      </LocatorWrapper>
    );
  }
}

function Map(props) {
  const theme = React.useContext(ThemeContext);
  return (
    <GoogleMap
      bootstrapURLKeys={{
        key: 'AIzaSyCZWRJ9l-6-MGTfvvRRwxvY4DpS7dRgHRw',
        libraries: 'places',
        v: '3.32',
        language: props.mapsLocaleLookup(props.language),
      }}
      defaultCenter={{
        lat: 47.551785,
        lng: 7.592743,
      }}
      center={props.currentLocation}
      defaultZoom={14}
      onGoogleApiLoaded={({ map, maps }) => props.onAPILoaded({ map, maps })}
      onChange={props.onMapChange}
      options={{
        clickableIcons: false
      }}
      yesIWantToUseGoogleMapApiInternals>
      {props.currentLocation && props.navigatorLocation &&
          <CurrentLocation
            key='current-location'
            lat={props.currentLocation.lat}
            lng={props.currentLocation.lng}
            map={props.map}
          />
      }
      {props.clusters.map((marker) => <Marker
        key={marker.points[0].store.uuid}
        lat={marker.wy}
        lng={marker.wx}
        numPoints={marker.numPoints}
        points={marker.points}
        map={props.map}
        theme={theme}
      />)}
    </GoogleMap>
  )
}

StoreLocatorView.propTypes = {
  setCurrentLocation: PropTypes.func.isRequired,
  searchLocation: PropTypes.func.isRequired,
  onAPILoaded: PropTypes.func.isRequired,
  map: PropTypes.shape(),
  onMapChange: PropTypes.func.isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore)
};

export default StoreLocatorView;
