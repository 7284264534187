import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../../themes/davidoff';

export default styled.button`
  -webkit-appearance: none;
  background: transparent;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  font-size: 0;
  text-indent: -9999px;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  position: absolute;
  right: 0;
  top: 0;
  outline: none;

  @media (min-width: 1025px) {
    display: none;
  }
  
  &:before {
    content: '';
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid ${davidoff.colors.brown};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  
  span {
    background: ${davidoff.colors.brown};
    display: block;
    height: 2px;
    left: 18px;
    position: absolute;
    right: 18px;
    top: 48%;
    
    .menu-opened & {
      background: transparent;
    }
    
    &:before,
    &:after {
      background-color: ${davidoff.colors.brown};
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      transition-duration: 0.3s, 0.3s;
      transition-delay: 0.3s, 0s;
      
      .menu-opened & {
        transition-delay: 0s, 0.3s;
      }
    }
    
    &:before {
      top: -5px;
      transition-property: top, transform;
      
      .menu-opened & {
        top: 0;
        transform: rotate(45deg);
      }
    }
    
    &:after {
      bottom: -5px;
      transition-property: bottom, transform;
      
      .menu-opened & {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
`;
