import React from 'react';
import styled from 'styled-components';

export default styled.div`
  height: 50px;
  order: 1;
  text-align: center;
  // width: 292px;
  
  @media only screen and (min-width: 1025px) {
    height: 34px;
    order: 2;
    margin: 30px auto 10px;
  }
  
  svg {
    height: 15px;
    margin: 17px 0;
    width: 125px;
    
    @media only screen and (min-width: 1025px) {
      height: 34px;
      margin: 0;
      width: auto;
    }
  }
`;
