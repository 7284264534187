import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import validator from 'validator';
import root from 'window-or-global';
import fetch from 'fetch-everywhere';
import icons from '../../common/icons/icons';
import Form from './styled/Form';
import EmailInput from './styled/EmailInput';
import ValidationMessage from './styled/ValidationMessage';
import HyphenTitles from '../../style-guide/HyphenTitle/HyphenTitle';
import NewsLetterText from './styled/NewsLetterText';
import InputWrapper from './styled/InputWrapper';
import Button from './styled/SubscribeButton';
import { Loader } from '../../style-guide/Loader/Loader';
import Subscribed from './styled/Subscribed';
const HyphenTitle = HyphenTitles.h4;

@inject('translationStore')
@observer
class NewsLetter extends Component {
  constructor(props) {
    super(props);

    this.form = null;
    this.email = null;
    this.honeyPot = null;

    this.state = {
      validated: false,
      validation: {
        email: false,
      },
      subscribed: false,
      pending: false,
      error: false,
    };

    this.subscribe = this.subscribe.bind(this);
    this.validate = this.validate.bind(this);
  }

  subscribe(ev) {
    ev.preventDefault()
    // Subscribe to mailchimp list
    // validate the fields
    if(this.validate(this.email) && this.validate(this.honeyPot)) {
      // if valid do a call to the server
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const { lang } = this.props;

      this.setState({
        pending: true,
      });

      fetch('/mailchimp/',
        {
          headers: headers,
          method: 'POST',
          content_type: 'application/json',
          body: JSON.stringify({
            email: this.email.value,
            real_name: this.honeyPot.value,
            lang,
          }),
        }
      ).then((response) => {
        return response.json();
      }).then((result) => {
        if(result.status){
          if(root.dataLayer) {
            root.dataLayer.push({
              event: 'newsletter-subscription-success',
              category: 'Newsletter subscription',
              action: 'Subscription successful',
              label: 'Location',
            });
          }
          this.setState({
            pending: false,
            subscribed: true,
          });
        } else {
          if(root.dataLayer) {
            root.dataLayer.push({
              event: 'newsletter-subscription-failed',
              category: 'Newsletter subscription',
              action: 'Subscription failed',
              label: 'Location',
            });
          }
          this.setState({
            error: true,
          });
        }
      });
    }
  }

  validate(formField) {
    switch(formField.name) {
      case 'email':
        const isValid = validator.isEmail(formField.value);
        this.setState({
          validation: {
            email: isValid,
          },
          validated: true,
        });

        return isValid;
        break;
      case 'real_name':
        return validator.isEmpty(formField.value);
      default:
        return !validator.isEmpty(formField.value);
    };
  }

  render() {
    const { translationStore } = this.props;

    if (translationStore.currentLanguage !== 'en') {
      return null;
    }

    return (
      <Form onSubmit={this.subscribe} ref={(form) => this.form = form} className={this.props.className} bottom={this.props.bottom}>
        {this.props.showTitle &&
        <HyphenTitle hyphenPosition='top' hyphenAlign='center'>{translationStore.translate('Newsletter')}</HyphenTitle>
        }
        <NewsLetterText bottom={this.props.bottom}>
          {translationStore.translate(
            'Be the first to hear about new products, collections and all the latest news from the DAVIDOFF world. Sign up for our newsletter to receive exclusive brand insights straight to your inbox.')}
        </NewsLetterText>
        <input
          type='text'
          name='real_name'
          placeholder='This field should be empty'
          style={{display:'none'}}
          ref={input => this.honeyPot= input}
        />
        <ValidationMessage
          show={!this.state.validation.email && this.state.validated}
        >
          {translationStore.translate('Please enter a valid email address')}
        </ValidationMessage>
        <InputWrapper
          pending={this.state.pending}
          subscribed={this.state.subscribed}
          error={this.state.error}
          bottom={this.props.bottom}
        >
          <EmailInput
            placeholder={translationStore.translate('Your email address')}
            type='email'
            name='email'
            required
            error={!this.state.validation.email && this.state.validated}
            ref={input => this.email = input}
            pending={this.state.pending}
            subscribed={this.state.subscribed}
          />
          <Button
            pending={this.state.pending}
            subscribed={this.state.subscribed}
            error={this.state.error}
          >{translationStore.translate('Subscribe')}
            <Loader
              loading={this.state.pending ? 1 : 0}
              complete={this.state.subscribed}
            />
          </Button>
          <Subscribed
            subscribed={this.state.subscribed}
          >{icons.check}</Subscribed>
        </InputWrapper>
      </Form>
    );
  }
}

NewsLetter.propTypes = {
  settings: PropTypes.shape({
  }).isRequired,
  lang: PropTypes.string.isRequired,
  className: PropTypes.string,
  showTitle: PropTypes.bool,
  bottom: PropTypes.bool,
};

NewsLetter.defaultProps = {
  className: '',
  showTitle: false,
  bottom: false,
};

export default NewsLetter;
