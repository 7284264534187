import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import StoryImageWrapper from './StoryImageWrapper';
import StoryBody from './StoryBody';
import StoryInner from './StoryInner';
import StoryLink from './StoryLink';
import davidoff from '../../../../themes/davidoff';

const baseDelay = 0;
const easing = {
  outExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
  inOutExpo: 'cubic-bezier(1, 0, 0, 1)',
};
const startAnimationClass = '.animate';

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

const slideInLeft = keyframes`
  0% {
		opacity: 0;
		transform: translateX(-100%);
	}
	
	100% {
      opacity: 1;
      transform: translateX(0%);
	}
`;

const fadeIn = keyframes`
  0% {
		opacity: 0;
	}
	
	100% {
      opacity: 1;
	}
`;

const fadeOut = keyframes`
  0% {
		opacity: 1;
	}
	
	100% {
      opacity: 0;
	}
`;

const fadeInTextBlack= keyframes`
  0% {
		color: rgba(0, 0, 0, 0);
	}
	
	100% {
		color: rgba(0, 0, 0, 1);
	}
`;

const fadeInTextWhite= keyframes`
  0% {
		color: rgba(255, 255, 255, 0);
		text-shadow: 1px 1px rgba(0, 0, 0, 0);
	}
	
	100% {
		color: rgba(255, 255, 255, 1);
		text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
	}
`;

const slideIn = keyframes`
  0% {
		transform: translateX(-100%);
	}
	
	100% {
    transform: translateX(0%);
	}
`;

const collapseRightOverlay = keyframes`
  0% {
		width: 300%;
	}
	
	100% {
      width: 0%;
	}
`;

const growHyphen = keyframes`
  0% {
    width: 0px;
	}
	
	100% {
    width: 60px;
	}
`;

const scaleImage = keyframes`
  0% {
    transform: scale(1);
	}
	
	100% {
    transform: scale(1.02);
	}
`;

const nudgeBottom = keyframes`
  0% {
    transform: translateY(5px);
	}
	
	100% {
    transform: translateY(0);
	}
`;

export default styled(ScrollEffect)`
  ${StoryImageWrapper} {
    position: relative;
    
    img {
      transform: scale(1);
      transform-origin: left;
      opacity: 0;
    }
    
    .story-animation-overlay {
      transform-origin: right;
      background-color: rgb(216,210, 203);
      transform: translateX(-300%);
      position: absolute;
      right: 0;
      top: 0;
      width: 300%;
      height: 100%;

      ${(props) => props.reverse ? `
        transform-origin: left;
      ` : ``}
    }
  }
  
  &${startAnimationClass} ${StoryImageWrapper} {
    img {
      animation: ${css`
        ${fpsToMs(11)}ms linear ${baseDelay + fpsToMs(16)}ms ${fadeIn},
        ${fpsToMs(52)}ms ease-out ${baseDelay + fpsToMs(28)}ms ${scaleImage}
      `};
      animation-fill-mode: forwards;
      
      ${(props) => props.reverse ? `
        animation-direction: reverse;
        animation-fill-mode: backwards;
      ` : `
        animation-direction: normal;
      `} 
    }
    
    .story-animation-overlay {
      animation: ${css`
        ${fpsToMs(12)}ms ${easing.OutExpo} ${baseDelay}ms ${slideIn},
        ${fpsToMs(14)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(13)}ms ${collapseRightOverlay}
      `};
      animation-fill-mode: forwards;
      
      ${(props) => props.reverse ? `
        animation-direction: reverse;
        animation-fill-mode: backwards;
      ` : `
        animation-direction: normal;
      `} 
    }
  }
  
  ${StoryInner} {
    h2 {
		  color: rgba(${props => props.inverseTextColor ? '255, 255, 255' : '0, 0, 0'}, 0);
      z-index: 1;
      ${props => props.inverseTextColor ? 'text-shadow: 1px 1px rgba(0, 0, 0, 0);' : ''}
      
      &::before {
        opacity: 0;
      }
        
      &::after {
        opacity: 0;
        width: 0;
      }
    }
  }
    
  &${startAnimationClass} ${StoryInner} {
    h2 {
      animation: ${css`${fpsToMs(8)}ms ease ${baseDelay + fpsToMs(28)}ms ${props => props.inverseTextColor ? fadeInTextWhite : fadeInTextBlack}`};
      animation-fill-mode: forwards;
      
      ${(props) => props.reverse ? `
        animation-direction: reverse;
        animation-fill-mode: backwards;
      ` : `
        animation-direction: normal;
      `} 
      
      &::before {
        animation: ${css`${fpsToMs(1)}ms ease ${baseDelay + fpsToMs(24)}ms ${fadeIn}`};
        animation-fill-mode: forwards;
     
        ${(props) => props.reverse ? `
          animation-direction: reverse;
          animation-fill-mode: backwards;
        ` : `
          animation-direction: normal;
        `} 
      }
      
      &::after {
        animation: ${css`
          ${fpsToMs(1)}ms linear ${baseDelay + fpsToMs(24)}ms ${fadeIn},
          ${fpsToMs(8)}ms ease-out ${baseDelay + fpsToMs(24)}ms ${growHyphen}
        `};
        animation-fill-mode: forwards;
     
        ${(props) => props.reverse ? `
          animation-direction: reverse;
          animation-fill-mode: backwards;
        ` : `
          animation-direction: normal;
        `} 
      }
    }
  }
  
  ${StoryBody}, ${StoryLink} {
    opacity: 0;
    transform: translateY(5px);
  }
  
  ${StoryLink}:hover {
    color: ${davidoff.colors.orangeLink};
  }
  
  &${startAnimationClass} ${StoryBody} {
    animation: ${css`
      ${fpsToMs(18)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(29)}ms ${fadeIn},
      ${fpsToMs(18)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(29)}ms ${nudgeBottom}
    `};
    animation-fill-mode: forwards;

    ${(props) => props.reverse ? `
      animation-direction: reverse;
      animation-fill-mode: backwards;
    ` : `
      animation-direction: normal;
    `} 
  }
  
  &${startAnimationClass} ${StoryLink} {
    animation: ${css`
      ${fpsToMs(18)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(30)}ms ${fadeIn},
      ${fpsToMs(18)}ms ${easing.OutExpo} ${baseDelay + fpsToMs(30)}ms ${nudgeBottom}
    `};
    animation-fill-mode: forwards;
    
    ${(props) => props.reverse ? `
      animation-direction: reverse;
      animation-fill-mode: backwards;
    ` : `
      animation-direction: normal;
    `} 
  }
  
`;
