import React from 'react';
import styled from 'styled-components';

export default styled.h2`
  ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '22px')}
  color: #000;
  line-height: 1.35;
  
  @media (min-width: 768px) {
    line-height: ${24/14};
  }
`
