import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { dataMapper } from "../../index";
import { TranslationStore } from "../../../stores/TranslationStore";
import WebFormContainer from "./styled/WebFormContainer";
import { WebformWrapper } from "./styled/WebformWrapper";
import HyphenTitle from "../../style-guide/HyphenTitle/HyphenTitle";
import davidoff from "../../../themes/davidoff";
import { components } from "hn-react-webform";
import FieldsetElement from "../../common/FieldsetElement/FieldsetElement";

const formTheme = {
  baseColor: davidoff.colors.black,
  primaryColor: davidoff.colors.black,
  secondaryColor: davidoff.colors.orange,
  buttonColor: davidoff.colors.brown,
  buttonColorHover: davidoff.colors.orange,
  buttonTextColor: davidoff.colors.white,
  labelDisplay: "before",
  inputBgColor: davidoff.colors.white,
  inputWidth: "63.5%",
  inlineLabelWidth: "36.5%",
  checkedColor: davidoff.colors.black,
  placeholderColor: davidoff.colors.grey,
  focusColor: "Highlight", // fall back to browser default
  inputHeight: "50px",
  textAreaHeight: "150px",
  fieldsetBgColor: davidoff.colors.grey,

  requiredColor: davidoff.colors.primary,
  errorColor: "red", // NM orange
  errorBgColor: "#fff", // 20% orange
  successColor: davidoff.colors.brown,
  borderRadius: "0",
  borderColor: davidoff.colors.lightbrown,

  spacingUnit: "0.5em",
  spacingUnitFieldset: "0.75em",
  spacingUnitCheckbox: "1em",
  spacingUnitRadio: "1em",
  buttonSpacingV: "10px",
  buttonSpacingH: "25px",
  formMaxWidth: "none",
};

class WebForm extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();

    this.state = {
      WebformModule: null,
    };
  }

  async componentDidMount() {
    this.setState({
      WebformModule: (await import("hn-react-webform")).default,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
  }

  scrollTop = () => {
    this.scrollTimeout = setTimeout(() => {
      this.wrapperRef.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  render() {
    const Title = HyphenTitle.h1;
    const { data, paragraph, translationStore } = this.props;
    const { WebformModule } = this.state;

    if (!WebformModule) {
      return null;
    }

    const form = dataMapper(data, paragraph.field_webform.target_uuid);
    // Handle custom Webform fields.
    components.fieldset = FieldsetElement;
    return (
      <WebformWrapper ref={(node) => (this.wrapperRef = node)}>
        <WebFormContainer>
          {this.props.paragraph.field_webform.target_id ===
          "b2b_form_landingspage" ? (
            <Title>{translationStore.translate("Subscribe here")}</Title>
          ) : (
            <Title>{translationStore.translate("Contact")}</Title>
          )}
          <WebformModule
            url={`${window.location.protocol}//${window.location.host}`}
            onSubmit={this.scrollTop}
            form={form}
            theme={formTheme}
          />
        </WebFormContainer>
      </WebformWrapper>
    );
  }
}

WebForm.propTypes = {
  paragraph: PropTypes.shape({
    field_webform: PropTypes.shape.isRequired,
  }).isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

export default inject("translationStore")(observer(WebForm));
