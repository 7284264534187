import React from 'react';
import MediaImage from './index.p';
import { dataMapper } from '../..';

function handleChildren(children, props) {
  if(typeof children === 'function') {
    return children(props);
  }

  return children;
};

const MediaImageContainer = (props) => {
  if(!props.data || !props.mediaReference) {
    return null;
  }

  const concreteMediaImage = dataMapper(props.data, props.mediaReference.target_uuid);
  if(!concreteMediaImage) {
    return null;
  }
  const { children, ...passProps } = props;
  const imageData = { ...passProps, mediaImage: concreteMediaImage };
  const renderChildren = handleChildren(children, imageData);
  const elementKey = `media-image-${props.mediaReference.target_uuid}`;

  if(props.component) {
    return [
      <props.component {...imageData} key={elementKey} />,
      renderChildren,
    ];
  }

  return [
    <MediaImage
      {...imageData}
      key={elementKey}
    />,
    renderChildren,
  ];
};

export default MediaImageContainer;
