import React from 'react';
import styled from 'styled-components';

export const NotificationContent = styled.div`
  display: inline-block;
  
  p {
    ${({theme}) => theme.fonts.parse('sansSerif', 'regular', '14px')}
    color: #fff;
    margin: 0;
  }
`;

export default NotificationContent;
