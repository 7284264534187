import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

const CampaignHeader = styled.div`
  width: 100%;
  margin: 0 0 100px;
  padding: 0;
  height: 400px;
  position: relative;

  @media (min-width: 768px){
    margin: 0 0 160px;
    //height: ${(props) => props.imageRatio.tablet}px;
  }

  @media (min-width: 1024px){
    margin: 0 0 100px;
    height: ${(props) => props.imageRatio.desktop}px;
  }

  @media (min-width: 1280px){
    height: ${(props) => props.imageRatio.desktopl}px;
  }

    @media (min-width: 1440px){
    height: ${(props) => props.imageRatio.desktopxl}px;
  }

  > div {
    max-width: none;
    width: 100%;
    flex-direction: row;
  }


  > div:nth-child(1) {
    display: block;
    padding: 0;
    flex-direction: row;
  }

  .video-element {
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding-top: 535px;
  }

  @media (max-width: 940px) {
    .video-element {
      padding-top: 57%;
    }
  }

  .video-element > div {
    left: 50%;
    padding-bottom: 56.25%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 0;
    width: 100%;
  }
`

export const CampaignHeaderImage = styled(CampaignHeader)`
  ${(props) => `background-image: url(${props.imageUri});`}
  background-size: cover;
  background-position: center center;

  ${(props) => typeof props.imageUri !== 'undefined' ? `
    // > div:nth-child(1) > div:nth-child(2) {
    //   max-width: 592px;
    //   position: absolute;
    //   top: 25%;
    //   left: 40%;
    //   -webkit-transform: translateX(-100%) translateY(-50%);
    //   -ms-transform: translateX(-100%) translateY(-50%);
    //   transform: translateX(-100%) translateY(-50%);
    }` : ''}
`
