import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../themes/davidoff';

export default styled.input`
  ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '13px')}
  border: 1px solid ${davidoff.colors.lightbrown};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: ${davidoff.colors.brown};
  height: 40px;
  letter-spacing: 1px;
  margin-bottom: 0;
  outline: none;
  padding: 0 15px;
  width: 70%;
  
  @media only screen and (min-width: 768px) {
    font-size: 17px;
    height: 60px;
    padding: 0 30px;
    width: 415px;
  }
  
  &::placeholder {
    color: ${davidoff.colors.brown};
    opacity: 1;
  }
  
  &::-ms-clear {
    display: none;
  }
`;
