import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import mapper from '../../index';
import HyphenTitle from '../../style-guide/HyphenTitle/HyphenTitle';
import Columns from '../../style-guide/Structure/Columns';
import DiscoverWrapper from './styled/DiscoverWrapper';
import DiscoverContainer from './styled/DiscoverContainer';
import TranslationStore from '../../../stores/TranslationStore';

const Title = HyphenTitle.h2;

/*
* TODO: Get the title from JSON It's not filled yet.
* */

const DiscoverProducts = ({ paragraph, data, translationStore }) => {
  const Categories = paragraph.field_categories.map(item => mapper(data, false, item.target_uuid));

  return (
    <DiscoverWrapper>
      <DiscoverContainer>
        <Title><span>{translationStore.translate('Discover our products')}</span></Title>
        <Columns>
          {Categories}
        </Columns>
      </DiscoverContainer>
    </DiscoverWrapper>
  );
};

DiscoverProducts.propTypes = {
  paragraph: PropTypes.shape({
    field_categories: PropTypes.array.isRequired,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
  translationStore: PropTypes.instanceOf(TranslationStore),
};

export default inject('translationStore')(observer(DiscoverProducts));
