import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.blockquote`
  ${(props) => props.theme.fonts.parse('sansSerif', 'mediumItalic', '24px')}
  line-height: 30px;
  margin: 0 auto;
  max-width: 600px;
  padding: 0 0 25px 15px;
  position: relative;
  width: 70%;
  font-style: normal;
  font-weight: 400;
  
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 40px;
    width: auto;
  }
  
  &:before,
  &:after {
    background-color: ${davidoff.colors.orange};
    bottom: 15px;
    content: '';
    height: 8px;
    left: 15px;
    position: absolute;
    width: 8px;
  }
  
  &:after {
    transform: skew(-20deg, 0deg);
    transform-origin: bottom left;
    width: 60px;
  }
  
  span {
    left: 0;
    position: absolute;
    top: 0;
  }
`;
