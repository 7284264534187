import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../style-guide/Loader/Loader';
import davidoff from '../../../../themes/davidoff';

export default styled.div`
    position: relative;
    ${Loader} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        z-index:999;
        color: ${davidoff.colors.orange};
        pointer-events:none;
    }
`;
