import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles';

export default styled.div`
  background-color: #fff;
  box-shadow: rgba(51, 51, 51, 0.3) 0px 1px 6px;
  height: 40vh;
  padding: ${structureStyles.mobileValues.spacing};
  position: relative;
  transform: translate3d(calc(-50% + 16px), calc(-100% + -75px), 0);
  width: 80vw;
  z-index: 10;
  
  @media (min-width: 768px) {
    height: auto;
    width: 400px;
  }
  
  @media (min-width: 1024px) {
    margin-top: -70px;
    padding: 15px ${structureStyles.desktopValues.spacing};
    transform: translate3d(-184px, -100% ,0);
  }
  
  &:after {
    filter: drop-shadow( rgba(51,51,51,0.5) 1px 7px 4px );
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    bottom: -20px;
    content: '';
    height: 0;
    left: 50%; 
    position: absolute;
    transform: translateX(-50%);
    width: 0; 
  }
`;
