import React from 'react';
import styled from 'styled-components';

export default styled.div`
  @media (min-width: 768px) {
    float: ${props => props.invertImagePosition ? 'left' : 'right'};
    width: 100%;
  }

  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }
`;
