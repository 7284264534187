import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const hyphenStyles = ({hyphenPosition, hyphenAlign, theme}) => {
  return `
    ${theme.fonts.parse('sansSerif', 'black', '20px')}
    color: #000;
    display: inline-block;
    line-height: 1.5;
    position: relative;
    text-transform: uppercase;
    ${hyphenPosition === 'top' ? `padding-top: 12px;` : ``}
    
    &:before,
    &:after {
      background-color: #fc6b0d;
      content: '';
      ${hyphenAlign === 'center' ? `left: 50%;` : `left: 0;`}
      height: 8px;
      position: absolute;
      
      ${hyphenAlign === 'center' ? `transform: translateX(-30px);` : `transform: translateX(0);`}
      ${hyphenPosition === 'top' ? `top: -4px;` : `bottom: -4px;`}
      width: 8px;
    }
    
    &:after {
      transform: skew(-20deg, 0deg) ${hyphenAlign === 'center' ? `translateX(-50%)` : `translateX(0);`};
      transform-origin: bottom left;
      width: 60px;
    }
  `;
};

const HyphenTitleH1 = styled.h1`
  ${props => hyphenStyles(props)}
`;
const HyphenTitleH2 = styled.h2`
  ${props => hyphenStyles(props)}

  margin-bottom: 5px;
`;
const HyphenTitleH3 = styled.h3`
  ${props => hyphenStyles(props)}
  font-size: 16px;
`;
const HyphenTitleH4 = styled.h4`
  ${props => hyphenStyles(props)}
  font-size: 14px;
`;

const propTypes = {
  hyphenPosition: PropTypes.string,
  hyphenAlign: PropTypes.string,
};

const defaultProps = {
  hyphenPosition: 'bottom',
  hyphenAlign: 'left',
};

// PropTypes
HyphenTitleH1.propTypes = propTypes;
HyphenTitleH2.propTypes = propTypes;
HyphenTitleH3.propTypes = propTypes;
HyphenTitleH4.propTypes = propTypes;

// Default props
HyphenTitleH1.defaultProps = defaultProps;
HyphenTitleH2.defaultProps = defaultProps;
HyphenTitleH3.defaultProps = defaultProps;
HyphenTitleH4.defaultProps = defaultProps;

export default {
  h1: HyphenTitleH1,
  h2: HyphenTitleH2,
  h3: HyphenTitleH3,
  h4: HyphenTitleH4,
};
