import React from 'react';
import styled from 'styled-components';
import MediaImageContainer from '../../../../../MediaImage/index.c';

export default styled(MediaImageContainer)`
  img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
`;
