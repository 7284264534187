export const defaultTheme = {
  name: 'default',
  fonts: {
    serif: {
      medium: {
        family: '"Saya Serif Davidoff"',
        weight: 500,
        defaultSize: '16px',
      },
    },
    sansSerif: {
      medium: {
        family: '"Saya Davidoff Medium"',
        weight: 400,
        defaultSize: '16px',
      },
      light: {
        family: '"Saya Davidoff Light"',
        weight: 400,
        defaultSize: '14px',
      },
      mediumItalic: {
        family: '"Saya Davidoff MediumItalic"',
        weight: 400,
        defaultSize: '16px',
      },
      regular: {
        family: '"Saya Davidoff"',
        weight: 400,
        defaultSize: '16px',
      },
      bold: {
        family: '"Saya Davidoff Bold"',
        weight: 400,
        defaultSize: '16px',
      },
      black: {
        family: '"Saya Davidoff Black"',
        weight: 400,
        defaultSize: '16px',
      },
    },
    parse(style, weight, size = false) {
      if(!this[style][weight]) {
        return console.error('You choose an illegal variation of fonts');
      }
      const font = this[style][weight];

      return `
        font-family: ${font.family}, sans-serif;
        ${size ? `font-size: ${size};` : ''}
        font-weight: ${font.weight};
      `;
    },
  },
};

export default defaultTheme;
