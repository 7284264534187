import React from 'react';
import styled from 'styled-components';
import Button from '../../../style-guide/Button/Button';
import { Loader } from '../../../style-guide/Loader/Loader';
import davidoff from '../../../../themes/davidoff';

export default styled(Button)`
  background-color: #000;
  border-radius: 4px;
  display: none;
  height: 50px;
  position: relative;
  line-height: 52px;
  width: 100%;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    display: block;
    font-size: 12px;
    line-height: 14px;
  }

  ${(props) => props.fetchingLocation ?
  `
    background-color: ${davidoff.colors.orange};
    text-indent: -9999px;
  ` :
  ``}

  ${Loader} {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
