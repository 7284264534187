import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import typographyStyles from '../../../../shared-styles/typography-styles.js';
import CategoryBlock from '../../Category/styled/CategoryBlock';

const startAnimationClass = '.animate';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const hyphenBeforeGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 8px;
  }
`;

const hyphenAfterGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 60px;
  }
`;

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

export default styled(ScrollEffect)`
  ${structureStyles.container}
  
  h2 {
    margin-left: ${structureStyles.mobileValues.titleIndentFull};
    max-width: 250px;
    text-transform: uppercase;
    z-index: 1;
    
    @media (max-width: 767px) {
      ${(props) => typographyStyles.labelMobile(props)};
      line-height: 2.5;
      font-size: 13px;
    }
    
    @media (min-width: 768px) {
      margin-left: 70px;
    }
    
    &:before,
    &:after {
      bottom: -8px;
      
      @media (min-width: 768px) {
        width: 0;
      }
    }
    
    span {
      @media (min-width: 768px) {
        opacity: 0;
      }
    }
  }
  
  @media (min-width: 768px) {
    ${CategoryBlock} {
      ${structureStyles.flexItem.third};
      opacity: 0;
      transform: translateY(50px);
    }
    
    &${startAnimationClass} {
      ${CategoryBlock}:first-child {
        animation: ${css`${fpsToMs(25)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`};
      }
      
      ${CategoryBlock}:nth-child(2) {
        animation: ${css`${fpsToMs(25)}ms ease ${fpsToMs(37)}ms forwards ${fadeIn}`};
      }
  
      ${CategoryBlock}:last-child {
        animation: ${css`${fpsToMs(25)}ms ease ${fpsToMs(44)}ms forwards ${fadeIn}`};
      }
      
      h2 {
        &:before {
          animation: ${css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenBeforeGrow}`}; 
        }
        &:after {
          animation: ${css`${fpsToMs(8)}ms ease ${fpsToMs(23)}ms forwards ${hyphenAfterGrow}`};
          animation-fill-mode: forwards;
        }
        span {
          animation: ${css`${fpsToMs(26)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`};
        } 
      }
    }
  }
`;
