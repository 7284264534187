import React from 'react';
import styled from 'styled-components';

export default styled.div`
  height: 44px;
  position: relative;
  width: 34px;
  // Correct the position of the marker
  top: -39px;
  left: -17px;

  svg {
    height: 44px;
    position: relative;
    transition: fill 300ms;
    width: 34px;
  }
  
  &:hover > svg {
    fill: #fd6b0d;
  }
  
  ${props => props.open ? `
    > svg {
      fill: #fd6b0d;
    }
  ` : ``}
`;
