import styled from "styled-components";
import React from 'react';
import Button from './Button';

const SearchButton = styled(Button)`
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  vertical-align: top;
`;

export default SearchButton;
