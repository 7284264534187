import React from 'react';
import styled from 'styled-components';
import ImageCaption from '../../../style-guide/Figcaption/Figcaption';

export default styled.div`
  margin-bottom: 2em;
  overflow: hidden;

  ${ImageCaption} {
    margin-bottom: 30px;
  }
  
  width: calc(100% + 26% + 26%);
  margin: 0 -26%;
   
  @media (min-width: 2160px) {
    max-width: 1920px;
    margin: 0 auto;
  }
`;
