import { webFonts } from '../config';

function createLink(url) {
  const link = document.createElement('link');

  link.href = url;
  link.rel = 'stylesheet';

  return link;
}

export function loadFont(langCode) {
  if(webFonts[langCode] && document) {
    const { fonts, loaded } = webFonts[langCode];

    if(!loaded) {
      fonts.forEach(url => document.head.appendChild(createLink(url)));
      webFonts[langCode].loaded = true;
    }
  }
}

export function createFontLinkTags(langCode) {
  if(webFonts[langCode]) {
    const { fonts } = webFonts[langCode];
    return fonts.map(url => `<link href="${url}" rel="stylesheet">`).join('');
  }

  return '';
}

export default loadFont;
