import React from 'react';
import * as styles from './styled'
import parse from 'html-react-parser';

export function CampaignText(props) {
  const { paragraph } = props;
  
  return (
    <styles.CampaignText>
      { paragraph.field_campaign_f_w_t_subtitle != null && (
        <h3>{paragraph.field_campaign_f_w_t_subtitle}</h3>
      )}
      { parse(paragraph.field_campaign_f_w_t_text.value) } 
    </styles.CampaignText>
  )
}