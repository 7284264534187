import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.div`
  min-height: calc(100vh - 225px); // sticky footer
  overflow: hidden;
  padding-top: 51px;
  
  @media only screen and (min-width: 1024px) {
    padding-top: 0;
    
    .fixed & {
      padding-top: 51px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .navigation-wrapper {
      background: #fff;
      height: 0;
      left: 0;
      opacity: 0;
      overflow: hidden;
      position: fixed;
      top: 50px;
      transition: opacity 0.35s ease-in, height 0.01s linear 0.35s;
      width: 100vw;

      &.menu-opened {
        height: calc(100vh - 50px);
        opacity: 1;
        overflow: scroll;
        transition-delay: 0s;
        z-index: 999;
      }
    }
  }
`;
