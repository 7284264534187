import React from 'react';
import styled from 'styled-components';

export default styled.p`
  ${(props) => props.theme.fonts.parse('sansSerif', 'medium', '18px')}
  line-height: 28px;
  
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
`;
