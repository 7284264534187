import React from 'react';
import styled from 'styled-components';

export default styled.button`
  border: 0;
  background: transparent;
  height: 30px;
  left: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  width: 30px;

  &:before {
    display: none;
  }
  
  svg {
    display: block;
    fill: #8c7d70;
    height: 12px;
    width: 12px;
    margin: 0;
  }

  &.slick-next {
    right: 10px;
    left: auto;
  }
  
  &.slick-prev {
    left: 10px;
    transform: translateY(-50%) rotate(-180deg);
  }
  
  &.slick-disabled {
    display: none !important;
  }
`;
