import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import productBG from "../../../../shared-styles/productBG";

const imageSettings = {
  breakpoints: {
    mobile: {
      breakpoint: "",
      height: 106,
      width: 176,
    },
  },
};

export default styled(Slider)`
  .slick-slide {
    background-color: #ece8e4;
    margin: 1px 1px 0 0;
    text-align: center;
    width: 176px;
    ${(props) => productBG(imageSettings, props.isCoffee)}

    img {
      max-width: 100%;
    }
  }

  .slide {
    background-color: #ece8e4;
    min-width: 176px;
    max-width: 176px;
    width: 176px;
    height: 106px;
    margin-top: 1px;
    margin-right: 1px;
    ${(props) => productBG(imageSettings, props.isCoffee)}
  }
`;
