import React from 'react';
import styled from 'styled-components';

export default styled.div`
  background-color: #f7f6f4;
  border-bottom: 1px solid #e8e3de;
  line-height: 40px;
  transition: transform 300ms;
  width: 100%;
  
  .menu-opened & {
    transform: translateX(0);
  }
  
  @media only screen and (min-width: 1025px) {
    height: 40px;
    position: absolute;
    top: 0;
  }
`;
