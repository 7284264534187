import React from 'react';
import styled from 'styled-components';

export default styled.div`
  background-color: #f7f5f3;
  overflow: auto;
  padding: 15px 15px 10px;

  @media (min-width: 1024px) {
    left: 0;
    height: calc(100vh - 50px);
    min-height: 540px;
    padding: 30px;
    top: 0;
    //transform: translateX(-100%);
    transition: transform 0.75s ease;
    width: 25%;
    
    ${(props) => props.showSideBar ? `
      transform: translateX(0);
      transition-delay: 1s;
    ` : ``}
  }
  
  @media (min-width: 1025px) {
    height: calc(100vh - 170px);
  }
`;
