import React from 'react';
import styled from 'styled-components';

export const MessageWrapper = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  height: 100%; 
  width: 100%;
  z-index: 9999;
  pointer-events: none;
`;

export default MessageWrapper;
