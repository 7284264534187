import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { stripProtocol } from '../../../../misc/index';
import SocialLink from '../SocialLink/SocialLink';

const SocialChannelListItem = styled.li`
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 32px;
  margin: 0 8px;
  position: relative;
  width: 32px;
`;

const SocialChannel = ({ channelName, url}) => {
  return (
      <SocialChannelListItem>
        <SocialLink
          channelName={channelName}
          url={stripProtocol(url)}
        />
      </SocialChannelListItem>
  );
};

SocialChannel.propTypes = {
  channelName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SocialChannel;
