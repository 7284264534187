import React from 'react';
import LdJson from 'react-ld-json';


export default function WebpageSchema(props) {
  const isRootPage = props.baseUrl === '/';

  if (isRootPage) {
    return (
      <LdJson
        data={{
          '@context': 'https://schema.org/',
          '@type': 'WebSite', 
          '@id': '#main-webpage',
          author: {
            '@id': '#main-organization',
          },
          potentialAction: {
            '@type': 'SearchAction',
            target: '/search?search={search_term_string}',
            'query-input': 'required name=search_term_string',
          },
          url: props.baseUrl,
        }}
      />
    );
  }

  return (
    <LdJson
      data={{
        '@context': 'https://schema.org/',
        '@type': isRootPage ? 'Website' : 'WebPage',
        '@id': '#main-webpage',
        author: {
          '@id': '#main-organization',
        },
        potentialAction: {
          '@type': 'SearchAction',
          target: '/search?search={search_term_string}',
          'query-input': 'required name=search_term_string',
        },
        url: props.baseUrl,
      }}
    />
  );
}
