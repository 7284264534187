import React from 'react';
import PropTypes from 'prop-types';
// import { PropTypes as MobXPropTypes} from 'mobx-react';
import VisualItem from './VisualFilterItem/VisualFilterItem';

const Visual = ({data, options, handler, selected}) => {
    const Options = options.map(option =>
      <VisualItem
        key={`visual-option-${option.key}`}
        data={data}
        option={option}
        handler={handler}
        selected={selected}
      />);

    return (
      <ul>
        {Options}
      </ul>
    );
};

Visual.propTypes = {
  data: PropTypes.shape().isRequired,
  options: PropTypes.array.isRequired,
  handler: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

export default Visual;
