import React from "react";
import PropTypes from "prop-types";
import CookieWrapper from "../styled/CookieWrapper";
import CookieInner from "../styled/CookieInner";
import CookieText from "../styled/CookieText";
import CookButtonWrapper from "../styled/CookButtonWrapper";
import CookieButton from "../styled/CookieButton";
import CookieExtraOptions from "../styled/CookieExtraOptions";

const CookieMessage = ({
  optIn,
  cookieText,
  buttonText,
  manageCookieButtonText,
  manageCookieTitle,
  manageCookieOptionsButtonText,
  manageCookieAcceptAllButtonText,
  isPrivacyPolicy,
  cookieLevelTexts,
}) => {
  const [showOptions, setShowOptions] = React.useState(false);
  const [checkedValue, setCheckedValue] = React.useState("no-cookies");
  const [cookieLevel, setCookieLevel] = React.useState("OPTIMAL");

  const handleManageButton = () => {
    setShowOptions(!showOptions);
  };

  const onChangeValue = (event) => {
    setCheckedValue(event.target.value);
  };

  React.useEffect(() => {
    switch (checkedValue) {
      case "no-cookies":
      case "functional-cookies":
        setCookieLevel("LIMITED");
        break;
      case "all-cookies":
      default:
        setCookieLevel("OPTIMAL");
        break;
    }
  }, [checkedValue]);

  const options = [
    {
      label: cookieLevelTexts[0],
      id: "no-cookies",
    },
    {
      label: cookieLevelTexts[1],
      id: "functional-cookies",
    },
    {
      label: cookieLevelTexts[2],
      id: "all-cookies",
    },
  ];

  return (
    <CookieWrapper isPrivacyPolicy={isPrivacyPolicy}>
      {/* eslint-disable-next-line react/no-danger */}
      {!showOptions && (
        <CookieInner>
          <CookieText
            dangerouslySetInnerHTML={{ __html: cookieText }} // eslint-disable-line react/no-danger
          />
          <CookButtonWrapper>
            <CookieButton onClick={optIn} type="button">
              {buttonText}
            </CookieButton>

            <CookieExtraOptions onClick={handleManageButton} type="button">
              {manageCookieButtonText}
            </CookieExtraOptions>
          </CookButtonWrapper>
        </CookieInner>
      )}

      {showOptions && (
        <CookieInner className="container-options">
          <h2>{manageCookieTitle}</h2>

          <CookieText
            dangerouslySetInnerHTML={{ __html: cookieText }} // eslint-disable-line react/no-danger
          />

          <CookButtonWrapper>
            <form>
              <ul>
                {options.map((e) => {
                  const key = `cookie-option-${e.id}`;
                  return (
                    <li key={key}>
                      <input
                        onChange={onChangeValue}
                        type="checkbox"
                        value={e.id}
                        id={e.id}
                        name="cookie-level"
                        checked={checkedValue <= e.id}
                      />
                      <label htmlFor={e.id}>{e.label}</label>
                    </li>
                  );
                })}
              </ul>
            </form>
            <div className="toolbar">
              <CookieButton
                onClick={(e) => optIn(e, cookieLevel)}
                type="button"
              >
                {manageCookieOptionsButtonText}
              </CookieButton>
              <CookieButton
                className="accept-all"
                onClick={(e) => optIn(e, "all-cookies")}
                type="button"
              >
                {manageCookieAcceptAllButtonText}
              </CookieButton>
            </div>
          </CookButtonWrapper>
        </CookieInner>
      )}
    </CookieWrapper>
  );
};

CookieMessage.propTypes = {
  optIn: PropTypes.func,
  cookieText: PropTypes.string,
  buttonText: PropTypes.string,
  manageCookieButtonText: PropTypes.string,
  manageCookieTitle: PropTypes.string,
  manageCookieOptionsButtonText: PropTypes.string,
};

CookieMessage.defaultProps = {
  setCookie: () => true,
  cookieText: "",
  buttonText: "Close",
  manageCookieButtonText: "",
  manageCookieTitle: "",
  manageCookieOptionsButtonText: "",
};

export default CookieMessage;
