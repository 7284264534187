import davidoff from '../themes/davidoff';

const closeStyles = `
  border: 2px solid ${davidoff.colors.gray};
  border-radius: 100%;
  height: 25px;
  opacity: 1;
  position: relative;
  transition: border-color 300ms, opacity 300ms ease 300ms;
  width: 25px;

  @media only screen and (min-width: 1025px) {
    height: 32px;
    width: 32px;
  }
  
  &:focus { 
    outline: none;
  }
  
  &::before, &::after {
    background-color: ${davidoff.colors.gray};
    content: ' ';
    height: 12px;
    left: 10px;
    position: absolute;        
    top: 4px;
    transition: background-color 300ms;
    width: 2px;
    
    @media only screen and (min-width: 1025px) {
      height: 17px;
      left: 13px;
      top: 5px;
    }
  }
  
  &::before {
    transform: rotate(45deg);
  }
  
  &::after {
    transform: rotate(-45deg);
  }
  
  &:hover {
    border-color: ${davidoff.colors.orange};
    
    &::before, &::after {
      background-color: ${davidoff.colors.orange};
    }
  }
`;

export default {
  link(props) {
    return `
      ${props.theme.fonts.parse('sansSerif', 'black', '14px')}
      color: ${davidoff.colors.orangeLink};
      line-height: 1.714;
      text-decoration: none;
      text-transform: uppercase;
      
      &::after {
        border-right: 2px solid ${davidoff.colors.orangeLink};
        border-bottom: 2px solid ${davidoff.colors.orangeLink};
        content: '';
        display: inline-block;
        height: 6px;
        margin-left: 10px;
        position: relative;
        transition: all 0.2s ease-out;
        transform: rotate(-45deg);
        width: 6px;
        top: -1px;
      }
    `;
  },
  close: closeStyles,
};
