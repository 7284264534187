import React from 'react';
import styled from 'styled-components';

export default styled.button`
  background: transparent;
  display: none;
  margin: 0;
  padding: 0;
  outline: none;
  
  @media only screen and (max-width: 1024px) {
    border: 2px solid #938579;
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    right: 20px;
    position: absolute;
    top: 10px;
    transform: rotate(-135deg);
    transition: transform 0.3s linear;
    transform-origin: center center;
    width: 20px;      

    ${props => props.subOpen && (props.level === 1) ? `
      transform: rotate(-45deg);
    ` : `
    `}
    
    &.open::after {
      transform: rotate(-45deg);
    }

    &::after {
      border-bottom: 2px solid #938579;
      border-left: 2px solid #938579;
      box-sizing: border-box;
      content: '';
      height: 6px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;
      width: 6px;
    }
  }
`;
