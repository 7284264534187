import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.strong`
  ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '14px')}
  color: ${davidoff.colors.brown};
  display: block;
  line-height: 32px;
  transform: translatey(-10px);
  text-transform: uppercase;
  
  @media (max-width: 767px) {
    display: none;
  }
`;
