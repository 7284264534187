import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { setHttpsProtocol, formatProductName } from '../../../misc/index';
import { dataMapper } from '../../index';
import { autoPlayVideo } from '../../../misc/index';

// Styled components
import IndustryWrapper from './styled/IndustryWrapper';
import IndustryImageWrapper from './styled/IndustryImageWrapper';
import IndustryImage from './styled/IndustryImage';
import IndustryVideo from './styled/IndustryVideo';
import IndustryContentWrapper from './styled/IndustryContentWrapper';
import IndustryParagraph from './styled/IndustryParagraph';
import IndustryTitle from './styled/IndustryTitle';
import IndustryText from './styled/IndustryText';
import IndustryLink from './styled/IndustryLink';
import IndustryProduct from '../../common/IndustryProduct/IndustryProduct';
import ImageLink from './styled/ImageLink';
import { withCookies, Cookies } from "react-cookie";

const sources = [
  {
    settings: {
      crop: 'fill',
      aspect_ratio: '16:9',
      gravity: 'auto',
      width: 375,
    },
    media: '(max-width: 375px)',
  },
  {
    settings: {
      crop: 'fill',
      aspect_ratio: '16:9',
      gravity: 'auto',
      width: 768,
    },
    media: '(max-width: 768px)',
  },
  {
    settings: {
      crop: 'fill',
      aspect_ratio: '16:9',
      gravity: 'auto',
      width: 1024,
    },
    media: '(max-width: 1024px)',
  },
  {
    settings: {
      aspect_ratio: '16:9',
      crop: 'fill',
      gravity: 'auto',
      width: 1216,
    },
    media: '(min-width: 1025px)',
  }
];

/*
* Image
* Video (optional)
* Title
* Intro text
* Link
* Product (optional)
* */
class IndustryBlock extends React.Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      videoPlaying: false
    };
    this.onVideoStart = this.onVideoStart.bind(this);
  }

  onVideoStart() {
    this.setState({
      videoPlaying: true
    });
  }

  getRelatedProduct() {
    const { paragraph, data } = this.props;
    const relatedBlock = getNested(() => paragraph.field_content_re.target_uuid, false);

    if(relatedBlock) {
      const relatedContent = dataMapper(data, relatedBlock);
      const title = {};

      if(!getNested(() => relatedContent.type.target_id, false)) {
        return null;
      }

      const settings = {};
      const sources = [
        {
          settings,
          media: '(min-width: 0)',
        }
      ];

      if (relatedContent && relatedContent.__hn) {
        if(relatedContent.type.target_id === 'product') {
          // get Product group if we are a product
          const productGroupID = getNested(() => relatedContent.field_product_group.target_uuid, false);

          const isCoffee = relatedContent.__hn?.locale_paths?.en?.startsWith("/coffee");

          if(!productGroupID) {
            return null;
          }

          const productGroup = dataMapper(data, relatedContent.field_product_group.target_uuid);

          // fill out the product title and image
          const productTitle = formatProductName(productGroup.field_display_text, relatedContent.field_display_text);
          const mediaReference = getNested(() => relatedContent.field_media_multiple[0]) || null;

          return (
            <IndustryProduct
              imageType="IndustryProduct"
              isCoffee={isCoffee}
              data={data}
              title={{
                mainTitle: productTitle.groupName,
                subTitle: productTitle.variantName
              }}
              settings={settings}
              sources={sources}
              mediaReference={mediaReference}
              url={relatedContent.__hn.url}
            />
          );
        } else if(
          relatedContent.type.target_id === 'collection' &&
          getNested(() => relatedContent.field_collection.target_uuid, false)
        ) {
          const collectionTerm = dataMapper(data, relatedContent.field_collection.target_uuid);
          const collectionHeader = dataMapper(data, collectionTerm.field_header.target_uuid);
          const mediaReference = collectionHeader.field_media;

          return (
            <IndustryProduct
              imageType="IndustryProduct"
              data={data}
              title={{
                mainTitle: collectionTerm.name,
                subTitle: ''
              }}
              settings={settings}
              sources={sources}
              alt={title.mainTitle}
              mediaReference={mediaReference}
              url={relatedContent.__hn.url}
            />
          )
        }
      }

      return null
    }
  }

  render() {
    const RelatedProduct = this.getRelatedProduct();

    const { paragraph, data, cookies } = this.props;
    const video = getNested(() => paragraph.field_slow_motion_video.uri, false);
    const link = paragraph.field_industry_link;
    const settings = {
      crop: 'fill',
      aspect_ratio: '16:9',
      width: 1216,
      gravity: 'auto',
    };

    var host = 'https://www.youtube.com';

    if (cookies.get("DavidoffCookiePolicy") && cookies.get("DavidoffCookiePolicy") !== 'OPTIMAL') {
      var host = 'https://www.youtube-nocookie.com';
    }

    return (
      <IndustryWrapper className={video ? 'has-video' : ''}>
        <IndustryImageWrapper>
          <ImageLink to={link.uri} title={link.title}>
            <IndustryImage
              imageType="ImageImage"
              data={data}
              mediaReference={paragraph.field_media}
              videoPlaying={this.state.videoPlaying}
              settings={settings}
              sources={sources}
            />
          </ImageLink>
          {video &&
          <IndustryVideo
            url={setHttpsProtocol(video)}
            playing={autoPlayVideo()}
            width='100%'
            height='auto'
            volume={0}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  modestbranding: 1,
                  fs: 0,
                },
                embedOptions: {
                  host: host
                }
              },
              vimeo: {
                playerOptions: {
                  api: 1,
                  background: 1,
                  loop: 1,
                  autoplay: 1,
                  autopause: 1,
                }
              }
            }}
            controls={false}
            loop
            onStart={this.onVideoStart}
          />
          }
        </IndustryImageWrapper>
        <IndustryContentWrapper>
          <IndustryParagraph>
            <IndustryTitle hyphenPosition='top'>
              {paragraph.field_title}
            </IndustryTitle>
            <IndustryText>
              {paragraph.field_text_body}
            </IndustryText>
            {getNested(() => link.uri, false) &&
            <IndustryLink to={link.uri}>{link.title}</IndustryLink>
            }
          </IndustryParagraph>
          {RelatedProduct}
        </IndustryContentWrapper>
      </IndustryWrapper>
    );
  }
}

IndustryBlock.propTypes = {
  paragraph: PropTypes.shape({
    field_industry_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
    field_text_body: PropTypes.string.isRequired,
    field_industry_link: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default withCookies(IndustryBlock);
