import React, { useEffect } from "react";
import styles from './styles.module.css'
import CookiebotWrapper from "./styled/CookiebotWrapper";

export function CookieBotHead(props) {
  return (
    <script
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid={props.cbid}
      type="text/javascript"
      data-blockingmode="auto"
    />
  );
}

export function CookieBotDeclaration(props) {
  const ref = React.useRef(null);

  return (
    <>
      <CookiebotWrapper>
        <div className={styles.cookieBot} ref={ref}/>
      </CookiebotWrapper>
    </>
  );
}
