import TweenLite from 'gsap/TweenLite';
import root from 'window-or-global';

export function scrollToElement(ev) {
  const { hash } = ev.target;
  if(hash !== '') {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if(element) {
      ev.preventDefault(); // block the default scrolling.
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset + 100,
        behavior: 'smooth'
      });
    }
  }
}

export default scrollToElement;
