import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../../style-guide/Container/Container'
import { NotificationText } from '../styled/NotificationText'
import { NotificationContent } from '../styled/NotificationContent'
import { DontShowAgainButton } from '../styled/DontShowAgainButton'
import { NotificationWrapper } from '../styled/NotificationWrapper'

export const Notification = props => {
  return (
    <NotificationWrapper>
      <Container>
        <NotificationText>
          <NotificationContent dangerouslySetInnerHTML={{ __html: props.text}} />
        </NotificationText>
        {props.showButton &&
          <DontShowAgainButton onClick={props.buttonAction}>
            {props.buttonText}
          </DontShowAgainButton>
        }
      </Container>
    </NotificationWrapper>
  );
}

Notification.propTypes = {
  text: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
};

Notification.defaultProps = {
  showButton: false,
  buttonText: 'Close',
  buttonAction: () => undefined,
};

export default Notification;
