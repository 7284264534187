import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import DrupalPage from './DrupalPage.jsx';

function DrupalRoute(props) {
  const { base, layout, mapper, data, translations, country } = props;

  return (
    <Route
      path='*'
      render={(props) => {
        const { location } = props;

        return (
          <DrupalPage
            data={data}
            base={base}
            layout={layout}
            location={location}
            mapper={mapper}
            country={country}
            variables={{ base, country, location }}
            translations={translations}
            {...props}
          />
        );
      }}
    />
  );
}

DrupalRoute.propTypes = {
  data: PropTypes.shape({}),
  base: PropTypes.string.isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  mapper: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
};

DrupalRoute.defaultProps = {
  data: {},
};

export default DrupalRoute;
