import React from 'react';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import { matchPath, withRouter } from 'react-router';
import viewport from '../../../../../lib/viewport/index';
import { stripProtocol } from '../../../../../misc/index';
// Styled Components
import MenuListItem from './styled/MenuListItem';
import MenuItemLink from './styled/MenuItemLink';
import TopBarItemText from './styled/TopBarItemText';
import SubNavIndicator from './styled/MenuItemDropDownIndicator';

class StandardPrimaryMenuItem extends React.Component {
  static propTypes = {
    menuItem: PropTypes.shape({
      title:PropTypes.string.isRequired,
      uri: PropTypes.string,
      external: PropTypes.bool.isRequired,
      extra_margin: PropTypes.bool,
      parent_style: PropTypes.bool,
    }).isRequired,
    level: PropTypes.number,
    title: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.bool,
    ]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    className: PropTypes.string,
    clickable: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    className: '',
    title: false,
    clickable: false,
    level: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      subOpen: false,
    };

    this.openSubNav = this.openSubNav.bind(this);
    this.isActive = this.isActive.bind(this);
    this.checkActive = this.checkActive.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(
      this.props.location.pathname === nextProps.location.pathname &&
      this.state.subOpen === nextState.subOpen &&
      this.props.isLoading === nextProps.isLoading
    ) {
      return false;
    }

    return true;
  }

  isActive(children = this.props.children, level = 0) {
    const results = [];

    // Create a function that checks all the children for this
    // children for menu items that have a active state.
    // It should loop through all of them. Check if they match
    // If one of the children matches... the parent get's a match
    // as well. And has the active state.
    React.Children.forEach(children, (child) => {
      if(child && child.props.children) {
        results.push(this.isActive(child.props.children, (level + 1)));
      } else if(child) {
        const childMenuItem = child.props.menuItem;

        // only check items with a uri and standard type
        if(childMenuItem.type === 'standard' && childMenuItem.uri) {
          const match = matchPath(childMenuItem.uri, {
            path: this.props.location.pathname,
            exact: false,
            strict: false
          });

          if(match && match.isExact) {
            results.push(true);
          } else if(this.props.location.pathname.indexOf(childMenuItem.uri) === 0) {
            results.push(true);
          }
        }
      }
    });

    // Add a level one check for selected
    if(level === 0) {
      const match = matchPath(this.props.menuItem.uri, {
        path: this.props.location.pathname,
        exact: false,
        strict: false
      });

      if(match && match.isExact) {
        results.push(true);
      } else if(this.props.location.pathname.indexOf(this.props.menuItem.uri) === 0) {
        results.push(true);
      }
    }

    return results.some(result => result === true);
  }

  checkActive() {
    // first check if the current item is a match
    // then check if there are matched children
    const match = matchPath(this.props.menuItem.uri, {
      path: this.props.location.pathname,
      exact: false,
      strict: false
    });

    if(this.props.children) {
      return this.isActive();
    }

    if(match) {
      return match && match.isExact;
    } else if (this.props.location.pathname.indexOf(this.props.menuItem.uri) === 0) {
      return true;
    }

    return false;
  }

  openSubNav(ev) {
    ev.stopPropagation();
    const { clickable } = this.props;
    const viewPortWidth = viewport().width;

    if(viewPortWidth < 1025 && clickable) {
      ev.preventDefault();

      this.setState({
        subOpen: !this.state.subOpen,
      });
    }

    return true;
  }

  render() {
    const { menuItem, children, className, title, level, isLoading } = this.props;
    // Don't show a menu when the page is loading.
    // This solves a problem with the fly-out that is still in place when we load the page.
    if(isLoading && level > 0) {
      return false;
    }
    const linkProps = {};

    if(menuItem.external) {
      linkProps.target = '_blank';
    }

    return (
      <MenuListItem
        className={className}
        parentStyle={getNested(() => menuItem.parent_style, false)}
        extraOffset={getNested(() => menuItem.extra_margin, false)}
        subOpen={this.state.subOpen}
        level={level}
        isLoading={isLoading}
      >
        {getNested(() => menuItem.parent_style, false) &&
        <h4 onClick={this.openSubNav}>
          <TopBarItemText>
            {title || menuItem.title}
          </TopBarItemText>
        </h4>
        }
        {!getNested(() => menuItem.parent_style, false) &&
        <MenuItemLink
          to={stripProtocol(getNested(() => menuItem.uri, '/'))}
          activeClassName='active'
          isActive={this.checkActive}
          onClick={this.openSubNav}
          {...linkProps}
        >
          <TopBarItemText dangerouslySetInnerHTML={{__html: title || menuItem.title}} />
        </MenuItemLink>
        }
        {children && !getNested(() => menuItem.parent_style, false) &&
          <SubNavIndicator
            subOpen={this.state.subOpen}
            level={level}
            onClick={this.openSubNav}
          />
        }
        {children}
      </MenuListItem>
    );
  }
}

export default withRouter(StandardPrimaryMenuItem);
