import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MenuButton from './styled/MenuButton';

const MenuTrigger = inject('translationStore')(observer(({ className, onClick, translationStore }) => {
  return (
      <MenuButton className={className} onClick={onClick}><span>{translationStore.translate('Open menu')}</span></MenuButton>
  );
}));

MenuTrigger.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

MenuTrigger.defaultProps = {
  className: '',
  onClick: () => true,
};

export default MenuTrigger;
