import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import StandardMenuItem from '../StandardMenuItem/StandardMenuItem';
import TopBarFlyOut from '../../TopBarFlyOut/TopBarFlyOut';
import FlyOutStore from '../../../../../stores/FlyOutStore';
import NewsLetter from '../../../NewsLetter/NewsLetter';

@observer
class NewsLetterMenuItem extends React.Component {
  @observable FlyOutStore = FlyOutStore.getInstance();

  constructor(props) {
    super(props);

    this.openFlyOut = this.openFlyOut.bind(this);
  }

  openFlyOut(ev) {
    ev.preventDefault();

    this.FlyOutStore.openFlyOut('newsletter');
  }

  render() {
    const { menuItem, settings, lang } = this.props;

    return (
      <StandardMenuItem menuItem={menuItem} onClick={this.openFlyOut} active={this.FlyOutStore.flyOuts.newsletter}>
        <TopBarFlyOut type='newsletter'>
          <NewsLetter settings={settings} lang={lang} />
        </TopBarFlyOut>
      </StandardMenuItem>
    );
  }
}

NewsLetterMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    title:PropTypes.string.isRequired,
    uri: PropTypes.string,
    external: PropTypes.bool.isRequired,
  }).isRequired,
  settings: PropTypes.shape().isRequired,
  lang: PropTypes.string.isRequired,
};

export default NewsLetterMenuItem;
