import React from 'react';
import styled from 'styled-components';
import ProductLink from './ProductLink';
import MediaImageContainer from '../../MediaImage/index.c';

export default styled(MediaImageContainer)`
  img {
    backface-visibility: hidden;
    display: block;
    margin: 0 auto;
    max-height: 140px;
    max-width: 140px;
    transition: transform 0.35s linear;
    transform: scale(1);
    
    @media (min-width: 768px) {
      max-height: 156px;
      max-width: 156px;
    }
    
    @media (min-width: 1024px) {
      max-height: 180px;
      max-width: 180px;
    }
    
    ${ProductLink}:hover & {
      transition: transform 2s linear;
      transform: scale(1.1);
    }
  }
`;
