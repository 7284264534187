export const cyrillicTheme = {
  name: 'cyrillic',
  fonts: {
    serif: {
      medium: {
        family: '"Roboto Slab"',
        weight: 400,
        defaultSize: '16px',
      },
    },
    sansSerif: {
      light: {
        family: '"Open Sans"',
        weight: 300,
        defaultSize: '14px',
      },
      medium: {
        family: '"Open Sans"',
        weight: 600,
        defaultSize: '16px',
      },
      mediumItalic: {
        family: '"Open Sans"',
        weight: 600,
        style: 'italic',
        defaultSize: '16px',
      },
      regular: {
        family: '"Open Sans"',
        weight: 400,
        defaultSize: '16px',
      },
      bold: {
        family: '"Open Sans"',
        weight: 700,
        defaultSize: '16px',
      },
      black: {
        family: '"Open Sans"',
        weight: 800,
        defaultSize: '16px',
      },
    },
    parse(type, weight, size = false) {
      if(!this[type][weight]) {
        return console.error('You choose an illegal variation of fonts');
      }
      const font = this[type][weight];

      return `
        font-family: ${font.family}, sans-serif;
        ${size ? `font-size: ${size};` : ''}
        ${font.style ? `font-style: ${font.style};` : ''}
        font-weight: ${font.weight};
      `;
    },
  },
};

export default cyrillicTheme;
