import React from 'react'
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.a`
  color: transparent;
  cursor: pointer;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  
  &:before,
  &:after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${davidoff.colors.brown};
    transition: background-color 300ms;
    content: '';
    }
  }
  
  &:before {
    top: 50%;
    transform: rotate(45deg);
  }
  
  &:after {
    bottom: 12px;
    transform: rotate(-45deg);
  }
  
  &:hover {
    color: transparent;
    
    &:before,
    &:after {
      @media (min-width: 1024px) {
        background-color: ${davidoff.colors.orange};
      }
    }
  }
`
