export default {
  label(props) {
    return `
      ${this.labelMobile(props)}
  
      @media (min-width: 768px) {
        font-size: 14px;
        left: auto;
        margin-bottom: 14px;
      }
    `;
  },
  labelMobile(props) {
    return `
      ${props.theme.fonts.parse('sansSerif', 'black', '11px')}
      letter-spacing: .05em;
      line-height: 3.5;
      text-transform: uppercase;
    `;
  },
};
