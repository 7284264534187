import React from 'react';
import PropTypes from 'prop-types';
// import { PropTypes as MobXPropTypes} from 'mobx-react';
// Styled Component
import Select from './styled/Select';

const DropDown = ({ options, handler, value }) => {
  const selectItems = options.map((option) => ({
      value: option.id,
      label: option.propertyName,
    }));

  return (
    <Select
      onChange={handler}
      options={selectItems}
      value={value}
      searchable={false}
    />
  );
};

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
  handler: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DropDown;
