import React from 'react';
import styled from 'styled-components';

export default styled.div`
  ${(props) => props.background ? 'background-color: lightgrey; padding: 15px 20px;' : '' }
  
  text-align: left;
  
  p:last-child {
    margin-bottom: 0;
  }
  
  @media only screen and (max-width: 500px) {
    padding: 15px 20px;
  }
`;