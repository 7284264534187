import React from 'react';
import styled from 'styled-components';
import Logo from '../../../Logo/Logo';

export default styled(Logo)`
  display: none;
  left: 3%;
  margin: 0;
  position: absolute;
  top: 10px;
  transform: scale(0);
  transform-origin: center;
  transition: all 300ms ease-in-out;
  width: 150px;
  z-index: 99;
  
  @media only screen and (min-width: 1025px) {
    display: block;
  }
  
  @media (min-width: 1250px) {
    left: 100px;
  }
  
  svg { width: 150px; }
  
  ${props => props.fixed ? `
    transform: scale(1);
  ` : ``}
`;
