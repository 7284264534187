import React from 'react';
import getNested from 'get-nested';
import PropTypes from 'prop-types';
import FilterOption from '../ProductFilterOption/ProductFilterOption';
import Accordion from './styled/Accordion';
import { observer } from 'mobx-react';

const ProductFilterGroup = ({filter, handler, selectedFilters}) => {
  const filters = getNested(() => filter.filters, getNested(() => filter.options, false));
  const Options = filter.order.map((filterKey) => {
    const currentFilter = filters[filterKey];
    const options = getNested(() => currentFilter.options, false);

    if(options) {
      return currentFilter.order.map((key) => {
        const option = options[key];

        return <FilterOption
          handler={handler}
          key={`filter-${option.key}`}
          option={option}
          selected={selectedFilters.indexOf(option.key) > -1}
        />;
      });
    }
    return <FilterOption handler={handler} key={`filter-${currentFilter.key}`} option={currentFilter} selected={selectedFilters.indexOf(currentFilter.key) > -1} />;
  });

  if(Options.length === 1) {
    return false;
  }

  return (
    <Accordion title={filter.label} isOpen>{Options}</Accordion>
  );
};

ProductFilterGroup.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    filters: PropTypes.shape({}),
    options: PropTypes.shape({}),
  }).isRequired,
  handler: PropTypes.func.isRequired,
};
ProductFilterGroup.defaultProps = {};

export default observer(ProductFilterGroup);
