import React from 'react';
import PropTypes from 'prop-types';
// Styled components
import IntroWrapper from './styled/IntroWrapper';
import IntroInner from './styled/IntroInner';
import { Loader } from '../../../style-guide/Loader/Loader';

const StoreLocatorLoader = ({ show }) => {
  return (
    <IntroWrapper show={show}>
      <IntroInner>
        <Loader loading={show} complete={!show} />
      </IntroInner>
    </IntroWrapper>
  );
};

StoreLocatorLoader.propTypes = {
  show: PropTypes.bool.isRequired,
};

export { StoreLocatorLoader };
