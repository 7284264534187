import React from 'react';
import styled from 'styled-components';

export const SpecialActivityText =  styled.div`
  padding-left: 32px;
  
  &, p {
    font-size: 12px;
    line-height: 1.5;
    
    @media (min-width: 768px) {
    font-size: 16px;
    }
  }
`;
