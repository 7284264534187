import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import productBG from "../../../../shared-styles/productBG";

const imageSettings = {
  breakpoints: {
    mobile: {
      breakpoint: "",
      height: 486,
      width: 645,
    },
    tablet: {
      breakpoint: "768px",
      height: 486,
      width: 791,
    },
  },
};

export default styled(Slider)`
  .slick-slide {
    background-color: #ece8e4;
    text-align: center;
    width: 100%;
    ${(props) => productBG(imageSettings, props.isCoffee)}

    @media (min-width: 768px) {
      min-height: 480px;
    }

    .zoomImageWrapper {
      overflow: hidden;
      max-height: 480px;
      cursor: zoom-in !important;

      > div {
        display: inline-block;
        margin: 0 auto;
        cursor: zoom-in !important;

        > img {
          max-width: 100%;
          width: unset !important;
          display: inline-block;
          max-height: 480px;
        }
      }
    }

    .zoomImage {
      ${(props) => productBG(imageSettings, props.isCoffee)}
      overflow: visible!important;
    }
  }
`;
