import React from 'react';
import styled from 'styled-components';

export default styled.figcaption`
  font-size: 14px;
  margin: 0 auto;
  max-width: 600px;
  padding-left: 15px;
  text-transform: uppercase;
  width: 70%;
  
  @media (min-width: 768px) {
    width: auto;
    padding: 0;
  }
`;
