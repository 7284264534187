import React from 'react';
import { hydrate } from 'react-dom';
import { site } from 'hn-react';
import Root from './client/Root';

site.url = '';

const mount = (RootComponent) => {
    require('babel-polyfill');

    hydrate(
      <RootComponent />,
      root);
  };

  if(module.hot) {
    module.hot.accept('./client/Root', () => {
      // eslint-disable-next-line global-require,import/newline-after-import
      const RootComponent = require('./client/Root').default;
      mount(RootComponent);
    });
  }

  mount(Root);
