import React from 'react';
import styled from 'styled-components';

export default styled.input.attrs(props => ({
  type: 'text',
}))`
  ${({theme}) => theme.fonts.parse('sansSerif', 'regular', '16px')}
  border: 0;
  border-radius: 4px;
  color: #000;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  width: 100%;
`;
