import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export const CampaignCallToAction = styled.div`
  background-image: url(${(props) => props.imageUri});
  background-size: cover;
  background-position: center center;
  width: 100%;
  padding: 40px 6%;
  
  @media (min-width: 768px) {
    margin: 120px auto;
    padding: 90px 0;
  }

  div{
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  
    padding: 0px 6%;

    @media (min-width: 768px) {
      padding: 0;
    }

    h2{ 
      ${(props) => props.theme.fonts.parse('serif', 'medium', '24px')}
      color: #fff;

      @media (min-width: 768px) {
        ${(props) => props.theme.fonts.parse('serif', 'medium', '40px')}
      }
    }

    p{
      color: #fff;
      margin: 0 0 2em;
      ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '16px')}

      @media (min-width: 768px) {
        ${(props) => props.theme.fonts.parse('sansSerif', 'bold', '22px')}
      }
    }
  }
`;

export const CtaButton = styled.a`
  ${props => props.theme.fonts.parse('sansSerif', 'black', '13px')}
  background-color: ${davidoff.colors.orange};
  border: 1px solid ${davidoff.colors.orange};
  border-radius: 4px;
  color: #fff;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  letter-spacing: 1px;
  outline: none;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 300ms, color 300ms, border-color 300ms;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: inline-block;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    width: auto;
  }
  
  @media only screen and (min-width: 1200px) {
    min-width: 176px;
  }

  &:hover {
    background-color: #fff;
    border-color: ${davidoff.colors.brown};
    color: ${davidoff.colors.brown};
  }

`;
