import React from 'react';
import styled from 'styled-components';
import linkStyles from '../../../../shared-styles/link-styles.js';

export const SpecialActivityLink = styled.span`
  ${props => linkStyles.link(props)}

  font-size: 12px;
  line-height: 1.5;
  
  @media (min-width: 768px) {
    font-size: 16px;
    
    &:hover::after {
      margin-left: 15px;
    }
  }
`;
