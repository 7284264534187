import React from 'react';
import styled  from 'styled-components';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import Article from '../../../common/Article/styled/ArticleBlock';
import ArticleImageWrapper from '../../../common/Article/styled/ArticleImageWrapper';
import ArticleTitle from '../../../common/Article/styled/ArticleTitle';
import ArticleLink from '../../../common/Article/styled/ArticleLink';
import Columns from '../../../style-guide/Structure/Columns';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import davidoff from '../../../../themes/davidoff';

export default styled(ScrollEffect)`
  padding: 45px 0 20px;
  
  @media (min-width: 768px) {
   padding: 60px 0 30px;
  }

  ${Columns} {
    justify-content: center;
  }

  ${Article} {
    background-color: ${davidoff.colors.lime};
    padding: ${structureStyles.mobileValues.spacing};
    //opacity: 0;
    //transform: translateY(50px);
    
    @media (min-width: 768px) {
      ${structureStyles.flexItem.third};
      padding: ${structureStyles.desktopValues.spacing};
    }
  }
  
  ${ArticleImageWrapper} {
    margin-left: -${structureStyles.mobileValues.spacing};
    margin-right: -${structureStyles.mobileValues.spacing};
    margin-top: -${structureStyles.mobileValues.spacing};
    
    @media (min-width: 768px) {
      margin-left: -${structureStyles.desktopValues.spacing};
      margin-right: -${structureStyles.desktopValues.spacing};
      margin-top: -${structureStyles.desktopValues.spacing};
    }
  }
  
  ${ArticleTitle} {
    @media (max-width: 1023px) {
      margin-top: 16px;
      margin-bottom: 11px;
    }
    
    @media (min-width: 1024px) {
    }
  }
  
  ${ArticleLink} {
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
`;