import styled from "styled-components";
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../style-guide/Button/Button';
import davidoff from '../../../../themes/davidoff';

export default styled(Button.withComponent(Link))`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '16px')}
  background-color: rgba(255, 255, 255, .2);
  border: 0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 15px auto;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: border-color 300ms linear, color 300ms linear, background-color 300ms linear;
  width: auto;
  
  @media (min-width: 768px) {
    margin-top: 30px;
  }
  
  &:hover {
    @media (min-width: 1024px) {
      background-color: ${davidoff.colors.lightGrey};
      border-color: ${davidoff.colors.lightbrown};
      color: ${davidoff.colors.brown};
    }
  }
`;
