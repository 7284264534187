import React from 'react';
import styled from 'styled-components';

export default styled.div`
    width: 100%;
    
    @media only screen and (min-width: 500px) {
        width: 60%;
        float: right;
        padding-top: 10px;
    }
`;