import React from 'react';
import styled from 'styled-components';
import MediaImageContainer from '../../../common/MediaImage/index.c';

export default styled(MediaImageContainer)`
  display: block;
  width: 100%;
  
  img {
    max-width: 100%;
  }
`;
