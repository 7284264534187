import React from 'react';
import LdJson from 'react-ld-json';
import readingTime from 'reading-time';
import PropTypes from 'prop-types';
import getNested from 'get-nested';
import mapper, { dataMapper } from '../../index';
import SocialSharingComponent from '../../common/SocialSharingComponent/SocialSharingComponent';
import Intro from '../../style-guide/Intro/Intro';
import RelatedArticlesView from '../../common/RelatedArticlesView/RelatedArticlesView';
import ProductHighlight from '../../common/ProductHighlight/ProductHighlight'

class Article extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { page, data } = this.props;

    const header  = dataMapper(data, page.field_header.target_uuid)

    const mediaImage = dataMapper(data, getNested(() => header.field_media.target_uuid));

    const image = getNested(() => mediaImage.field_media_image.url);

    const articleIntroduction = page.field_body_summary;

    const articleText = page.field_article_content
      .map(item => {
        const entity = dataMapper(data, item.target_uuid);

        if (entity.field_body) {
          return entity.field_body.value.replace(/<[^>]*>?/gm, '');
        }

        return '';
      })
      .join('\n')

    const articleStats = readingTime(articleIntroduction + articleText);

    return (
      <div>
        <LdJson
          data={{
            '@context': 'https://schema.org/',
            '@type': 'Article',
            about: articleIntroduction,
            articleBody: `${articleIntroduction}\n${articleText}`,
            author: { '@id': '#main-organization' },
            creator: { '@id': '#main-organization' },
            dateCreated: page.created.value,
            dateModified: page.changed.value,
            datePublished: page.created.value,
            name: page.title,
            headline: page.title,
            inLanguage: 'NL',
            image,
            mainEntityOfPage: {
              '@id': '#main-webpage',
            },
            publisher: { '@id': '#main-organization' },
            text: `${articleIntroduction}\n${articleText}`,
            timeRequired: `PT${Math.ceil(articleStats.minutes || 0)}M`,
            wordCount: articleStats.wordCount,
          }}
        />
        {mapper(data, false, page.field_header.target_uuid)}
        <div className="content-container">
          {getNested(() => page.field_body_summary, false) &&
          <Intro>
            {page.field_body_summary}
          </Intro>
          }
          <div className='components'>
            { page.field_article_content.map(item => mapper(data, false, item.target_uuid)) }
          </div>
          <SocialSharingComponent shareText={page.title} />
        </div>
        <RelatedArticlesView page={page} data={data} title />
        <ProductHighlight data={data} />
      </div>
    );
  }
}

Article.propTypes = {
  page: PropTypes.shape({
    field_article_content: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    field_body_summary: PropTypes.string,
    field_header: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }).isRequired,
    field_related_stories: PropTypes.shape({
      target_uuid: PropTypes.string.isRequired,
    }),
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default Article;
