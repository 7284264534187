import React from 'react';
import styled from 'styled-components';
import { Container } from '../../../style-guide/Container/Container';

export const DisclaimerWrapper = styled.div`
  background: rgba(0,0,0,0.75);
  border-bottom: 1px solid #000;
  padding: 15px;
  
  ${Container}{
    opacity: 1;
    position: relative;
    padding-left: 0;
  }
 `;

export default DisclaimerWrapper;
