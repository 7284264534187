import React from 'react';
import styled from 'styled-components';

export default styled.div`
  display: none;
  left: 50%;
  padding-bottom: 56.25%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(16 / 9 * 100vh);
  z-index: 0;
  
  @media (min-width: 1025px) {
    display: block;
  }
  
  @media (min-width: 768px) and (max-aspect-ratio: 16/9){
    width: calc(16 / 9 * 100vh);
  }
  
  @media (min-width: 768px) and (min-aspect-ratio: 16/9){
    width: 100%;
  }

  &:after {
    background: transparent;
    content: '';
    display: none;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    transform: translateZ(0);
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  .ReactModal__Content & {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    display: block;
    max-width: 100%;
  }
`;
