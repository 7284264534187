import React from 'react';
import styled from 'styled-components';

export default styled.div`
  margin: 0 auto 20px;
  max-width: 800px;
  padding: 0 20px;
  position: relative;
  
  @media (min-width: 1024px) {
    margin-bottom: 60px;
    padding: 0;
  }
`;
