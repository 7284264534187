import React from 'react';
import styled from 'styled-components';

const ValidationMessage = styled.div`
  color: red;
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  transition: ease 0.35s linear;
  width: 100%;
  
  @media (min-width: 768px) {
    width: 600px;
  }
  
  ${props => props.show ? `display: block; opacity: 1;`: `display: none;  opacity:  0`}
`;

export default ValidationMessage;
