import React from 'react';
import PropTypes from 'prop-types';
import TabNavWrapper from './styled/TabNavWrapper';
import TabNavList from './styled/TabNavList';
import TabNavItem from './styled/TabNavItem';

const TabNav = ({navItems, changeHandler, activeTab}) => {
  const Tabs = navItems.map((navItem, idx) => {
    return <TabNavItem key={navItem.key} active={activeTab === idx}>
      <label htmlFor={navItem.key}>
        {navItem.title}
        <input type='radio' name='tab-nav-item' value={idx} checked={activeTab === idx} id={navItem.key} onChange={changeHandler} />
      </label>
    </TabNavItem>;
  });

  return (
    <TabNavWrapper>
      <TabNavList>
        {Tabs}
      </TabNavList>
    </TabNavWrapper>
  );
};

TabNav.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

TabNav.defaultProps = {};

export default TabNav;
