import React from 'react';
import PropTypes from 'prop-types';
import ImageHeader from '../../common/ImageHeader/Component';

export default function CTAButtonHeader(props) {
  return (
    <ImageHeader
      image={props.paragraph.field_media}
      data={props.data}
      scrollDownLabel={props.paragraph.field_no_link_anchor}
      cta={props.paragraph.field_link ? {
        label: props.paragraph.field_link.title,
        url: props.paragraph.field_link.uri
      } : undefined}
      videoUrl={props.paragraph.field_slow_motion_video ? props.paragraph.field_slow_motion_video .uri : undefined}
    />
  );
};

CTAButtonHeader.propTypes = {
  paragraph: PropTypes.shape({
    field_link: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      options: PropTypes.Array,
    }),
    field_no_link_anchor: PropTypes.string.isRequired,
    field_media: PropTypes.shape({
      target_id: PropTypes.number.isRequired,
      target_type: PropTypes.string.isRequired,
      target_uuid: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      }).isRequired,
    field_slow_motion_video: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};
