import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
// import structureStyles from '../../../shared-styles/structure-styles.js';

// TODO: when proper font is available, padding-top should be removed and value should be added to line-height

export default styled.button`
  ${(props) => props.theme.fonts.parse('sansSerif', 'black', '10px')}
  -webkit-appearance: none;
  backface-visibility: hidden;
  background-color: transparent;
  border: none;
  color: ${davidoff.colors.brown};
  line-height: 28px;
  padding: 2px 10px 0;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  white-space: nowrap;
  
  @media (min-width: 768px) {
    font-size: 14px;
  }
  
  &:hover {  
    &::before {
      transform: translateX(-2px) rotate(135deg);
    }
    
    &::after {
      transform: translateX(2px) rotate(-45deg);
    }
  }
  
  &::before, &::after {
    display: none;
    border-right: 2px solid ${davidoff.colors.brown};
    border-bottom: 2px solid ${davidoff.colors.brown};
    content: '';
    height: 4px;
    position: relative;
    top: 0;
    transition: all 0.2s ease-out;
    width: 4px;
    
    @media (min-width: 768px) {
      height: 6px;
      width: 6px;
    }
  }
  
  &::before {
    margin-right: 5px;
    transform: rotate(135deg);
    
    @media (min-width: 768px) {
      margin-right: 10px;
    }
  }
  
  &::after {
    margin-left: 5px;
    transform: rotate(-45deg);
    
    @media (min-width: 768px) {
      margin-left: 10px;
    }
  }
  
  ${props => props.invertArrow ? `
    left: 0;
    transform: translateX(-100%);
    
    &::before {
      display: inline-block;
    }
  `:`
    right: 0;
    transform: translateX(100%);
    
    &::after {
      display: inline-block;
    }
  `}
  
  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
  
  span {
    @media (max-width: 479px) {
      display: none;
    }
  }
`;
