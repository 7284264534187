import React from 'react';
import styled from 'styled-components';
import structureStyles from '../../../../shared-styles/structure-styles.js';


export default styled.nav`
  position: relative;
  display: inline-block;

  @media (min-width: 480px) {
    margin-left: 80px; 
    margin-right: 80px; 
  }
  
  @media (min-width: 768px) {
    margin-left: 120px; 
    margin-right: 120px; 
  }
`;
