import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';
import Accordion from '../../../common/Accordion/Accordion';
import AccordionHeading from '../../../common/Accordion/styled/AccordionHeading';
import AccordionBody from '../../../common/Accordion/styled/AccordionBody';
import AccordionItem from '../../../common/Accordion/styled/AccordionItem';

export default styled(Accordion)`
  border-top: 1px solid ${davidoff.colors.lightbrown};
  
  ${AccordionHeading} {
    ${(props) => props.theme.fonts.parse('sansSerif', 'bold')}
    height: 38px;
    line-height: 45px;
    margin: 0;
    
    @media (min-width: 1024px) {
      line-height: 40px;
    }
  }
  
  ${AccordionBody} {
    padding: 0;
  }
  
  ${AccordionItem} {
    color: #000;
    display: block;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    
    svg {
      border: 1px solid #8d7e71;
      border-radius: 100%;
      fill: #8d7e71;
      height: 20px;
      margin-right: 5px;
      padding: 4px;
      transform: rotate(90deg);
      transition: transform 300ms;
      position: absolute;
      right: 0;
      top: 13px;
      width: 20px;
      
      @media (min-width: 1024px) {
        border: 0;
        height: 13px;
        padding: 0;
        width: 13px; 
      }
    }
    
    &.open {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
`;
