import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProductWrapper from './styled/ProductWrapper';
import ProductImageWrapper from './styled/ProductImageWrapper';
import ProductImage from './styled/ProductImage';
import ProductTitle from './styled/ProductTitle';
import ProductSubTitle from './styled/ProductSubTitle';
import ProductLink from './styled/ProductLink';

const IndustryProduct = ({ title, alt, data, mediaReference, url, isCoffee }) => {
  const settings = {
    crop: 'fill',
    height: 180,
    width: 180,
  };

  const sources = [
    {
      settings,
      media: '(min-width: 0)',
    }
  ];

  return (
    <ProductWrapper>
      <ProductLink to={url}>
        <ProductImageWrapper isCoffee={isCoffee}>
          <ProductImage
            imageType="IndustryProduct"
            data={data}
            mediaReference={mediaReference}
            sources={sources}
            alt={alt}
            settings={settings}
            lazyLoad={false}
          />
        </ProductImageWrapper>
        <ProductTitle>{title.mainTitle}</ProductTitle>
        {/*<ProductSubTitle>{title.subTitle}</ProductSubTitle>*/}
      </ProductLink>
    </ProductWrapper>
  );
};

IndustryProduct.propTypes = {
  title: PropTypes.shape({
    mainTitle: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
  }).isRequired,
  mediaReference: PropTypes.shape({
    target_uuid: PropTypes.string,
  }),
  url: PropTypes.string.isRequired,
};
IndustryProduct.defaultProps = {

};

export default IndustryProduct;
