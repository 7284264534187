import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.a`
  background-color: #aea49b;
  border-radius: 100%;
  display: block;
  height: 32px;
  position: relative;
  transition: background-color 300ms ease-in-out;
  width: 32px;
  
  @media (min-width: 768px) {
    background-color: ${davidoff.colors.gray};
  }
  
  svg {
    bottom: 0;
    fill: #fff;
    left: 0;
    margin: auto;
    max-height: 16px;
    max-width: 16px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  &:hover {
    @media (min-width: 1024px) {
      background-color: ${davidoff.colors.darkGrey};
    }
  }
`;
