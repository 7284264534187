import React from 'react';
import styled from 'styled-components';
import productBG from '../../../../../../../shared-styles/productBG';

const imageSettings = {
  crop: 'fill',
  breakpoints: {
    mobile: {
      breakpoint: '',
      height: 111,
      width: 80,
    }
  }
};

export default styled.label`
  display: block;
  float: none;
  overflow: hidden;
  margin: 0 10px 10px 0;
  position: relative;
  min-width: 71px;
  max-width: 71px;
  width: auto;
  ${productBG(imageSettings)}
  
  &:after {
    background-color: #000;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  
  ${props => props.selected ? `
    &:after {
      opacity: 0.5;
    }
  ` : ``};
`;
