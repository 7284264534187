import React from 'react';
import styled from 'styled-components';
import structure from '../../../shared-styles/structure-styles';

export const Container = styled.div`
  ${structure.container}
  ${structure.containerPadding}
  
  ${(props) => props.small ? `
    max-width: 800px;
  ` : ``}
`;
