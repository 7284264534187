import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import ScrollEffect from '../../../common/ScrollEffect/ScrollEffect';
import ArticleBlock from '../../Article/styled/ArticleBlock';
import ArticleLink from '../../Article/styled/ArticleLink';
import ArticleTitle from '../../Article/styled/ArticleTitle';
import structureStyles from '../../../../shared-styles/structure-styles.js';
import davidoff from '../../../../themes/davidoff';

const startAnimationClass = '.animate';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const nudge = keyframes`
  0% {
    transform: translateY(5px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const hyphenBeforeGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 8px;
  }
`;

const hyphenAfterGrow = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 60px;
  }
`;

function fpsToMs(frames) {
  // 25 frames = 1000 ms
  // 1 frame = 40 ms
  return frames * 40;
}

const fadeInSlowAnimation= css`${fpsToMs(25)}ms ease ${fpsToMs(28)}ms forwards ${fadeIn}`;

const fadeInMediumAnimation = css`${fpsToMs(25)}ms ease ${fpsToMs(37)}ms forwards ${fadeIn}`;

const fadeInFastAnimation = css`${fpsToMs(25)}ms ease ${fpsToMs(44)}ms forwards ${fadeIn}`;

const fadeInVerySlowAnimation= css`${fpsToMs(26)}ms ease ${fpsToMs(18)}ms forwards ${fadeIn}`;

const nudgeSlowAnimation= css`${fpsToMs(30)}ms ease ${fpsToMs(38)}ms forwards ${nudge}`;

const nudgeMediumAnimation= css`${fpsToMs(30)}ms ease ${fpsToMs(47)}ms forwards ${nudge}`;

const nudgeLargeAnimation= css`${fpsToMs(30)}ms ease ${fpsToMs(54)}ms forwards ${nudge}`;

const hyphenBeforeGrowAnimation= css`${fpsToMs(8)}ms ease ${fpsToMs(15)}ms forwards ${hyphenBeforeGrow}`;

const hyphenAfterGrowAnimation= css`${fpsToMs(8)}ms ease ${fpsToMs(15)}ms forwards ${hyphenAfterGrow}`;

export default styled(ScrollEffect)`
  h2 {
    margin-left: 45px;
    max-width: 190px;
    padding-top: ${structureStyles.mobileValues.spacing};
    text-transform: uppercase;
    z-index: 1;
    
    @media (min-width: 768px) {
      margin-left: 70px;
      padding-top: 60px;
    }
    
    &:before,
    &:after {
      bottom: -8px;
      
      @media (min-width: 768px) {
        width: 0;
      }
    }
    
    span {
      @media (min-width: 768px) {
        opacity: 0;
      }
    }
  }
  
  ${ArticleBlock} {
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  
  @media (min-width: 768px) {
    ${ArticleBlock} {
      ${structureStyles.flexItem.third};
      opacity: 0;
      transform: translateY(50px);
    }
    
    ${ArticleTitle}, ${ArticleLink} {
      opacity: 0.5;
      transform: translateY(5px);
    }
    
    &${startAnimationClass} {
      ${ArticleBlock}:first-child {
        animation: ${fadeInSlowAnimation};
        
        ${ArticleTitle}, ${ArticleLink} {
          animation: ${nudgeSlowAnimation};
        }
      }
      
      ${ArticleBlock}:nth-child(2) {
        animation: ${fadeInMediumAnimation};
        
        ${ArticleTitle}, ${ArticleLink} {
          animation: ${nudgeMediumAnimation};
        }
      }
  
      ${ArticleBlock}:nth-child(3) {
        animation: ${fadeInFastAnimation};
        
        ${ArticleTitle}, ${ArticleLink} {
          animation: ${nudgeLargeAnimation};
        }
      }
      
      h2 {
        &:before {
          animation: ${hyphenBeforeGrowAnimation};
        }

        &:after {
          animation: ${hyphenAfterGrowAnimation};
          animation-fill-mode: forwards;
        }

        span {
          animation: ${fadeInVerySlowAnimation};
        } 
      }
    }
  }
  
  ${ArticleTitle} {
    @media (max-width: 767px) {
      margin: 10px 0 5px;
    }
  }
  
  ${ArticleLink} {
    display: block;
    
    @media (max-width: 767px) {
      font-size: 10px;
      
      &::after {
        height: 4px;
        width: 4px;
      }
    }
  }
`;
