import React from 'react';
import styled from 'styled-components';

export default styled.li`
  border-bottom: 1px solid #f1efec;
  display: block;
  
  @media only screen and (min-width: 1025px) {
    border: 0;
    display: inline-block;
  }
  
  &:last-child {
    border: 0;
    
    @media only screen and (min-width: 1025px) {
      padding-right: 15px;
    }
  }
`;
