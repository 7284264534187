import React from 'react';
import styled from 'styled-components';

export default styled.h2`
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 80%; 
  max-width: 800px;
`;
