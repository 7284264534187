import React from 'react';
import styled from 'styled-components';
import davidoff from '../../../../themes/davidoff';

export default styled.div`
  background-color: ${davidoff.colors.lime};
  max-width: 800px;
  padding: 20px 5%;
  
  @media (min-width: 768px) {
    margin: 60px auto;
    padding: 0 20px 20px;
  }
  
  @media (min-width: 1024px) {
    padding: 0 100px 60px;
  }
  
  h1 {
    @media (min-width: 768px) {
      top: -39px;
      padding-bottom: 10px;
      margin-bottom: 0;
    }
  }
`;
