import React from 'react';
import styled from 'styled-components';
import ProductBlock from './ProductBlock';
import ProductBlockComponent from '../../../ProductHighlight/styled/ProductBlock';
import MediaImageContainer from '../../../MediaImage/index.c';

export default styled(MediaImageContainer)`
  img, & {
    display: block;
    transition: transform 0.35s linear;
    transform: scale(1);
    transform-origin: center center;
    width: 100%;
    
    ${ProductBlock}:hover & {
      transition: transform 2s linear;
      transform: scale(1.1);
    }
     
    ${ProductBlockComponent}:hover & {
      transition: transform 2s linear;
      transform: scale(1.065);
    }
  }
`;
