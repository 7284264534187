import React, { Component } from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import getNested from 'get-nested';
import debounce from 'lodash.debounce';
import viewPort from '../../../lib/viewport';
import ResponsiveImage from '../../style-guide/Image/ResponsiveImage';
import MediaImageContainer from '../MediaImage/index.c';

class RatioImage extends Component {
  constructor(props) {
    super(props);
    this.aspectRatios = props.aspectRatios;
    const defaultRatio = getNested(() => props.aspectRatios.defaultRatio, 'mobile');

    this.state = {
      aspectRatio: props.aspectRatios[defaultRatio],
      hasError: false,
    };

    this.updateAspectRatio = debounce(this.updateAspectRatio.bind(this), 100);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.warn(error, info);
  }

  UNSAFE_componentWillMount() {
    if(root.document) {
      this.updateAspectRatio();
      root.addEventListener('resize', this.updateAspectRatio);
    }
  }
  // Cleanup eventlisteners
  componentWillUnmount() {
    root.removeEventListener('resize', this.updateAspectRatio);
  }

  updateAspectRatio() {
    let platform = 'mobile';
    // change the aspect ratio based on mobile
    if(viewPort().width >= 768) {
      platform = 'desktop';
    }
    this.setState({
      aspectRatio: this.aspectRatios[platform],
    });
  }

  render() {
    const { settings, ...props } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    const defaultSettings = {
      aspect_ratio: this.state.aspectRatio,
      crop: 'fill',
      width: 'auto',
      placeholder: '',
    };

    const mergedSettings = Object.assign({}, defaultSettings, settings)

    const sources = [
      {
        settings: mergedSettings,
        media: '(min-width: 0)',
      }
    ];

    return (
      <MediaImageContainer
        {...props}
        settings={mergedSettings}
        sources={sources}
      />
    );
  }
}

RatioImage.propTypes = {
  settings: PropTypes.shape().isRequired,
  aspectRatios: PropTypes.shape({
    mobile: PropTypes.string.isRequired,
    desktop: PropTypes.string.isRequired,
  }).isRequired,
};

export default RatioImage;
